import { Component, OnInit, ViewChild } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Services
import { TranslateService } from '@ngx-translate/core';
import { CoursesService } from 'src/app/core/services/courses';
import { LoginService } from 'src/app/core/services/login';
import { UsersService } from 'src/app/core/services/users';

// Models
import { CourseAssessmentModel } from 'src/app/core/models/courses';

@Component({
  selector: 'app-modal-curso-motivaciones-estudiantes',
  templateUrl: './modal-curso-motivaciones-estudiantes.component.html',
  styleUrls: ['./modal-curso-motivaciones-estudiantes.component.scss']
})
export class ModalCursoMotivacionesEstudiantesComponent implements OnInit {

  idCurso: number;

  valoraciones: CourseAssessmentModel[];
  valoracionesFiltradas: CourseAssessmentModel[];

  verTodos = true;
  cargando = false;

  constructor(public translateService: TranslateService,
              public coursesService: CoursesService,
              private loginService: LoginService,
              private usersService: UsersService,
              public activeModal: NgbActiveModal) {
  }

  ngOnInit() {

    this.cargando = true;

    this.obtenerDatosValoraciones(this.idCurso);

    this.mostrarComentarios('');

    this.cargando = false;
  }

  obtenerDatosValoraciones(idCurso) {
    this.valoraciones = [{ idValoracion: 1, idCourse: this.idCurso, fecha: '16/09/2020 17:51:16', valoracion: 4, nivel: 12.4, textoValoracion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum'},
                         { idValoracion: 2, idCourse: this.idCurso, fecha: '14/09/2020 09:10:37', valoracion: 3, nivel: 12.3, textoValoracion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
                         { idValoracion: 3, idCourse: this.idCurso, fecha: '13/09/2020 19:01:51', valoracion: 5, nivel: 11.6, textoValoracion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
                         { idValoracion: 4, idCourse: this.idCurso, fecha: '13/09/2020 12:44:21', valoracion: 3, nivel: 12.9, textoValoracion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
                         { idValoracion: 5, idCourse: this.idCurso, fecha: '11/09/2020 17:59:17', valoracion: 2, nivel: 12.3, textoValoracion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
                         { idValoracion: 6, idCourse: this.idCurso, fecha: '11/09/2020 16:42:23', valoracion: 5, nivel: 12.9, textoValoracion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
                         { idValoracion: 7, idCourse: this.idCurso, fecha: '10/09/2020 17:12:09', valoracion: 5, nivel: 11.2, textoValoracion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
                         { idValoracion: 8, idCourse: this.idCurso, fecha: '06/09/2020 13:32:29', valoracion: 3, nivel: 11.7, textoValoracion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
                         { idValoracion: 9, idCourse: this.idCurso, fecha: '05/09/2020 17:27:23', valoracion: 4, nivel: 11.6, textoValoracion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
                         { idValoracion: 10, idCourse: this.idCurso, fecha: '04/09/2020 19:29:49', valoracion: 1, nivel: 12.6, textoValoracion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
                         { idValoracion: 11, idCourse: this.idCurso, fecha: '04/09/2020 19:20:02', valoracion: 4, nivel: 12.7, textoValoracion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
                         { idValoracion: 12, idCourse: this.idCurso, fecha: '02/09/2020 17:05:19', valoracion: 4, nivel: 12.6, textoValoracion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
                         { idValoracion: 13, idCourse: this.idCurso, fecha: '01/09/2020 12:49:28', valoracion: 5, nivel: 11.3, textoValoracion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'},
                         { idValoracion: 14, idCourse: this.idCurso, fecha: '01/09/2020 11:26:16', valoracion: 4, nivel: 11.9, textoValoracion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}];
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);

    // tslint:disable-next-line: max-line-length
    //this.usersService.addUserLog(this.loginService.getUser().idUser, `Cerrar ventana modal - Curso motivaciones estudiantes`, 'OK');
  }

  mostrarComentarios(estrellas) {

    if (estrellas !== '') {

      this.verTodos = true;

      this.valoracionesFiltradas = this.valoraciones.filter(x => x.valoracion === estrellas);
    } else {
      this.verTodos = false;

      this.valoracionesFiltradas = this.valoraciones;
    }
  }
}
