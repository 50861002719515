export class CourseModel {
    idCourse: number;
    courseSWLevel: number;
    courseTittle: string;
    creationDate: string;
    deleteDate: string;
    description: string;
    editDate: string;
    user: UserModel;
    language: LanguageModel;
    modeType: string;
    nodeStart: number;
    normalAge: number;
    published: number;
    quality: number;
    secondaryLanguage: number;
    sonsNumber: number;
    cpicture: string;
    subject: SubjectModel;
    idCountry: string;
    languageData?: any;
    secondaryLanguageData?: any;

    constructor(level: number, title: string, idSubject: number, description: string, principalLang: number, secondaryLang: number, idUser: number, idCourse?: number, cpicture?: string,) {
        this.idCourse = idCourse || 0
        this.courseSWLevel = level || 0
        this.courseTittle = title || ''
        this.description = description || ''
        this.subject = {
            idSubject: idSubject || null
        }
        this.language = {
            idLanguage: principalLang || null
        }
        this.secondaryLanguage = secondaryLang || null

        this.user = {
            idUser: idUser
        }
        this.idCountry = 'ESP'
        this.cpicture = cpicture || null
    }
}

export interface SubjectModel {
    idSubject: number
}

export interface LanguageModel {
    idLanguage: number
}
export interface UserModel {
    idUser: number
}
