import { Component } from '@angular/core';
import { RegisterIconsService } from 'src/app/core/services/shared/register-icons.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent { 

  constructor(private iconService: RegisterIconsService) {}

  ngOnInit() {
    this.iconService.registerIcons();    
  }

}
