import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';

// Graph Model
import { gNode } from '../../models/graph/gNode.model';
import { gEdge } from '../../models/graph/gEdge.model';


// GraphQL
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { LoginService } from '../login/login.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { QuizModel } from '../../models/quizzes';
import { SIGMA_CONSTANTS } from '../../utils/sigma-constants';
import { NodeCoverPipe } from 'src/app/shared/pipes/node-cover.pipe';



@Injectable({
    providedIn: 'root'
})
export class GraphService {

    constructor(private apollo: Apollo, private datePipe: DatePipe, private loginService: LoginService, private http: HttpClient, private nodeCoverPipe: NodeCoverPipe) { }

    private queryGetTargetQuiz = gql`query ($idTarget: Int!, $idCourse: Int!, $idQuiz: Int!) {
        targetsQuizzes(where: { idTarget: $idTarget, idCourse: $idCourse, idQuiz: $idQuiz }) {
          nodes {
            idTargetQuiz
            idQuiz
            idCourse
            idTarget
            row
            columnX
          }
        }
      }`;

    returnQuizzesNodesFields = `{
        node {
            idNode
            tittle
            nodeSummary
            nodeSwlevel
            duration
            certificable
            idCourseCreation
            idTargetCreation
            author {
              nick
            }
            language {
              language
            }
            nodesNodes {
              idNodeNode
              idNode1
              idNode2
            }
          }
          row
          columnX
        }
      }
      targetsQuizzes(where: { idTarget: $id}) {
        nodes {
          idCourseTargetOrigin
          idTargetOrigin
          idQuiz
          row
          columnX
          quizz {
            stringCaptionsTitle
            quizzesNodes{
                idNodeQuiz
                idTarget
                idNode
                idQuiz
                ordinal
            }
            quizzesQuizzes{
                idQuizQuiz
                linkedIdQuiz
                myIdQuiz
                myIdCourse
                myIdTarget
            }
          }
      }`;

    returnQuizzesNodesField = `{
                idNodeQuiz
                idTarget
                idNode
                idQuiz
                ordinal
            }`;

    returNodesTargetsField = `{
                idNodeTarget
                idTargetA
                idNodeB
                ordinal
      }`;

    public graph;

    public newNodeId = new Subject();


    // ------------------------------------------------------------------------------
    //    G  E  T
    // ------------------------------------------------------------------------------

    // *** G R A P H ***
    /**
     * Recupera el grafo de un mapa y curso (nodos y quizzes)
     *
     * @param targetId Id del mapa.
     * @param courseId Id del curso.
     * @returns Un objeto:
     * - nodes: nodos/quizzes del grafo
     * - edges: conexiones entre nodos/quizzes
     */
    public graphFromTargetCreationID(targetId: string, courseId: string) {

        const variables = {
            id: parseInt(targetId)
        };


        const query = gql`query($id: Int!) {
      nodesTargets(where: { idTargetA: $id }) {
        nodes {
          node {
            idNode
            tittle
            nodeSummary
            nodeSwlevel
            duration
            certificable
            idCourseCreation
            idTargetCreation
            author {
              nick
            }
            language {
              language
            }
            nodesNodes {
              idNodeNode
              idNode1
              idNode2
            }
          }
          row
          columnX
        }
      }
      targetsQuizzes(where: { idTarget: $id }) {
        nodes {
            idCourseTargetOrigin
            idTargetOrigin
            idQuiz
            row
            columnX
            quizz {
                stringCaptionsTitle
                quizzesNodes {
                    idNodeQuiz
                    idTarget
                    idNode
                    idQuiz
                    ordinal
                }
                quizzesQuizzes{
                    idQuizQuiz
                    linkedIdQuiz
                    myIdQuiz
                    myIdCourse
                    myIdTarget
                }
            }
        }
      }
    }`;

        return this.query(query, variables).pipe(map(data => {

            var nodes: gNode[] = [];
            var edges: gEdge[] = [];

            // Added Node and Edge IDs
            var idNodeNodes = [];
            var idNodes = [];
            var idQuizzes = [];
            var idQuizzesNodes = [];
            var x, y;
            var author, language, badge;
            data.data.nodesTargets.nodes.forEach(nodeData => {
                nodeData.node.forEach((n) => {
                    // Coords.
                    y = nodeData.row ? parseInt(nodeData.row) : Math.floor((Math.random() * 100) + 1);
                    x = nodeData.columnX ? parseInt(nodeData.columnX) : Math.floor((Math.random() * 100) + 1);
                    author = (n.author && n.author.length > 0) ? n.author[0].nick : '';
                    language = (n.language && n.language.length > 0) ? n.language[0].language : '';
                    nodes.push({
                        id: 'n' + n.idNode.toString(),
                        idOriginal: n.idNode,
                        label: n.tittle,
                        x: x,
                        y: y,
                        badge: 0,
                        originalX: x,
                        originalY: y,
                        size: SIGMA_CONSTANTS.NODE_SIZE,
                        type: 'image',
                        url: SIGMA_CONSTANTS.IMAGE_DEFAULT,
                        originalColor: '#D7DBDC',
                        color: '#D7DBDC',
                        description: n.nodeSummary ? n.nodeSummary : '',
                        nodeType: 'Node',
                        nodeSwlevel: n.nodeSwlevel ? n.nodeSwlevel : 0,
                        duration: n.duration ? n.duration : 0,
                        idTargetCreation: n.idTargetCreation,
                        idCourseCreation: n.idCourseCreation,
                        //-------------
                        delete: false
                    });
                    idNodes.push(n.idNode);
                });
            });


            // QUIZZES
            data.data.targetsQuizzes.nodes.forEach((quizzData) => {
                quizzData.quizz.forEach((q) => {
                    if (!idQuizzes.includes(quizzData.idQuiz)) {

                        y = quizzData.row ? parseInt(quizzData.row) : Math.floor((Math.random() * 100) + 1);
                        x = quizzData.columnX ? parseInt(quizzData.columnX) : Math.floor((Math.random() * 100) + 1);

                        nodes.push({
                            id: 'q' + quizzData.idQuiz.toString(),
                            idOriginal: quizzData.idQuiz,
                            label: '', // q.stringCaptionsTitle,
                            x: x,
                            y: y,
                            originalX: x,
                            originalY: y,
                            size: 8,
                            type: 'text',
                            text: q.stringCaptionsTitle,
                            //url: SIGMA_CONSTANTS.IMAGE_DEFAULT,
                            originalColor: '#FFFFFF',
                            color: '#FFFFFF',
                            description: '',
                            nodeType: 'Quiz',
                            nodeSwlevel: 0,
                            duration: 0,
                            idCourseCreation: quizzData.idCourseTargetOrigin,
                            idTargetCreation: quizzData.idTargetCreation,
                            // ------------------------
                            delete: false,
                            hidden: this.loginService.esEstudiante()
                            //backgroundColor: ''
                        });
                        idQuizzes.push(quizzData.idQuiz);
                    }
                });
            });

            // Se recorre de nuevo porque en la bbdd hay conexiones de nodos que no existen.
            data.data.nodesTargets.nodes.forEach(nodeData => {
                nodeData.node.forEach((n) => {
                    if (n.nodesNodes) {
                        n.nodesNodes.forEach(e => {
                            if ((idNodes.includes(e.idNode1) && idNodes.includes(e.idNode2))) {
                                if (!idNodeNodes.includes('n' + e.idNode1 + '_n' + e.idNode2)) {
                                    //idNodeNodes.push(e.idNodeNode);
                                    idNodeNodes.push('n' + e.idNode1 + '_n' + e.idNode2);
                                    edges.push({
                                        id: 'n' + e.idNode1.toString() + '_n' + e.idNode2.toString(),
                                        source: 'n' + e.idNode1.toString(),
                                        target: 'n' + e.idNode2.toString(),
                                        originalType: e.type ? e.type : 'branch',
                                        type: e.type ? e.type : 'branch',
                                        color: '#D7DBDC',
                                        size: e.size ? e.size : 2,
                                        idOriginal: e.idNodeNode,
                                        connectionType: 'NodesNodes',
                                        delete: false
                                    });
                                }
                            }
                        });
                    }
                });
            });

            // Se recorre de nuevo porque en la bbdd hay conexiones de nodos que no existen.
            data.data.targetsQuizzes.nodes.forEach(quizzData => {
                quizzData.quizz.forEach((q) => {
                    if (q.quizzesNodes) {
                        q.quizzesNodes.forEach(e => {
                            if ((idQuizzes.includes(quizzData.idQuiz) && idNodes.includes(e.idNode))) {
                                if (!idQuizzesNodes.includes('q' + quizzData.idQuiz + '_n' + e.idNode)) {
                                    //idNodeNodes.push(e.idNodeNode);
                                    idQuizzesNodes.push('q' + quizzData.idQuiz + '_n' + e.idNode);
                                    edges.push({
                                        id: 'q' + quizzData.idQuiz.toString() + '_n' + e.idNode.toString(),
                                        source: 'q' + quizzData.idQuiz.toString(),
                                        target: 'n' + e.idNode.toString(),
                                        originalType: 'line',
                                        type: 'line',
                                        color: '#D7DBDC',
                                        size: 1.5,
                                        idOriginal: e.idNodeQuiz,
                                        connectionType: 'QuizzesNodes',
                                        delete: false
                                    });
                                }
                            }
                        });
                    }
                });
            });

            // Se recorre de nuevo porque en la bbdd hay conexiones de nodos que no existen.
            data.data.targetsQuizzes.nodes.forEach(quizzData => {
                quizzData.quizz.forEach((q) => {
                    if (q.quizzesQuizzes) {
                        q.quizzesQuizzes.forEach(e => {
                            if ((idQuizzes.includes(quizzData.idQuiz) && idQuizzes.includes(e.linkedIdQuiz) && (quizzData.idQuiz == e.myIdQuiz)
                                && this.loginService.esAutor())) {
                                if (!idQuizzesNodes.includes('q' + quizzData.idQuiz + '_q' + e.linkedIdQuiz)) {
                                    idQuizzesNodes.push('q' + quizzData.idQuiz + '_q' + e.linkedIdQuiz);
                                    edges.push({
                                        id: 'q' + quizzData.idQuiz.toString() + '_q' + e.linkedIdQuiz.toString(),
                                        source: 'q' + quizzData.idQuiz.toString(),
                                        target: 'q' + e.linkedIdQuiz.toString(),
                                        originalType: 'line',
                                        type: 'line',
                                        color: '#D7DBDC',
                                        size: 1.5,
                                        idOriginal: e.idQuizQuiz,
                                        connectionType: 'QuizzesQuizzes',
                                        delete: false
                                    });
                                }
                            }
                        });
                    }
                });
            });

            //Recorrer los nodos y asignar false a la propiedad hidden del quiz para que se muestre en el grafo.
            this.showQuizzes(edges, nodes);
            return {
                nodes: nodes,
                edges: edges
            };
        }));
    }

    public getNodesFromCourseGraph(courseId: number, graphId: number): Observable<any> {
        return this.http.get<any>(`courses/${courseId}/graphs/${graphId}/nodes`)
            .pipe(
                map(res => (res.data as gNode[]).map(
                    n => {
                        return (
                            {
                                ...n,
                                originalX: n.x,
                                originalY: n.y,
                                color: '#D7DBDC',
                                originalColor: '#D7DBDC',
                                type: 'circle',
                                image: {
                                    url: this.nodeCoverPipe.transform(n.pictureNode, n.imageNode),
                                    clip: SIGMA_CONSTANTS.IMAGE_CLIP,
                                    scale: SIGMA_CONSTANTS.IMAGE_SCALE
                                },
                                url: this.nodeCoverPipe.transform(n.pictureNode, n.imageNode),
                                size: SIGMA_CONSTANTS.NODE_SIZE,
                                zindex: 0,
                                power3: n.ordinalPower3 > 0 ? true : false,
                                power2: n.ordinalPower2 > 0 ? true : false,
                                power1: n.ordinalPower1 > 0 ? true : false,
                                power0: n.ordinalPower0 > 0 ? true : false,
                                powerNegative1: n.ordinalPowerNegative1 > 0 ? true : false,
                                powerNegative2: n.ordinalPowerNegative2 > 0 ? true : false,
                                powerNegative3: n.ordinalPowerNegative3 > 0 ? true : false,
                            }
                        );
                    }
                )),
                map((res: gNode[]) => {
                    const edges: any[] = [...res.map(n => n.edges ? (n.edges as any[]) : []).flat()];

                    return ({ nodes: [...res], edges: edges })
                })
            );
    }

    public getQuizzesFromCourseGraph(courseId: number, graphId: number): Observable<any> {
        return this.http.get<any>(`courses/${courseId}/graphs/${graphId}/quizzes`)
            .pipe(
                map(res => (res.data as QuizModel[]).map(
                    q => (
                        {
                            ...q,
                            originalX: q.x,
                            originalY: q.y,
                            type: 'text',
                            size: SIGMA_CONSTANTS.QUIZ_SIZE,
                            zindex: 0
                        }
                    )
                )),
                map((res: any[]) => {
                    const edges: any[] = [...res.map(n => n.edges ? (n.edges as any[]) : []).flat()];

                    return ({ nodes: [...res], edges: edges })
                })
            );
    }
    // *** N O D E ***
    public getNode(id) {
        const variables = {
            id: parseInt(id)
        };

        const query = gql`query($id: Int!) {
        nodes(where: { idNode: $id })  {
          nodes {
            idNode
            tittle
            nodeSummary
            nodeSwlevel
            duration
            certificable
            idCourseCreation
            idTargetCreation
            creationDateString
            lastEditionDateString
            author {
              nick
            }
            language {
              language
            }
            nodesFiles {
                idNodeFile
                textFile
                audioFile
                videoFile
              }
          }
        }
    }`;

        var node;
        return this.query(query, variables).pipe(map(data => {
            if (data.data.nodes.length == 0) {

                return Object.assign({ tittle: '', nodeSummary: '', author: '', language: '', }, this.emptyDataNode());
            }
            data.data.nodes.nodes.forEach((n) => {

                var author = (n.author && n.author.length > 0) ? n.author[0].nick : '';
                var language = (n.language && n.language.length > 0) ? n.language[0].language : '';
                var files = (n.nodesFiles && n.nodesFiles.length > 0) ? n.nodesFiles[0] : {
                    videoFile: '',
                    audioFile: '',
                    textFile: '',
                    pictureFile: ''
                }; // Falta crear pdfFILE
                node = n;
                node.author = author;
                node.language = language;
                node.videoFile = files.videoFile;
                node.audioFile = files.audioFile;
                node.pictureFile = files.pictureFile;
                node.textFile = files.textFile;
            }
            );
            return node;
        }));
    }

    // *** N O D E ***
    public emptygNode() {
        return {
            id: 'temp',
            label: '',
            x: 0,
            y: 0,
            originalX: 0,
            originalY: 0,
            size: 1,
            originalColor: '#D7DBDC',
            color: '#D7DBDC',
            description: '',
            nodeType: 'Node',
            nodeSwlevel: 0,
            duration: 0,
            delete: false
        }
    }

    // *** N O D E ***
    public emptyDataNode() {
        var date = this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss');
        return {
            certificable: false,
            language1: '',
            language2: '',
            author: '',
            video: false,
            image: false,
            audio: false,
            pdf: false,
            textfile: false,
            creation: date,
            edition: date,
            idCourseCreation: 0,
            idTargetCreation: 0
        }
    }



    // *** Q U I Z ***
    /**
     * No se usa
     *
     * @deprecated
     * @param id
     * @returns
     */
    public async getQuiz(id) {
        const variables = {
            id: parseInt(id)
        };

        var query = gql`query($id: Int!) {
            nodes(where: { idNode: $id })  {
                nodes {
                    idQuiz
                    idAuthor
                    stringCaptionsTitle
                    quizSwlevel
                    duration
                    idQuizFather
                    idQuizMother
                }
            }
        }`;
        var quiz;
        return await this.query(query, variables).toPromise().then(data => {
            data.data.nodes.quizzes.forEach((q) => {
                quiz = q;
            });
            return quiz;
        });
    }

    /**
     *
     * @param idTarget
     * @param idCourse
     * @param idQuiz
     * @returns
     */
    public async getTargetQuiz(idTarget, idCourse, idQuiz) {

        const variables = {
            idTarget: idTarget,
            idQuiz: idQuiz,
            idCourse: idCourse
        };

        var targetQuiz;
        return await this.query(this.queryGetTargetQuiz, variables).toPromise().then(data => {
            data.data.targetsQuizzes.nodes.forEach((tq) => {
                targetQuiz = tq;
            });
            return targetQuiz;
        });
    }

    // ------------------------------------------------------------------------------
    //   D B    A C T I O N S
    // ------------------------------------------------------------------------------

    // *** N O D E ***
    // C R E A T E
    public async createNode(node, targetId, courseId) {

        var mutation = gql`mutation {
                          createNode ( nodes: {
                                idNode: 0,
                                tittle: "${node.label}",
                                nodeSummary: "${node.description}",
                                nodeSwlevel: ${node.nodeSwlevel},
                                duration: ${parseInt(node.duration)},
                                certificable: ${node.certificable ? 1 : 0},
                                creationDateString: "${this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss').toString()}" ,
                                lastEditionDateString: "${this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss').toString()}",

                                idTargetCreation: ${parseInt(targetId)}
                            }) {idNode}
        }`; //  idCourseTargetA: ${parseInt(courseId)}

        var result = await this.mutate(mutation).toPromise().then((result));
        var idNodeB = result.data.createNode.idNode;
        mutation = gql`mutation {
            createNodesTargets ( nodesTargets: {
                idNodeTarget: 0,
                row: ${Math.trunc(node.y)},
                columnX: ${Math.trunc(node.x)},
                idTargetA: ${parseInt(targetId)},
                idNodeB: ${idNodeB},
                idCourseTargetA:${parseInt(courseId)},
                idCourseTargetOrigin:${parseInt(courseId)},
                idTargetOrigin:${parseInt(targetId)},
                connectionsText: ""
            }) { idNodeTarget}
        }`; //  idCourseTargetA: ${parseInt(courseId)}

        await this.mutate(mutation).toPromise();

        this.newNodeId.next({
            id: 'n' + idNodeB,
            label: node.label,
            x: node.x,
            y: node.y,
            originalX: node.x,
            originalY: node.y,
            type: node.type,
            url: node.url,
            size: 8,
            originalColor: '#D7DBDC',
            color: '#D7DBDC',
            description: node.description,
            nodeType: 'Node',
            nodeSwlevel: node.nodeSwlevel,
            duration: node.duration,
            delete: false,
            paste: false,
        });
    }

    // *** N O D E ***
    // U P D A T E
    public async updateNode(node) {

        var mutation = gql`mutation {
                updateNode (
                    nodes: {
                        idNode: ${node.idOriginal},
                        tittle: "${node.label}",
                        nodeSummary: "${node.description}",
                        nodeSwlevel: ${node.nodeSwlevel},
                        duration: ${parseInt(node.duration)},
                        certificable: ${node.certificable ? 1 : 0},
                        lastEditionDateString: "${this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss').toString()}"
                    })
                }`;
        await this.apollo.mutate({
            mutation: mutation
        }).toPromise().then(y => {
            console.log("NODO ACTUALIZADO")
        });

        var id = node.idOriginal;
        // Comprobar si tiene registro en la tabla nodesFiles:
        const query = gql`query($id: Int!) {
            nodesFiles(where: { idNode: $id })  {
                nodes {
                    idNodeFile
                    }
                }
            }`;

        const variables = { id: parseInt(id) };
        var idNodeFile;
        this.query(query, variables).pipe(map(data => {
            data.data.nodes.nodesFiles.forEach((nf) => {
                idNodeFile = nf.idNodeFile;
            });
        }));

        console.log("buscando nodefile", idNodeFile, "picture", node.pictureFile, "node", node)
        mutation = gql`mutation {
            createNodesFiles ( nodesFiles: {
                    idNode: ${node.idOriginal},
                    idNodeFile: ${idNodeFile ? idNodeFile : 0},
                    videoFile: "${node.videoFile ? node.videoFile : ''}",
                    audioFile: "${node.audioFile ? node.audioFile : ''}",
                    pictureFile: "${node.pictureFile ? node.pictureFile : ''}",
                    textFile: "${node.textFile ? node.textFile : ''}"
                }) {idNodeFile}
            }`;
        await this.apollo.mutate({
            mutation: mutation
        }).toPromise().then(y => {
            console.log("FICHEROS DE NODO ACTUALIZADO")
        });


    }


    // *** N O D E - N O D E  ( C O N N E C T I O N S ) ***
    // all
    public async updateNodesNodes(edges) {

        // edges.filter((e) => e.connectionType === 'NodesNodes').forEach(async edge => {
        var data = edges.filter((e) => e.connectionType === 'NodesNodes');
        var edge;
        for (edge of data) {
            //console.log('-->', edge.idOriginal)
            if (edge.delete === true) {
                if (edge.idOriginal) {
                    await this.deleteNodeNode(edge.idOriginal);
                }
            } else {
                if (!edge.idOriginal) {
                    await this.createNodeNode(edge);
                } else {
                    await this.updateNodeNode(edge);
                }

            }

        }
        return true;
    }

    // *** N O D E - N O D E  ( C O N N E C T I O N S ) ***
    // D E L E T E
    private deleteNodeNode(id) {
        var mutation = gql`mutation {
      deleteNodesNodes ( id: ${id} )
    }`;
        return this.mutate(mutation).toPromise();
    }


    // *** N O D E - N O D E  ( C O N N E C T I O N S ) ***
    // U P D A T E
    private updateNodeNode(edge) {
        var mutation = gql`mutation {
                      updateNodesNodes ( nodesNodes: {
                          idNodeNode: ${edge.idOriginal},
                          idNode1: ${this.parseId(edge.source)},
                          idNode2: ${this.parseId(edge.target)}
                      })
          }`;
        return this.mutate(mutation).toPromise();
    }

    // *** N O D E - N O D E  ( C O N N E C T I O N S ) ***
    // C R E A T E
    private async createNodeNode(edge) {
        var mutation = gql`mutation {
                      createNodesNodes ( nodesNodes: {
                            idNodeNode: 0,
                            idNode1: ${parseInt(this.parseNodeId(edge.source))},
                            idNode2: ${parseInt(this.parseNodeId(edge.target))}
                        }) { idNodeNode }
    }`;
        return await this.mutate(mutation).toPromise();
    }

    // *** N O D E - T A R G E T  ( M A P S ) ***
    // all
    public async updateNodesTargets(nodes, targetId, courseId) {
        var mutation, query;
        console.log("Start Update Nodes Targets")
        for (let node of nodes) {
            if (node.delete === true) {
                /* if (node.idOriginal) {
                     mutation = gql`mutation {
                                     deleteNodesTargets ( id: ${node.idOriginal} )
                                 }`;
                     await this.apollo.mutate({
                         mutation: mutation
                     }).toPromise().then(y => {
                     });
                 }*/

                /*Find Node Target */
                query = gql`query($ta: Int!, $id: Int!) {
                    nodesTargets(where: { idTargetA: $ta, idNodeB : $id }) {
                        nodes {
                            idNodeTarget
                        }
                    }
                }`;
                var variables = {
                    id: this.parseId(node.id),
                    ta: parseInt(targetId),
                    cta: parseInt(courseId)
                };

                var nodesTargets = this.apollo.query<any>({
                    query: query,
                    variables: variables
                });

                await nodesTargets.toPromise().then(async data => {
                    let idNodeTarget = parseInt(data.data.nodesTargets.nodes[0]["idNodeTarget"]);
                    if (idNodeTarget) {
                        mutation = gql`mutation {
                                                deleteNodesTargets ( id: ${idNodeTarget} )
                                            }`;
                        await this.apollo.mutate({
                            mutation: mutation
                        }).toPromise().then(y => {
                        });
                    }
                    if (node.idOriginal) {
                        mutation = gql`mutation {
                                    deleteNode ( id: ${node.idOriginal} )
                                   }`;
                        await this.apollo.mutate({
                            mutation: mutation
                        }).toPromise().then(y => {
                        });
                    }
                });
            }
            else {
                if (!node.idOriginal) {
                    this.createNode(node, targetId, courseId);
                } else {
                    if (!node.paste) {
                        // Aquí al existir ya lo único que hace es actualizar su posición en el grafo
                        var variables = {
                            id: this.parseId(node.id),
                            ta: parseInt(targetId),
                            cta: parseInt(courseId)
                        };
                        query = gql`query($ta: Int!, $id: Int!) {
                                nodesTargets(where: { idTargetA: $ta, idNodeB : $id }) {
                                    nodes {
                                        idNodeTarget
                                    }
                                }
                            }`;
                        var nodesTargets = this.apollo.query<any>({
                            query: query,
                            variables: variables
                        });
                        await nodesTargets.toPromise().then(async data => {
                            let idNodeTarget = parseInt(data.data.nodesTargets.nodes[0]["idNodeTarget"]);
                            mutation = gql`mutation {
                                    updateNodesTargets (
                                        nodesTargets: {
                                            idNodeTarget: ${idNodeTarget}
                                            row: ${parseInt(node.y)},
                                            columnX: ${parseInt(node.x)}
                                        })
                                    }`;
                            await this.apollo.mutate({
                                mutation: mutation
                            }).toPromise().then(y => {
                            });
                        });
                    } else {
                        //Aqui lo que hace como es una copia del nodo, solamente crea la relacion entre nodo y grafo en la BD
                        mutation = gql`mutation {
                            createNodesTargets ( nodesTargets: {
                                idNodeTarget: 0,
                                row: ${Math.trunc(node.y)},
                                columnX: ${Math.trunc(node.x)},
                                idTargetA: ${parseInt(targetId)},
                                idNodeB: ${node.idOriginal},
                                idCourseTargetA:${parseInt(courseId)},
                                idCourseTargetOrigin:${parseInt(node.idCourseCreation)},
                                idTargetOrigin:${parseInt(node.idTargetCreation)},
                                connectionsText: "",
                                ordinal: ${0}
                            }) { idNodeTarget}
                        }`;
                        await this.apollo.mutate({
                            mutation: mutation
                        }).toPromise().then(y => {
                        });
                    }

                }
            }
        }
        console.log("End Update Nodes Targets")
        return true;
    }

    // ====================================================

    // *** Q U I Z - N O D E  ( C O N N E C T I O N S ) ***
    // all
    /**
     *
     * @param edges Se encarga de borrar, actualizar y crear las relaciones entre quizzes y nodos
     * @param idTarget
     * @returns
     */
    public async updateQuizzesNodes(edges, idTarget) {
        var data = edges.filter((e) => e.connectionType === 'QuizzesNodes');
        var edge;
        var mutation;
        console.log('Start Update Quizes Nodes')

        for (edge of data) {


            if (edge.delete === true) {
                if (edge.idOriginal) {
                    mutation = gql`mutation {
                                    deleteQuizzesNodes ( id: ${edge.idOriginal} )
                                }`;
                    await this.apollo.mutate({
                        mutation: mutation
                    }).toPromise().then(y => {
                    });
                }
            } else {
                if (!edge.idOriginal) {
                    mutation = gql`mutation {
                                     createQuizzesNodes ( quizzesNodes: {
                                        idNodeQuiz: 0,
                                        idQuiz: ${this.parseId(edge.source)},
                                        idNode: ${this.parseId(edge.target)},
                                        ordinal:${0}
                                     }) { idNodeQuiz }
                                   }`;
                    await this.apollo.mutate({
                        mutation: mutation
                    }).toPromise().then(y => {
                    });
                } else {
                    mutation = gql`mutation {
                                     updateQuizzesNodes ( quizzesNodes: {
                                        idNodeQuiz: ${edge.idOriginal},
                                        idQuiz: ${this.parseId(edge.source)},
                                        idNode: ${this.parseId(edge.target)},
                                        certified: false,
                                        firstNode: false
                                      })
                                    }`;
                    await this.apollo.mutate({
                        mutation: mutation
                    }).toPromise().then(y => {
                    });
                }
            }
        }
        console.log('End Update Quizes Nodes')
        return true;
    }

    // *** Q U I Z - Q U I Z  ( C O N N E C T I O N S ) ***
    public async updateQuizzesQuizzes(edges) {
        var data = edges.filter((e) => e.connectionType === 'QuizzesQuizzes');
        var edge;
        var mutation;
        console.log('Start Update Quizzes Quizzes')
        for (edge of data) {
            if (edge.delete === true) {
                if (edge.idOriginal) {
                    mutation = gql`mutation {
                                    deleteQuizzesQuizzes ( id: ${edge.idOriginal} )
                                }`;
                    await this.apollo.mutate({
                        mutation: mutation
                    }).toPromise().then(y => {
                    });
                }
            } else {
                if (!edge.idOriginal) {
                    mutation = gql`mutation {
                                     createQuizzesQuizzes ( quizzesQuizzes: {
                                        idQuizQuiz: 0,
                                        myIdQuiz: ${this.parseId(edge.target)},
                                        linkedIdQuiz: ${this.parseId(edge.source)}
                                     }) { idQuizQuiz }
                                   }`;
                    await this.apollo.mutate({
                        mutation: mutation
                    }).toPromise().then(y => {
                    });
                } else {
                    mutation = gql`mutation {
                                     updateQuizzesQuizzes ( quizzesQuizzes: {
                                        idQuizQuiz: ${edge.idOriginal},
                                        myIdQuiz: ${this.parseId(edge.target)},
                                        linkedIdQuiz: ${this.parseId(edge.source)}
                                      })
                                    }`;
                    await this.apollo.mutate({
                        mutation: mutation
                    }).toPromise().then(y => {
                    });
                }
            }
        }
        console.log('End Update Quizzes Quizzes')
        return true;
    }

    // *** Q U I Z - T A R G E T  ( M A P S ) ***
    // all
    public async updateTargetsQuizzes(quizzes, targetId, courseId) {
        var mutation, query;
        console.log("Start Update TargetsQuizzes")
        for (let node of quizzes) {
            if (node.delete === true) {
                /*if (node.idOriginal) {
                    mutation = gql`mutation {
                                    deleteTargetsQuizzes ( id: ${node.idOriginal} )
                                }`;
                    await this.apollo.mutate({
                        mutation: mutation
                    }).toPromise().then(y => {
                    });
                }*/
                var variables = {
                    id: parseInt(this.parseQuizId(node.id)),
                    ta: parseInt(targetId),
                    cta: parseInt(courseId)
                }
                query = gql`query($ta: Int!, $id: Int!) {
                        targetsQuizzes(where: { idTarget: $ta, idQuiz : $id }) {
                            nodes {
                                idTargetQuiz
                            }
                        }
                    }`;
                var targetsQuizzes = this.apollo.query<any>({
                    query: query,
                    variables: variables
                });
                await targetsQuizzes.toPromise().then(async data => {
                    let idTargetsQuiz = parseInt(data.data.targetsQuizzes.nodes[0]["idTargetQuiz"]);
                    if (idTargetsQuiz) {
                        mutation = gql`mutation {
                                        deleteTargetsQuizzes ( id: ${idTargetsQuiz} )
                                    }`;
                        await this.apollo.mutate({
                            mutation: mutation
                        }).toPromise().then(y => {
                        });
                    }

                    if (node.idOriginal) {
                        mutation = gql`mutation {
                                    deleteQuizzes ( id: ${node.idOriginal} )
                                }`;
                        await this.apollo.mutate({
                            mutation: mutation
                        }).toPromise().then(y => {
                        });
                    }

                    if (node.idOriginal) {
                        /*Eliminar la relacion Template Quiz*/
                        var variables = {
                            id: parseInt(node.idOriginal)
                        }

                        query = gql`query($id: Int!) {
                                templatesQuizzes(where: {idQuiz : $id }) {
                                nodes {
                                    idTemplateQuizz
                                }
                            }
                        }`;

                        var templatesQuizzes = this.apollo.query<any>({
                            query: query,
                            variables: variables
                        });

                        await templatesQuizzes.toPromise().then(async data => {
                            if (data.data.templatesQuizzes.nodes.length > 0) {
                                let arrayTemplatesQuizzes = data.data.templatesQuizzes.nodes;
                                for (let templateQuiz of arrayTemplatesQuizzes) {
                                    let idTemplateQuiz = parseInt(templateQuiz.idTemplateQuizz);
                                    mutation = gql`mutation {
                                        deleteTemplatesQuizzes ( id: ${idTemplateQuiz})
                                    }`;
                                    await this.apollo.mutate({
                                        mutation: mutation
                                    }).toPromise().then(y => {
                                    });
                                }
                            }
                        });
                    }
                });
            } else {
                if (!node.idOriginal) {
                    mutation = gql`mutation {
                                        createTargetsQuizzes ( nodesNodes: {
                                            idNodeTarget: 0,
                                            idTarget: ${parseInt(targetId)},
                                            idCourse: ${parseInt(courseId)},
                                            columnX: ${parseInt(node.x)},
                                            row: ${parseInt(node.y)}
                                        }) { idTargetQuiz }
                                    }`;
                    await this.apollo.mutate({
                        mutation: mutation
                    }).toPromise().then(y => {
                    })
                } else {
                    var variables = {
                        id: parseInt(this.parseQuizId(node.id)),
                        ta: parseInt(targetId),
                        cta: parseInt(courseId)
                    }
                    query = gql`query($ta: Int!, $id: Int!) {
                            targetsQuizzes(where: { idTarget: $ta, idQuiz : $id }) {
                                nodes {
                                    idTargetQuiz
                                }
                            }
                        }`;
                    var targetsQuizzes = this.apollo.query<any>({
                        query: query,
                        variables: variables
                    });
                    await targetsQuizzes.toPromise().then(async data => {
                        let idTargetsQuiz = parseInt(data.data.targetsQuizzes.nodes[0]["idTargetQuiz"]);
                        mutation = gql`mutation {
                                updateTargetsQuizzes (
                                    targetsQuizzes: {
                                        idTargetQuiz: ${idTargetsQuiz},
                                        columnX: ${parseInt(node.x)},
                                        row: ${parseInt(node.y)}
                                    })
                                }`;
                        await this.apollo.mutate({
                            mutation: mutation
                        }).toPromise().then(y => {
                        });
                    });
                }
            }
        }
        console.log("End Update TargetsQuizzes")
        return true;
    }

    public async updateOrCreateTargetQuiz(idTarget, idCourse, quiz) {

        var targetQuiz = await this.getTargetQuiz(idTarget, idCourse, quiz.idOriginal).then((targetQuiz));
        var mutation;
        console.log("targetQuiz : ", targetQuiz)
        if (!targetQuiz) { // CREATE
            console.log("ID COURSE", idCourse)
            mutation = gql`mutation {
                createTargetsQuizzes ( targetsQuizzes: {
                    idTargetQuiz: 0,
                    idTarget: ${idTarget},
                    idQuiz: ${quiz.idOriginal},
                    idCourse: ${idCourse},
                    columnX: ${Math.trunc(quiz.x)},
                    row: ${Math.trunc(quiz.y)}
                }) { idTargetQuiz }
            }`;
        } else { //UPDATE
            mutation = gql`mutation {
                updateTargetsQuizzes ( targetsQuizzes: {
                    idTargetQuiz: ${targetQuiz.idTargetQuiz},
                    idTarget: ${idTarget},
                    idQuiz: ${quiz.idOriginal},
                    idCourse: ${idCourse},
                    columnX: ${Math.trunc(quiz.x)},
                    row: ${Math.trunc(quiz.y)}
                })
            }`;
        }

        var variables = {
            idTarget: idTarget,
            idCourse: idCourse,
            idQuiz: quiz.idOriginal
        }

        await this.apollo.mutate({
            mutation: mutation,
            refetchQueries: [{
                query: this.queryGetTargetQuiz,
                variables: variables
            }]
        }).toPromise().then(y => {
        })

    }

    // *** Q U I Z - N O D E (ORDINALES) ***
    public async updateOrdinalQuizzesNodes(edges, idTarget) {
        var data = edges.filter((e) => e.connectionType === 'QuizzesNodes');
        var edge;
        var mutation;
        var arrayEdges: any[] = [];
        console.log('Start Update Quizzes Nodes ')

        for (edge of data) {
            arrayEdges.push(edge);
        };

        //for (edge of data) {
        if (!edge.idOriginal) {
            var idNode;
            if (edge.source.includes('n')) {
                idNode = this.parseId(edge.source);
            } else if (edge.target.includes('n')) {
                idNode = this.parseId(edge.target);
            }

            /*var refetchquery = gql`query($filter: QuizzesNodesFilter) {
                quizzesNodes(
                    where: $filter
                    ) {
                       nodes ${this.returnQuizzesNodesField}
                    }
            }`;*/

            const variables = {
                id: parseInt(idTarget)
            };

            var refetchquery = gql`query($id: Int!) {
                    nodesTargets(where: { idTargetA: $id }) {
                        nodes ${this.returnQuizzesNodesFields}
                    }
                }`;

            const filter = { idNode: idNode };
            await this.apollo.query<any>({
                query: gql`query($filter: QuizzesNodesFilter) {
                    quizzesNodes(
                         where: $filter
                       ) {
                         nodes ${this.returnQuizzesNodesField}
                       }
                  }`,
                fetchPolicy: 'network-only',
                variables: {
                    filter: filter
                }
            }).subscribe((data) => {
                var maxValue: number = 0;
                var lastValue: number = 0;
                var arraySort: any[] = [];
                var lastNodeQuiz: any;
                if (data.data.quizzesNodes.nodes.length > 0) {
                    maxValue = Math.max.apply(null, data.data.quizzesNodes.nodes.map(function (o) { return o.ordinal; }));
                    var array = data.data.quizzesNodes.nodes.filter((nq) => nq.ordinal === 0);
                    arraySort = array.sort(function (a, b) {
                        return a["idNodeQuiz"] < b["idNodeQuiz"];
                    });

                    arraySort.forEach(nd => {
                        lastNodeQuiz = nd;
                        maxValue = maxValue + 1;
                        mutation = gql`mutation {
                            updateQuizzesNodes(quizzesNodes:{
                                idNodeQuiz: ${lastNodeQuiz.idNodeQuiz},
                                idTarget: ${lastNodeQuiz.idTarget},
                                idNode: ${parseInt(lastNodeQuiz.idNode)},
                                idQuiz: ${parseInt(lastNodeQuiz.idQuiz)},
                                ordinal: ${maxValue}
                             })
                           }`;
                        this.apollo.mutate({
                            mutation: mutation,
                            refetchQueries: [{
                                query: refetchquery,
                                variables: variables
                            }]
                        }).toPromise().then(y => {
                            for (edge of arrayEdges) {
                                if (edge.id.includes('q' + lastNodeQuiz.idQuiz)) {
                                    edge.idOriginal = lastNodeQuiz.idNodeQuiz;
                                }
                            }
                            console.log("Finally updateOrdinalQuizzesNodes");
                        });
                    });
                }
            });
        }

        console.log('End Update Quizzes Nodes Ordinales')
        return true;
    }

    // *** N O D E - T A R G E T (ORDINALES) ***
    public async updateOrdinalNodesTargets(nodes, idTarget, idCourse) {
        var mutation;
        var node;
        var arrayNodes: any[] = [];
        console.log('Start Update Nodes Target')

        /*for (node of nodes) {
            arrayNodes.push(node);
        }*/

        //if (!node.idOriginal) {
        const variables = {
            idTargetA: idTarget
        };

        var refetchquery = gql`query($filter: NodesTargetsFilter) {
                    nodesTargets(
                        where: $filter
                        ) {
                           nodes ${this.returNodesTargetsField}
                        }
                }`;

        /*query = gql`query($ta: Int!, $id: Int!) {
            nodesTargets(where: { idTargetA: $ta, idNodeB : $id }) {
                nodes {
                    idNodeTarget
                }
            }
         }`;*/

        const filter = { idTargetA: idTarget };
        await this.apollo.query<any>({
            query: gql`query($filter: NodesTargetsFilter) {
                    nodesTargets(
                         where: $filter
                       ) {
                         nodes ${this.returNodesTargetsField}
                       }
                  }`,
            fetchPolicy: 'network-only',
            variables: {
                filter: filter
            }
        }).subscribe((data) => {
            var maxValue: number = 0;
            var arraySort: any[] = [];
            var lastNodeTarget: any;
            if (data.data.nodesTargets.nodes.length > 0) {
                maxValue = Math.max.apply(null, data.data.nodesTargets.nodes.map(function (o) { return o.ordinal; }));
                var array = data.data.nodesTargets.nodes.filter((nq) => (nq.ordinal === 0 || nq.ordinal === null));
                arraySort = array.sort(function (a, b) {
                    return a["idNodeTarget"] < b["idNodeTarget"];
                });
                arraySort.forEach(nt => {
                    lastNodeTarget = nt;
                    maxValue = maxValue + 1;
                    mutation = gql`mutation {
                            updateNodesTargets(nodesTargets:{
                                idNodeTarget: ${lastNodeTarget.idNodeTarget},
                                idTargetA: ${parseInt(lastNodeTarget.idTargetA)},
                                idNodeB: ${parseInt(lastNodeTarget.idNodeB)},
                                ordinal: ${maxValue}
                             })
                           }`;
                    this.apollo.mutate({
                        mutation: mutation,
                        refetchQueries: [{
                            query: refetchquery,
                            variables: variables
                        }]
                    }).toPromise().then(y => {
                        /*for(node of arrayNodes){
                            if(node.id.includes('' + lastNodeTarget.idNodeB)){
                                node.idOriginal=lastNodeTarget.idNodeB;
                            }
                        }*/
                        console.log("Finally updateOrdinalNodesTargets");
                    });
                });
            }
        });
        //}
        console.log('End Update Nodes Targets Ordinales')
        return true;
    }

    /*public graphFromSubjects(){

           var query = gql`{
       subjects {
         nodes {
           idSubject
           subject1
         }
       }
     }`;

           var nodes: gNode[] = [];
           var edges: gEdge[] = [];
           var cont = 1;
           return this.query(query, {}).pipe(map(data => {
               data.data.subjects.nodes.forEach((subject) => {

                   var L = 10,
                       N = 100,
                       E = 500;

                   var x = L * Math.cos(Math.PI * 2 * cont / N - Math.PI / 2);
                   var y = L * Math.sin(Math.PI * 2 * cont / N - Math.PI / 2);
                   cont++;
                   nodes.push({
                       id: 's' + subject.idSubject.toString(),
                       idOriginal: 0,
                       label: '',
                       x: x,
                       y: y,
                       originalX: x,
                       originalY: y,
                       size: 8,
                       type: 'text',
                       text: subject.subject1,
                       originalColor: '#D7DBDC',
                       color: '#D7DBDC',
                       description: subject.subject1,
                       nodeType: 'Quiz',
                       nodeSwlevel: 0,
                       duration: 0,
                       delete: false
                       //backgroundColor: ''
                   });
               });
               return {
                   nodes: nodes,
                   edges: edges
               };
           }));
    }*/


    // --------------------------------------
    //  P A R S E
    // --------------------------------------
    private parseQuizId(id) {
        return id.split("q", 2)[1];
    }

    private parseNodeId(id) {
        return id.split("n", 2)[1];
    }

    private parseId(id) {
        if (id.split("q", 2).length > 1) {
            return parseInt(id.split("q", 2)[1]);
        } else if (id.split("n", 2).length > 1) {
            return parseInt(id.split("n", 2)[1]);
        } else {
            return 0;
        }
    }



    public async setGraphColor(nodes, user) {
        if (nodes) {
            for (var i = 0; i < nodes.length; i++) {
                if (nodes[i].nodeType === "Node") {
                    var datos = await this.getColorScore(user.idUser, nodes[i].idOriginal).toPromise().then(datos);
                    var score = datos.data.usersNodes.nodes[0] == undefined ? 0 : datos.data.usersNodes.nodes[0].score;
                    if (score >= 20 && score < 40) {
                        nodes[i].originalColor = '#ff0000';
                        nodes[i].color = '#ff0000';
                    }
                    else if (score >= 40 && score < 60) {
                        nodes[i].originalColor = '#ffff00';
                        nodes[i].color = '#ffff00';

                    }
                    else if (score >= 60 && score < 80) {
                        nodes[i].originalColor = '#90ee90';
                        nodes[i].color = '#90ee90';
                    }
                    else if (score >= 80 && score <= 100) {
                        nodes[i].originalColor = '#006400';
                        nodes[i].color = '#006400';
                    }
                    else {
                        nodes[i].originalColor = '#808080';
                        nodes[i].color = '#808080';

                    }

                    datos = await this.getColorOperators(nodes[i].idOriginal).toPromise().then(datos);
                    var allOperators = datos.data.nodesOperator.nodes[0] == undefined ? 0 : datos.data.nodesOperator.nodes;
                    var operatorsCount =
                        [
                            { name: 'ABSTRACT', key: 0 },
                            { name: 'TEMPORARY', key: 0 },
                            { name: 'ASSOSTATIC', key: 0 },
                            { name: 'SENMOTEMO', key: 0 },
                        ];
                    for (var h = 0; h < allOperators.length; h++) {
                        var index = operatorsCount.findIndex(x => x.name === allOperators[h].operator[0].level);
                        operatorsCount[index].key += 1;
                    }
                    var maxVal = Math.max.apply(Math, operatorsCount.map(function (o) { return o.key; }));
                    nodes[i].operator = operatorsCount.find(x => x.key === maxVal).name;
                }
            }

        }

        return nodes;
    }

    private getColorScore(idUser, idNode) {
        var query = gql`query($iu: Int!,$in: Int!) {
            usersNodes(where: { idUser: $iu, idNode: $in }){
        nodes{
          score
        }
      }
      }`;

        var variables = {
            iu: idUser,
            in: idNode
        }
        return this.query(query, variables);

    }

    private getColorOperators(idNode) {
        var query = gql`query($in: Int!) {
                nodesOperator(where: { idNode: $in }) {
                  nodes {
                   idOperator
                    operator{
                      level
                    }
                  }
            }
      }`;

        var variables = {
            in: idNode
        }
        return this.query(query, variables);

    }
    public async getBadges(nodes) {
        for (var i = 0; i < nodes.length; i++) {
            nodes[i].badge = await this.getBadge(nodes[i].idOriginal)
        };
        return nodes;
    }

    public async getBadge(idNode) {
        var query = gql`query($idNodeB: Int!) {
            nodesTargets(where: { idNodeB: $idNodeB }) {
              nodes {
                idCourseTargetA
                }
        }
      }`;

        var variables = {
            idNodeB: idNode
        }

        return this.query(query, variables).toPromise().then(data => {
            var count = 0;
            data.data.nodesTargets.nodes.forEach((q) => {
                count += 1;
            });
            return count === 1 ? 0 : count;
        });
    }

    // public async getPowers(nodes, graphId: number) {
    //     for (var i = 0; i < nodes.length; i++) {
    //         await this.getPowersById(nodes[i], graphId)
    //     };
    //     return nodes;
    // }

    // public async getPowersById(node, target) {
    //     var query = gql`query($idNode: Int!, $idTarget: Int!) {
    //         powerNodeTarget(where: { idNode: $idNode, idTarget: $idTarget }) {
    //           nodes{
    //             idPowerNodeTarget
    //             idTarget
    //             orderPower0
    //             orderPower1
    //             orderPower2
    //             orderPowerNegative1
    //             orderPowerNegative2
    //             idNode
    //           }
    //     }
    //   }`;
    //     var variables = {
    //         idNode: node.idOriginal,
    //         idTarget: target
    //     }
    //     return this.query(query, variables).toPromise().then(data => {
    //         data.data.powerNodeTarget.nodes.forEach((q) => {
    //             node.idPowerNodeTarget = q.idPowerNodeTarget;
    //             node.ordinalPower2 = q.orderPower2;
    //             node.power2 = node.ordinalPower2 > 0 ? true : false;
    //             node.ordinalPower1 = q.orderPower1;
    //             node.power1 = node.ordinalPower1 > 0 ? true : false;
    //             node.ordinalPower0 = q.orderPower0;
    //             node.power0 = node.ordinalPower0 > 0 ? true : false;
    //             node.ordinalPowerNegative1 = q.orderPowerNegative1;
    //             node.powerNegative1 = node.ordinalPowerNegative1 > 0 ? true : false;
    //             node.ordinalPowerNegative2 = q.orderPowerNegative2;
    //             node.powerNegative2 = node.ordinalPowerNegative2 > 0 ? true : false;
    //         });
    //     });
    // }
    // public modeAutomaticOn(){
    //     this.isStudyModeAutomatic.next(true);
    // }

    // public modeAutomaticOff(){
    //     this.isStudyModeAutomatic.next(false);
    // }


    // --------------------------------------
    //  Q U E R Y
    // --------------------------------------
    private query(query, variables) {

        return this.apollo.query<any>({
            query: query,
            variables: variables
        },
        );
    }

    private mutate(mutation) {

        return this.apollo.mutate({
            mutation: mutation
        });
    }

    private showQuizzes(edges: any, nodes: any) {
        edges.forEach(edge => {
            nodes.forEach(node => {
                if (edge.source == node.id) {
                    node.hidden = false;
                }
            });
        });
    }

    public saveBackgroundImage(graphId: number, file: File) {
        const form: FormData = new FormData()

        form.append('files', file)
        return this.http.post(`graphs/${graphId}/targetImage`, form);
    }

}
