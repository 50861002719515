import { Injectable } from '@angular/core';

// Service
import { MapperService } from './mapper.service';

// Model
import { UserProfileValidationModel } from '../../models/users';

@Injectable()
export class UsersProfileValidationMapperService extends MapperService<UserProfileValidationModel, any> {

    public map(data: UserProfileValidationModel): any {
        return {
          idUserProfileValidation: data.idUserProfileValidation,
          idUser: data.idUser,
          idUserStudentParent: data.idUserStudentParent,
          idUserTeacherCenter: data.idUserTeacherCenter,
          idUserEmployeerEnterprise: data.idUserEmployeerEnterprise,
          ip: data.ip,
          uuid: data.uuid,
          temporaryPassword: data.temporaryPassword,
          messageSms: data.messageSms,
          attempNumbers: data.attempNumbers,
          sendDate: data.sendDate,
          sendDateString: data.sendDateString,
          validationDate: data.validationDate,
          validationDateString: data.validationDateString
        };
    }
}
