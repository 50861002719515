import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Operator } from 'src/app/core/models/operators/operator.model';

@Component({
    selector: 'app-operators-table',
    templateUrl: './operators-table.component.html',
    styleUrls: ['./operators-table.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OperatorsTableComponent implements OnInit {
    @Input() operators: Operator[] = [];
    @Input() type: string;

    @Output() operatorClicked: EventEmitter<Operator> = new EventEmitter<Operator>();

    constructor() { }

    ngOnInit() {
    }

    selectOperator(operator: Operator) {
        this.operatorClicked.emit(operator);
    }
}
