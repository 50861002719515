import { Injectable } from '@angular/core';

// Service
import { MapperService } from './mapper.service';

// Model
import { UserAuthorModel } from '../../models/users/user-author.model';

@Injectable()
export class UsersAuthorsMapperService extends MapperService<UserAuthorModel, any> {

    public map(data: UserAuthorModel): any {

      return {
        idUserAuthor: data.idUserAuthor,
        idUser: data.idUser,
        bic: data.bic,
        iban: data.iban,
        creationDate: data.creationDate,
        creationDateString: data.creationDateString
      };
    }
}
