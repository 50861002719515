import { User } from './../../../../core/models/users/user.models';
import { CourseTargetModel } from './../../../../core/models/courses/course-target.model';
import { ImagenPipe } from './../../../pipes/imagen.pipe';
import { SnackbarService } from './../../../../core/services/shared/snackbar.service';
import { ErrorStateMatcher } from '@angular/material';
import { IdiomaModel } from './../../../../core/models/masters/idioma.model';
import { NivelModel } from './../../../../core/models/masters/nivel.model';
import { MateriaModel } from 'src/app/core/models/masters';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, NgForm, AbstractControl, FormGroupDirective } from '@angular/forms';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Models
import { CourseModel } from 'src/app/core/models/courses';

// Services
import { TranslateService } from '@ngx-translate/core';
import { CoursesService } from 'src/app/core/services/courses';
import { TargetsService } from 'src/app/core/services/targets';
import { LoginService } from 'src/app/core/services/login';
import { MastersService } from 'src/app/core/services/masters';
import { AlertService } from 'src/app/core/services/shared';

// Components
import { ModalEditarCursoMapaComponent } from 'src/app/shared/components/cursos/modal-editar-curso-mapa';
import { ModalCursoLenguajeComponent } from 'src/app/shared/components/cursos/modal-curso-lenguaje';
import { ModalCursoNivelesComponent } from 'src/app/shared/components/cursos/modal-curso-niveles';
import { ModalCursoTiempoComponent } from 'src/app/shared/components/cursos/modal-curso-tiempo';
import { ModalCursoMotivacionesComponent } from 'src/app/shared/components/cursos/modal-curso-motivaciones';
import { ModalSubirImagenComponent } from 'src/app/shared/components/cursos/modal-subir-imagen';
import { ModalCursoAutoresComponent } from 'src/app/shared/components/cursos/modal-curso-autores';

import { ModalAceptarCancelarComponent } from 'src/app/shared/components/modal';

function langMatcher(c: AbstractControl): { [key: string]: boolean } | null {
    const lang1Control = c.get('lang1');
    const lang2Control = c.get('lang2');

    if (lang1Control.pristine || lang2Control.pristine) {
        return null;
    }

    if (lang1Control.value !== lang2Control.value) {
        return null;
    }


    return { 'match': true };
}

export class ParentGroupValidationStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && (control.dirty || control.touched));
        const invalidParent = !!(control && (control.dirty || control.touched) && control.parent && control.parent.invalid && control.parent.dirty);

        return (invalidCtrl || invalidParent);
    }
}

@Component({
    selector: 'app-modal-editar-curso',
    templateUrl: './modal-editar-curso.component.html',
    styleUrls: ['./modal-editar-curso.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        ImagenPipe
    ]
})
export class ModalEditarCursoComponent implements OnInit {

    public formCourse: FormGroup;

    id: string;
    curso: CourseModel
    courseTargets: CourseTargetModel[] = []
    idImage: string = ''

    user: User
    saving: boolean = false

    matcher = new ParentGroupValidationStateMatcher();

    // Maestros
    materias: MateriaModel[] = [];
    niveles: NivelModel[] = [];
    idiomas: IdiomaModel[] = [];

    cargando: boolean = false;

    scrolled: boolean = false;

    validationMessages = {
        title: [],
        description: [],
        subject: [],
        lang1: [],
        lang2: [],
        level: [],
        image: []
    };

    constructor(public router: Router,
        public translateService: TranslateService,
        public coursesService: CoursesService,
        public targetsService: TargetsService,
        private mastersService: MastersService,
        public loginService: LoginService,
        private alertService: AlertService,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private snackBar: SnackbarService,
        private imagePipe: ImagenPipe
    ) {
    }

    updateUrl(event: any) {
        event.target.src = '../../assets/images/no-image.png'
    }

    ngOnInit() {

        this.cargando = true;
        this.user = this.loginService.getUser()

        this.createForm()

        // Obtengo los maestros
        this.obtenerDatosMaestros();

        if (this.id !== 'nuevo') {
            // Si no es nuevo busco sus datos
            this.obtenerDatosCurso(this.id);
        }
        else
            this.cargando = false;

        this._translateText()


    }

    isMine(): boolean {
        if (this.loginService.esAutor() && this.user.idUser === this.curso.user.idUser)
            return true
        return false
    }

    private _translateText() {
        this.translateService.get('VALIDACIONES.TITLEREQUIRED').subscribe((res: string) => {
            this.validationMessages.title.push({ type: 'required', message: res });
        });

        this.translateService.get('VALIDACIONES.DESCRIPTIONREQUIRED').subscribe((res: string) => {
            this.validationMessages.description.push({ type: 'required', message: res });
        });

        this.translateService.get('VALIDACIONES.SUBJECTREQUIRED').subscribe((res: string) => {
            this.validationMessages.subject.push({ type: 'required', message: res });
        });

        this.translateService.get('VALIDACIONES.LANG1REQUIRED').subscribe((res: string) => {
            this.validationMessages.lang1.push({ type: 'required', message: res });
        });

        this.translateService.get('VALIDACIONES.LEVELREQUIRED').subscribe((res: string) => {
            this.validationMessages.level.push({ type: 'required', message: res });
        });

        this.translateService.get('VALIDACIONES.IMAGETREQUIRED').subscribe((res: string) => {
            this.validationMessages.image.push({ type: 'required', message: res });
        });

        this.translateService.get('VALIDACIONES.LANGMATCH').subscribe((res: string) => {
            this.validationMessages.lang2.push({ type: 'match', message: res });
        });

    }


    createForm() {

        this.formCourse = this.fb.group({
            image: [{ value: '', disabled: false }],
            title: ['', Validators.compose([Validators.required])],
            description: ['', Validators.compose([Validators.required])],
            subject: ['', Validators.compose([Validators.required])],
            level: [''],
            langGroup: this.fb.group({
                lang1: ['', Validators.compose([Validators.required])],
                lang2: [''],
            }, { validator: langMatcher }),
            published : [false]
        })
    }

    get imageControl(): FormControl {
        if (this.formCourse)
            return (this.formCourse.get('image') as FormControl)
        else
            return null
    }

    get titleControl(): FormControl {
        if (this.formCourse)
            return (this.formCourse.get('title') as FormControl)
        else
            return null
    }

    get descriptionControl(): FormControl {
        if (this.formCourse)
            return (this.formCourse.get('description') as FormControl)
        else
            return null
    }

    get langGroup(): FormGroup {
        if (this.formCourse) {
            return (this.formCourse.get('langGroup') as FormGroup);
        } else {
            return null;
        }
    }

    get lang1Control(): FormControl {
        if (this.langGroup) {
            return (this.langGroup.get('lang1') as FormControl);
        } else {
            return null;
        }
    }

    get lang2Control(): FormControl {
        if (this.langGroup) {
            return (this.langGroup.get('lang2') as FormControl);
        } else {
            return null;
        }
    }

    get levelControl(): FormControl {
        if (this.formCourse)
            return (this.formCourse.get('level') as FormControl)
        else
            return null
    }



    obtenerDatosMaestros() {
        // Load subjects
        this.mastersService.getAllSubjects().subscribe(data => { this.materias = data });
        // Load languages
        this.mastersService.getAllLanguages().subscribe(data => { this.idiomas = data; });
        //Load levels
        this.mastersService.getAllLevels().subscribe(data => { this.niveles = data })
    }


    obtenerDatosCurso(id) {

        this.cargando = true;

        this.coursesService.getCourseById(id).subscribe(response => {
            this.curso = response.data.courses // Course detail
            this.courseTargets = response.data.coursesTarget // Course graphs


            this.formCourse.patchValue({
                title: this.curso.courseTittle || '',
                description: this.curso.description || '',
                subject: this.curso.subject.idSubject || 0,
                level: this.curso.courseSWLevel || 0,
                langGroup: {
                    lang1: this.curso.language.idLanguage || 0,
                    lang2: this.curso.secondaryLanguage || ''
                },
                image: this.curso.cpicture ? this.imagePipe.transform(this.curso.cpicture, 'cursos') : '',
                published : this.curso.published ? true  : false
            })

            if (!this.isMine())
                this.formCourse.disable()

            this.cargando = false;
        });
    }

    /**
     * Publish or unpublish course
     * @param $ev Click event in the switch component
     */
    publish($ev){
        $ev.preventDefault()
        $ev.stopImmediatePropagation()
        let currentValue:boolean = !this.formCourse.get('published').value
        let modalMessage:string = currentValue ? this.translateService.instant('EDITARCURSO.PUBLISHMSG') : this.translateService.instant('EDITARCURSO.UNPUBLISHMSG')
        let errorMessage:string = currentValue ? this.translateService.instant('GENERAL.KOPUBLISH') : this.translateService.instant('GENERAL.KOUNPUBLISH')
        let okMessage:string = currentValue ? this.translateService.instant('GENERAL.OKPUBLISH') : this.translateService.instant('GENERAL.OKUNPUBLISH')

        //Open modal message alert to confirm the selection

        const modalRef = this.modalService.open(ModalAceptarCancelarComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-60'
            });


        modalRef.componentInstance.mensaje = modalMessage

        modalRef.result.then((result: boolean) => {
            if (result) {
                this.mastersService.setPublishType('course',Number(this.id),currentValue).subscribe(result => {
                    if(result.data){
                        this.snackBar.success(okMessage)
                        this.formCourse.get('published').setValue(currentValue) // Update form value
                    }                        
                    else
                        this.snackBar.error(this.translateService.instant('EDITARCURSO.KOPUBLISHCONTENT'))                        
                }, err => {
                    this.snackBar.error(errorMessage)
                })
                 
            }
        });
    }

    // GRABAR CURSO
    grabarCurso() {



        if (this.formCourse.valid) {
            let image: File | string = ''

            this.saving = true
            const v = this.formCourse.value
            let request: CourseModel = new CourseModel(v.level, v.title, v.subject, v.description, v.langGroup.lang1, v.langGroup.lang2, this.user.idUser)

            if (this.id !== 'nuevo') {
                request.idCourse = this.curso.idCourse
                //Si el curso no tiene imagen
                if (this.curso.cpicture == '' || this.curso.cpicture == null) {
                    if (typeof v.image === 'object')
                        image = v.image
                }
                else { //Si el curso tiene imagen
                    if (typeof v.image == 'string' && v.image != '')
                        request.cpicture = this.curso.cpicture
                    else if (typeof v.image == 'object') {
                        request.cpicture = this.curso.cpicture
                        image = v.image
                    }
                    else if (typeof v.image == 'string' && v.image == '') {
                        request.cpicture = null
                    }
                }
            }
            else{
                image = v.image
            }

            request.published = v.published ? Date.now() : null

            this.coursesService.setCourse(request, image).subscribe(result => {
                //Show an alert message
                this.snackBar.success(this.translateService.instant('EDITARCURSO.OKSAVE'))
                this.saving = false
                this.obtenerDatosCurso(result.data.idCourse)
                this.id = result.data.idCourse.toString()
                //this.closeModal('Curso guardado')
            }, error => {
                this.snackBar.error(this.translateService.instant('EDITARCURSO.KOSAVE'))
                this.saving = false
            })
        }


    }
    // FIN GRABAR CURSO

    // BORRAR CURSO
    borrarCurso() {
        if (!this.loginService.esAutor()) {
            return;
        }

        // Abro un modal preguntando si desea borrar el curso o no
        const modalRef = this.modalService.open(ModalAceptarCancelarComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-60'
            });


        modalRef.componentInstance.mensaje = this.translateService.instant('EDITARCURSO.DESEABORRARCURSO');

        modalRef.result.then((result: boolean) => {
            // Si devuelve true lo borro y si devuelve false no hago nada

            if (result) {

                this.coursesService.deleteCourse(this.curso.idCourse)
                    .subscribe((resp: any) => {
                        // Cierro la modal del detalle del curso
                        this.activeModal.close('Curso borrado');
                        this.snackBar.success(this.translateService.instant('EDITARCURSO.OKDELETE'))
                    }, (error) => {
                        this.snackBar.error(this.translateService.instant('EDITARCURSO.KODELETE'))
                    });
            }
        });
    }
    // FIN BORRAR CURSO

    closeModal(sendData) {
        this.activeModal.close(sendData);
    }
    // FIN CURSO

    // MOTIVACIONES
    mostrarMotivaciones() {
        if (!this.loginService.esAutor()) {
            return;
        }

        const modalRef = this.modalService.open(ModalCursoMotivacionesComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-65'
            });

        // tslint:disable-next-line: max-line-length
        //this.usersService.addUserLog(this.loginService.getUser().idUser, `Abrir ventana modal - Mostrar motivaciones - Editar curso`, 'OK');

        modalRef.componentInstance.idCurso = this.curso.idCourse;
        modalRef.componentInstance.valoracionEstudiante = 4.42;
        modalRef.componentInstance.emitidaAutor = 4.36;

        modalRef.result.then((result) => {
            // No hago nada
        }, (reason) => {
        });
    }
    // FIN MOTIVACIONES

    // NIVELES EDAD
    mostrarNivelesEdad() {
        if (!this.loginService.esAutor()) {
            return;
        }

        // tslint:disable-next-line: max-line-length
        //this.usersService.addUserLog(this.loginService.getUser().idUser, `Abrir ventana modal - Mostrar niveles edad - Editar curso`, 'OK');

        const modalRef = this.modalService.open(ModalCursoNivelesComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-70'
            });

        modalRef.componentInstance.curso = this.curso;

        modalRef.result.then((result) => {
            // No hago nada
        }, (reason) => {
        });
    }
    // FIN NIVELES EDAD

    // Tiempo
    mostrarDesgloseTiempo() {
        if (!this.loginService.esAutor()) {
            return;
        }

        const modalRef = this.modalService.open(ModalCursoTiempoComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-70'
            });

        // tslint:disable-next-line: max-line-length
        //this.usersService.addUserLog(this.loginService.getUser().idUser, `Abrir ventana modal - Mostrar desglose tiempo - Editar curso`, 'OK');

        modalRef.componentInstance.curso = this.curso;

        modalRef.result.then((result) => {
            // No hago nada
        }, (reason) => {
        });
    }
    // FIN NIVELES EDAD

    // LENGUAJE
    asignarLenguajeCurso() {
        if (!this.loginService.esAutor()) {
            return;
        }

        const modalRef = this.modalService.open(ModalCursoLenguajeComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-85'
            });

        // tslint:disable-next-line: max-line-length
        //this.usersService.addUserLog(this.loginService.getUser().idUser, `Abrir ventana modal - Mostrar asignar lenguaje curso - Editar curso`, 'OK');

        modalRef.componentInstance.curso = this.curso;

        // Y capturo el evento que se ha producido
        modalRef.result.then((response) => {
            // No hago nada
        }, (reason) => {
        });
    }
    // FIN LENGUAJE

    // AUTORES
    verAutoresCurso() {
        if (!this.loginService.esAutor()) {
            return;
        }

        const modalRef = this.modalService.open(ModalCursoAutoresComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-85'
            });

        // tslint:disable-next-line: max-line-length
        //this.usersService.addUserLog(this.loginService.getUser().idUser, `Abrir ventana modal - Mostrar autores - Editar curso`, 'OK');

        modalRef.componentInstance.curso = this.curso;

        modalRef.result.then((result) => {
            // No hago nada
        }, (reason) => {
        });
    }
    // FIN AUTORES

    // MAPAS
    verDetalleMapa(idMapa: number) {

        // Si soy estudiante le mando al mapa directamente
        // Se cambia a si no es autor, para permitir al padre
        if (!this.loginService.esAutor()) {
            // Y le mando al mapa seleccionado
            this.router.navigate([`/course/${this.curso.idCourse}/graph/${idMapa}`]);

            this.closeModal('Abrir mapa');

            return;
        }

        if (this.loginService.esAutor()) {
            // Si soy autor le llevo a la edicion de metadatos del mapa
            const modalRef = this.modalService.open(ModalEditarCursoMapaComponent, { scrollable: true, windowClass: 'modal-dialog-80' });

            modalRef.componentInstance.id = idMapa;
            modalRef.componentInstance.curso = this.curso;

            modalRef.result.then((result) => {
                if (result && result === 'closeAll') {
                    this.closeModal('Editar nodos mapa');
                } else if (result) {
                    this.targetsService.getCourseTargetByIdCourse(this.curso.idCourse).subscribe(result => {
                        this.courseTargets = result.data
                    });
                }
            }, (err) => { })
        }
    }

    nuevoMapa() {

        if (!this.loginService.esAutor()) { return; }

        const modalRef = this.modalService.open(ModalEditarCursoMapaComponent, { scrollable: true, windowClass: 'modal-dialog-80' });

        modalRef.componentInstance.id = 'nuevo';
        modalRef.componentInstance.curso = this.curso;

        modalRef.result.then((result) => {
            if (result) {
                this.targetsService.getCourseTargetByIdCourse(this.curso.idCourse).subscribe(result => {
                    this.courseTargets = result.data
                })
            }
        }, (reason) => {
        });
    }
    // FIN MAPAS
}
