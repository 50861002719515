import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

// Models
import { DetailCourseTargetModel, ResponseCourseTargetDetail, ResponseCourseTargets } from 'src/app/core/models/courses';


const CREATETARGET = 'courses/$1/graphs'
const GETTARGETSBYCOURSE = 'getListCourseByIdCourse'
const GETTARGET = 'graphs/$1'

@Injectable({
  providedIn: 'root'
})
export class TargetsService {

  constructor(private http: HttpClient) { }


  /**
   * Function that returns the detail of the target
   * @param idTarget The id of target
   * @returns An Observable of ResponseCourseTargetDetail
   */
  getTargetById(idTarget: number): Observable<ResponseCourseTargetDetail> {
    return this.http.get<ResponseCourseTargetDetail>(GETTARGET.replace('$1', idTarget.toString()))
  }

  /**
   * Function that detele a target
   * @param idTarget The id of target
   * @returns An Observable. Get HTTP CODE 200 to delete OK
   */
  deleteTarget(idTarget: number): Observable<any>{
    return this.http.delete(GETTARGET.replace('$1', idTarget.toString()))
  }

  /**
   * Function that creates or updates a target
   * @param target DetailCourseTargetModel object, with target info
   * @param file Image to upload
   * @param idCourse Id of the course to which that target belongs
   * @returns An Observable. Get HTTP CODE 200 to create or update OK
   */
  setTarget(target: DetailCourseTargetModel, file: File | string, idCourse:number): Observable<any> {

    let form: FormData = new FormData()    
    form.append('target', JSON.stringify(target))
    form.append('files', file)
    return this.http.post<any>(CREATETARGET.replace('$1',idCourse.toString()),form)
  }

  /**
   * Function that returns a list with the targets of the course
   * @param idCourse Course id
   * @returns An Observable of ResponseCourseTargets
   */
  getCourseTargetByIdCourse(idCourse: number): Observable<ResponseCourseTargets> {
    let httpParams = new HttpParams().set('idCourse', idCourse.toString())
    return this.http.get<ResponseCourseTargets>(GETTARGETSBYCOURSE, {params: httpParams})
  }
}
