import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'languageName'
})
export class LanguageNamePipe implements PipeTransform {

    constructor(private translate: TranslateService) { }

    transform(languageIsoCode: string): any {
        let result: string;
        switch (languageIsoCode.toLowerCase()) {
            case 'de':
                result = this.translate.instant('IDIOMA.ALEMAN')
                break;
            case 'es':
                result = this.translate.instant('IDIOMA.ESPAÑOL')
                break;
            case 'pt':
                result = this.translate.instant('IDIOMA.PORTUGUES')
                break;
            case 'en':
                result = this.translate.instant('IDIOMA.INGLES')
                break;
        }
        return result;
    }
}
