import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { LocalStorage } from 'src/app/core/utils/local-storage';
import { AccessToken } from 'src/app/core/utils/access-token';
import { DateUtils } from 'src/app/core/utils';

// Routes
import { APP_ROUTES } from './app.routes.module';

// Modules
import { GuardsModule } from './core/guards/guards.module';

// Interceptors
import { ErrorInterceptor } from './core/services/shared/error-interceptor.service';
import { JwtInterceptor } from './core/services/shared/jwt-interceptor.service';

// Components
import { AppComponent } from 'src/app/app.component';
import { PagesModule } from 'src/app/pages/pages.module';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule, MatButtonModule } from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LayoutModule } from './core/layout/layout.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgxMaskModule } from 'ngx-mask';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CoreServiceModule } from './core/services/core-services.module';
import { QuillModule } from 'ngx-quill';
import { SocialLoginModule, AuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    //return new TranslateHttpLoader(http);
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

let config = new AuthServiceConfig([
    {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(
            "1043386557581-d8mkegi24n815n5lnurtfau60a49sk0t.apps.googleusercontent.com",
            { scope: "https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/user.gender.read" }
        ),

    }
]);

export function provideConfig() {
    return config;
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        LayoutModule,
        APP_ROUTES,
        GuardsModule,
        PagesModule,
        // NoopAnimationsModule,
        BrowserAnimationsModule,
        MatDialogModule,
        MatButtonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgCircleProgressModule.forRoot({}),
        NgxMaskModule.forRoot({
            validation: false,
        }),
        CoreServiceModule,
        QuillModule.forRoot(),
        SocialLoginModule
    ],
    entryComponents: [

    ],
    providers: [
        AccessToken,
        DateUtils,
        { provide: LocalStorage, useFactory: getLocalStorage },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        {
            provide: AuthServiceConfig,
            useFactory: provideConfig
        }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

export function getLocalStorage() {
    return window.localStorage;
}


