import { Component, OnInit, EventEmitter, Output } from '@angular/core';
// Models
import { QuizzesCourseModel } from 'src/app/core/models/quizzes/quizzes-course.model';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { QuizModel } from 'src/app/core/models/quizzes';
import { runInThisContext } from 'vm';
import { BodyReproducirQuizzesComponent } from '../body-reproducir-quizzes/body-reproducir-quizzes.component';
import { Subject } from 'rxjs';
import { LoginService } from 'src/app/core/services/login';
import { VariablesPublicUtils } from 'src/app/core/utils/variablesPublic.utils';
import { TemplatesService } from 'src/app/core/services/templates/templates.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalReproducirQuizzesComponent } from '../modal-reproducir-quizzes.component';
import { QuizzesstackService } from 'src/app/core/services/quizzesstack/quizzesstack.service';


@Component({
  selector: 'app-footer-reproducir-quizzes',
  templateUrl: './footer-reproducir-quizzes.component.html',
  styleUrls: ['./footer-reproducir-quizzes.component.scss']
})

export class FooterReproducirQuizzesComponent implements OnInit {
  @Output() quizEnviado:EventEmitter<any> = new EventEmitter<any>();
  @Output() mostrar:EventEmitter<boolean> = new EventEmitter<boolean>();

  id:string;
  quizCourse:QuizzesCourseModel[];
  quizzes:QuizzesCourseModel[]=[];
  quizzesBloque:QuizzesCourseModel[];
  templateQuiz:any;
  templateElementQuiz:any;
  posIni;
  posEnd;
  cantQuizzes:number;
  widthQuiz:number;
  cargando:boolean;
  maxQuizzes:number;
  previus:boolean;
  next:boolean;
  widthPrevius:number;
  userQuiz;
  cant:number;
  public quiz;
  public idUser;
  public variablesPublicUtils;
  public node;
  currentGraph:any;
  automatic:any;
  type:any;
  executeAutomatic:any;

  constructor(public quizService: QuizzesService,
              public loginService: LoginService,
              public templateService:TemplatesService,
              private modalService: NgbModal,
              public quizzesStackService: QuizzesstackService) {
                this.quizService.currentQuiz.subscribe((n) => {
                  this.quiz = n;
                });
                this.quizService.currentGraph.subscribe((g) => {
                  this.currentGraph = g;
                });
                this.quizService.automatic.subscribe((a:boolean)=>{
                  this.automatic = a;
                 });
                this.quizService.currentNode.subscribe((n) => {
                  this.node = n;
                });
                this.quizService.type.subscribe((t) => {
                  this.type = t;
                });
                this.quizService.executeAutomatic.subscribe((ea) => {
                    this.executeAutomatic = ea;
                });
    }

  ngOnInit() {
    localStorage.getItem('automatic');
    this.automatic=JSON.parse(localStorage.getItem('automatic'));
    //if(this.automatic && this.type=="node"){
    if(this.type=="node" && this.loginService.esEstudiante()){
      console.log("currentGraph : ", this.currentGraph);
      console.log("Iniciando carga");
      this.cargando = true;
      this.quizService.setLoading(this.cargando);
      this.maxQuizzes=40;
      this.previus=false;
      this.next=false;
      this.idUser=this.loginService.getUser().idUser;
      this.variablesPublicUtils = new VariablesPublicUtils();
      this.obtenerDatosQuizzesNode(this.currentGraph);
      this.cant=0;
    }
  }

  obtenerDatosQuizzesNode(graph:any) {
    //OBSERVACION AVECES EL GRAFO GRABA EN NODO EN EL TARGET Y AVECES EN SOURCE
    var node = 'n'+ this.node.idOriginal;
    var edges = graph.edges.filter((edge) => edge.connectionType = "QuizzesNodes");
    var quizzesNodes = edges.filter((edge) => ((edge.target.includes('n') && edge.target==node) || (edge.source.includes('n') && edge.source==node)));

    quizzesNodes.forEach((quiz)=>{
        if(quiz.source.includes('q')){
            quiz.idQuiz=parseInt(quiz.source.substring(1,));
            quiz.idCourse=graph.idCourse;
            quiz.idTarget=graph.idTarget;
        }else if(quiz.target.includes('n')){
            quiz.idQuiz=parseInt(quiz.target.substring(1,));
            quiz.idCourse=graph.idCourse;
            quiz.idTarget=graph.idTarget;
        }
    });

    /*quizzesNodes.forEach((quiz) => {
        this.obtenerDatosQuizzesTarget(quiz.idTarget,quiz.idQuiz,quizzesNodes);
    });*/

    for (let i = 0; i < quizzesNodes.length; i++) {
        var quiz = quizzesNodes[i];
        this.obtenerDatosQuizzesTarget(quiz.idTarget,quiz.idQuiz,quizzesNodes);
    };

     this.cargando = false;
     this.quizService.setLoading(this.cargando);
  }

  obtenerDatosQuizzesTarget(idTarget, idQuiz, quizzesNodes) {

    this.quizService.getTargetsQuizzesByIdTarget(idTarget,idQuiz)
    .subscribe(({data}) => {
        if(data.targetsQuizzes.nodes.length>0){
            var quiz = data.targetsQuizzes.nodes[0];
            if(data.targetsQuizzes.nodes[0].quizz.length>0 && data.targetsQuizzes.nodes[0].quizz[0].quizzesNodes.length>0){
                quiz.ordinal = data.targetsQuizzes.nodes[0].quizz[0].quizzesNodes[0].ordinal;
            }
            this.quizzes.push(quiz);
        }
     if(this.quizzes.length == quizzesNodes.length){
        var array = this.quizzes.sort(function (a,b) {
            return a["ordinal"] - b["ordinal"];
         });
         this.calculateWidthQuizNext(array,0,this.quizzes.length);
         this.showQuiz(array[0].idQuiz);
     }
   });
  }

  public showQuiz(idQuiz:number){
    this.executeAutomatic=JSON.parse(localStorage.getItem('executeAutomatic'));
     if(this.executeAutomatic){
        var userQuiz={
            idUser:this.loginService.getUser().idUser,
            idQuizOriginal:idQuiz,
            idTarget:this.currentGraph.idTarget
          };
          this.quizService.setUserQuiz(userQuiz);
          //this.getQuizStack(userQuiz);
          /*GET QUIZ OF STACK*/
          this.quizzesStackService.getQuizStack(userQuiz);
     }
   }

  getColor(quiz:any) {
    if(quiz.quizz[0].usersQuizzes[0]){
        quiz.score = quiz.quizz[0].usersQuizzes[0].score;
    }else{
        quiz.score = null;
    }
    if(quiz.score == null){
        return this.variablesPublicUtils.LEAD;//plomo
    }
    if(0<=quiz.score && quiz.score<=8){
        return this.variablesPublicUtils.RED;//rojo
    }
    if(9<=quiz.score && quiz.score<=21){
        return this.variablesPublicUtils.YELLOW;//amarillo
    }
    if(22<=quiz.score){
        return this.variablesPublicUtils.GREEN;//verde
    }

    /*if(this.cant==this.quizzesBloque.length){
       console.log("Numeral de Bloque :", this.quizzesBloque.length);
       console.log("Cantidad :", this.cant);
       console.log("Las cantidades se igualaron");
       this.cargando = false;
       this.quizService.setLoading(this.cargando);
    }*/
  }

   getWidth() {
    console.log("getWidth:");
     let width = this.widthQuiz + "px";
     return width;
    }

    getWidthPrevius() {
        let width = this.widthPrevius + "px";
        return width;
       }

    getWidthNext() {
        let width = this.widthPrevius + "px";
        return width;
       }

    toPrevius(){
        this.calculateWidthQuizPrevius(this.quizzes);
    }

    toNext(){
        this.calculateWidthQuizNext(this.quizzes,this.posIni,this.posEnd);
    }

    calculateWidthQuizPrevius(quizzes:QuizzesCourseModel[]){
        var posEnd=this.posIni;
        var posIni=this.posIni-38;
        //this.widthPrevius=(document.getElementById("quizzes").clientWidth-1)/this.maxQuizzes;
        this.quizzesBloque=quizzes.slice(posIni,posEnd);
        this.cantQuizzes=this.quizzesBloque.length;
        this.widthQuiz =((document.getElementById("quizzes").clientWidth-1)/(this.cantQuizzes+2))
        //this.widthQuiz =(((document.getElementById("quizzes").clientWidth-1)-(2*this.widthPrevius))/(this.cantQuizzes))
        this.previus=true;
        this.next=true;
    }

    calculateWidthQuizNext(quizzes:any[], posIni:number, posEnd:number){
        console.log("Inicia calculateWidthQuizNext");
        this.posEnd=posEnd;
        if(posEnd>this.maxQuizzes){
            if(posIni==0){
                this.quizzesBloque=quizzes.slice(posIni,38);
                this.posIni=38;
            }else if (this.posIni>=38){
                 var dif = quizzes.length-this.posIni;
                 if(dif<=38){
                    this.posIni=this.posIni;
                    this.quizzesBloque=quizzes.slice(this.posIni,quizzes.length);
                 }else{
                    this.posIni=posIni;
                    this.posEnd=posIni+38;
                    this.quizzesBloque=quizzes.slice(this.posIni,this.posEnd);
                 }
            }
            this.previus=true;
            this.next=true;
            this.cantQuizzes=this.quizzesBloque.length;
            this.widthPrevius=(document.getElementById("quizzes").clientWidth-1)/this.maxQuizzes;
            //this.widthQuiz =((document.getElementById("quizzes").clientWidth-1)/(this.cantQuizzes+2))
            this.widthQuiz =(((document.getElementById("quizzes").clientWidth-1)-(2*this.widthPrevius))/(this.cantQuizzes))
        }else{
            this.quizzesBloque=quizzes.slice(posIni,posEnd);
            this.cantQuizzes=this.quizzesBloque.length;
            //this.widthPrevius=(document.getElementById("quizzes").clientWidth-1)/this.maxQuizzes;
            console.log(" document.getElementById(quizzes) : ", document.getElementById("quizzes"));
            this.widthQuiz =((document.getElementById("quizzes").clientWidth-1)/(this.cantQuizzes))
            //this.widthQuiz =(((document.getElementById("quizzes").clientWidth-1)-(2*this.widthPrevius))/(this.cantQuizzes))
        }
        console.log("Termina calculateWidthQuizNext");
        console.log("this.cantQuizzes : ", this.cantQuizzes);

        for (let index = 0; index < this.quizzesBloque.length; index++) {
            const element = this.quizzesBloque[index];
            element.color = this.getColor(element);
            element.width = this.getWidth();
            this.quizzesBloque[index] = element;
        };
        /*this.cargando = false;
        this.quizService.setLoading(this.cargando);*/
        this.quizService.setArrayQuiz(this.quizzesBloque);
        console.log("this.cantQuizzes Final : ", this.quizzesBloque);
    }

    getAlternativeQuiz(arrayQuizzes:any){
        var min = 0
        var max =arrayQuizzes.length -1
        var i = Math.floor(Math.random() * (max - min) + min);
        return arrayQuizzes[i];
    }

    getQuizStack(userQuiz:any){
        //Consulto pila para el quizz original ordenada por id de pila
        var nodesqs:any[];
        var nodesqa:any[];
        var quizzesStack:any[];
        var quizzesAlternatives:any[];
        var idQuiz:any;
        var subscribe = this.quizzesStackService.getQuizzesStackByIdQuizOriginal(userQuiz).subscribe((data)=>{
            console.log("quizzesstack : ", data.data.quizzesstack.nodes);
            if (data.data.quizzesstack.nodes.length > 0) {
                nodesqs=data.data.quizzesstack.nodes;
               //ORDER ASC
               quizzesStack = nodesqs.sort(function (a, b) {
                   return a["idQuizzesstack"]-b["idQuizzesstack"];
                });
                   var lastQuiz = quizzesStack[quizzesStack.length-1];
                   if(lastQuiz.idQuizOriginal == userQuiz.idQuizOriginal && lastQuiz.result == 0){
                    var susbQuizQuiz = this.quizService.getQuizzesQuizzesById(userQuiz.idQuizOriginal).subscribe((data) => {
                        if(data.data.quizzesQuizzes.nodes.length > 0){
                            nodesqa=data.data.quizzesQuizzes.nodes;
                           //ORDER ASC
                           quizzesAlternatives = nodesqa.sort(function (a, b) {
                             return a["idQuizQuiz"]-b["idQuizQuiz"];
                            });
                            var i = 0;
                            var cant = 0;
                            while (cant < quizzesAlternatives.length) {
                                cant++;
                                var indice = this.getIndice(quizzesAlternatives, lastQuiz.idQuiz);
                               if(lastQuiz.result == 0){
                                if(indice == -1){
                                    idQuiz = quizzesAlternatives[0].linkedIdQuiz;
                                    this.showQuizStack(idQuiz,userQuiz);
                                    return true;
                                }

                                if(indice < (quizzesAlternatives.length-1)) {
                                    idQuiz = quizzesAlternatives[indice + 1].linkedIdQuiz;
                                    this.showQuizStack(idQuiz,userQuiz);
                                    return true;
                                }

                                if(indice == (quizzesAlternatives.length-1)){
                                    idQuiz = lastQuiz.idQuizOriginal;
                                    this.showQuizStack(idQuiz,userQuiz);
                                    return true;
                                }

                               }else if(lastQuiz.result == 1){
                                 //Si esta y respondio correctamente
                                 //(preguntar a Ramon)
                               }
                            }
                        }else{
                         // Si no hay alternativos
                         //Se presenta el quiz original
                           idQuiz=userQuiz.idQuizOriginal;
                           this.showQuizStack(idQuiz,userQuiz);
                        }
                        susbQuizQuiz.unsubscribe();
                      });
                   }else if (lastQuiz.idQuizOriginal == userQuiz.idQuizOriginal && lastQuiz.result == 1){
                         //Si esta y respondio correctamente
                          //(preguntar a Ramon)
                   }
            }else{
                //Si no esta
                //Se presenta el original
                idQuiz=userQuiz.idQuizOriginal;
                this.showQuizStack(idQuiz,userQuiz);
            }
            subscribe.unsubscribe();
        });

           /* console.log("quizzesstack : ", data.data.quizzesstack.nodes);
            if (data.data.quizzesstack.nodes.length > 0) {
               nodes=data.data.quizzesstack.nodes;
               //ORDER ASC
               quizzesStack = nodes.sort(function (a, b) {
                   return a["idQuizzesstack"]-b["idQuizzesstack"];
                });
                   var principalQuiz = quizzesStack[0]
                   if(principalQuiz.idQuizOriginal == quiz.idQuiz && principalQuiz.result == 0){
                       //Si esta y no respondio correctamente
                      //Te posicionas al final de la pila
                      var lastQuiz = quizzesStack[quizzesStack.length-1];
                      if(lastQuiz.idQuizOriginal==quiz.idQuiz){
                      //Si este es el principal
                      //Consultas la tabla de quizzes alternativos ordenados por id
                      var susbQuizQuiz = this.quizService.getQuizzesQuizzesById(quiz.idQuiz).subscribe((data) => {
                        if(data.data.quizzesQuizzes.nodes.length > 0){
                         //Si hay quiz alternativos
                         //Se presenta el primer alternativo
                           //ORDER ASC
                           quizzesAlternatives = nodes.sort(function (a, b) {
                             return a["idQuizQuiz"]-b["idQuizQuiz"];
                            });
                            idQuiz = quizzesAlternatives[0].idQuiz;
                        }else{
                         // Si no hay
                         //Se presenta el quiz original
                           idQuiz=quiz.idQuiz;
                        }
                        susbQuizQuiz.unsubscribe();
                      });
                      }else if(lastQuiz.idQuizOriginal != quiz.idQui){
                          if(lastQuiz.result == 1){
                            //Sino es el principal
                            //Si este ultimo se repondio correctamente
                            //(preguntar a Ramon)
                          }else if(lastQuiz.result == 0){
                            //Sino respondio correctamente
                            //Consultas la tabla de quizzes alternativos ordenados por id
                            //Se presenta el siguiente alternativo
                            var susbQuizQuiz = this.quizService.getQuizzesQuizzesById(quiz.idQuiz).subscribe((data) => {
                                if(data.data.quizzesQuizzes.nodes.length > 0){
                                   //ORDER ASC
                                   quizzesAlternatives = nodes.sort(function (a, b) {
                                     return a["idQuizQuiz"]-b["idQuizQuiz"];
                                    });
                                    idQuiz = quizzesAlternatives[1].idQuiz;
                                }else{
                                 // Si no hay
                                 //Se presenta el quiz original
                                   idQuiz=quiz.idQuiz;
                                }
                                susbQuizQuiz.unsubscribe();
                              });
                          }
                      }
                   }else if (principalQuiz.idQuizOriginal == quiz.idQuiz && principalQuiz.result == 1){
                         //Si esta y respondio correctamente
                          //(preguntar a Ramon)
                   }
            }else{
                //Si no esta
                //Se presenta el original
                idQuiz=quiz.idQuiz;
            }*/
    }

    showQuizStack(idQuiz:any,userQuiz:any){
        this.quizService.getQuizById(idQuiz).then((quiz)=>{
            console.log("DATA OF QUIZ: ", quiz);
            //this.quizService.setArrayQuiz(this.quizzesBloque);
            //this.quizEnviado.emit(quiz);// Solo para el carge el quiz al inicio y carge la plantilla en en body
            quiz.idQuiz = idQuiz;//idQuiz Original o Alternativa
            quiz.idQuizOriginal = userQuiz.idQuizOriginal;//idQuiz Original
            this.quizService.setSelectQuiz(quiz);//Las demas gestiones se hace con este quiz
            this.templateService.setFlagTemplateUpdate(true);
            if(quiz && quiz.templatesQuizzes[0]){
                  /*this.quizService.getUsersQuizzes(userQuiz).then((usersQuizzes)=>{
                    if(usersQuizzes){
                        usersQuizzes.timesDone = usersQuizzes.timesDone + 1;
                    }
                    userQuiz.score=0;
                    this.quizService.createUsersQuizzes(userQuiz,usersQuizzes);
                    this.quizService.setUserQuiz(userQuiz);
                 })*/

                 userQuiz.score=0;
                 this.quizService.createUsersQuizzes(userQuiz);
                 this.quizService.setUserQuiz(userQuiz);
              }
          });
      }

    getIndice(arrayQuiz,idQuiz) {
        var indice = -1;
        arrayQuiz.filter(function (quiz, i) {
            if (quiz.linkedIdQuiz === idQuiz) {
                indice = i;
            }
        });
        return indice;
    }
}
