import { Component, OnInit, Input } from '@angular/core';
import * as countdown from 'countdown';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { TemplatesService } from 'src/app/core/services/templates/templates.service';
import { LoginService } from 'src/app/core/services/login/login.service';
import { ModalReproducirNodoComponent } from '../../modal-reproducir-nodo/modal-reproducir-nodo.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TargetsService } from 'src/app/core/services/targets';
import { timingSafeEqual } from 'crypto';
import { QuizzesstackService } from 'src/app/core/services/quizzesstack/quizzesstack.service';
import { VariablesPublicUtils } from 'src/app/core/utils/variablesPublic.utils';

interface Time {
    hours: number,
    minutes: number,
    seconds: number;
}

@Component({
    selector: 'app-body-reproducir-quizzes',
    templateUrl: './body-reproducir-quizzes.component.html',
    styleUrls: ['./body-reproducir-quizzes.component.scss']
})

export class BodyReproducirQuizzesComponent implements OnInit {
    //@Input() node;
    @Input() mostrar: boolean;

    progress = 0;
    progressBar = document.querySelector('.progress-bar');
    intervalId;
    time: Time;
    iniBlock: boolean;
    countSeconds: number;
    timerId: number = null;
    @Input() date: Date | string;
    quiz: any;
    templates: any[];
    cargando: boolean;
    inicio: boolean;
    elementsQP: any[] = [];
    elementsCorrect: any[] = [];
    arrayQuiz: any[] = [];
    answer: boolean;
    isRevealed: boolean;
    isResponse: boolean;
    userQuiz: any;
    automatic: boolean;
    node: any;
    currentGraph: any;
    type: any;
    nextNodo: any;
    variablesPublicUtils: any;

    private templateTypes = ['VideoQuestions',
        'ImageQuestions',
        'AudioQuestions',
        'PdfQuestions',
        'TextQuestions',
        'VideoOptions',
        'ImageOptions',
        'AudioOptions',
        'PdfOptions',
        'TextOptions',
        'VideoCorrect',
        'ImageCorrect',
        'AudioCorrect',
        'PdfCorrect',
        'TextCorrect'];

    private elementIcons = ['videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject',
        'videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject',
        'videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject'];

    constructor(public quizService: QuizzesService,
        public templateService: TemplatesService,
        public loginService: LoginService,
        public modalService: NgbModal,
        public activeModal: NgbActiveModal,
        public targetsService: TargetsService,
        public quizzesStackService: QuizzesstackService) {
        this.quizService.selectQuiz.subscribe((n) => {
            this.quiz = n;
            this.showQuiz(this.quiz);
        });
        this.templateService.isResponse.subscribe((n: boolean) => {
            this.isResponse = n;
        });
        this.quizService.loading.subscribe((n: boolean) => {
            this.cargando = n;
        });
        this.quizService.userQuiz.subscribe((uq) => {
            this.userQuiz = uq;
        });
        this.quizService.automatic.subscribe((a: boolean) => {
            this.automatic = a;
        });
        this.quizService.currentGraph.subscribe((g) => {
            this.currentGraph = g;
        });
        this.quizService.currentNode.subscribe((n) => {
            this.node = n;
        });
        this.quizService.type.subscribe((t) => {
            this.type = t;
        });
    }

    ngOnInit() {
        this.isRevealed = true;
        this.isResponse = false;
        this.inicio = true;
        this.templates = [];
        this.variablesPublicUtils = new VariablesPublicUtils();
        this.quizService.arrayQuiz.subscribe((a: any) => {
            this.arrayQuiz = a;
        });
    }

    discoverAnswer() {
        console.log("Descubir respuesta");
        this.answer = (this.answer == true) ? false : true;
        this.isRevealed = true;
        this.isResponse = false;
        if (this.timerId) {
            clearInterval(this.timerId);
        }
        this.evaluateResponse();
    }

    evaluationNegative() {
        console.log("Autoevaluacion Negativa");
        this.isRevealed = true;
        this.isResponse = false;
        if (this.timerId) {
            clearInterval(this.timerId);
        }
        this.evaluateResponse();
    }

    evaluationPositive() {
        console.log("Autoevaluacion Positiva");
        this.isRevealed = true;
        this.isResponse = false;
        if (this.timerId) {
            clearInterval(this.timerId);
        }
        this.evaluateResponse();
    }

    nextQuiz(quiz) {
        /*this.userQuiz.idQuizOriginal=quiz.idQuiz;
        var index = this.arrayQuiz.findIndex(function(el){
              return el.idQuiz == quiz.idQuiz
        });*/

        //Verificar si es el ultimo quiz
        //Invocar al array de nodos ordenado
        //Verificar si el nodo es el ultimo
        //Si no es ultimo sigues al siguiente
        //Si es el ultimo cierra el modal.

        if ((this.automatic && !this.isLastNode(this.node) && (index == (this.arrayQuiz.length - 1)))) {
            this.nextNode(this.nextNodo);
        } else {

            this.userQuiz.idQuizOriginal = quiz.idQuiz;
            var index = this.arrayQuiz.findIndex(function (el) {
                return el.idQuiz == quiz.idQuiz
            });

            if (index != -1 && (index < this.arrayQuiz.length - 1)) {
                var nexQuiz = this.arrayQuiz[index + 1];
                this.quizService.getQuizById(nexQuiz.idQuiz).then((quiz) => {
                    this.templateService.setFlagTemplateUpdate(true);
                    if (quiz && quiz.templatesQuizzes[0]) {
                        /*this.quizService.getUsersQuizzes(this.userQuiz).then((usersQuizzes)=>{
                          if(usersQuizzes){
                              usersQuizzes.timesDone = usersQuizzes.timesDone + 1;
                          }
                          this.userQuiz.score=0;
                          this.quizService.createUsersQuizzes(this.userQuiz,usersQuizzes);
                       })*/
                        this.userQuiz.score = 0;
                        this.quizService.createUsersQuizzes(this.userQuiz);
                    }
                    this.quizService.setUserQuiz(this.userQuiz);
                    /*GET QUIZ OF STACK*/
                    this.quizzesStackService.getQuizStack(this.userQuiz);
                    //this.showQuiz(quiz);
                });
            } else {
                this.closeModal()
            }
        }
    }

    showQuiz(quiz: any) {
        console.log("QUIZZ BODY REPRODUCIR: showQuiz : ", quiz);
        //this.cargando=false;
        this.answer = false;
        this.isRevealed = false;
        //this.isDisabled=(this.isDisabled==true)?false:true;
        this.quiz = quiz;
        this.templates = [];
        var elementsQP = [];
        var elementsCorrect = [];
        var susbTemplateQuiz = this.quizService.DEPRECATEDgetTemplatesQuizzesById(this.quiz.idQuiz).subscribe(({ data }) => {
            data.templatesQuizzes.nodes.forEach(templateData => {
                templateData.template[0].templateElement.forEach(element => {
                    var dataElement: any;
                    var subscribe = this.quizService.DEPRECATEDgetTemplatesElementQuizzesByIdElement(element.idTemplateElement, this.quiz.idQuiz).subscribe((data) => {
                        //var dataElement:any;
                        //console.log("data.data.templatesElementQuizzes.nodes.length : ", data.data.templatesElementQuizzes.nodes.length);
                        if (data.data.templatesElementQuizzes.nodes.length > 0) {
                            dataElement = data.data.templatesElementQuizzes.nodes[0].data;
                        } else {
                            dataElement = "";
                        }
                        //console.log("dataElement : ");
                        if (element.elementType <= 9) {
                            elementsQP.push({
                                idTemplateElement: element.idTemplateElement,
                                idTemplate: element.idTemplate,
                                elementType: element.elementType,
                                xposition: element.xposition,
                                yposition: element.yposition,
                                xsize: element.xsize,
                                ysize: element.ysize,
                                style: this.templateTypes[element.elementType],
                                icon: this.elementIcons[element.elementType],
                                data: dataElement
                            });
                        } else {
                            elementsCorrect.push({
                                idTemplateElement: element.idTemplateElement,
                                idTemplate: element.idTemplate,
                                elementType: element.elementType,
                                xposition: element.xposition,
                                yposition: element.yposition,
                                xsize: element.xsize,
                                ysize: element.ysize,
                                style: this.templateTypes[element.elementType],
                                icon: this.elementIcons[element.elementType],
                                data: dataElement
                            });
                        }
                        subscribe.unsubscribe();
                    })
                });
                this.templates.push({
                    idTemplateQuizz: templateData.idTemplateQuizz,
                    idTemplate: templateData.idTemplate,
                    idQuiz: templateData.idQuiz,
                    templateTittle: templateData.template[0].templateTittle,
                    quizInstructions: templateData.template[0].quizInstructions,
                    writable: templateData.template[0].writable,
                    compuCorrect: templateData.template[0].compuCorrect,
                    multiplexed: templateData.template[0].multiplexed,
                    elementsQP: elementsQP,
                    elementsCorrect: elementsCorrect
                });
                console.log("@@@this.templates : ", this.templates);
                this.inicio = false;
                this.mostrarClock(this.quiz);
            })
            susbTemplateQuiz.unsubscribe();
        });

        if (this.templates.length == 0) {
            if (this.timerId) {
                clearInterval(this.timerId);
            }
            var canvas = document.getElementById("canvas");
            var lienzo = this.retornarLienzo(canvas);
            lienzo.clearRect(0, 0, 300, 150);
        }
    }

    mostrarClock(quiz: any) {
        if (quiz && this.templates.length > 0) {
            if (this.timerId) {
                clearInterval(this.timerId);
            }
            this.iniBlock = false;
            let date = new Date();
            date.setSeconds(date.getSeconds() + quiz.duration);
            this.timerId = countdown(date, (ts) => {
                this.time = ts;
                this.showClock(this.time, this.countSeconds);
                if (!this.iniBlock) {
                    this.iniBlock = true;
                    this.countSeconds = this.time.seconds + this.time.minutes * 60;
                };
                if (this.time.minutes == 0 && this.time.seconds == 0) {
                    clearInterval(this.timerId);
                    this.answer = true;
                    this.evaluateResponse();
                    this.isRevealed = true;
                }
            }, countdown.HOURS | countdown.MINUTES | countdown.SECONDS)
        }
    }

    showClock(time, countSeconds) {
        var canvas = document.getElementById('canvas');
        var lienzo = this.retornarLienzo(canvas);
        if (lienzo) {
            var segundo = time.minutes * 60 + time.seconds;
            var radio = 25;
            // Defino límites en pí­xeles
            var minx = 10;
            var maxx = 45;
            var miny = 10;
            var maxy = 45;
            // Centro del recuadro
            var cx = minx + (maxx - minx) / 2;
            var cy = miny + (maxy - miny) / 2;
            // Borrar lienzo
            lienzo.clearRect(0, 0, 300, 300);
            lienzo.strokeStyle = "rgb(0,0,0)";
            lienzo.lineWidth = 1;
            // Dibujar el círculo del reloj
            lienzo.beginPath();
            lienzo.arc(cx, cy, radio, 0, Math.PI * 2, false);
            lienzo.stroke();
            // Dibujar los segundos restantes
            lienzo.beginPath();
            lienzo.fillStyle = "rgb(0,0,0)";
            lienzo.moveTo(cx, cy); // posiciono el lápiz en el centro del recuadro
            var angulo = 2 * Math.PI * (segundo / countSeconds); // calculo el ángulo del segundero
            lienzo.arc(cx, cy, radio, -Math.PI / 2, -Math.PI / 2 - angulo, false);
            lienzo.lineTo(cx, cy);
            lienzo.fill();
            //Mostrar la hora
            lienzo.beginPath();
            lienzo.font = "12pt Arial Black"
            //lienzo.fillStyle = "rgb(255,255,255)";
            lienzo.fillStyle = "rgb(117, 108, 108)";
            var minute = (time.minutes <= 9) ? "0" + time.minutes : time.minutes;
            var second = (time.seconds <= 9) ? "0" + time.seconds : time.seconds
            lienzo.fillText(minute + ":" + second, (cx / 2) - 10, cx + 5);
            //color de fonde de lienzo
            //lienzo.beginPath();
            //lienzo.fillStyle = "rgb(255,255,255)";
        }
    }

    retornarLienzo(canvas) {
        if (canvas.getContext) {
            var lienzo = canvas.getContext('2d');
            return lienzo;
        } else {
            return false;
        }
    }

    evaluateResponse() {
        this.quiz.idUser = this.loginService.getUser().idUser;
        this.quiz.knowledge = 0;
        this.quiz.idTarget = this.currentGraph.idTarget;
        this.quiz.idCourse = this.currentGraph.idCourse;
        this.quiz.result = 0;
        this.quiz.nextQuestion = 0;

        this.quizzesStackService.createQuizzesStackOriginal(this.quiz).then(() => {
            this.calculateAchieveKnowledge(this.quiz);
        });
    };

    /*getScore(usersQuizzes){
      var min = Math.ceil(0);
      var max = Math.floor(100);
      var score = Math.floor(Math.random() * (max - min) + min);
      return score;
      return this.calculateAchieveKnowledge(usersQuizzes);
     }*/

    nextNode(nextNode) {
        const modalRef = this.modalService.open(ModalReproducirNodoComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-65'
            })
        modalRef.componentInstance.node = nextNode;
        modalRef.componentInstance.currentGraph = this.currentGraph;
        modalRef.componentInstance.type = 'node';
        modalRef.result.then((result) => {
            console.log(result);
        }, (reason) => {
        });
    }

    isLastNode(node) {
        var nodes = this.currentGraph.nodes.filter((node) => node.nodeType == "Node");
        var array = nodes.sort(function (a, b) {
            return a["idOriginal"] - b["idOriginal"];
        });

        var n = array[array.length - 1];
        if (n.idOriginal == node.idOriginal) {
            return true;
        } else {
            var pos = 0;
            for (n of array) {
                pos = pos + 1;
                if (n.idOriginal == node.idOriginal) {
                    this.nextNodo = array[pos];
                    return false;
                }
            }
        }
    }

    calculateKnowledge(idQuiz) {
        /*Calcula y devuelve el conocimiento de un quiz (tenemos en cuenta IdQuiz)*/
        var knowledge = 0;
    }

    calculateAchieveKnowledge(quiz: any) {
        /*Calcula y devuelve el conocimiento del conjunto de original y alternativos de un quiz (tenemos en cuenta IdQuizOriginal).*/
        var knowledge = 0;
        var calculateKnowledge = 0;
        var nodes: any[];
        var quizzesStack: any[];
        var i = 0;
        var cont = 0;
        var WEIGHT = 0;
        var quizstack: any;
        var subscribe = this.quizzesStackService.getQuizzesStackByIdQuizOriginal(quiz).subscribe((data) => {
            console.log("quizzesstack : ", data.data.quizzesstack.nodes);
            if (data.data.quizzesstack.nodes.length > 0) {
                nodes = data.data.quizzesstack.nodes;
                //ORDER DESC
                quizzesStack = nodes.sort(function (a, b) {
                    return b["idQuizzesstack"] - a["idQuizzesstack"];
                });
                while (i < quizzesStack.length) {
                    cont++;
                    quizstack = quizzesStack[i];
                    console.log("Block statement execution no." + i)
                    if (cont == 1) {
                        if (quizzesStack[i].result == 1) {
                            calculateKnowledge = this.variablesPublicUtils.LATEST * this.variablesPublicUtils.CORRECT
                        } else {
                            calculateKnowledge = this.variablesPublicUtils.LATEST * this.variablesPublicUtils.INCORRECT
                        }
                    }
                    if (cont == 2) {
                        if (quizzesStack[i].result == 1) {
                            calculateKnowledge = this.variablesPublicUtils.PENULTIMATE * this.variablesPublicUtils.CORRECT
                        } else {
                            calculateKnowledge = this.variablesPublicUtils.PENULTIMATE * this.variablesPublicUtils.INCORRECT
                        }
                    }
                    if (cont == 3) {
                        if (quizzesStack[i].result == 1) {
                            calculateKnowledge = this.variablesPublicUtils.ANTEPEN * this.variablesPublicUtils.CORRECT
                        } else {
                            calculateKnowledge = this.variablesPublicUtils.ANTEPEN * this.variablesPublicUtils.INCORRECT
                        }
                    }
                    if (cont == 4) {
                        if (quizzesStack[i].result == 1) {
                            calculateKnowledge = this.variablesPublicUtils.ANTEANTEPEN * this.variablesPublicUtils.CORRECT
                        } else {
                            calculateKnowledge = this.variablesPublicUtils.ANTEANTEPEN * this.variablesPublicUtils.INCORRECT
                        }
                    }
                    if (cont == 5) {
                        if (quizzesStack[i].result == 1) {
                            calculateKnowledge = this.variablesPublicUtils.ANTEANTEANTEPEN * this.variablesPublicUtils.CORRECT
                        } else {
                            calculateKnowledge = this.variablesPublicUtils.ANTEANTEANTEPEN * this.variablesPublicUtils.INCORRECT
                        }
                    }
                    if (cont == 6) {
                        WEIGHT = this.variablesPublicUtils.ANTEANTEANTEANTEPEN;
                        if (quizzesStack[i].result == 1) {
                            calculateKnowledge = this.variablesPublicUtils.ANTEANTEANTEANTEPEN * this.variablesPublicUtils.CORRECT
                        } else {
                            calculateKnowledge = this.variablesPublicUtils.ANTEANTEANTEANTEPEN * this.variablesPublicUtils.INCORRECT
                        }
                    }
                    if (cont > 6) {
                        WEIGHT = WEIGHT / 2;
                        if (quizzesStack[i].result == 1) {
                            knowledge = WEIGHT * this.variablesPublicUtils.CORRECT
                        } else {
                            knowledge = WEIGHT * this.variablesPublicUtils.INCORRECT
                        }
                    }
                    //UPDATE KNOWLEDGE
                    quizstack.knowledge = calculateKnowledge;
                    this.quizzesStackService.updateQuizzesStackOriginal(quizstack);
                    knowledge = knowledge + calculateKnowledge;
                    i++;
                }
                if (i == quizzesStack.length) {
                    //UPDATE SCORE
                    /*this.quizService.getUsersQuizzes(this.userQuiz).then((usersQuizzes)=>{
                        if(usersQuizzes){
                           usersQuizzes.timesDone = usersQuizzes.timesDone + 1;
                        }
                        this.userQuiz.score = knowledge|0;//double to number
                        this.quizService.createUsersQuizzes(this.userQuiz,usersQuizzes);
                    })*/
                    this.userQuiz.score = knowledge | 0;//double to number
                    this.quizService.createUsersQuizzes(this.userQuiz);
                }

            }
            subscribe.unsubscribe();
            //usersQuizzes.score=this.getScore(usersQuizzes);
            //return knowledge;
        });
    }

    closeModal() {
        this.activeModal.close();
    }

    ngOnDestroy() {
        if (this.timerId) {
            clearInterval(this.timerId);
        }
    }
}

