import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class TitleService
{
    public barTitle: string;

    public constructor(private title: Title) { }

    // Define el titulo del navegador
    public setHTMLTitle(htmlTitle: string) {
        this.title.setTitle(htmlTitle);
    }

    public getHTMLTitle(): string {
        return this.title.getTitle();
    }
    // FIN Define el titulo del navegador

    // Define el titulo de la aplicacion (BarTitle)
    public setBarTitle(barTitle: string) {
        this.barTitle = barTitle;
    }

    public getBarTitle(): string {
        return this.barTitle;
    }
    // FIN Define el titulo de la aplicacion (BarTitle)
}
