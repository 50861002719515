import { Injectable } from '@angular/core';

// GraphQL
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { QuizModel } from '../../models/quizzes';
import { HttpClient } from '@angular/common/http';
import { map, switchMap, tap } from 'rxjs/operators';
import { SIGMA_CONSTANTS } from '../../utils/sigma-constants';
import { ApiResponse } from '../../models/shared/api-response.model';
import { QuizTemplate } from '../../models/quizzes/quiz-template.model';
import { ElementDataModel } from '../../models/quizzes/element-data.model';
import { TEMPLATE_ELEMENTS_TYPES } from '../../utils/template-elements-types';
import { STORAGE_KEYS } from '../../utils/storage-keys';

@Injectable({
    providedIn: 'root'
})
export class QuizzesService {

    public currentQuiz = new Subject();
    public selectQuiz = new Subject();
    public userQuiz = new Subject();
    public newQuiz = new Subject();
    public currentImagen = new Subject();
    public arrayQuiz = new Subject();
    public loading = new Subject();
    public currentGraph = new Subject();
    public currentNode = new Subject();
    public type = new Subject();
    public executeAutomatic = new Subject();

    // DEPRECATED
    public automatic;

    private _automatic: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    returnCourseQuizzesFields = `{
    idCourseQuiz
    idCourse
    idQuiz
  }`;

    returnQuizzesNodesFields = `{
    idNodeQuiz
    idCourse
    idTarget
    idQuiz
  }`;

    /*returnQuizFields = `{
      idQuiz
      idQpattern
      quizType
      idAuthor
      duration
    }`; */

    returnQuizFields = `{
    idQuiz
    idQpattern
    quizType
    idAuthor
    duration
    author {
      nick
    }
    quizSwlevel
    certifiedNodesToGo
    idQuestionLanguage
    questionLanguage{
        idLanguage
        language
    }
    idAnswerLanguage
    answerLanguage{
        idLanguage
        language
    }
    stringCaptionsTitle
    idQuizMother
    idQuizFather
    quizValue
    idMultiplexQuiz
    ordinalMultiplex
    rightAnswer
    stringAnswers
    stringAnswersVideo
    stringAnswersImage
    stringAnswersAudio
    stringAnswersDoc
    stringAnswersText
    stringOptionsVideo
    stringOptionsImage
    stringOptionsAudio
    stringOptionsDoc
    stringOptionsText
    stringQuestionsVideo
    stringQuestionsImage
    stringQuestionsAudio
    stringQuestionsDoc
    stringQuestionsText
    templatesQuizzes{
        idQuiz
        idTemplate
    }
    creationDateString
    lastEditionDateString
    quizzesNodes{
        ordinal
    }
  }`;

    returnTemplatesElementQuizzesFields = `{
    idTemplateElementQuizz
    idTemplateElement
    idQuiz
    data
  }`;

    returnTargetQuizzesFields = `{
    idTargetQuiz
    idCourse
    idTarget
    idQuiz
    quizz{
        idQuiz
        usersQuizzes{
            idQuiz
            idUser
            score
            timesDone
        }
        quizzesNodes{
            ordinal
        }
    }
    ordinal
  }`;

    returnQuizzesQuizzesFields = `{
    idQuizQuiz
    myIdCourse
    course{
     idCourse
    }
    myIdQuiz
    quizz{
     idQuiz
    }
    myIdTarget
    target{
     idTarget
    }
    linkedIdQuiz
    role
  }`;

    constructor(private apollo: Apollo, private datePipe: DatePipe, private http: HttpClient) {
        this._automatic.next(JSON.parse(localStorage.getItem(STORAGE_KEYS.AUTOMATIC)));
    }


    public createQuiz(courseId: number, graphId: number, quiz: QuizModel): Observable<any> {
        const q = {
            user: quiz.user,
            quizTittle: quiz.quizTittle,
            quizInstructions: quiz.quizInstructions,
            x: quiz.x,
            y: quiz.y,
            duration: quiz.duration,
            ordinal: quiz.ordinal,
            quizSwlevel: quiz.quizSwlevel,
            idTargetCreation: graphId,
            idCourseCreation: courseId,
            nodeType: 'Quiz',
            id: quiz.id,
            idOriginal: quiz.idOriginal,
            creationDate: quiz.creationDate,
            published : quiz.published
        }

        return this.http.post<any>(`courses/${courseId}/graphs/${graphId}/quizzes`, q).pipe(
            map(res => {
                if (!res.error.code) {
                    res.data = {
                        ...res.data,
                        originalX: res.data.x,
                        originalY: res.data.y,
                        type: 'text',
                        size: SIGMA_CONSTANTS.QUIZ_SIZE,
                        zindex: 0,
                    };
                }

                return res;
            })
        );
    }

    public saveQuizPos(id: number, x: number, y: number, courseId: number, graphId: number, idUser: number) {
        const q = {
            idOriginal: id,
            x,
            y,
            idCourseCreation: courseId,
            idTargetCreation: graphId,
            idUser
        }

        return this.http.post<any>(`courses/${courseId}/graphs/${graphId}/quizzes/`, q);
    }

    public deleteQuiz(quizId: number, courseId: number, graphId: number) {
        return this.http.delete(`courses/${courseId}/graphs/${graphId}/quizzes/${quizId}`);
    }

    public createEdge(nodeFromId: string, nodeToId: string, courseId: number, graphId: number) {
        return this.http.put(`courses/${courseId}/graphs/${graphId}/idFrom/${nodeFromId}/edges/idConect/${nodeToId}`, {});
    }

    public deleteEdge(nodeFromId: string, nodeToId: string, courseId: number, graphId: number) {
        return this.http.delete(`courses/${courseId}/graphs/${graphId}/idFrom/${nodeFromId}/edges/idConect/${nodeToId}`);
    }

    getCourseQuizzesById(id: number) {
        const filter = { idCourse: id };

        return this.apollo.query<any>({
            query: gql`query($filter: Courses_QuizzsFilter) {
              courses_quizzs(
                where: $filter
              ) {
                nodes ${this.returnCourseQuizzesFields}
              }
        }`,
            variables: {
                filter: filter
            }
        });
    }

    getQuizzesNodesById(id: number) {
        const filter = { idQuiz: id };

        return this.apollo.query<any>({
            query: gql`query($filter: QuizzesNodesFilter) {
            quizzesNodes(
                where: $filter
              ) {
                nodes ${this.returnQuizzesNodesFields}
              }
        }`,
            variables: {
                filter: filter
            }
        });
    }

    public getQuiz(id: number, courseId: number, graphId: number): Observable<any> {
        return this.http.get<ApiResponse<any>>(`courses/${courseId}/graphs/${graphId}/quizzes/${id}`).pipe(map(res => res.data));
    }

    getQuizById(id: number) {
        const variables = {
            id: id
        };

        var query = gql`query($id: Int!) {
                      quizzes(where: { idQuiz: $id })
                      {
                        nodes ${this.returnQuizFields}
                      }
                }`;

        var quiz;
        return this.query(query, variables).toPromise().then((data) => {
            if (data.data.quizzes.nodes.length > 0) {
                quiz = data.data.quizzes.nodes[0];
                if (data.data.quizzes.nodes[0].author.length > 0) {
                    quiz.author.firstName = data.data.quizzes.nodes[0].author[0].nick;
                }
            }
            return quiz;
        });

    }

    getQuizzById(id: number) {
        const filter = { idQuiz: id };

        return this.apollo.query<any>({
            query: gql`query($filter: QuizzesFilter) {
            quizzes(
                where: $filter
              ) {
                nodes ${this.returnQuizFields}
              }
        }`,
            variables: {
                filter: filter
            }
        });
    }

    public async createUsersQuizzes(quizUser: any) {
        console.log("NODO OBTENIDO DE BD quizUser : ", quizUser);
        //console.log("NODO OBTENIDO DE BD usersQuizzes : ",usersQuizzes);
        const variables = { id: parseInt(quizUser.idUser), idq: parseInt(quizUser.idQuizOriginal) };
        const query = gql`query($id: Int!, $idq: Int!) {
        usersQuizzes(where: {idUser: $id, idQuiz : $idq}) {
            nodes {
                idUserQuiz,
                idUser,
                score,
                timesDone
            }
        }
    }`;
        var result = await this.query(query, variables).toPromise().then((data) => {
            if (data.data.usersQuizzes.nodes.length > 0) {
                return data.data.usersQuizzes.nodes[0];
            }
        });

        if (result) {
            result.timesDone = result.timesDone + 1;
            //var idUserQuiz = result.idUserQuiz;
            //var timesDone = result.timesDone;
            //var score = usersQuizzes.score;
            var mutation = gql`mutation {
             updateUsersQuizzes( usersQuizzes: {
                idUserQuiz: ${parseInt(result.idUserQuiz)},
                idUser: ${quizUser.idUser},
                idQuiz: ${quizUser.idQuizOriginal},
                score: ${quizUser.score},
                timesDone: ${parseInt(result.timesDone)},
                lastCompletionDate: "${this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss')}" ,
                lastCompletionDateString: "${this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss').toString()}",
                })
            }`;
        } else {
            mutation = gql`mutation {
            createUsersQuizzes ( usersQuizzes: {
                      idUserQuiz: 0,
                      idUser: ${quizUser.idUser},
                      idQuiz: ${quizUser.idQuizOriginal},
                      score: ${quizUser.score},
                      timesDone:${1},
                      lastCompletionDate: "${this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss')}",
                      lastCompletionDateString: "${this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss').toString()}",
                  }) {idUserQuiz}
                }`;
        }

        const _variables = { idt: parseInt(quizUser.idTarget), iq: parseInt(quizUser.idQuizOriginal) };
        var refetchquery = gql`query($idt: Int!, $iq: Int!) {
            targetsQuizzes(where: { idTarget : $idt, idQuiz:$iq}) {
                nodes {
                    idTargetQuiz
                    idCourse
                    idTarget
                    idQuiz
                    quizz{
                        idQuiz
                        usersQuizzes{
                            idQuiz
                            idUser
                            score
                            timesDone
                        }
                    }
                    ordinal
                }
            }
            }`;
        await this.apollo.mutate({
            mutation: mutation,
            refetchQueries: [{
                query: refetchquery,
                variables: _variables
            }]
        }).toPromise().then((y) => {
            console.log("TERMINO DE ACTUALIZAR createUsersQuizzes()");
        });
    }

    getUsersQuizzes(quizUser) {
        const variables = { id: parseInt(quizUser.idUser), idq: parseInt(quizUser.idQuizOriginal) };
        const query = gql`query($id: Int!, $idq: Int!) {
        usersQuizzes(where: {idUser: $id, idQuiz : $idq}) {
            nodes {
                idUserQuiz,
                idUser,
                score,
                timesDone
            }
        }
    }`;
        var result = this.query(query, variables).toPromise().then((data) => {
            if (data.data.usersQuizzes.nodes.length > 0) {
                return data.data.usersQuizzes.nodes[0];
            }
        });
        return result;
    }

    getUsersInQuizzes(arrayQuizzes: any[], idUser: any) {

        const query = gql`query($id: Int!, $idq: Int!) {
        usersQuizzes(where: { idUser: $id, idQuiz : $idq }) {
            nodes {
                idUserQuiz,
                idUser,
                score,
                timesDone
            }
        }
    }`;
        for (let index = 0; index < arrayQuizzes.length; index++) {
            const quiz = arrayQuizzes[index];

            var userQuiz = {
                idUser: idUser,
                idQuiz: quiz.idQuiz
            };

            const variables = { id: parseInt(userQuiz.idUser), idq: parseInt(userQuiz.idQuiz) };
            var result: any;
            this.query(query, variables).toPromise().then((data) => {
                if (data.data.usersQuizzes.nodes.length > 0) {
                    result = data.data.usersQuizzes.nodes[0];
                }
                if (result) {
                    quiz.score = result.score;
                } else {
                    quiz.score = null;
                }
            });
        }
        return arrayQuizzes;
    }

    getcreateUsersQuizzes(id: number) {
        const filter = { idQuiz: id };

        return this.apollo.query<any>({
            query: gql`query($filter: QuizzesNodesFilter) {
            quizzesNodes(
                where: $filter
              ) {
                nodes ${this.returnQuizzesNodesFields}
              }
        }`,
            variables: {
                filter: filter
            }
        });
    }

    updateQuiz(quiz) {
        var mutation = gql`mutation {
      updateQuizzes (
          quizzes: {
            idQuiz: ${quiz.idOriginal},
            stringCaptionsTitle: "${quiz.stringCaptionsTitle}",
            quizSwlevel: ${quiz.quizSwlevel},
            duration: ${parseInt(quiz.duration)},
            idQuestionLanguage: ${parseInt(quiz.idQuestionLanguage)},
            idAnswerLanguage: ${parseInt(quiz.idAnswerLanguage)},
            lastEditionDateString: "${this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss').toString()}"
          })
      }`;

        var variables = {
            id: quiz.idOriginal
        };

        var queryRefetch = gql`query($id: Int!) {
                        quizzes(where: { idQuiz: $id })
                        {
                          nodes ${this.returnQuizFields}
                        }
                  }`;

        return this.apollo.mutate({
            mutation: mutation,
            refetchQueries: [{
                query: queryRefetch,
                variables: variables
            }]
        }).toPromise().then(y => {
            console.log("TERMINA DE ACTUALIZAR", y)
        });
    }

    updateQuizById(quiz: any) {
        var mutation = gql`mutation {
      updateQuizzes (
          quizzes: {
            idQuiz: ${quiz.idQuiz},
            idQpattern: ${quiz.idQpattern},
            quizType: ${quiz.quizType},
            idAuthor: ${quiz.idAuthor},
            duration: ${parseInt(quiz.duration)},
            quizSwlevel: ${quiz.quizSwlevel},
            certifiedNodesToGo: ${quiz.certifiedNodesToGo},
            idQuestionLanguage: ${parseInt(quiz.idQuestionLanguage)},
            idAnswerLanguage: ${parseInt(quiz.idAnswerLanguage)},
            stringCaptionsTitle: "${quiz.stringCaptionsTitle}",
            idQuizMother: ${quiz.idQuizMother},
            idQuizFather: ${quiz.idQuizFather},
            quizValue: ${quiz.quizValue},
            idMultiplexQuiz: ${quiz.idMultiplexQuiz},
            ordinalMultiplex: ${quiz.ordinalMultiplex},
            rightAnswer: "${quiz.rightAnswer}",
            stringAnswers: "${quiz.stringAnswers}",
            stringAnswersVideo: "${quiz.stringAnswersVideo}",
            stringAnswersImage: "${quiz.stringAnswersImage}",
            stringAnswersAudio: "${quiz.stringAnswersAudio}",
            stringAnswersDoc: "${quiz.stringAnswersDoc}",
            stringAnswersText: "${quiz.stringAnswersText}",
            stringOptionsVideo: "${quiz.stringOptionsVideo}",
            stringOptionsImage: "${quiz.stringOptionsImage}",
            stringOptionsAudio: "${quiz.stringOptionsAudio}",
            stringOptionsDoc: "${quiz.stringOptionsDoc}",
            stringOptionsText: "${quiz.stringOptionsText}",
            stringQuestionsVideo: "${quiz.stringQuestionsVideo}",
            stringQuestionsImage: "${quiz.stringQuestionsImage}",
            stringQuestionsAudio: "${quiz.stringQuestionsAudio}",
            stringQuestionsDoc: "${quiz.stringQuestionsDoc}",
            stringQuestionsText: "${quiz.stringQuestionsText}",
            lastEditionDateString: "${this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss').toString()}"
          })
      }`;

        var variables = {
            id: quiz.idQuiz
        };

        var queryRefetch = gql`query($id: Int!) {
                        quizzes(where: { idQuiz: $id })
                        {
                          nodes ${this.returnQuizFields}
                        }
                  }`;

        return this.apollo.mutate({
            mutation: mutation,
            refetchQueries: [{
                query: queryRefetch,
                variables: variables
            }]
        }).toPromise().then(y => {
            console.log("TERMINA DE ACTUALIZAR", y)
        });
    }

    public DEPRECATEDcreateQuiz(quiz) {
        console.log("ESTOY EN CREAR QUIZ : ", quiz);
        var mutation = gql`mutation {
      createQuizzes (
          quizzes: {
            idQuiz: 0,
            stringCaptionsTitle: "${quiz.stringCaptionsTitle}",
            quizSwlevel: ${quiz.quizSwlevel ? quiz.quizSwlevel : 1},
            duration: ${parseInt(quiz.duration ? quiz.duration : 10)},
            idAuthor: ${parseInt(quiz.author.idUser)},
            idQuestionLanguage: ${parseInt(quiz.idQuestionLanguage ? quiz.idQuestionLanguage : 36)},
            idAnswerLanguage: ${parseInt(quiz.idAnswerLanguage ? quiz.idAnswerLanguage : 36)},
            lastEditionDateString: "${this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss').toString()}"
          }) {idQuiz}
      }`;

        return this.mutate(mutation).toPromise().then((a: any) => {
            var idNewQuiz = a.data.createQuizzes.idQuiz;
            /*var  variables = {
                id: idNewQuiz
            };
            var queryRefetch = gql`query($id: Int!) {
                          quizzes(where: { idQuiz: $id })
                          {
                            nodes ${this.returnQuizFields}
                          }
                    }`;*/

            /*this.newQuiz.next({
                id: 'q' + idNewQuiz,
                idOriginal: idNewQuiz,
                label: '',
                x: quiz.x,
                y: quiz.y,
                originalX: quiz.x,
                originalY: quiz.y,
                type: 'text',
                text: quiz.stringCaptionsTitle,
                size: 8,
                originalColor: '#D7DBDC',
                color: '#D7DBDC',
                description: quiz.description,
                nodeType: 'Quiz',
                nodeSwlevel: quiz.quizSwlevel,
                duration: quiz.duration,
                delete: false
            });

            return idNewQuiz;*/

            var Newquiz = {
                id: 'q' + idNewQuiz,
                idOriginal: idNewQuiz,
                label: '',
                x: quiz.x,
                y: quiz.y,
                originalX: quiz.x,
                originalY: quiz.y,
                type: 'text',
                text: quiz.stringCaptionsTitle,
                size: 8,
                originalColor: '#D7DBDC',
                color: '#D7DBDC',
                description: quiz.description,
                nodeType: 'Quiz',
                nodeSwlevel: quiz.quizSwlevel,
                duration: quiz.duration,
                delete: false
            };
            return Newquiz;
            /*return this.apollo.mutate({
              mutation: mutation,
              refetchQueries: [{
                query: queryRefetch,
                variables: variables
              }]
            }).toPromise().then(y => {
              return y;
            });*/
        });
    }

    public createTempQuizz(quiz: any) {
        this.newQuiz.next({
            id: 'q' + quiz.idOriginal,
            idOriginal: quiz.idOriginal,
            label: '',
            x: quiz.x,
            y: quiz.y,
            originalX: quiz.x,
            originalY: quiz.y,
            type: 'text',
            text: quiz.stringCaptionsTitle,
            size: 8,
            originalColor: '#D7DBDC',
            color: '#D7DBDC',
            description: quiz.description,
            nodeType: 'Quiz',
            nodeSwlevel: quiz.quizSwlevel,
            duration: quiz.duration,
            delete: false
        });
    }

    getTemplatesQuizzesByIdQuiz(id: number) {
        const filter = { idQuiz: id };
        return this.apollo.query<any>({
            query: gql`query($filter: TemplatesQuizzesFilter) {
                templatesQuizzes(
                    where: $filter
                  ) {
                    nodes ${this.returnTemplateQuizzesFields}
                  }
            }`,
            variables: {
                filter: filter
            }
        });
    }

    returnTemplateQuizzesFields = `{
    idTemplateQuizz
    idQuiz
    idTemplate
    template{
        idTemplate
        templateTittle
        templateSnapshot
        tittle
        quizInstructions
        writable
        compuCorrect
        multiplexed
        templateElement{
            idTemplateElement
            idTemplate
            elementType
            xposition
            yposition
            xsize
            ysize
            }
        }
    }`;

    DEPRECATEDgetTemplatesQuizzesById(id: number) {
        const filter = { idQuiz: id };
        return this.apollo.query<any>({
            query: gql`query($filter: TemplatesQuizzesFilter) {
            templatesQuizzes(
                where: $filter
              ) {
                nodes ${this.returnTemplateQuizzesFields}
              }
        }`,

            variables: {
                filter: filter
            }
        });
    }

    assignTemplateToQuiz(quizId: number, templateId: number): Observable<ApiResponse<any>> {
        return this.http.put<ApiResponse<any>>(`template/${templateId}/quiz/${quizId}`, {});
    }

    /**
     * Devuelve la plantilla de un quiz con sus elementos. Antiguo getTemplatesQuizzesById.
     * @param quizId identificador del quiz cuya plantilla se va a recuperar
     * @returns un observable que contiene la información dentro del objeto data
     */
    getQuizTemplate(quizId: number): Observable<QuizTemplate> {
        return this.http.get<ApiResponse<QuizTemplate>>(`templateQuizzesById/${quizId}`).pipe(map(res => res.data));
    }

    getTemplatesElementQuizzesByIdQuiz(idTemplate: number, idQuiz: number) {
        const filter = { idTemplateElement: idTemplate, idQuiz: idQuiz };

        return this.apollo.query<any>({
            query: gql`query($filter: TemplatesElementQuizzesFilter) {
            templatesElementQuizzes(
                where: $filter
              ) {
                nodes ${this.returnTemplatesElementQuizzesFields}
              }
        }`,
            variables: {
                filter: filter
            }
        });
    }

    DEPRECATEDgetTemplatesElementQuizzesByIdElement(idTemplateElement: number, idQuiz: number) {
        //const filter = {idTemplateElement: idTemplateElement};
        const filter = { idTemplateElement: idTemplateElement, idQuiz: idQuiz };

        return this.apollo.query<any>({
            query: gql`query($filter: TemplatesElementQuizzesFilter) {
            templatesElementQuizzes(
                where: $filter
              ) {
                nodes ${this.returnTemplatesElementQuizzesFields}
              }
        }`,
            variables: {
                filter: filter
            }
        });
    }

    /**
     * Devuelve los datos del elemento de la plantilla en el quiz: si es autocorregido y el texto/archivo asociado. Antiguo getTemplatesElementQuizzesByIdElement.
     * @param templateElementId identificador del elemento de la plantilla
     * @param quizId identificador del quiz al que pertenece la plantilla que tiene el elemento
     * @returns un observable que contiene la información dentro del objeto data
     */
    public getQuizElementData(templateElementId: number, quizId: number): Observable<ElementDataModel> {
        return this.http.get<ApiResponse<ElementDataModel[]>>(`templateElementsBy/${quizId}/${templateElementId}`).pipe(map(res => res.data[0]));
    }

    /**
     * Devuelve la plantilla asociada al quiz con todos sus elementos.
     * @param quiz quiz cuya template y elementos se van a recuperar.
     * @returns un observable que contiene la información de la plantilla con los elementos en la propiedad elements.
     */
    public getQuizTemplateWithElements(quiz: QuizModel): Observable<any> {
        return this.getQuizTemplate(quiz.idOriginal).pipe(
            switchMap(quizTemplate => {
                // Tenemos la información de la plantilla y la plantilla tiene elementos
                const calls = [];
                if (quizTemplate.template && quizTemplate.template.templateElement && quizTemplate.template.templateElement.length > 0) {

                    quizTemplate.template.templateElement.forEach(element => {
                        calls.push(this.getQuizElementData(element.idTemplateElement, quiz.idOriginal).pipe(
                            map(elementData => {
                                return {
                                    idTemplateElement: element.idTemplateElement,
                                    idTemplate: element.idTemplate,
                                    elementType: element.elementsType.idElementType,
                                    xPosition: element.xPosition,
                                    yPosition: element.yPosition,
                                    xSize: element.xSize,
                                    ySize: element.ySize,
                                    style: TEMPLATE_ELEMENTS_TYPES[element.elementsType.idElementType].name,
                                    icon: TEMPLATE_ELEMENTS_TYPES[element.elementsType.idElementType].icon,
                                    data: elementData ? elementData.data : undefined,
                                    idTemplateElementQuizz: elementData ? elementData.idTemplateElementQuizz : undefined
                                }
                            })
                        ))
                    })
                }
                return calls.length > 0 ? forkJoin(calls).pipe(map((elements) => { quizTemplate.elements = elements; return quizTemplate; })) : of(quizTemplate);
            })
        );
    }

    public setQuizElementData(quizId: number, elementId: number, responseCheck?: number, templateElementId?: number, file?: File, text?: string) {
        const body = {
            idTemplateElement: elementId,
            idQuiz: quizId,
            idTemplateElementQuizz: templateElementId,
            data: text,
            responseCheck: responseCheck ? 1 : 0
        }

        let form: FormData = new FormData()

        form.append('quiz', JSON.stringify(body));
        form.append('files', file || '');

        return this.http.post<ApiResponse<any>>(`uploadFileQuiz`, form);
    }

    getTargetsQuizzesByIdTarget(idTarget: number, idQuiz) {
        const filter = { idTarget: idTarget, idQuiz: idQuiz };

        return this.apollo.query<any>({
            query: gql`query($filter: TargetsQuizzesFilter) {
            targetsQuizzes(
                where: $filter
              ) {
                nodes ${this.returnTargetQuizzesFields}
              }
        }`,
            variables: {
                filter: filter
            }
        });
    }

    getQuizzesQuizzesById(id: number) {
        const filter = { myIdQuiz: id };
        return this.apollo.query<any>({
            query: gql`query($filter: QuizzesQuizzesFilter) {
            quizzesQuizzes(
                where: $filter
              ) {
                nodes ${this.returnQuizzesQuizzesFields}
              }
        }`,
            variables: {
                filter: filter
            }
        });
    }

    async createQuizzesQuizzes(quiz: any) {
        const variables = { myIdQuiz: parseInt(quiz.myIdQuiz) };

        var mutation = gql`mutation {
            createQuizzesQuizzes ( quizzesQuizzes: {
                      idQuizQuiz: 0,
                      myIdCourse: ${quiz.idCourse},
                      myIdTarget: ${quiz.idTarget},
                      myIdQuiz: ${quiz.myIdQuiz},
                      linkedIdQuiz: ${quiz.linkedIdQuiz},
                      role: "${quiz.role}",
                  }) {idQuizQuiz}
                }`;


        var refetchquery = gql`query($filter: QuizzesQuizzesFilter) {
            quizzesQuizzes(
                where: $filter
                ) {
                   nodes ${this.returnQuizzesQuizzesFields}
                }
             }`;

        await this.apollo.mutate({
            mutation: mutation,
            refetchQueries: [{
                query: refetchquery,
                variables: variables
            }]
        }).toPromise().then((y) => {
            console.log("TERMINO DE ACTUALIZAR createQuizzesQuizzes()");
        });
    }

    deleteQuizzesQuizzes(quiz: any) {
        //delete conection
    }

    // -------------------------------
    // G E T
    // -------------------------------

    // *** CURRENT NODE ***
    public setCurrentQuiz(quiz) {
        this.currentQuiz.next(quiz);
    }

    public setCurrentGraph(currentGraph) {
        this.currentGraph.next(currentGraph);
    }

    public setSelectQuiz(quiz) {
        this.selectQuiz.next(quiz);
    }

    public setNewQuiz(quiz) {
        this.newQuiz.next(quiz);
    }

    public setUserQuiz(userQuiz) {
        this.userQuiz.next(userQuiz);
    }

    public setArrayQuiz(arrayquiz) {
        this.arrayQuiz.next(arrayquiz);
    }

    public setLoading(loading: any) {
        this.loading.next(loading);
    }

    public setCurrenImagen(imagen: any) {
        this.currentImagen.next(imagen);
    }

    public getAutomatic() {
        return this._automatic.asObservable();
    }

    public setAutomatic(automatic: boolean): void {
        this._automatic.next(automatic);
        localStorage.setItem(STORAGE_KEYS.AUTOMATIC, automatic.toString());
    }

    public setExecuteAutomatic(executeAutomatic) {
        this.executeAutomatic.next(executeAutomatic);
        localStorage.setItem('executeAutomatic', executeAutomatic);
    }

    public setCurrentNode(node) {
        this.currentNode.next(node);
    }

    public setType(type) {
        this.type.next(type);
    }

    public emptyCurrentQuiz() {
        // Pendiente
    }

    public emptyArrayQuiz() {
        // Pendiente
    }


    // *** N O D E ***
    public getEmptyQuiz() {
        var date = this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss');
        return {
            stringCaptionsTitle: '',
            language1: '',
            language2: '',
            author: '',
            creation: date,
            edition: date,
            quizSwlevel: 0,
            duration: 0,
            text: ''
        }
    }



    // --------------------------------------
    //  Q U E R Y
    // --------------------------------------
    private query(query, variables) {

        return this.apollo.query<any>({
            query: query,
            variables: variables
        },
        );
    }

    private mutate(mutation) {

        return this.apollo.mutate({
            mutation: mutation
        });
    }


}
