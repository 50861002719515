import { SIGMA_CONSTANTS } from 'src/app/core/utils/sigma-constants';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GraphService } from 'src/app/core/services/graph/graph.service';
import { FormsComponent } from '../forms.component';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { gNode } from 'src/app/core/models/graph/gNode.model';
import { NodeService } from 'src/app/core/services/node/node.service';
import { LoginService } from 'src/app/core/services/login';

declare var $: any;

@Component({
    selector: 'app-node-form',
    templateUrl: './node-form.component.html',
    styleUrls: ['./node-form.component.scss']
})
export class NodeFormComponent extends FormsComponent implements OnInit {

    public form: FormGroup = new FormGroup({
        name: new FormControl('', Validators.required),
        description: new FormControl(''),
        level: new FormControl(0),
        duration: new FormControl('00:00'),
        power2: new FormControl(false),
        ordinalPower2: new FormControl(0),
        power1: new FormControl(false),
        ordinalPower1: new FormControl(0),
        power0: new FormControl(false),
        ordinalPower0: new FormControl(0),
        powerNegative1: new FormControl(false),
        ordinalPowerNegative1: new FormControl(0),
        powerNegative2: new FormControl(false),
        ordinalPowerNegative2: new FormControl(0),
        certificable: new FormControl(false),
        language1: new FormControl(''),
        language2: new FormControl(''),
        video: new FormControl(false),
        image: new FormControl(false),
        audio: new FormControl(false),
        textfile: new FormControl(false),
        pdf: new FormControl(false),
        ordinal: new FormControl(0),
    });

    @Output() send = new EventEmitter<boolean>();
    @Output() newNode = new EventEmitter<gNode>();

    private creation;
    private edition;
    private author;
    private data;
    private idpowerNodeTarget;

    constructor(public graphServ: GraphService, public nodeService: NodeService, public loginService: LoginService, public quizService: QuizzesService) {
        super(graphServ, nodeService, quizService);
        this.nodeService.currentNode.subscribe((n) => {
            this.record = n;
            console.log(n);
            this.refreshFileData(n);
            console.log(n);
        });
    }


    // ----------------------------------------------
    //     D A T A    I N I T
    // ----------------------------------------------
    public refreshFileData(node) {
        this.record.videoFile = node.videoFile;
        this.record.audioFile = node.audioFile;
        this.record.pictureFile = node.pictureFile;
        this.record.textfile = node.textfile;
        this.record.pdfFile = node.pdfFile;

        this.form.get('video').setValue(node.videoFile && node.videoFile !== '' ? true : false);
        this.form.get('image').setValue(node.pictureFile && node.pictureFile !== '' ? true : false);
        this.form.get('audio').setValue(node.audioFile && node.audioFile !== '' ? true : false);
        this.form.get('pdf').setValue(node.pdfFile && node.pdfFile !== '' ? true : false);
        this.form.get('textfile').setValue(node.textfile && node.textfile !== '' ? true : false);

        this.form.get('power2').setValue(node.ordinalPower2 === 0 || node.ordinalPower2 == null ? false : true);
        this.form.get('ordinalPower2').setValue(node.ordinalPower2 != 0 ? node.ordinalPower2 : undefined);
        this.form.get('power1').setValue(node.ordinalPower1 === 0 || node.ordinalPower1 == null ? false : true);
        this.form.get('ordinalPower1').setValue(node.ordinalPower1 != 0 ? node.ordinalPower1 : undefined);
        this.form.get('power0').setValue(node.ordinalPower0 === 0 || node.ordinalPower0 == null ? false : true);
        this.form.get('ordinalPower0').setValue(node.ordinalPower0 != 0 ? node.ordinalPower0 : undefined);
        this.form.get('powerNegative1').setValue(node.ordinalPowerNegative1 === 0 || node.ordinalPowerNegative1 == null ? false : true);
        this.form.get('ordinalPowerNegative1').setValue(node.ordinalPowerNegative1 != 0 ? node.ordinalPowerNegative1 : undefined);
        this.form.get('powerNegative2').setValue(node.ordinalPowerNegative2 === 0 || node.ordinalPowerNegative2 == null ? false : true);
        this.form.get('ordinalPowerNegative2').setValue(node.ordinalPowerNegative2 != 0 ? node.ordinalPowerNegative2 : undefined);
    }

    public readData(id) {

        if (id === 0) {
            this.record.author = this.loginService.getUser();

            //this.record = Object.assign({tittle:'',nodeSummary: '', author: this.loginService.getUser(), language: ''}, this.nodeService.getEmptyNode());

        } else {
            var dataSubscription = this.nodeService.getNodeById(id).subscribe((result) => {
                // this.reset();
                this.form.get('name').setValue(result.tittle);
                this.form.get('description').setValue(result.nodeSummary);
                this.form.get('level').setValue(result.nodeSwlevel);
                this.form.get('duration').setValue(this.minutesToTime(result.duration));
                this.form.get('language1').setValue(result.language);
                //  language2
                this.form.get('certificable').setValue(result.certificable);
                // ordinal
                this.form.get('ordinal').setValue(result.ordinal);

                //  this.refreshFileData(result);

                //this.record = {...result, ...this.record};
                this.record.author = result.author;
                this.record.creationDateString = result.creation;
                this.record.lastEditionDateString = result.lastEditionDateString;
                this.record.idCourseCreation = result.idCourseCreation;
                this.record.idTargetCreation = result.idTargetCreation;
                this.record.idOriginal = result.idNode;
                this.idpowerNodeTarget = result.idPowerNodeTarget;

                dataSubscription.unsubscribe();
            });
        }
    }

    // -----------------------------------------------
    //   D B      A C T I O N S
    // -----------------------------------------------


    public save() {
        const fv = this.form.value;

        this.record.certificable = fv.certificable;
        this.record.label = fv.name;
        this.record.tittle = fv.name;
        this.record.description = fv.description;
        this.record.nodesummary = fv.description;
        this.record.nodeSwlevel = fv.level;
        this.record.duration = this.durationToMinutes();
        if (fv.power2 && fv.ordinalPower2 != undefined) {
            this.record.power2 = fv.power2;
            this.record.ordinalPower2 = fv.ordinalPower2;
        } else {
            this.record.power2 = false;
            this.record.ordinalPower2 = null;
        }
        if (fv.power1 && fv.ordinalPower1 != undefined) {
            this.record.power1 = fv.power1;
            this.record.ordinalPower1 = fv.ordinalPower1;
        } else {
            this.record.power1 = false;
            this.record.ordinalPower1 = null;
        }
        if (fv.power0 && fv.ordinalPower0 != undefined) {
            this.record.power0 = fv.power0;
            this.record.ordinalPower0 = fv.ordinalPower0;
        } else {
            this.record.power0 = false;
            this.record.ordinalPower0 = null;
        }
        if (fv.powerNegative1 && fv.ordinalPowerNegative1 != undefined) {
            this.record.powerNegative1 = fv.powerNegative1;
            this.record.ordinalPowerNegative1 = fv.ordinalPowerNegative1;
        } else {
            this.record.powerNegative1 = false;
            this.record.ordinalPowerNegative1 = null;
        }
        if (fv.powerNegative2 && fv.ordinalPowerNegative2 != undefined) {
            this.record.powerNegative2 = fv.powerNegative2;
            this.record.ordinalPowerNegative2 = fv.ordinalPowerNegative2;
        } else {
            this.record.powerNegative2 = false;
            this.record.ordinalPowerNegative2 = null;
        }


        this.newNode.emit(this.record);

        if (this.record.idOriginal) {

            this.nodeService.updateNode(this.record);
            this.readData(this.record.idOriginal);
        } else {
            // OJO TEMPORAL
            this.record.size = SIGMA_CONSTANTS.NODE_SIZE;
            this.record.type = 'circle';
            this.record.url = SIGMA_CONSTANTS.IMAGE_DEFAULT;

            this.nodeService.DEPRECATEDcreateNode(this.record, this.idMapa, this.idCurso);

        }

        this.send.emit(true);
        this.changed.emit(false);

    }

    public onSubmit() {

    }

}


