import { Component, OnInit } from '@angular/core';

import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';

// Services
import { TranslateService } from '@ngx-translate/core';
import { CoursesService } from 'src/app/core/services/courses';
import { LoginService } from 'src/app/core/services/login';
import { UsersService } from 'src/app/core/services/users';

// Modals
import { CourseModel } from 'src/app/core/models/courses';

@Component({
  selector: 'app-modal-curso-tiempo',
  templateUrl: './modal-curso-tiempo.component.html',
  styleUrls: ['./modal-curso-tiempo.component.scss']
})
export class ModalCursoTiempoComponent implements OnInit {

  curso: CourseModel;

  cargando = false;

  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = ['6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17',
                                    '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30+'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [85, 101, 103, 95, 80, 78, 77, 75, 74, 50, 44, 43, 39, 37, 35, 33, 32, 32, 31, 31, 28, 27, 27, 24, 35],
      label: 'Tiempo medio en completar (mins)' }
  ];
  public barChartColors: Color[] = [
    { backgroundColor: '#1CA4FC' }
  ];

  constructor(public translateService: TranslateService,
              public coursesService: CoursesService,
              private loginService: LoginService,
              private usersService: UsersService,
              private activeModal: NgbActiveModal) {
  }

  ngOnInit() {

    this.cargando = true;

    this.cargando = false;
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);

    // tslint:disable-next-line: max-line-length
    //this.usersService.addUserLog(this.loginService.getUser().idUser, `Cerrar ventana modal - Curso tiempo`, 'OK');
  }
}
