import { Component, OnInit } from '@angular/core';

import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Services
import { TranslateService } from '@ngx-translate/core';
import { CoursesService } from 'src/app/core/services/courses';

// Models
import { CourseModel, CourseStatisticsAuthorModel } from 'src/app/core/models/courses';

@Component({
  selector: 'app-modal-curso-autores',
  templateUrl: './modal-curso-autores.component.html',
  styleUrls: ['./modal-curso-autores.component.scss']
})
export class ModalCursoAutoresComponent implements OnInit {

  curso: CourseModel;

  autores: CourseStatisticsAuthorModel[]  = [];

  cargando = false;

  constructor(public translateService: TranslateService,
              public coursesService: CoursesService,
              private activeModal: NgbActiveModal) {
  }

  ngOnInit() {

    this.cargando = true;

    this.obtenerEstadisticasAutores();

    this.cargando = false;
  }

  obtenerEstadisticasAutores() {

    this.autores = [
      {idCourse: 1, idAutor: 1, autor: 'John Gibson', cursos: 82, grafos: 893, explicaciones: 2614, quizzesOriginales: 5312, quizzesAlternativas: 18082, quizzesHomologadas: 1191, quizzesCertificadas: 5382, conexionesDentro: 4891, conexionesFuera: 614, rankings: 115, ratings: 4.30, image: '1.jpg'},
      {idCourse: 1, idAutor: 4, autor: 'Martha Van Aanholt', cursos: 12, grafos: 81, explicaciones: 566, quizzesOriginales: 21892, quizzesAlternativas: 11171, quizzesHomologadas: 29223, quizzesCertificadas: 29829, conexionesDentro: 1829, conexionesFuera: 261, rankings: 4511, ratings: 4.14, image: '4.jpg'},
      {idCourse: 1, idAutor: 3, autor: 'Ken Grealish', cursos: 0, grafos: 14, explicaciones: 98, quizzesOriginales: 1180, quizzesAlternativas: 2891, quizzesHomologadas: 913, quizzesCertificadas: 0, conexionesDentro: 281, conexionesFuera: 0, rankings: 89887, ratings: 3.75, image: '3.jpg'}];
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
}
