import { Component, OnInit, Input, ViewChild} from '@angular/core';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { BodyReproducirQuizzesComponent } from './body-reproducir-quizzes/body-reproducir-quizzes.component';
import { PlantillaReproducirQuizComponent } from './body-reproducir-quizzes/plantilla-reproducir-quiz/plantilla-reproducir-quiz.component';
import { FooterReproducirQuizzesComponent } from './footer-reproducir-quizzes/footer-reproducir-quizzes.component';
import { LoginService } from 'src/app/core/services/login/login.service';
import { TemplatesService } from 'src/app/core/services/templates/templates.service';

declare var $: any;

@Component({
  selector: 'app-modal-reproducir-quizzes',
  templateUrl: './modal-reproducir-quizzes.component.html',
  styleUrls: ['./modal-reproducir-quizzes.component.scss']
})
export class ModalReproducirQuizzesComponent implements OnInit {
mensajeQuiz:string;
quizEnviado:any;
mostrar:boolean;
@Input() type:string;
@Input() node:any;
@Input() quiz:any;
@Input() idCurso:string;
@Input() idMapa:number;
@Input() currentGraph:any;
@Input() automatic:any;

@ViewChild(BodyReproducirQuizzesComponent,{static:false}) bodyReproducir: BodyReproducirQuizzesComponent;
@ViewChild(FooterReproducirQuizzesComponent,{static:true}) footerReproducir: FooterReproducirQuizzesComponent;

constructor(private quizService: QuizzesService,
            public loginService: LoginService,
            public templateService:TemplatesService) {
                 this.quizService.automatic.subscribe((n) => {
                    this.automatic = n;
                  });
 }

ngOnInit() {
    console.log(" this.currentGraph : ",this.currentGraph);
    localStorage.getItem('automatic');
    this.automatic=JSON.parse(localStorage.getItem('automatic'));
    if(this.type=="quiz"){
        this.quizService.setCurrentQuiz(this.node);
    }else{
        this.quizService.setCurrentNode(this.node);
    }
    this.currentGraph.idTarget=this.idMapa;
    this.currentGraph.idCourse=this.idCurso;
    this.quizService.setCurrentGraph(this.currentGraph);
    this.quizService.setAutomatic(this.automatic);
    this.quizService.setType(this.type);
    if(this.type=="quiz"){
        this.mostrarPregunta(this.node.idOriginal);
    }
}

public mostrarPregunta(id:number){
    //Buscar el quiz relacionado
    var arrayQuizzes=[];
    var quizCurrent={linkedIdQuiz:id};
    var idQuiz:any;
    var susbQuizQuiz = this.quizService.getQuizzesQuizzesById(id).subscribe((data) => {
           arrayQuizzes = data.data.quizzesQuizzes.nodes;
           arrayQuizzes.push(quizCurrent)
           if(arrayQuizzes.length > 0){
               var quizAsociado = this.obtenerQuizAsociado(arrayQuizzes);
                   idQuiz=quizAsociado.linkedIdQuiz
           }

    this.quizService.getQuizById(idQuiz).then((quiz)=>{
       if(quiz && quiz.templatesQuizzes[0]){
           var userQuiz={
               idUser:this.loginService.getUser().idUser,
               idQuiz:quiz.idQuiz,
               idTarget:this.currentGraph.idTarget
             };

             /*this.quizService.getUsersQuizzes(userQuiz).then((usersQuizzes)=>{
               if(usersQuizzes){
                   usersQuizzes.timesDone = usersQuizzes.timesDone + 1;
               }
               this.quizService.createUsersQuizzes(userQuiz,usersQuizzes);
               this.quizService.setUserQuiz(userQuiz);
               this.mostrarQuiz(quiz);
               this.quizService.setSelectQuiz(quiz);
               this.templateService.setFlagTemplateUpdate(true);
            })*/
            this.quizService.createUsersQuizzes(userQuiz);
            this.quizService.setUserQuiz(userQuiz);
            this.mostrarQuiz(quiz);
            this.quizService.setSelectQuiz(quiz);
            this.templateService.setFlagTemplateUpdate(true);
         }
    });
   susbQuizQuiz.unsubscribe();
   })
  }

  obtenerQuizAsociado(arrayQuizzes:any){
    var min = 0
    var max =arrayQuizzes.length -1
    var i = Math.floor(Math.random() * (max - min) + min);
    return arrayQuizzes[i];
   }

  //Evento desde el Foot
  mostrarQuiz(quiz:any){
    this.bodyReproducir.showQuiz(quiz);
  }
}
