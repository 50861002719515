import { Component, OnInit } from '@angular/core';

// Services
import { TranslateService } from '@ngx-translate/core';

// Utils
import { LocalStorage } from 'src/app/core/utils';

@Component({
  selector: 'app-no-page-found',
  templateUrl: './no-page-found.component.html',
  styleUrls: ['./no-page-found.component.scss']
})
export class NoPageFoundComponent implements OnInit {

  public constructor(public translateService: TranslateService,
                     private localStorage: LocalStorage) {
  }

  public ngOnInit() {

    const lang = this.localStorage.getItem('language');
    this.translateService.use(lang);
  }
}
