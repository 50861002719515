import { Component, OnInit, ElementRef, ViewChild, Input, Renderer2, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as printJS from 'print-js';
import { LoginService } from 'src/app/core/services/login';
import { gNode } from 'src/app/core/models/graph/gNode.model';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { ModalSelectRecipientComponent } from '../../modal-select-recipient/modal-select-recipient.component';
import { NodeFormComponent } from '../../../forms/node-form/node-form.component';
import { NodeService } from 'src/app/core/services/node/node.service';
import { ModalReproducirQuizzesComponent } from '../../modal-reproducir-quizzes/modal-reproducir-quizzes.component';
import { QuizzesService } from 'src/app/core/services/quizzes/quizzes.service';
//import { PrintService } from 'src/app/core/services/shared/print.service';

declare var $: any;

@Component({
    selector: 'app-head-nodes',
    templateUrl: './head-nodes.component.html',
    styleUrls: ['./head-nodes.component.scss']
})

export class HeadNodesComponent implements OnInit {


    @ViewChild('nodeForm', { static: false }) private nodeForm: NodeFormComponent;

    @Input() nomQuiz: string;
    @Input() nomNode: string;
    @Input() nomGrafo: string;
    @Input() type: string;
    informacionQuiz: string;
    buttons = [
        {
            id: 1, icon: 'fab fa-instagram', color: '#517fa4'
        },
        {
            id: 2, icon: 'fab fa-whatsapp', color: '#4dc247'
        },
        {
            id: 3, icon: 'fab fa-twitter', color: '#55acee'
        },
        {
            id: 4, icon: 'fab fa-facebook-f', color: '#3b5998'
        },
        {
            id: 5, icon: 'salware', color: 'rgb(33, 76, 152)'
        },
        {
            id: 6, icon: 'fab fa-linkedin-in', color: '#007bb5'
        },
        {
            id: 7, icon: 'fab fa-youtube', color: '#bb0000'
        },
        {
            id: 8, icon: 'fab fa-pinterest', color: '#cb2027'
        }
    ];


    // A.G.

    @Input() node;
    //@Input() node;
    @Input() idCurso: number;
    @Input() idMapa: number;
    @Output() newNode = new EventEmitter<gNode>();
    currentGraph: any;
    public pendingChanges = false;

    setPendingChanges($event) {
        console.log('setPendingChanges');
        this.pendingChanges = $event;
    }

    receiveNewNodeData($event) {
        console.log('receiveNewNodeData');
        this.newNode.emit($event);
    }
    // Fin A.G.


    fabTogglerState = 'inactive';
    @ViewChild('quizzes', { static: true }) printEl: ElementRef;

    constructor(public activeModal: NgbActiveModal,
        public loginService: LoginService,
        private modalService: NgbModal,
        private nodeService: NodeService,
        private quizService: QuizzesService) {
        this.nodeService.currentNode.subscribe((n) => this.node = n);
        this.quizService.currentGraph.subscribe((g) => this.currentGraph = g)
    }


    ngOnInit() {

        // Mod. A.G. 12/11/200
        $('.dropdown-menu').parent().on({
            "shown.bs.dropdown": function () { this.closable = false; },
            "click": function (e) {
                if (e.target.className === 'close') {
                    this.closable = true;
                }
            },
            "hide.bs.dropdown": function () { return this.closable; }
        });
        // Fin A.G.
    }

    // Mod. A.G. 10/11/2020
    // Modal actualizar la descripción del nodo.
    public updateDesc = new Subject<boolean>();

    public updateDescription($event) {
        this.updateDesc.next($event);
    }
    // Fin Mod.


    closeModal(sendData) {
        this.activeModal.close(sendData);
    }

    enviarRedesSociales(id) {
        console.log("enviar redes sociales");
    }


    save(type: string) {

        this.nodeForm.save();
        this.activeModal.close();
    }

    imprimir() {
        console.log("Proceso de impresion");
    }

    seleccionarDestinatario() {
        const modalRef = this.modalService.open(ModalSelectRecipientComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-55'
            })

        // modalRef.componentInstance.idCurso = 1;
        modalRef.result.then((result) => {
            console.log(result);
        }, (reason) => {
        });
    }

    showAutomaticStudy() {
        if (this.loginService.esEstudiante()) {
            const modalRef = this.modalService.open(ModalReproducirQuizzesComponent,
                {
                    scrollable: true,
                    windowClass: 'modal-dialog-95'
                })
            modalRef.componentInstance.node = this.node;
            modalRef.componentInstance.idCurso = this.idCurso;
            modalRef.componentInstance.idMapa = this.idMapa;
            modalRef.componentInstance.currentGraph = this.currentGraph;
            modalRef.componentInstance.type = 'node';
            modalRef.componentInstance.nomGrafo = '';
            modalRef.result.then((result) => {
                console.log(result);
            }, (reason) => {
            });
        }

    }

}
