import { User } from './../../models/users/user.models';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

// GraphQL
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

// Models
import {
    UserAuthorModel, UserParentModel, UserCenterModel,
    UserEmployeerModel, UserStudentParentModel, UserProfileValidationModel, UserStudentCenterModel,
    UserTeacherCenterModel, UserStudentTeacherModel, UserSettingsProfileModel
} from 'src/app/core/models/users';

// Utils
import { DateUtils } from 'src/app/core/utils';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const UPDATEUSER = 'update-user'

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    userLiteFields = `{
    idUser
    firstName
    surname
  }`;

    userMiddleFields = `{
      idUser
      firstName
      surname
      mail
      mobile
      pictureUser
      birthdate
      birthdateString
  }`;

    centerFields = `{
    idCenter
    centerName
    centerAddress
    centerCountry
    centerRegion
    centerMail
    centerPhone
    centerUrl
    centerPicture
  }`;

    enterpriseFields = `{
    idEnterprise
    name
    address
    country
    region
    mail
    phone
    url
    remarks
  }`;

    userStudentsParentsFields = `{
    idUserStudentParent
    idUser
    firstName
    surname
    mail
    mobile
    birthdate
    birthdateString
    creationDate
    creationDateString
  }`;

    userStudentsCentersFields = `{
    idUserStudentCenter
    idUser
    idCenter
    creationDate
    creationDateString
    center ${this.centerFields}
  }`;

    userStudentsTeachersFields = `{
    idUserStudentTeacher
    idStudent
    idTeacher
    student ${this.userLiteFields},
    teacher ${this.userLiteFields},
    creationDate
    creationDateString
    validationDate
    validationDateString
    unvalidateDate
    unvalidateDateString
  }`;

    userTeachersCentersFields = `{
    idUserTeacherCenter
    idUser
    idCenter
    creationDate
    creationDateString
    center ${this.centerFields}
  }`;

    usersAuthorsFields = `{
    idUserAuthor
    idUser
    bic
    iban
    creationDate
    creationDateString
  }`;

    usersParentsFields = `{
    idUserParent
    idUser
    creditCard
    expirationDateCard
    cvv
    creationDate
    creationDateString
  }`;

    usersCentersFields = `{
    idUserCenter
    idUser
    idCenter
    creationDate
    creationDateString
    center ${this.centerFields}
  }`;

    usersEnterprisesFields = `{
    idUserEnterprise
    idUser
    idEnterprise
    creationDate
    creationDateString
    enterprise ${this.enterpriseFields}
  }`;

    usersProfileValidationFields = `{
    idUserProfileValidation
    idUserStudentParent
    idUserTeacherCenter
    idUserEmployeerEnterprise
    sendDate
    validationDate
  }`;

    usersSettingsProfilesFields = `{
    idUserSettingProfile
    idUser
    profile
    creationDate
    creationDateString
  }`;

    userFields = `{
    idUser
    nick
    keyWord
    firstName
    surname
    birthdate
    birthdateString
    mail
    mobile
    pictureUser
    idPreferredLanguage
    salwareAdministration
    creationDate
    creationDateString
    profile
    idGenre
    validationDate
    validationDateString
    usersStudentsParents ${this.userStudentsParentsFields}
    usersStudentsCenters ${this.userStudentsCentersFields}
    usersStudentsTeachers ${this.userStudentsTeachersFields}
    usersTeachersCenters ${this.userTeachersCentersFields}
    usersProfileValidation ${this.usersProfileValidationFields}
    usersAuthors ${this.usersAuthorsFields}
    usersParents ${this.usersParentsFields}
    UsersCenters ${this.usersCentersFields}
    usersEnterprises ${this.usersEnterprisesFields}
    usersSettingsProfiles ${this.usersSettingsProfilesFields}
  }`;

    userValidationFields = `{
    idUser
    uuid
    attempNumbers
    sendDate
    validationDate
  }`;

    usersQuery = gql`
  {
    users {
      nodes {
        idUser
        nick
        firstName
        surname
        birthdate
        birthdateString
        mail
        mobile
        idPreferredLanguage
        idGenre
      }
    }
  }`;

    constructor(private apollo: Apollo,
        private dateUtils: DateUtils,
        private http: HttpClient) { }

    getUserByIdUser(idUser):Observable<any> {
        const apiUrl = 'getUserById?id=' + idUser;
        return this.http.get<any>(apiUrl);
    }

    updateUser(user: User):Observable<any> {
      return this.http.post<any>(UPDATEUSER,user)
    }


    // Verifica si el usuario ha introducido la informacion suficiente para ser validado
    checkIfUserDataAreRegistered(user, profile) {

        // Para que tenga acceso a todas las funcionalidades, voy a comprobar el perfil
        // Dependiendo del perfil habra que comprobar unas cosas u otras
        switch (profile) {

            case 'estudiante':

                // La fecha de nacimiento debe estar informada
                if (user.birthdate == null || user.birthdate === undefined) {
                    return { result: false, mensaje: 'USERDATA.AVISOINFORMARDATOS', tab: 'Datos' };
                } else {
                    // Debo comprobar que es mayor de 18 años, si no es mayor de 18 años debo
                    // comprobar que ya lo ha validado uno de sus padres
                    let fecha = new Date();
                    fecha.setTime(Date.parse(user.birthdate));

                    if (new Date() > new Date(fecha.setFullYear(fecha.getFullYear() + 18))) {
                        return { result: true };
                    } else {
                        // Y ahora hay que verificar si algun padre le ha validado
                        if (user.usersProfileValidation !== undefined &&
                            user.usersProfileValidation.filter(x => x.validationDate !== undefined && x.validationDate != null).length > 0) {
                            // Ya ha sido validado por alguno de sus padres
                            return { result: true };
                        } else {
                            // Y lo mando a los datos de usuario
                            return { result: false, mensaje: 'USERDATA.AVISODEBESSERVALIDADOPORALGUNODETUSPADRES', tab: 'Padres' };
                        }
                    }
                }
            default:
                return { result: true };
        }
    }

    // Users Authors
    setUserAuthor(data: UserAuthorModel) {

        const userAuthorFields = `usersAuthors: {
      idUserAuthor: ${data.idUserAuthor}
      idUser: ${data.idUser}
      bic: ${(data.bic !== null && data.bic !== undefined) ? `"${data.bic}"` : null}
      iban: ${(data.iban !== null && data.iban !== undefined) ? `"${data.iban}"` : null}
      creationDate: ${data.creationDate === null || data.creationDate === undefined ?
                `"${this.dateUtils.getDateFormatted(new Date())}"` : `"${data.creationDate}"`}
      creationDateString: ${data.creationDateString === null || data.creationDateString === undefined ?
                `"${this.dateUtils.getDateFormatted(new Date())}"` : `"${data.creationDateString}"`}
    }`;

        if (data.idUserAuthor === 0) {

            const createUserAuthor = gql`mutation {
        createUsersAuthors (${userAuthorFields})
        ${this.usersAuthorsFields}
      }`;

            return this.apollo.mutate({
                mutation: createUserAuthor
            });
        } else {

            const updateUserAuthor = gql`mutation {
        updateUsersAuthors (${userAuthorFields})
      }`;

            return this.apollo.mutate({
                mutation: updateUserAuthor
            });
        }
    }
    // Fin Users Authors

    // Users Parents
    setUserParent(data: UserParentModel) {

        const userParentFields = `usersParents: {
      idUserParent: ${data.idUserParent}
      idUser: ${data.idUser}
      creditCard: ${(data.creditCard !== null && data.creditCard !== undefined) ? `"${data.creditCard}"` : null}
      expirationDateCard: ${(data.expirationDateCard !== null && data.expirationDateCard !== undefined) ?
                `"${data.expirationDateCard}"` : null}
      cvv: ${(data.cvv !== null && data.cvv !== undefined) ? `"${data.cvv}"` : null}
      creationDate: ${data.creationDate === null || data.creationDate === undefined ?
                `"${this.dateUtils.getDateFormatted(new Date())}"` : `"${data.creationDate}"`}
      creationDateString: ${data.creationDateString === null || data.creationDateString === undefined ?
                `"${this.dateUtils.getDateFormatted(new Date())}"` : `"${data.creationDateString}"`}
    }`;

        if (data.idUserParent === 0) {

            const createUserParent = gql`mutation {
        createUsersParents (${userParentFields})
        ${this.usersParentsFields}
      }`;

            return this.apollo.mutate({
                mutation: createUserParent
            });
        } else {

            const updateUserParent = gql`mutation {
        updateUsersParents (${userParentFields})
      }`;

            return this.apollo.mutate({
                mutation: updateUserParent
            });
        }
    }
    // Fin Users Parents

    // Users Centers
    setUserCenter(data: UserCenterModel) {

        const userCenterFields = `usersCenters: {
      idUserCenter: ${data.idUserCenter}
      idUser: ${data.idUser}
      idCenter: ${(data.idCenter !== null && data.idCenter !== undefined) ? data.idCenter : null}
      creationDate: ${data.creationDate === null || data.creationDate === undefined ?
                `"${this.dateUtils.getDateFormatted(new Date())}"` : `"${data.creationDate}"`}
      creationDateString: ${data.creationDateString === null || data.creationDateString === undefined ?
                `"${this.dateUtils.getDateFormatted(new Date())}"` : `"${data.creationDateString}"`}
    }`;

        if (data.idUserCenter === 0) {

            const createUserCenter = gql`mutation {
        createUsersCenters (${userCenterFields})
        ${this.usersCentersFields}
      }`;

            return this.apollo.mutate({
                mutation: createUserCenter
            });
        } else {

            const updateUserCenter = gql`mutation {
        updateUsersCenters (${userCenterFields})
      }`;

            return this.apollo.mutate({
                mutation: updateUserCenter
            });
        }
    }
    // Fin Users Centers

    // Users Enterprises
    setUserEnterprise(data: UserEmployeerModel) {

        const userEnterpriseFields = `usersEnterprises: {
      idUserEnterprise: ${data.idUserEnterprise}
      idUser: ${data.idUser}
      idEnterprise: ${(data.idEnterprise !== null && data.idEnterprise !== undefined) ? data.idEnterprise : null}
      creationDate: ${data.creationDate === null || data.creationDate === undefined ?
                `"${this.dateUtils.getDateFormatted(new Date())}"` : `"${data.creationDate}"`}
      creationDateString: ${data.creationDateString === null || data.creationDateString === undefined ?
                `"${this.dateUtils.getDateFormatted(new Date())}"` : `"${data.creationDateString}"`}
    }`;

        if (data.idUserEnterprise === 0) {

            const createUserEnterprise = gql`mutation {
        createUsersEnterprises (${userEnterpriseFields})
        ${this.usersEnterprisesFields}
      }`;

            return this.apollo.mutate({
                mutation: createUserEnterprise
            });
        } else {

            const updateUserEnterprise = gql`mutation {
        updateUsersEnterprises (${userEnterpriseFields})
      }`;

            return this.apollo.mutate({
                mutation: updateUserEnterprise
            });
        }
    }
    // Fin Users Enterprises

    setUserStudentParent(data: UserStudentParentModel) {

        const userStudentParentFields = `usersStudentsParents: {
      idUserStudentParent: ${data.idUserStudentParent}
      idUser: ${data.idUser}
      firstName: ${(data.firstName !== null && data.firstName !== undefined) ? `"${data.firstName}"` : null}
      surname: ${(data.surname !== null && data.surname !== undefined) ? `"${data.surname}"` : null}
      birthdate: ${(data.birthdate !== null && data.birthdate !== undefined && data.birthdate !== '') ? `"${data.birthdate}"` : null}
      birthdateString: ${(data.birthdate !== null && data.birthdate !== undefined) ?
                `"${data.birthdate}"` : null}
      mail: ${(data.mail !== null && data.mail !== undefined) ? `"${data.mail}"` : null}
      mobile: ${(data.mobile !== null && data.mobile !== undefined) ? `"${data.mobile}"` : null}
      creationDate: ${data.creationDate === null || data.creationDate === undefined ?
                `"${this.dateUtils.getDateFormatted(new Date())}"` : `"${data.creationDate}"`}
      creationDateString: ${data.creationDateString === null || data.creationDateString === undefined ?
                `"${this.dateUtils.getDateFormatted(new Date())}"` : `"${data.creationDateString}"`}
    }`;

        if (data.idUserStudentParent === 0) {

            const createUserStudentParent = gql`mutation {
        createUsersStudentsParents (${userStudentParentFields})
        { idUserStudentParent }
      }`;

            return this.apollo.mutate({
                mutation: createUserStudentParent
            });
        } else {

            const updateUserStudentParent = gql`mutation {
        updateUsersStudentsParents (${userStudentParentFields})
      }`;

            return this.apollo.mutate({
                mutation: updateUserStudentParent
            });
        }
    }

    deleteUserStudentParent(idUserStudentParent) {

        const deleteUserStudentParent = gql`mutation {
      deleteUsersStudentsParents (id: ${idUserStudentParent})
    }`;

        return this.apollo.mutate({
            mutation: deleteUserStudentParent
        });
    }
    // Fin Users Students Parents

    // Users Students Centers
    setUserStudentCenter(data: UserStudentCenterModel) {

        const userStudentCenterFields = `usersStudentsCenters: {
      idUserStudentCenter: ${data.idUserStudentCenter}
      idUser: ${data.idUser}
      idCenter: ${data.idCenter}
      creationDate: ${data.creationDate === null || data.creationDate === undefined ?
                `"${this.dateUtils.getDateFormatted(new Date())}"` : `"${data.creationDate}"`}
      creationDateString: ${data.creationDateString === null || data.creationDateString === undefined ?
                `"${this.dateUtils.getDateFormatted(new Date())}"` : `"${data.creationDateString}"`}
    }`;

        if (data.idUserStudentCenter === 0) {

            const createUserStudentCenter = gql`mutation {
        createUsersStudentsCenters (${userStudentCenterFields})
        { idUserStudentCenter }
      }`;

            return this.apollo.mutate({
                mutation: createUserStudentCenter
            });
        }
    }

    deleteUserStudentCenter(idUserStudentCenter) {

        const deleteUserStudentCenter = gql`mutation {
      deleteUsersStudentsCenters (id: ${idUserStudentCenter})
    }`;

        return this.apollo.mutate({
            mutation: deleteUserStudentCenter
        });
    }
    // Fin Users Students Centers

    // Users Students Teachers
    getTeacherCenterByIdCenter(idCenter) {

        console.log(idCenter)
        const filter = { idCenter };

        return this.apollo.query<any>({
            query: gql`query($filter: UsersTeachersCentersFilter) {
        usersTeachersCenters( where: $filter ) {
          nodes {
            user {
              idUser
              firstName
              surname
            }
          }
        }
      }`,
            variables: {
                filter
            }
        });
    }

    setUserStudentTeacher(data: UserStudentTeacherModel) {

        const userStudentTeacherFields = `usersStudentsTeachers: {
      idUserStudentTeacher: ${data.idUserStudentTeacher}
      idStudent: ${data.idStudent}
      idTeacher: ${data.idTeacher}
      creationDate: ${data.creationDate === null || data.creationDate === undefined ?
                `"${this.dateUtils.getDateFormatted(new Date())}"` : `"${data.creationDate}"`}
      creationDateString: ${data.creationDateString === null || data.creationDateString === undefined ?
                `"${this.dateUtils.getDateFormatted(new Date())}"` : `"${data.creationDateString}"`}
      validationDate: ${data.validationDate === null || data.validationDate === undefined ?
                `"${this.dateUtils.getDateFormatted(new Date())}"` : `"${data.validationDate}"`}
      validationDateString: ${data.validationDateString === null || data.validationDateString === undefined ?
                `"${this.dateUtils.getDateFormatted(new Date())}"` : `"${data.validationDateString}"`}
    }`;

        if (data.idUserStudentTeacher === 0) {

            const createUserStudentTeacher = gql`mutation {
        createUsersStudentsTeachers (${userStudentTeacherFields})
        { idUserStudentTeacher }
      }`;

            return this.apollo.mutate({
                mutation: createUserStudentTeacher
            });
        }
    }

    validateUserStudentTeacher(idUserStudentTeacher) {

        const userStudentTeacherFields = `usersStudentsTeachers: {
      idUserStudentTeacher: ${idUserStudentTeacher}
      validationDate: "${this.dateUtils.getDateFormatted(new Date())}"
      validationDateString: "${this.dateUtils.getDateFormatted(new Date())}"
    }`;

        const updateUserStudentTeacher = gql`mutation {
      updateUsersStudentsTeachers (${userStudentTeacherFields})
    }`;

        return this.apollo.mutate({
            mutation: updateUserStudentTeacher
        });
    }

    deleteUserStudentTeacher(idUserStudentTeacher) {

        const deleteUserStudentTeacher = gql`mutation {
      deleteUsersStudentsTeachers (id: ${idUserStudentTeacher})
    }`;

        return this.apollo.mutate({
            mutation: deleteUserStudentTeacher
        });
    }
    // Fin Users Students Teachers

    // Users Teachers Centers
    getUserStudentsTeacherByIdTeacher(idTeacher) {
        const filter = { idTeacher };

        return this.apollo.query<any>({
            query: gql`query($filter: UsersStudentsTeachersFilter) {
        usersStudentsTeachers( where: $filter ) {
          nodes
          {
            idUserStudentTeacher
            idStudent
            idTeacher
            student ${this.userMiddleFields}
            teacher ${this.userMiddleFields}
            creationDate
            creationDateString
            validationDate
            validationDateString
            unvalidateDate
            unvalidateDateString
          }
        }
      }`,
            variables: {
                filter
            }
        });
    }

    setUserTeacherCenter(data: UserTeacherCenterModel) {

        const userTeacherCenterFields = `usersTeachersCenters: {
      idUserTeacherCenter: ${data.idUserTeacherCenter}
      idUser: ${data.idUser}
      idCenter: ${data.idCenter}
      creationDate: ${data.creationDate === null || data.creationDate === undefined ?
                `"${this.dateUtils.getDateFormatted(new Date())}"` : `"${data.creationDate}"`}
      creationDateString: ${data.creationDateString === null || data.creationDateString === undefined ?
                `"${this.dateUtils.getDateFormatted(new Date())}"` : `"${data.creationDateString}"`}
    }`;

        if (data.idUserTeacherCenter === 0) {

            const createUserTeacherCenter = gql`mutation {
        createUsersTeachersCenters (${userTeacherCenterFields})
        { idUserTeacherCenter }
      }`;

            return this.apollo.mutate({
                mutation: createUserTeacherCenter
            });
        }
    }

    deleteUserTeacherCenter(idUserTeacherCenter) {

        const deleteUserTeacherCenter = gql`mutation {
      deleteUsersTeachersCenters (id: ${idUserTeacherCenter})
    }`;

        return this.apollo.mutate({
            mutation: deleteUserTeacherCenter
        });
    }
    // Fin Users Teachers Centers

    // Users Validations
    getUserProfileValidationByUUID(uuid) {
        const filter = { uuid };

        return this.apollo.query<any>({
            query: gql`query($filter: UsersProfileValidationFilter) {
            usersProfileValidation(
              where: $filter
            ) {
              nodes
              {
                idUserProfileValidation
                attempNumbers
                sendDate
                validationDate
                user {
                  firstName
                  surname
                }
              }
            }
      }`,
            variables: {
                filter
            }
        });
    }

    setUserProfileValidation(data: UserProfileValidationModel) {

        const userProfileValidationFields = `usersProfileValidation: {
      idUserProfileValidation: ${data.idUserProfileValidation}
      idUser: ${data.idUser}
      idUserStudentParent: ${data.idUserStudentParent != null ? data.idUserStudentParent : null}
      idUserTeacherCenter: ${data.idUserTeacherCenter != null ? data.idUserTeacherCenter : null}
      uuid: "${data.uuid}"
      messageSms: "${data.messageSms}"
      attempNumbers: ${data.attempNumbers}
      sendDate: "${this.dateUtils.getDateFormatted(new Date())}"
      sendDateString: "${this.dateUtils.getDateFormatted(new Date())}"
    }`;

        // Solo se puede hacer insert
        const createUserProfileValidation = gql`mutation {
      createUsersProfileValidation (${userProfileValidationFields})
        { uuid }
      }`;

        return this.apollo.mutate({
            mutation: createUserProfileValidation
        });
    }

    errorUserProfileValidation(data: UserProfileValidationModel) {

        const usersProfileValidationFields = `usersProfileValidation: {
      idUserProfileValidation: ${data.idUserProfileValidation}
      attempNumbers: ${data.attempNumbers}
    }`;

        // Actualizo los datos
        const updateUserProfileValidation = gql`mutation {
      updateUsersProfileValidation (${usersProfileValidationFields})
      }`;

        return this.apollo.mutate({
            mutation: updateUserProfileValidation
        });
    }

    updateUserProfileValidation(data: UserProfileValidationModel) {

        const usersProfileValidationFields = `usersProfileValidation: {
      idUserProfileValidation: ${data.idUserProfileValidation}
      validationDate: "${this.dateUtils.getDateFormatted(new Date())}"
      validationDateString: "${this.dateUtils.getDateFormatted(new Date())}"
    }`;

        // Actualizo los datos
        const updateUserProfileValidation = gql`mutation {
        updateUsersProfileValidation (${usersProfileValidationFields})
      }`;

        return this.apollo.mutate({
            mutation: updateUserProfileValidation
        });
    }

    checkUserProfileValidation(uuid: string, temporaryPassword: string) {
        const filter = { uuid, temporaryPassword };

        return this.apollo.query<any>({
            query: gql`query($filter: UsersProfileValidationFilter) {
            usersProfileValidation( where: $filter ) {
              nodes ${this.usersProfileValidationFields}
            }
      }`,
            variables: {
                filter
            }
        });
    }
    // Fin Users Profile Validation


    setUserSettingsProfile(data: UserSettingsProfileModel) {

        const userSettingProfileFields = `usersSettingsProfiles: {
      idUserSettingProfile: ${data.idUserSettingProfile}
      idUser: ${data.idUser}
      profile: "${data.profile}"
      creationDate: "${this.dateUtils.getDateFormatted(new Date())}"
      creationDateString: "${this.dateUtils.getDateFormatted(new Date())}"
    }`;

        // Solo se puede hacer insert
        const createUserValidation = gql`mutation {
        createUsersSettingsProfiles (${userSettingProfileFields})
        {
          idUserSettingProfile
          idUser
          profile
          creationDate
          creationDateString
         }
      }`;

        return this.apollo.mutate({
            mutation: createUserValidation
        });
    }
    // Fin Users Settings Profile

    // Sons User
    getSonsByUser(mobile) {
        const filter = { mobile };

        return this.apollo.query<any>({
            query: gql`query($filter: UsersStudentsParentsFilter) {
        usersStudentsParents( where: $filter ) {
              nodes {
                idUserStudentParent
                user {
                  idUser
                  firstName
                  surname
                  pictureUser
                  mail
                  birthdate
                  mobile
                  usersProfileValidation {
										idUserStudentParent
                    validationDate
                  }
                }
              }
            }
      }`,
            variables: {
                filter
            }
        });
    }
    // FIN Sons user
}
