import { Injectable } from '@angular/core';

import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RegisterIconsService {

    private icons = ['add_info',
        'add_quizz',
        'add_multi_quizz',
        'search_info',
        'search_quizz',
        'sing',
        'column',
        'piscina',
        'summation',
        'logaritmo',
        'innovation',
        'maths',
        'check',
        'foco',
        'police',
        'function',
        'waves',
        'force',
        'justice',
        'red',
        'subconjunto',
        'congruente',
        'implica',
        'clock',
        'operators',
        'askforhelp',
        'view_nodes',
        'view_quizzes',
        'view_all',
        'stairs',
        'graph',
        'salware',
        'alumnos',
        'favorite',
        'profesores',
        'send',
        'delete',
        'edit',
        'save',
        'done',
        'favorito',
        'person',
        'lonely',
        'couple',
        'add',
        'pulling-rope',
        'person_search',
        'template',
        'template_add',
        'manual',
        'automatic',
        'information',
        'school',
        'fact_check',
        'calendar_today',
        'work_outline',
        'military_tech',
        'sports_esports',
        'contact_phone',
        'psychology',
        'settings',
        'business',
        'group',
        'groups',
        'location_city',
        'person',
        'escalator',
        'play_arrow',
        'cast_for_education',
        'record_voice_over',
        'work',
        'person_search'
    ];

    constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private http: HttpClient) { }

    public registerIcons(): void {
        this.loadIcons(Object.values(this.icons), 'assets/images/icons');
    }

    private loadIcons(iconKeys: string[], iconUrl: string): void {
        iconKeys.forEach(key => {
            this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`));
        });
    }


    /*private loadIcons(iconKeys: string[], iconUrl: string): Subscription {
      return this.getJSON().subscribe(data => {
                            var key: string;
                            data.forEach(element => {
                              key = element.icon;
                              this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`));
                            });
              });
    }*/

}
