export class VariablesPublicUtils {

    public constructor() { }

    /*COLOR*/
    public LEAD = 'rgb(165, 165, 165)';
    public RED =  'rgb(255, 11, 18)';
    public YELLOW = 'rgb(255, 252, 56)';
    public GREEN = 'rgb(138, 253, 50)';
    public GREEN_DARK = 'rgb(40, 248, 45)';

    /*QUIZZES STACK*/
    public LATEST = 12;
    public PENULTIMATE = 6;
    public ANTEPEN = 3;
    public ANTEANTEPEN = 1.5;
    public ANTEANTEANTEPEN = 0.75;
    public ANTEANTEANTEANTEPEN = 0.375;

    /*ANSWERS*/
    public CORRECT = 1;
    public INCORRECT = 0.4;
}
