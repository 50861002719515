import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// Services
import { LoginService } from 'src/app/core/services/login';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    public constructor(private loginService: LoginService) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request)
            .pipe(
                map((res: any) => this.mapToError(res)),
                catchError(httpResponse => {
                    if (httpResponse.status === 401) {

                        this.loginService.logout();
                        location.reload(true);
                    }

                    let errorMessage = '';
                    const errorResponse = httpResponse.error;

                    if (httpResponse.status && httpResponse.status !== 200) {

                        errorMessage = httpResponse.statusText;

                        if (errorResponse && errorResponse.error_description) {
                            errorMessage = errorResponse.error_description;
                        } else if (errorResponse && errorResponse.error) {
                            errorMessage = errorResponse.error;
                        }
                    } else {
                        errorMessage = errorResponse.error ? errorResponse.error.error_description : '';
                    }

                    if (errorMessage === '') {
                        errorMessage = 'Sorry an unexpected error has occurred.';
                    }

                    console.log(`[ErrorInterceptor]. Error message: ${errorMessage}`);

                    return throwError(errorMessage);
                }
                )
            );
    }

    private mapToError(res: any) {
        if (res.error && res.error.code) {
            throw new Error(res.error);
        } else {
            return res;
        }
    }
}
