export class DateUtils {
    public constructor() { }

    public getDateFormatted(getDate: Date) {

        // adjust 0 before single digit date
        const date = ('0' + getDate.getDate()).slice(-2);

        // current month
        const month = ('0' + (getDate.getMonth() + 1)).slice(-2);

        // current year
        const year = getDate.getFullYear();

        const hours = ('0' + (getDate.getUTCHours())).slice(-2);
        const minutes = ('0' + (getDate.getUTCMinutes())).slice(-2);
        const seconds = ('0' + (getDate.getUTCSeconds())).slice(-2);
        const milliseconds = getDate.getUTCMilliseconds();

        // prints date in YYYY-MM-DD format
        const fecha = `${year}-${month}-${date}T${hours}:${minutes}:${seconds}.${milliseconds}+00:00`;

        return fecha;
    }
}
