import { Language } from "../shared";

export class FilterCourseModel {
    courseTittle: string;
    idCountry:    string;
    language:     Language;
    subject:      Subject;
    user:         UserCourse;
    

    constructor(title?:string, country?:string, idLang?: number, idSubject?:number, idUser?:number){
        this.courseTittle = title || null
        this.idCountry = country || null
        this.language = {
            idLanguage : idLang || 0
        }
        this.subject = {
            idSubject : idSubject || 0
        }
        this.user = {
            firstName : null,
            surname : null,
            idUser : idUser || 0
        }
    }
}

export interface Subject {
    idSubject: number;
}

export interface UserCourse {
    firstName: string;
    surname:   string;
    idUser:    number;
}

