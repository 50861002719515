import { Component, OnInit, ViewChild, ViewChildren, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModelIcono } from 'src/app/core/models/courses/icono.model';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-registrar-nodo',
  templateUrl: './registrar-nodo.component.html',
  styleUrls: ['./registrar-nodo.component.scss']
})
export class RegistrarNodoComponent implements OnInit {
    tittle:string;
    description:string;
    labels:string;
    senmotemos:ModelIcono[]=[];
    assostatics:ModelIcono[]=[];
    temporaries:ModelIcono[]=[];
    abstracts :ModelIcono[]=[];
    formats:any[]=[{titulo:"video",formato:"send"},{titulo:"imagen",formato:"image"},{titulo:"audio",formato:"volume_up"},{titulo:"pdf",formato:"picture_as_pdf"},{titulo:"texto",formato:"text_snippet"}];
    iconosSenmotemos:ModelIcono[]=[];
    iconosAssostatics:ModelIcono[]=[];
    iconosTemporaries:ModelIcono[]=[];
    iconosAbstracts:ModelIcono[]=[];
    selectedOptionSend:boolean=false;
    selectedOptionImage:boolean=false;
    selectedOptionVolume:boolean=false;
    selectedOptionPicture:boolean=false;
    selectedOptionText:boolean=false;

    @ViewChildren('senmotemo') elements;
    @ViewChild("senmotemo",{static:false}) senmotemo:ElementRef;
    @ViewChild('modalReproducirFormato', {static: false}) modalReproducirFormato: ModalComponent;

  constructor(public modalService: NgbModal) { }

  ngOnInit() {
    this.obtenerIconos();
  }

  cancelar() {
    this.modalService.dismissAll();
  }

  registrar() {
    this.modalService.dismissAll();
  }

  adicionarIconoSenmotemos(icono){
    console.log("Elementos: " + this.senmotemo.nativeElement);
    if (this.iconosSenmotemos.length<18){
        this.iconosSenmotemos.push(icono);
    }
  }

  adicionarIconoAssostatics(icono){
    if (this.iconosAssostatics.length<18){
        this.iconosAssostatics.push(icono);
    }
  }

  adicionarIconoTemporaries(icono){
    if (this.iconosTemporaries.length<18){
        this.iconosTemporaries.push(icono);
    }
  }

  adicionarIconoAbstracts(icono){
    if (this.iconosTemporaries.length<18){
       this.iconosAbstracts.push(icono);
    }
  }

  elegirOpcionSend(){
      this.selectedOptionSend=true;
  }

  elegirOpcionVolume(){
    this.selectedOptionVolume=true;
  }

  elegirOpcionPicture(){
    this.selectedOptionPicture=true;
  }

  elegirOpcionImage(){
    this.selectedOptionImage=true;
  }

  elegirOpcionText(){
    this.selectedOptionText=true;
  }

  reproducirFormato(){
    this.modalReproducirFormato.open({windowClass:"xlModal"});
  }

  obtenerIconos(){
    //Aqui debemos llamar al servicio
    this.senmotemos=[{id:1,nombre:"eye",src:"../../../../../assets/images/nodes/eye.png", tipo:""},
    {id:2,nombre:"ear",src:"../../../../../assets/images/nodes/ear.png", tipo:""},
    {id:3,nombre:"nose",src:"../../../../../assets/images/nodes/nose.png", tipo:""},
    {id:4,nombre:"hand",src:"../../../../../assets/images/nodes/hand.png", tipo:""},
    {id:5,nombre:"voice",src:"../../../../../assets/images/nodes/voice.png", tipo:""},
    {id:6,nombre:"heart",src:"../../../../../assets/images/nodes/heart.png", tipo:""},
    {id:7,nombre:"arrow_down",src:"../../../../../assets/images/nodes/arrow_down.png", tipo:""},
    {id:8,nombre:"arrow_up",src:"../../../../../assets/images/nodes/arrow_up.png", tipo:""}];

    /*this.senmotemos=[{id:1,nombre:"eye",src:"send"},
    {id:2,nombre:"ear",src:"send"},
    {id:3,nombre:"nose",src:"send"},
    {id:4,nombre:"hand",src:"send"},
    {id:5,nombre:"voice",src:"send"},
    {id:6,nombre:"heart",src:"send"},
    {id:7,nombre:"arrow_down",src:"send"},
    {id:8,nombre:"arrow_up",src:"send"}];*/

    this.assostatics=[{id:1,nombre:"person",src:"../../../../../assets/images/nodes/person.png", tipo:""},
    {id:2,nombre:"planet",src:"../../../../../assets/images/nodes/planet.png", tipo:""},
    {id:3,nombre:"lyrics",src:"../../../../../assets/images/nodes/lyrics.png", tipo:""},
    {id:4,nombre:"signs",src:"../../../../../assets/images/nodes/signs.png", tipo:""},
    {id:5,nombre:"run",src:"../../../../../assets/images/nodes/run.png", tipo:""},
    {id:6,nombre:"tools",src:"../../../../../assets/images/nodes/tools.png", tipo:""},
    {id:7,nombre:"finger",src:"../../../../../assets/images/nodes/finger.png", tipo:""},
    {id:8,nombre:"arrows",src:"../../../../../assets/images/nodes/twoarrows.png", tipo:""}];

    this.temporaries=[{id:1,nombre:"clock",src:"../../../../../assets/images/nodes/clock_yellow.png", tipo:""},
    {id:2,nombre:"bumeran",src:"../../../../../assets/images/nodes/bumeran.png", tipo:""},
    {id:3,nombre:"arrow",src:"../../../../../assets/images/nodes/arrow.png", tipo:""},
    {id:4,nombre:"camera",src:"../../../../../assets/images/nodes/camera.png", tipo:""},
    {id:5,nombre:"more",src:"../../../../../assets/images/nodes/more.png", tipo:""}];

    this.abstracts=[{id:1,nombre:"sing",src:"../../../../../assets/images/nodes/sing.png", tipo:""},
    {id:2,nombre:"column",src:"../../../../../assets/images/nodes/column.png", tipo:""},
    {id:3,nombre:"summation",src:"../../../../../assets/images/nodes/summation.png", tipo:""},
    {id:4,nombre:"algoritmo",src:"../../../../../assets/images/nodes/algoritmo.png", tipo:""},
    {id:5,nombre:"innovation",src:"../../../../../assets/images/nodes/innovation.png", tipo:""},
    {id:6,nombre:"lyrics",src:"../../../../../assets/images/nodes/lyrics_m.png", tipo:""},
    {id:7,nombre:"check",src:"../../../../../assets/images/nodes/check.png", tipo:""},
    {id:8,nombre:"foco",src:"../../../../../assets/images/nodes/foco.png", tipo:""},
    {id:9,nombre:"police",src:"../../../../../assets/images/nodes/police.png", tipo:""},
    {id:10,nombre:"function",src:"../../../../../assets/images/nodes/function.png", tipo:""},
    {id:11,nombre:"music",src:"../../../../../assets/images/nodes/music.png", tipo:""},
    {id:12,nombre:"waves",src:"../../../../../assets/images/nodes/waves.png", tipo:""},
    {id:13,nombre:"force",src:"../../../../../assets/images/nodes/force.png", tipo:""},
    {id:14,nombre:"justice",src:"../../../../../assets/images/nodes/justice.png", tipo:""},
    {id:15,nombre:"red",src:"../../../../../assets/images/nodes/red.png", tipo:""},
    {id:16,nombre:"integral",src:"../../../../../assets/images/nodes/integral.png", tipo:""}]
    }
}
