import { Component, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Services
import { TranslateService } from '@ngx-translate/core';
import { CoursesService } from 'src/app/core/services/courses';
import { LoginService } from 'src/app/core/services/login';
import { UsersService } from 'src/app/core/services/users';

@Component({
  selector: 'app-modal-curso-motivaciones-autor',
  templateUrl: './modal-curso-motivaciones-autor.component.html',
  styleUrls: ['./modal-curso-motivaciones-autor.component.scss']
})
export class ModalCursoMotivacionesAutorComponent implements OnInit {

  idCurso: string;

  valoracionEstudiante: number;
  emitidaAutor: number;

  cargando = false;

  constructor(public translateService: TranslateService,
              public coursesService: CoursesService,
              private loginService: LoginService,
              private usersService: UsersService,
              public activeModal: NgbActiveModal) {
  }

  ngOnInit() {

    this.cargando = true;

    this.cargando = false;
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);

    //this.usersService.addUserLog(this.loginService.getUser().idUser, `Cerrar ventana modal - Curso motivaciones autor`, 'OK');
  }

  // MODAL MOTIVACIONES ESTUDIANTES
  mostrarMotivacionesEstudiantes() {

  }

  // FIN MODAL MOTIVACIONES ESTUDIANTES

  // MODAL MOTIVACIONES AUTOR
  mostrarMotivacionesAutor() {

  }

  // FIN MODAL MOTIVACIONES AUTOR
}
