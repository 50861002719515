import { Component, OnInit } from '@angular/core';
import { SigmaToolbarsService } from 'src/app/core/services/sigma-toolbars/sigma-toolbars.service';

@Component({
  selector: 'app-show-modal-course',
  templateUrl: './show-modal-course.component.html',
  styleUrls: ['./show-modal-course.component.scss']
})
export class ShowModalCourseComponent implements OnInit {

  constructor(private toolsService: SigmaToolbarsService) { }

  ngOnInit() {
  }

  emitshowModalCourse(){
    this.toolsService.changeShowModalCourses();
  }

}
