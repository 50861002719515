import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TranslateService } from '@ngx-translate/core';

// Services
import { MastersService } from 'src/app/core/services/masters';
import { CoursesService } from 'src/app/core/services/courses';
import { LoginService } from 'src/app/core/services/login';
import { UsersService } from 'src/app/core/services/users';
import { AlertService } from 'src/app/core/services/shared';

// Models
import { CourseModel } from 'src/app/core/models/courses/course.model';
import { IdiomaModel } from 'src/app/core/models/masters';

@Component({
  selector: 'app-modal-lenguaje',
  templateUrl: './modal-curso-lenguaje.component.html'
})
export class ModalCursoLenguajeComponent implements OnInit {

  curso: CourseModel;

  buscadorLenguajePrincipal: string;
  buscadorLenguajeSecundario: string;

  listaLenguajes: IdiomaModel[];

  listaLenguajesPrincipal: IdiomaModel[];
  listaLenguajesSecundario: IdiomaModel[];

  constructor(public translateService: TranslateService,
              public coursesService: CoursesService,
              private loginService: LoginService,
              private usersService: UsersService,
              private mastersService: MastersService,
              private alertService: AlertService,
              public activeModal: NgbActiveModal) { }

  ngOnInit() {

    // // si no tengo idiomas creo uno vacio
    // if (this.curso.languageData === null) {
    //   this.curso.languageData = new IdiomaModel();

    //   this.curso.languageData.idLanguage = 0;
    //   this.curso.languageData.idLanguageIso = '-';
    //   this.curso.languageData.language = '';
    // }

    // // si no tengo idiomas creo uno vacio
    // if (this.curso.secondaryLanguageData === null) {
    //   this.curso.secondaryLanguageData = new IdiomaModel();

    //   this.curso.secondaryLanguageData.idLanguage = 0;
    //   this.curso.secondaryLanguageData.idLanguageIso = '-';
    //   this.curso.secondaryLanguageData.language = '';
    // }

    // Obtengo todos los idiomas disponibles
    this.mastersService.getAllLanguages().subscribe( data => {this.listaLenguajes = this.listaLenguajesPrincipal = this.listaLenguajesSecundario =  data;});
  }

  buscarLenguajePrincipal() {

    if (this.buscadorLenguajePrincipal === '') {
      this.listaLenguajesPrincipal = this.listaLenguajes;
    } else {
      // tslint:disable-next-line: max-line-length
      this.listaLenguajesPrincipal = this.listaLenguajes.filter(x => x.language.toUpperCase().indexOf(this.buscadorLenguajePrincipal.toUpperCase()) !== -1);
    }
  }

  buscarLenguajeSecundario() {

    if (this.buscadorLenguajeSecundario === '') {
      this.listaLenguajesSecundario = this.listaLenguajes;
    } else {
      // tslint:disable-next-line: max-line-length
      this.listaLenguajesSecundario = this.listaLenguajes.filter(x => x.language.toUpperCase().indexOf(this.buscadorLenguajeSecundario.toUpperCase()) !== -1);
    }
  }

  public seleccionarLenguaje(idLenguaje, idLenguajeISO, lenguaje, principal) {

    // Y aqui lo actualizo
    // this.coursesService.getCourseById(Number(this.curso.idCourse))
    //                     .subscribe(data  => {

    //                       const curso: CourseModel = data;

    //                       if (principal) {

    //                         // if (curso.languageData == null){
    //                         //   curso.languageData = new IdiomaModel();
    //                         // }

    //                         // // Modifico el lenguaje principal
    //                         // curso.languageData.idLanguage = idLenguaje;
    //                         // curso.languageData.idLanguageIso = idLenguajeISO;
    //                         // curso.languageData.language = lenguaje;

    //                         // Y lo grabo
    //                         this.coursesService.setCourse(curso)
    //                                           .subscribe((resp: any) => {
    //                                             // Muestro aviso
    //                                             // tslint:disable-next-line: max-line-length
    //                                             this.alertService.success(this.translateService.instant('CURSOLENGUAJE.SEHASELECCIONADOCORRECTAMENTEELLENGUAJEPRINCIPAL'), AlertService.AlertServiceContextValues.ModalCursoLenguaje);

    //                                             if (this.buscadorLenguajePrincipal !== '') {
    //                                               // Borro el texto si lo hubiera
    //                                               this.buscadorLenguajePrincipal = '';

    //                                               // Y recargo el listado
    //                                               this.buscarLenguajePrincipal();

    //                                               // Lo registro en el log
    //                                               // tslint:disable-next-line: max-line-length
    //                                               //this.usersService.addUserLog(this.loginService.getUser().idUser, `Grabar lenguaje principal curso - Curso Lenguaje`, 'OK');
    //                                             }
    //                                           }, (error) => {
    //                                             // tslint:disable-next-line: max-line-length
    //                                             this.alertService.error(this.translateService.instant('ERROR.HAOCURRIDOUNERROR'), AlertService.AlertServiceContextValues.ModalCursoLenguaje);

    //                                             // tslint:disable-next-line: max-line-length
    //                                             //this.usersService.addUserLog(this.loginService.getUser().idUser, `Grabar lenguaje principal curso - Curso Lenguaje - ERROR: ${error}`, 'ERROR');
    //                                           });
    //                       } else {

    //                         // if (curso.secondaryLanguageData == null){
    //                         //   curso.secondaryLanguageData = new IdiomaModel();
    //                         // }

    //                         // // Modifico el lenguaje secundario
    //                         // curso.secondaryLanguageData.idLanguage = idLenguaje;
    //                         // curso.secondaryLanguageData.idLanguageIso = idLenguajeISO;
    //                         // curso.secondaryLanguageData.language = lenguaje;

    //                         // Y lo grabo
    //                         this.coursesService.setCourse(curso)
    //                                           .subscribe((resp: any) => {
    //                                             // Muestro aviso
    //                                             // tslint:disable-next-line: max-line-length
    //                                             this.alertService.success(this.translateService.instant('CURSOLENGUAJE.SEHASELECCIONADOCORRECTAMENTEELLENGUAJESECUNDARIO'), AlertService.AlertServiceContextValues.ModalCursoLenguaje);

    //                                             if (this.buscadorLenguajeSecundario !== '') {
    //                                               // Borro el texto si lo hubiera
    //                                               this.buscadorLenguajeSecundario = '';

    //                                               // Y recargo el listado
    //                                               this.buscarLenguajeSecundario();

    //                                               // tslint:disable-next-line: max-line-length
    //                                               //this.usersService.addUserLog(this.loginService.getUser().idUser, `Grabar lenguaje secundario curso - Curso Lenguaje`, 'OK');
    //                                             }
    //                                           }, (error) => {
    //                                             // tslint:disable-next-line: max-line-length
    //                                             this.alertService.error(this.translateService.instant('ERROR.HAOCURRIDOUNERROR'), AlertService.AlertServiceContextValues.ModalCursoLenguaje);

    //                                             // tslint:disable-next-line: max-line-length
    //                                             //this.usersService.addUserLog(this.loginService.getUser().idUser, `Grabar lenguaje secundario curso - Curso Lenguaje - ERROR: ${error}`, 'ERROR');
    //                                           });
    //                       }
    //                     });
  }

    // Y lo grabo
  closeModal(sendData) {
    this.activeModal.close(sendData);

    // tslint:disable-next-line: max-line-length
    //this.usersService.addUserLog(this.loginService.getUser().idUser, `Cerrar ventana modal - Curso Lenguaje`, 'OK');
  }
}
