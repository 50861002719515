import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../models/shared/api-response.model';
import { ChangePasswordModel } from '../../models/users/change-password.model';

@Injectable({
    providedIn: 'root'
})
export class ChangePasswordService {

    constructor(private http: HttpClient) { }

    changePassword(user: ChangePasswordModel): Observable<any> {
        const url = `changed-keyword-user`;
        return this.http.post<any>(url, user);
    }

    checkToken(userId: number, token: string): Observable<boolean> {
        return this.http.get<ApiResponse<boolean>>(`validation-user-change-keyword/${userId}/${token}`).pipe(map(res => res.data));
    }
}
