import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChangePasswordService } from '../services/change-password/change-password.service';
import { ROUTES_NAME } from '../utils/routes-name';

@Injectable({
    providedIn: 'root'
})
export class ChangePasswordGuard implements CanLoad {
    constructor(private changePasswordService: ChangePasswordService, private router: Router) { }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        const idUser = this.router.getCurrentNavigation().extractedUrl.queryParams.idUser;
        const token = this.router.getCurrentNavigation().extractedUrl.queryParams.token;

        return this.changePasswordService.checkToken(idUser, token).pipe(map(res => {
            if (!res) {
                this.router.navigate([ROUTES_NAME.LOGIN]);
            }

            return res;
        }));
    }
}
