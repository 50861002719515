import { AuthorModel } from './../../../../core/models/masters/author-response.model';
import { MateriaModel } from 'src/app/core/models/masters';
import { IdiomaModel } from './../../../../core/models/masters/idioma.model';
import { PaisModel } from './../../../../core/models/masters/pais.model';
import { Component, OnInit } from '@angular/core';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Models
import { FilterCourseModel, CourseModel } from 'src/app/core/models/courses';

// Services
import { TranslateService } from '@ngx-translate/core';
import { CoursesService } from 'src/app/core/services/courses';
import { LoginService } from 'src/app/core/services/login';
import { MastersService } from 'src/app/core/services/masters';
import { AlertService } from 'src/app/core/services/shared';

// Components
import { ModalEditarCursoComponent } from 'src/app/shared/components/cursos/modal-editar-curso';

import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-modal-cursos',
    templateUrl: './modal-cursos.component.html',
    styleUrls: ['./modal-cursos.component.scss']
})
export class ModalCursosComponent implements OnInit {

    authorControl: FormControl = new FormControl();
    isMyCourses: FormControl = new FormControl(true);
    filteredOptions: Observable<AuthorModel[]>;

    cursos: CourseModel[] = [];
    filtro: FilterCourseModel = new FilterCourseModel();

    // Almacena la opcion que ha elegido el estudiante (Estudiar - Enseñar - Competir)
    opcion: string;

    materias: MateriaModel[];
    idiomas: IdiomaModel[];
    paises: PaisModel[];
    authors: AuthorModel[] = [];

    cargando = false;

    constructor(public translateService: TranslateService,
        public coursesService: CoursesService,
        public loginService: LoginService,
        public mastersService: MastersService,
        private alertService: AlertService,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal) {
    }

    displayFn(author?: AuthorModel): string {
        return author ? author.name : "";
    }

    private _filter(name: string): AuthorModel[] {

        if (name) {
            this.isMyCourses.disable()
            return this.authors.filter(author => author.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
        }
        else {
            this.isMyCourses.enable()
            return this.authors.slice()
        }
    }


    ngOnInit() {
        this.cargando = true;
        this.isMyCourses.patchValue(this.loginService.esAutor());
        this.obtenerDatosMaestros();
        this.filtrarCursos();
    }

    obtenerDatosMaestros() {
        this.mastersService.getAllSubjects().subscribe(data => { this.materias = data; });
        this.mastersService.getAllLanguages().subscribe(data => { this.idiomas = data; });
        this.mastersService.getAllCountries().subscribe(data => { this.paises = data });
        this.mastersService.getAuthors().subscribe(result => {

            this.authors = result.data

            this.filteredOptions = this.authorControl.valueChanges
                .pipe(
                    startWith(''),
                    map(value => typeof value === 'string' ? value : value.name),
                    map(name => this._filter(name))
                );
        })
    }

    updateUrl(event: any) {
        event.target.src = '../../assets/images/no-image.png'
    }

    checkValue() {
        if (this.isMyCourses.value) {
            this.authorControl.setValue('')
            this.authorControl.disable()
        }
        else
            this.authorControl.enable()
    }

    filtrarCursos() {
        this.cargando = true;
        const USER = this.loginService.getUser()
        const FILTERUSER = this.authorControl.value
        const FILTERMYCOURSES = this.isMyCourses.value

        if (FILTERMYCOURSES)
            this.filtro.user.idUser = USER.idUser
        else if (FILTERUSER)
            this.filtro.user.idUser = FILTERUSER.idUser
        else
            this.filtro.user.idUser = 0

        this.coursesService.getAllCourses(this.filtro)
            .subscribe(data => {
                this.cursos = data;
                this.cargando = false
            }, (error) => {
                // tslint:disable-next-line: max-line-length
                this.alertService.error(this.translateService.instant('ERROR.HAOCURRIDOUNERROR'), AlertService.AlertServiceContextValues.ModalCursoLenguaje);
                this.cargando = false
            });
    }

    verDetalleCurso(idCurso: number) {
        const modalRef = this.modalService.open(ModalEditarCursoComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-100'
            });

        modalRef.componentInstance.id = idCurso;
        modalRef.result.then((result) => {

            switch (result) {
                case 'Curso borrado':
                    // tslint:disable-next-line: max-line-length
                    this.alertService.success(this.translateService.instant('CURSOS.ELCURSOSEHABORRADOCORRECTAMENTE'), AlertService.AlertServiceContextValues.ModalCurso);
                    break;
                case 'Curso modificado':
                    // tslint:disable-next-line: max-line-length
                    this.alertService.success(this.translateService.instant('CURSOS.ELCURSOSEHAMODIFICADOCORRECTAMENTE'), AlertService.AlertServiceContextValues.ModalCurso);
                    break;
                case 'Abrir mapa':
                    // Esta opcion se produce cuando un estudiante ha seleccionado un mapa, hay que cerrar todas las modales
                    this.closeModal(result);
                    break;
                case 'Editar nodos mapa':
                    // Esta opcion se produce cuando un autor ha seleccionado editar los nodos, hay que cerrar todas las modales
                    this.closeModal(result);
                    break;
            }

            // Refresco el listado
            this.filtrarCursos();
        }, (reason) => {
        });
    }

    nuevoCurso() {
        const modalRef = this.modalService.open(ModalEditarCursoComponent, { scrollable: true, windowClass: 'modal-dialog-100 modal-dialog-100-nuevo' });
        modalRef.componentInstance.id = 'nuevo';
        modalRef.result.then((result) => { this.filtrarCursos(); }, (reason) => { });
    }

    closeModal(sendData) {
        this.activeModal.close(sendData);
    }
}
