import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PowerService {
    public powerActive = new Subject();
    public checksVisibility = new Subject();

    public emitPowerActive(numberPower: Array<number>) {
        this.powerActive.next(numberPower)
    }


    public emitChecksVisibility(visible) {
        this.checksVisibility.next(visible)
    }

    public convertNumberArrayToPowerObject(powers:Array<number>){
        let tmpObject:any = {}

        powers.forEach(e => {
            switch(e){
                case 3:                    
                    tmpObject.power3 = true
                    break;
                case 2:
                    tmpObject.power2 = true
                    break;
                case 1:
                    tmpObject.power1 = true
                    break;
                case 0:
                    tmpObject.power0 = true
                    break;
                case -1:
                    tmpObject.powerNegative1 = true
                    break;
                case -2:
                    tmpObject.powerNegative2 = true
                    break;
                case -3:
                    tmpObject.powerNegative3 = true
                    break;
            }
        })

        return tmpObject;

    }

}

