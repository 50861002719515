import { CourseModel } from 'src/app/core/models/courses';
import { Component, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Services
import { CoursesService } from 'src/app/core/services/courses';

@Component({
  selector: 'app-modal-subir-imagen',
  templateUrl: './modal-subir-imagen.component.html'
})
export class ModalSubirImagenComponent implements OnInit {

  id: string;
  nombreCurso: string;
  imagenCurso: string;
  tipo: string;

  imagenSubir: File;
  imagenTemp: any;

  constructor(public activeModal: NgbActiveModal,
              private coursesService: CoursesService) {
   }

  ngOnInit() {

    console.log('ngOnINIT', this.imagenCurso, this.tipo);
  }

  seleccionImagen(archivo: File) {

    if (!archivo) {
      this.imagenSubir = null;
      return;
    }

    if (archivo.type.indexOf('image') < 0) {

      // TO DO AVISOS
      console.log('El archivo seleccionado no es una imagen');
      // Swal.fire({
      //   title: 'Solo imagenes',
      //   text: 'El archivo seleccionado no es una imagen',
      //   icon: 'error'
      // });

      this.imagenSubir = null;
      return;
    }

    this.imagenSubir = archivo;

    let reader = new FileReader();
    let urlImagenTemp = reader.readAsDataURL(archivo);

    reader.onloadend = () => this.imagenTemp = reader.result;
  }

  subirImagen() {

    console.log(this.imagenSubir);

    // this.subirArchivoService.subirArchivo(this.imagenSubir, this.tipo, this.id)
    //                         .then(resp => {

    //                           console.log(resp);

                              // Y ahora en base al tipo actualizo una estructura u otra
    switch (this.tipo) {

      case 'cursos':

        // Y aqui lo actualizo
        // this.coursesService.getCourseById(Number(this.id))
        //                   .subscribe( data => {

        //                     const curso: CourseModel = data;

        //                     // Modifico la imagen
        //                     curso.cpicture = this.imagenSubir.name;

        //                     // Y lo grabo
        //                     this.coursesService.setCourse(curso)
        //                                       .subscribe((resp: any) => {
        //                                         // TO DO - MOSTRAR MENSAJES
        //                                         this.closeModal('La actualización se ha realizado correctamente');
        //                                       }, (error) => {
        //                                         console.log('Hubo un error: ', error);
        //                                         return null;
        //                                       });
        //                   });
    }
                            // })
                            // .catch( err => {
                            //   console.log('Error en la carga...');
                            // });
  }

  closeModal(sendData) {
    this.imagenTemp = null;
    this.imagenSubir = null;

    this.activeModal.close(sendData);
  }
}