import { Component } from '@angular/core';

import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Services
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-aceptar-cancelar',
  templateUrl: './modal-aceptar-cancelar.component.html',
  styleUrls: ['./modal-aceptar-cancelar.component.scss']
})
export class ModalAceptarCancelarComponent {

  mensaje: string;

  constructor(public translateService: TranslateService,
              private activeModal: NgbActiveModal) {
  }

  aceptar() {
    this.activeModal.close(true);
  }

  cancelar() {
    this.activeModal.close(false);
  }

  closeModal() {
    this.activeModal.close(false);
  }
}
