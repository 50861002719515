import * as JWT from 'jwt-decode';
import * as uuid from 'uuid';

export enum TokenType {
    JWT,
    BearerToken
}

export class AccessToken {

    private tokenPayload: any;
    private tokenType: TokenType;

    public readonly userRoles: any[] = [];

    public source: string;
    public userId: uuid;
    public userName: string;

    public constructor() {
        this.tokenType = TokenType.JWT;
    }

    public materializeFromLocalStorage(): boolean {
        if (this.isInBrowser()) {

            const logHeader = `[AccessToken::getTokenPayloadFromLocalStorage]`;

            const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

            if (!currentUser) {
                // console.warn(`${logHeader}. No user found in local storage`);
            } else if ( !currentUser.access_token ) {
                // console.warn(`${logHeader}. No access token found in local storage`);
            } else {

                this.tokenType === TokenType.BearerToken ?
                                   this.initializeBearerToken( currentUser ) : 
                                   this.initialize( currentUser.access_token );

                // console.warn( `${logHeader}. Access token found in local storage for current user` );
            }
        }
        return this.hasPayload;
    }

    public get hasPayload(): boolean {
        return this.tokenPayload;
    }

    public initialize(sourceAccessToken: string): boolean {

        this.source = sourceAccessToken;

        this.tokenPayload = JWT( sourceAccessToken );

        if ( !this.tokenPayload ) {
            console.error( '[AccessToken::initialize. No Token Payload provided!]' );
        } else {
            this.processPayload();
        }

        return this.hasPayload;
    }

    public initializeBearerToken( currentUser: any ): boolean {

        this.source = currentUser.access_token;

        this.tokenPayload = currentUser.access_token;

        if (!this.tokenPayload) {
            console.error( '[AccessToken::initialize. No Token Payload provided!]' );
        } else {
            this.userId = this.tokenPayload.userId;
        }

        return this.hasPayload;
    }

    public hasRole( userRoleValue: any | undefined ): boolean {
        return !!( userRoleValue ) && this.userRoles && this.userRoles.indexOf( userRoleValue ) >= 0;
    }

    private processPayload() {
        this.userId = this.tokenPayload.sub;
        this.userName = this.tokenPayload.name;
    }
    private isInBrowser(): boolean {
        return typeof window !== 'undefined';
    }
}
