import { RouterModule, Routes } from '@angular/router';

// Guards
import { AuthGuard, ProfileValidationGuard } from './core/guards';

// Components
import { NoPageFoundComponent } from './pages/no-page-found';
import { BaseLayoutComponent } from './core/layout/base-layout/base-layout.component';
import { SettingsLayoutComponent } from './core/layout/settings-layout/settings-layout.component';
import { AuthLayoutComponent } from './core/layout/auth-layout/auth-layout.component';
import { ROUTES_NAME } from './core/utils/routes-name';
import { ChangePasswordGuard } from './core/guards/change-password.guard';

// PENDIENTE AÑADIR RENUEVA TOKEN VerificaTokenGuard

const appRoutes: Routes = [
    {
        path: 'login',
        redirectTo: ROUTES_NAME.LOGIN, pathMatch: 'full'
    },
    {
        path: 'register',
        redirectTo: ROUTES_NAME.SIGN_UP, pathMatch: 'full'
    },
    {
        path: 'forgot-password',
        redirectTo: ROUTES_NAME.FORGOT_PASSWORD, pathMatch: 'full'
    },
    {
        path: 'validate-profile',
        loadChildren: () => import('./pages/validate-profile/validate-profile.module').then(m => m.ValidateProfileModule)
    },
    {
        path: 'user-data',
        component: SettingsLayoutComponent,
        loadChildren: () => import('./pages/user-data/user-data.module').then(m => m.UserDataModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'auth',
        component: AuthLayoutComponent,
        children: [
            { path: '', redirectTo: ROUTES_NAME.LOGIN, pathMatch: 'full' },
            {
                path: 'login',
                loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
            },
            {
                path: 'sign-up',
                loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule)
            },
            {
                path: 'forgot-password',
                loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
            },
            {
                path: 'change-password',
                loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordModule),
                canLoad: [ChangePasswordGuard]
            },
            {
                path: 'validate-account',
                loadChildren: () => import('./pages/validate-account/validate-account.module').then(m => m.ValidateAccountModule)
            },
        ]
    },
    {
        path: '',
        component: BaseLayoutComponent,
        children: [
            { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
            {
                path: 'dashboard',
                loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
                canActivate: [AuthGuard]
            },
            // Nodos
            {
                path: 'course',
                loadChildren: () => import('./pages/nodos/nodos.module').then(m => m.NodosModule),
                canActivate: [AuthGuard]
            }
        ]
    },
    { path: '**', component: NoPageFoundComponent },
];

export const APP_ROUTES = RouterModule.forRoot(appRoutes);
