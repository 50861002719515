import { Component, Input, OnInit } from '@angular/core';
import { PowerService } from 'src/app/core/services/power/power.service';
import { FormGroup, FormBuilder } from '@angular/forms';

const MAXVALUE:number = 3
const MINVALUE:number = -3

@Component({
    selector: 'app-power-component',
    templateUrl: './power-component.component.html',
    styleUrls: ['./power-component.component.scss']
})
export class PowerComponentComponent implements OnInit {
    @Input() disabled: boolean = false

    public powerGroup:FormGroup    
    private _arrayValues: Array<number> = []


    checksVisibility: boolean = false;

    constructor(private powerService: PowerService, private fb: FormBuilder) {
        this._generateForm();
    }

    private _generateForm():void{
        this.powerGroup = this.fb.group({
            "3": [false],
            "2": [false],
            "1": [false],
            "0": [true],
            "-1": [false],
            "-2": [false],
            "-3": [false]
        })
    }

    ngOnInit() {
        this._arrayValues.push(0)        
    }

    activatePower():void {
        this.checksVisibility = !this.checksVisibility;
        this.powerService.emitChecksVisibility(this.checksVisibility);
    }

    powerUp():void {
        let valMax:number = Math.max.apply(Math,this._arrayValues)
        this._updateMoreLessValue(valMax,MAXVALUE,1)
    }

    powerDown():void {
        let valMin:number = Math.min.apply(Math, this._arrayValues)
        this._updateMoreLessValue(valMin,MINVALUE,-1)        
    }

    toggleSelection($ev, value:number):void { 
        //Elimino el valor del array
        if(!$ev.checked)
        {
            this._arrayValues.forEach((e,i) => {
                if(e === value)
                    this._arrayValues.splice(i,1)
            })
        }
        else
            this._arrayValues.push(value)

        this._arrayValues.sort((a , b) => a - b)

        this.powerService.emitPowerActive(this._arrayValues) //Lanza el evento al padre para recoger los valores del power.        
    }

    private _updateMoreLessValue(value:number, limitValue:number, nextValue:number):void{
        let currentValue:number = value
        if(this._arrayValues.length === 0){
            this._arrayValues.push(0)
            this.powerGroup.get('0').setValue(true)
        }
        else if(this._arrayValues.length === 1){
            
            if(value !== limitValue){
                currentValue += nextValue
                this._arrayValues.pop()
                this._arrayValues.push(currentValue)
                this.powerGroup.get(value.toString()).setValue(false)
                this.powerGroup.get((currentValue).toString()).setValue(true)
            }            
        }
        else{

            if(value !== limitValue){
                currentValue += nextValue                   
                this._arrayValues.forEach(e => {
                    this.powerGroup.get(e.toString()).setValue(false)
                })
                this._arrayValues = []    
                this.powerGroup.get((currentValue).toString()).setValue(true)
                this._arrayValues.push(currentValue)
            }
            else{
                let tmp: Array<number> = []
                if(nextValue < 0)
                    tmp = this._arrayValues.splice(1,this._arrayValues.length-1)
                else
                    tmp = this._arrayValues.splice(0,this._arrayValues.length-1)       

                tmp.forEach(e => { this.powerGroup.get(e.toString()).setValue(false) })
            }    
        }
        if(value !== limitValue){
            this.powerService.emitPowerActive(this._arrayValues) //Lanza el evento al padre para recoger los valores del power.            
        }
            
    }
}
