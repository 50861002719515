import { Injectable } from '@angular/core';
import { Operator } from '../../models/operators/operator.model';

@Injectable({
    providedIn: 'root'
})
export class OperatorsService {

    constructor() { }

    public getOperators(): Operator[] {
        return [
            { id: 1, order: 9, name: "OPERATORS.IMAGEN.NAME", icon: "visibility", type: "senmotemo", description: "OPERATORS.IMAGEN.DESCRIPTION" },
            { id: 2, order: 8, name: "OPERATORS.SONIDO.NAME", icon: "hearing", type: "senmotemo", description: "OPERATORS.SONIDO.DESCRIPTION" },
            { id: 41, order: 7, name: "OPERATORS.OLFATO.NAME", icon: "air", type: "senmotemo", description: "OPERATORS.OLFATO.DESCRIPTION" },
            { id: 40, order: 6, name: "OPERATORS.GUSTO.NAME", icon: "restaurant", type: "senmotemo", description: "OPERATORS.GUSTO.DESCRIPTION" },
            { id: 3, order: 5, name: "OPERATORS.TACTO.NAME", icon: "pan_tool", type: "senmotemo", description: "OPERATORS.TACTO.DESCRIPTION" },
            { id: 4, order: 4, name: "OPERATORS.HABLA.NAME", icon: "record_voice_over", type: "senmotemo", description: "OPERATORS.HABLA.DESCRIPTION" },
            { id: 5, order: 3, name: "OPERATORS.EMOCION.NAME", icon: "favorite", type: "senmotemo", description: "OPERATORS.EMOCION.DESCRIPTION" },
            { id: 6, order: 2, name: "OPERATORS.ALIVIO.NAME", icon: "arrow_downward", type: "senmotemo", description: "OPERATORS.ALIVIO.DESCRIPTION" },
            { id: 7, order: 1, name: "OPERATORS.ESFUERZO.NAME", icon: "arrow_upward", type: "senmotemo", description: "OPERATORS.ESFUERZO.DESCRIPTION" },
            { id: 8, order: 8, name: "OPERATORS.QUIEN.NAME", icon: "person", type: "assostatic", description: "OPERATORS.QUIEN.DESCRIPTION" },
            { id: 9, order: 7, name: "OPERATORS.DONDE.NAME", icon: "public", type: "assostatic", description: "OPERATORS.DONDE.DESCRIPTION" },
            { id: 10, order: 6, name: "OPERATORS.COMO.NAME", icon: "copyright", type: "assostatic", description: "OPERATORS.COMO.DESCRIPTION" },
            { id: 11, order: 5, name: "OPERATORS.PORQUE.NAME", icon: "help", type: "assostatic", description: "OPERATORS.PORQUE.DESCRIPTION" },
            { id: 12, order: 4, name: "OPERATORS.ACCION.NAME", icon: "directions_run", type: "assostatic", description: "OPERATORS.ACCION.DESCRIPTION" },
            { id: 13, order: 3, name: "OPERATORS.HERRAMIENTA.NAME", icon: "construction", type: "assostatic", description: "OPERATORS.HERRAMIENTA.DESCRIPTION" },
            { id: 14, order: 2, name: "OPERATORS.APLICACION.NAME", icon: "thumb_up", type: "assostatic", description: "OPERATORS.APLICACION.DESCRIPTION" },
            { id: 42, order: 1, name: "OPERATORS.ASOCIACIONINDETERMINADA.NAME", icon: "sync_alt", type: "assostatic", description: "OPERATORS.ASOCIACIONINDETERMINADA.DESCRIPTION" },
            { id: 15, order: 5, name: "OPERATORS.CUANDO.NAME", icon: "alarm", type: "temporary", description: "OPERATORS.CUANDO.DESCRIPTION" },
            { id: 16, order: 4, name: "OPERATORS.ANTES.NAME", icon: "keyboard_arrow_left", type: "temporary", description: "OPERATORS.ANTES.DESCRIPTION" },
            { id: 17, order: 3, name: "OPERATORS.DESPUES.NAME", icon: "arrow_forward", type: "temporary", description: "OPERATORS.DESPUES.DESCRIPTION" },
            { id: 18, order: 2, name: "OPERATORS.HISTORIA.NAME", icon: "videocam", type: "temporary", description: "OPERATORS.HISTORIA.DESCRIPTION" },
            { id: 19, order: 1, name: "OPERATORS.MAS.NAME", icon: "add", type: "temporary", description: "OPERATORS.MAS.DESCRIPTION" },
            { id: 22, order: 4, name: "OPERATORS.EXPLICACION.NAME", icon: "sing", type: "abstract", description: "OPERATORS.EXPLICACION.DESCRIPTION" },
            { id: 23, order: 18, name: "OPERATORS.BASE.NAME", icon: "column", type: "abstract", description: "OPERATORS.BASE.DESCRIPTION" },
            { id: 24, order: 17, name: "OPERATORS.MASPROFUNDIDAD.NAME", icon: "piscina", type: "abstract", description: "OPERATORS.MASPROFUNDIDAD.DESCRIPTION" },
            { id: 25, order: 16, name: "OPERATORS.RESUMEN.NAME", icon: "summation", type: "abstract", description: "OPERATORS.RESUMEN.DESCRIPTION" },
            { id: 26, order: 8, name: "OPERATORS.CLASIFICACION.NAME", icon: "logaritmo", type: "abstract", description: "OPERATORS.CLASIFICACION.DESCRIPTION" },
            { id: 27, order: 15, name: "OPERATORS.ATAJO.NAME", icon: "innovation", type: "abstract", description: "OPERATORS.ATAJO.DESCRIPTION" },
            { id: 28, order: 14, name: "OPERATORS.MEMORIA.NAME", icon: "maths", type: "abstract", description: "OPERATORS.MEMORIA.DESCRIPTION" },
            { id: 29, order: 11, name: "OPERATORS.CUMPLE.NAME", icon: "check", type: "abstract", description: "OPERATORS.CUMPLE.DESCRIPTION" },
            { id: 30, order: 2, name: "OPERATORS.IDEA.NAME", icon: "foco", type: "abstract", description: "OPERATORS.IDEA.DESCRIPTION" },
            { id: 31, order: 6, name: "OPERATORS.DEBER.NAME", icon: "police", type: "abstract", description: "OPERATORS.DEBER.DESCRIPTION" },
            { id: 32, order: 1, name: "OPERATORS.FUNCION.NAME", icon: "function", type: "abstract", description: "OPERATORS.FUNCION.DESCRIPTION" },
            { id: 33, order: 13, name: "OPERATORS.EXISTE.NAME", icon: "waves", type: "abstract", description: "OPERATORS.EXISTE.DESCRIPTION" },
            { id: 34, order: 5, name: "OPERATORS.CAPACIDAD.NAME", icon: "force", type: "abstract", description: "OPERATORS.CAPACIDAD.DESCRIPTION" },
            { id: 35, order: 10, name: "OPERATORS.EXCEPCION.NAME", icon: "justice", type: "abstract", description: "OPERATORS.EXCEPCION.DESCRIPTION" },
            { id: 36, order: 7, name: "OPERATORS.GENERALIZACION.NAME", icon: "red", type: "abstract", description: "OPERATORS.GENERALIZACION.DESCRIPTION" },
            { id: 37, order: 9, name: "OPERATORS.INCLUSION.NAME", icon: "subconjunto", type: "abstract", description: "OPERATORS.INCLUSION.DESCRIPTION" },
            { id: 38, order: 12, name: "OPERATORS.EQUIVALE.NAME", icon: "congruente", type: "abstract", description: "OPERATORS.EQUIVALE.DESCRIPTION" },
            { id: 39, order: 3, name: "OPERATORS.IMPLICA.NAME", icon: "implica", type: "abstract", description: "OPERATORS.IMPLICA.DESCRIPTION" }
        ]
    }
}
