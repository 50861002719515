import { CourseDetailResponseModel } from './../../models/courses/course-detail-response.model';
import { CourseResponseModel } from './../../models/courses/course-response.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';


// Models
import { CourseModel, FilterCourseModel } from 'src/app/core/models/courses';


const GETALLCOURSES = 'coursByFilter'
const GETCOURSEDETAIL = 'getCourseByIdCourse'
const CREATEUPDATECOURS = 'create-course'
const DELETECOURSE = 'deleteCourse'

@Injectable({
  providedIn: 'root'
})
export class CoursesService {

  constructor(private http:HttpClient) { }

  /**
   * Function that returns all courses filtered
   * @param params Object with filter params
   * @returns An Observable of CourseModel[]
   */
  getAllCourses(params:FilterCourseModel):Observable<CourseModel[]> {        
    return this.http.post<CourseModel[]>(GETALLCOURSES, params)
  }

  /**
   * Function that returns the information of a given course
   * @param idCourse Course identificator
   * @returns An Observable of CourseDetailResponseModel
   */
  getCourseById(idCourse: number): Observable<CourseDetailResponseModel> {

    let httpParams = new HttpParams().set('idCourse', idCourse.toString());
    return this.http.get<CourseDetailResponseModel>(GETCOURSEDETAIL, {params: httpParams})
  }

  /**
   * Function that creates or updates the course
   * @param course Object course
   * @param file Image file
   * @returns An Observable of CourseResponseModel
   */
  setCourse(course: CourseModel, file?: File | string): Observable<CourseResponseModel> {
    let form: FormData = new FormData()
    
    form.append('cours', JSON.stringify(course))
    form.append('files', file)

    return this.http.post<CourseResponseModel>(CREATEUPDATECOURS,form)
  }

  /**
   * Function that removes a given course
   * @param idCourse Course identifier
   * @returns An Observable. Get HTTP CODE 200 to delete OK
   */
  deleteCourse(idCourse:number): Observable<any> {
    let params = new HttpParams().set('idCourse', idCourse.toString())    
    return this.http.delete<any>(DELETECOURSE, {params : params})
  }
}
