import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-registrar-curso',
  templateUrl: './registrar-curso.component.html',
  styleUrls: ['./registrar-curso.component.scss']
})
export class RegistrarCursoComponent implements OnInit {
    titulo: string;
    materias:string[]=["Materia 1","Materia 2","Materia 3", "Materia 4"];
    niveles:string[]=["Nivel 1","Nivel 2","Nivel 3", "Nivel 4"];
    idiomas:string[]=["Español","Ingles","Frances", "Aleman"];
    paises:string[]=["España","Inglaterra","Francia", "Alemania"];
    regiones:string[]=["Region 1","Region 2","Region 3", "Region 4"];
    cursosRealizados:string[]=["Curso de Idiomas","Curso de Matemáticas","Curso de Física","Curso de Física","Curso de Biología"];

  public modalClose: NgbActiveModal;

  constructor() { }

  ngOnInit() {
  }

  cancelar() {

  }

  registrar() {

  }

  /*openModal() {
    this.modal.open({ size: 'lg' });
  }*/
}
