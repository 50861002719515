import { Component, OnInit } from '@angular/core';
import { SigmaToolbarsService } from 'src/app/core/services/sigma-toolbars/sigma-toolbars.service';

@Component({
  selector: 'app-help-tool',
  templateUrl: './help-tool.component.html',
  styleUrls: ['./help-tool.component.scss']
})
export class HelpToolComponent implements OnInit {


  private status = false;

  constructor(private toolsService: SigmaToolbarsService) { }

  ngOnInit() {
    this.toolsService.helpStatus.subscribe(status => this.status = status);
  }

  changeStatus() {
    this.status = !this.status;
    this.toolsService.changeHelpStatus(this.status);
  }
}
