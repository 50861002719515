import { MastersService } from 'src/app/core/services/masters';
import { IdiomaModel } from 'src/app/core/models/masters';
import { LangUtils } from './../../../core/utils/lang.utils';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// Services
import { TranslateService } from '@ngx-translate/core';

// Utils
import { LocalStorage } from 'src/app/core/utils';

@Component({
  selector: 'app-idioma',
  templateUrl: './idioma.component.html',
  styleUrls: ['./idioma.component.scss']
})
export class IdiomaComponent implements OnInit {

  idioma: string;
  langList:IdiomaModel[];
  availableLangs:String[];
  @Output() idiomaSeleccionado: EventEmitter<number> = new EventEmitter();

  constructor(private localStorage: LocalStorage, private lang: LangUtils, private masterService: MastersService, private translate: TranslateService) { }

  ngOnInit() {    
    this.masterService.getAllLanguages().subscribe(data => { 
      this.langList = data      
      this.availableLangs = this.langList.map(e => e.idLanguageIso.toLowerCase())
      this.lang.setAppLang(this.availableLangs);
      this.idioma = `../../../../assets/images/flags/${this.localStorage.getItem('language')}.png`;  
    })    
  }

  public changeLang(lang: string) {

    this.localStorage.setItem('language', lang);
    this.lang.setAppLang(this.availableLangs);

    this.idioma = `../../../../assets/images/flags/${this.localStorage.getItem('language')}.png`;

    this.idiomaSeleccionado.emit(this.localStorage.getItem('language'));
  }

  private _getLiteral(idLang:string){
    let traduction:string;
    switch(idLang){

      case 'de':
        traduction = this.translate.instant('IDIOMA.ALEMAN')
        break;
      case 'es':
        traduction = this.translate.instant('IDIOMA.ESPAÑOL')
        break
      case 'pt':
        traduction = this.translate.instant('IDIOMA.PORTUGUES')
        break
      case 'en':
        traduction = this.translate.instant('IDIOMA.INGLES')
        break
    }
    return traduction    
  }
}
