import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ModalReproducirComponent } from 'src/app/shared/components/modal-reproducir/modal-reproducir.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { TemplatesService } from 'src/app/core/services/templates/templates.service';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { ImagenPipe } from 'src/app/shared/pipes/imagen.pipe';

@Component({
    selector: 'app-plantilla-reproducir-quiz',
    templateUrl: './plantilla-reproducir-quiz.component.html',
    styleUrls: ['./plantilla-reproducir-quiz.component.scss']
})
export class PlantillaReproducirQuizComponent implements OnInit {
    @Input() templates: any[];
    @Input() answer: boolean;
    @Input() isRevealed: boolean;
    @Input() quiz: any;
    //templates: any[];

    elementsQP: any[] = [];
    elementsCorrect: any[] = [];
    isResponse: boolean;
    source: any;
    elementCurrent: any;
    description: string;
    fontStyles: string[] = ["normal", "italic", "oblique", "inherit", "initial", "unset"];
    fontStylex: string;
    fontSizes: string[] = ["8", "9", "10", "11", "12", "14", "16", "18", "20"];
    fontSizex: string;
    correctElement: any[] = [];

    /*todo = [
      'Get to work',
      'Pick up groceries',
      'Go home',
      'Fall asleep'
    ];

    done = [
      'Get up',
      'Brush teeth',
      'Take a shower',
      'Check e-mail',
      'Walk dog'
    ];*/


    /*drop(event: CdkDragDrop<any[]>) {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        transferArrayItem(event.previousContainer.data,
                          event.container.data,
                          event.previousIndex,
                          event.currentIndex);
        this.resaltar=false;
      }
    }

    dropEntered(event: CdkDragEnter<any[]>){
      this.resaltar=true;
    }*/

    constructor(
        public modalService: NgbModal,
        public templateService: TemplatesService,
        public quizService: QuizzesService,
        private imagePipe: ImagenPipe
    ) {
        this.quizService.selectQuiz.subscribe((q) => {
            this.quiz = q;
        });
        this.templateService.flagTemplateUpdate.subscribe((n: boolean) => {
            if (n) {
                this.ngOnInit();
            }
        });
    }

    ngOnInit() {
        console.log("***this.templates : ", this.templates);
        this.isResponse = false;
        this.description = "";
    }

    reproducirRecurso(element: any) {
        if (!(element.elementType == 4 || element.elementType == 9 || element.elementType == 14)) {
            const modalRef = this.modalService.open(ModalReproducirComponent,
                {
                    scrollable: true,
                    windowClass: 'modal-dialog-70'
                })
            modalRef.componentInstance.element = element;
            modalRef.result.then((result) => {
                console.log(result);
            }, (reason) => {
            });
        }
    }

    getImage(element: any) {
        if (element.icon == "videocam") {
            return '../../../assets/images/video.jpg';
        }

        if (element.icon == "image") {
            return '../../../assets/images/' + element.data;
        }

        if (element.icon == "volume_up") {
            return '../../../../../assets/images/headphones.jpg';
        }

        if (element.icon == "picture_as_pdf") {
            return '../../../assets/document/' + element.data;
        }

        if (element.icon == "subject") {
            return '../../../assets/document/' + element.data;
        }
    }

    createTypeElements(elements: any[]) {
        console.log("createTypeElements ");
        if (elements != null) {
            var elementsQP = [];
            var elementsCorrect = [];
            elements.forEach((element) => {
                if (element.elementType < 9) {
                    elementsQP.push(element);
                }
                if (element.elementType >= 10 && element.elementType <= 14) {
                    elementsCorrect.push(element);
                }
            });
            this.templates[0].elements = elementsQP;
            this.templates[0].elementsCorrect = elementsCorrect;
        }
    }

    createTypesElements(elements: any[]) {
        console.log("elements ", elements);
        if (elements != null) {
            var elementsQPt = [];
            var elementsCorrectt = [];
            elements.forEach((element) => {
                if (element.elementType < 9) {
                    elementsQPt.push(element);
                }
                if (element.elementType >= 10 && element.elementType <= 14) {
                    elementsCorrectt.push(element);
                }
            });
            this.elementsQP = elementsQPt;
            this.elementsCorrect = elementsCorrectt;
            console.log("this.elementsQP ", this.elementsQP);
            console.log("this.elementsCorrect ", this.elementsCorrect);
        }
    }

    getColor(resaltar: any) {
        if (resaltar) {
            return 'rgb(141,143,145)';
        } else {
            return '#ccc';
        }
    }

    onlyOptions(item: CdkDrag) {
        if (item.element.nativeElement.id >= "4" && item.element.nativeElement.id <= "9") {
            return true;
        } else {
            return false;
        }
    }

    checkResponse() {
        console.log("checkResponse");
        this.isResponse = (this.isResponse == true) ? false : true;
        this.templateService.setIsResponse(this.isResponse);
    }

    cargarRecurso(event, element: any) {
        console.log("@@@@this.quiz : ", this.quiz);
        console.log("@@@@element : ", element);
        if (element.elementType == 9) {
            console.log("Texto");
        } else if (element.elementType != 9) {
            console.log("Options");
            var file = event.target.files[0];
            if (file) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                console.log("Tipo : " + file.type);
                reader.onload = async (event: any) => {
                    this.source = event.target.result;
                    console.log("Id  : ", element.idTemplateElement);
                    console.log("this.source  : ", file.name);
                    var idDiv = '#' + element.idTemplateElement;
                    console.log("idDiv : ", idDiv);
                    document.getElementById(idDiv).style.backgroundImage = 'url(' + this.getImages(file) + ')';
                    element.data = file.name;
                    //  this.templateService.createTemplatesElementQuizzes(element.idTemplateElement,this.quiz.idQuiz,element.data);
                }
            }
        }
    }

    getImages(file: any) {
        console.log("+++++++type++++++: ", file.type);
        if (file.type == "video/mp4") {
            return '../../../../../assets/images/video.jpg';
        }
        if (file.type == "image/jpeg" || file.type == "image/png") {
            console.log("Imagen : " + file);
            return file;
        }
        if (file.type == "audio/mpeg") {
            return '../../../../../assets/images/headphones.jpg';
        }
        if (file.type == "pdf") {
            return '../../../../../assets/document/' + file.name;
        }
        if (file.type == "txt") {
            return '../../../../../assets/document/' + file.name;
        }
    }

    setElementType(quiz: any, element: any) {
        console.log("setElementType quiz : ", quiz);
        console.log("setElementType element : ", element);
        switch (element.elementType) {
            case (0): {
                quiz.stringQuestionsVideo = element.idTemplateElement;
                break;
            }
            case (1): {
                quiz.stringQuestionsImage = element.idTemplateElement;
                break;
            }
            case (2): {
                quiz.stringQuestionsAudio = element.idTemplateElement;
                break;
            }
            case (3): {
                quiz.stringQuestionsDoc = element.idTemplateElement;
                break;
            }
            case (4): {
                quiz.stringQuestionsText = element.idTemplateElement;
                break;
            }
            case (5): {
                quiz.stringOptionsVideo = element.idTemplateElement;
                break;
            }
            case (6): {
                quiz.stringOptionsImage = element.idTemplateElement;
                break;
            }
            case (7): {
                quiz.stringOptionsAudio = element.idTemplateElement;
                break;
            }
            case (8): {
                quiz.stringOptionsDoc = element.idTemplateElement;
                break;
            }
            case (9): {
                quiz.stringOptionsText = element.idTemplateElement;
                break;
            }
            case (10): {
                quiz.stringAnswersVideo = element.idTemplateElement;
                break;
            }
            case (11): {
                quiz.stringAnswersImage = element.idTemplateElement;
                break;
            }
            case (12): {
                quiz.stringAnswersAudio = element.idTemplateElement;
                break;
            }
            case (13): {
                quiz.stringAnswersDoc = element.idTemplateElement;
                break;
            }
            case (14): {
                quiz.stringAnswersText = element.idTemplateElement;
                break;
            }
            default: {
                break;
            };
        }
    }

    getDescription(element: any) {
        this.elementCurrent = element;
        console.log("element: ", element);
        console.log("this.elementCurrent: ", this.elementCurrent);
        var subscribe = this.templateService.getTemplatesElementQuizzes(this.elementCurrent.idTemplateElement, this.quiz.idQuiz).subscribe((data) => {
            console.log(" data.data.templatesElementQuizzes.nodes.length : ", data.data.templatesElementQuizzes.nodes.length);
            if (data.data.templatesElementQuizzes.nodes.length > 0) {
                this.description = data.data.templatesElementQuizzes.nodes[0].data;
            } else {
                this.description = "";
            }
            console.log("this.description : ", this.description);
            var parts: string[] = this.description.split("#");
            this.description = parts[0];
            this.fontStylex = parts[1];
            this.fontSizex = parts[2];
            subscribe.unsubscribe();
        });
    }

    /*saveQuestion(desc:any){
       var description = desc+"#"+this.fontStylex+"#"+this.fontSizex;
       this.templateService.createTemplatesElementQuizzes(this.elementCurrent.idTemplateElement,this.quiz.idQuiz,description);
    }*/

    closeModalText() {
        console.log("closeModalText");
        this.description = "";
        console.log("this.description : ", this.description);
    }

    showFondStyle(event: Event) {
        this.fontStylex = (event.target as HTMLSelectElement).value;
    }

    showFontSize(event: Event) {
        this.fontSizex = (event.target as HTMLSelectElement).value + 'px';
    }
}
