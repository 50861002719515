import { Injectable } from '@angular/core';

// Service
import { MapperService } from './mapper.service';

// Model
import { CenterModel } from '../../models/masters';

@Injectable()
export class CentersMapperService extends MapperService<CenterModel, any> {

    public map(data: CenterModel): any {
      return {
        idCenter: data.idCenter,
        idUserStudentCenter: data.idUserStudentCenter,
        idUserTeacherCenter: data.idUserTeacherCenter,
        centerName: data.centerName,
        centerAddress: data.centerAddress,
        centerCountry: data.centerCountry,
        centerRegion: data.centerRegion,
        centerMail: data.centerMail,
        centerPhone: data.centerPhone,
        centerUrl: data.centerUrl,
        centerPicture: data.centerPicture
      };
    }
}
