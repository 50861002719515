import { Injectable } from '@angular/core';

// Service
import { MapperService } from './mapper.service';

// Model
import { UserEmployeerModel } from '../../models/users';

@Injectable()
export class UsersEmployeersMapperService extends MapperService<UserEmployeerModel, any> {

    public map(data: UserEmployeerModel): any {
      return {
        idUserEnterprise: data.idUserEnterprise,
        idUser: data.idUser,
        idEnterprise: data.idEnterprise,
        creationDate: data.creationDate,
        creationDateString: data.creationDateString
      };
    }
}
