import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';

// Graph Model
import { gNode } from '../../models/graph/gNode.model';
import { gEdge } from '../../models/graph/gEdge.model';


// GraphQL
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { HttpClient } from '@angular/common/http';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { ModelIcono } from '../../models/courses/icono.model';
import { SIGMA_CONSTANTS } from '../../utils/sigma-constants';



@Injectable({
    providedIn: 'root'
})
export class NodeService {
    public newNode = new Subject();
    public currentNode = new Subject();

    private queryGetNode = gql`query($id: Int!) {
  nodes(where: { idNode: $id })  {
    nodes {
      idNode
      tittle
      nodeSummary
      nodeSwlevel
      duration
      certificable
      idCourseCreation
      idTargetCreation
      creationDateString
      lastEditionDateString
      author {
        nick
      }
      language {
        language
      }
      nodesFiles {
          idNodeFile
          textFile
          audioFile
          videoFile
          pictureFile
          pdfFile
        }
        nodesTargets{
            idNodeB
            idTargetA
            ordinal
        }
        powerNodeTarget{
          idPowerNodeTarget
          idTarget
          orderPower0
          orderPower1
          orderPower2
          orderPowerNegative1
          orderPowerNegative2
          idNode
        }
    }
  }
}`;

    private queryNodesFiles = gql`query($id: Int!) {
  nodesFiles(where: { idNode: $id })  {
      nodes {
          idNodeFile
          }
      }
  }`;

    private powerNodeTarget = gql`query($id: Int!) {
    powerNodeTarget(where: { idPowerNodeTarget: $id })  {
        nodes {
          idTarget
          orderPower0
          orderPower1
          orderPower2
          orderPowerNegative1
          orderPowerNegative2
          idNode
            }
        }
    }`;

    constructor(private apollo: Apollo, private datePipe: DatePipe, private http: HttpClient) { }

    // -------------------------------
    // G E T
    // -------------------------------

    // *** CURRENT NODE ***
    public setCurrentNode(node) {
        this.currentNode.next(node);
    }

    public emptyCurrentNode() {
        // Pendiente
    }

    // *** N O D E ***
    public getEmptyNode() {
        var date = this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss');
        return {
            certificable: false,
            language1: '',
            language2: '',
            author: '',
            video: '',
            image: '',
            audio: '',
            pdf: '',
            textfile: '',
            creation: date,
            edition: date,
            idCourseCreation: 0,
            idTargetCreation: 0
        }
    }


    // *** N O D E ***
    public getNodeById(id) {
        var variables = {
            id: parseInt(id)
        };


        var node;
        return this.query(this.queryGetNode, variables).pipe(map(data => {
            if (data.data.nodes.length == 0) {

                return Object.assign({ tittle: '', nodeSummary: '', author: '', language: '', }, this.getEmptyNode());
            }
            data.data.nodes.nodes.forEach((n) => {

                var author = (n.author && n.author.length > 0) ? n.author[0].nick : '';
                var language = (n.language && n.language.length > 0) ? n.language[0].language : '';
                var files = (n.nodesFiles && n.nodesFiles.length > 0) ? n.nodesFiles[0] : {
                    videoFile: '',
                    audioFile: '',
                    textFile: '',
                    pictureFile: '',
                    pdfFile: ''
                };
                var ordinal = (n.nodesTargets && n.nodesTargets.length > 0) ? n.nodesTargets[0].ordinal : 0;
                node = n;
                node.label = n.tittle;
                node.description = n.nodeSummary;
                node.author = author;
                node.language = language;
                node.videoFile = files.videoFile;
                node.audioFile = files.audioFile;
                node.pictureFile = files.pictureFile;
                node.textFile = files.textFile;
                node.pdfFile = files.pdfFile;
                node.ordinal = ordinal;
                // node.idpowerNodeTarget = n.powerNodeTarget[0].idpowerNodeTarget;
                if (n.powerNodeTarget) {
                    n.powerNodeTarget.forEach((p) => {
                        node.idPowerNodeTarget = p.idPowerNodeTarget;
                        node.ordinalPower2 = p.orderPower2;
                        node.power2 = node.ordinalPower2 > 0 ? true : false;
                        node.ordinalPower1 = p.orderPower1;
                        node.power1 = node.ordinalPower1 > 0 ? true : false;
                        node.ordinalPower0 = p.orderPower0;
                        node.power0 = node.ordinalPower0 > 0 ? true : false;
                        node.ordinalPowerNegative1 = p.orderPowerNegative1;
                        node.powerNegative1 = node.ordinalPowerNegative1 > 0 ? true : false;
                        node.ordinalPowerNegative2 = p.orderPowerNegative2;
                        node.powerNegative2 = node.ordinalPowerNegative2 > 0 ? true : false;

                    });
                }
            }
            );
            return node;
        }));
    }

    // ------------------------------------------------------------------------------
    //   D B    A C T I O N S
    // ------------------------------------------------------------------------------
    public getNode(id: number, courseId: number, graphId: number) {
        return this.http.get(`courses/${courseId}/graphs/${graphId}/nodes/${id}`).pipe(
            map((res: any) => {
                if (res.data && res.data.length > 0) {
                    res.data[0].pictureNode = (res.data[0].pictureNode !== 'null' && res.data[0].pictureNode !== '') ? res.data[0].pictureNode : undefined;
                }
                return res;
            }),
            catchError(() => of({ error: { code: 500 } }))
        );
    }

    // *** N O D E ***
    // C R E A T E
    public createNode(courseId: number, graphId: number, node: gNode, file: File): Observable<any> {
        const n = {
            user: {
                idUser: node.user.idUser,
                nick: node.user.nick
            },
            subject: {
                idSubject: node.subject.idSubject,
                subject: node.subject.subject
            },
            language: {
                idLanguage: 38,
                idLanguageIso: "ES",
                language: "Español"
            },
            duration: node.duration,
            label: node.label,
            description: node.description,
            certificable: node.certificable,
            nodeType: 'Node',
            idTargetCreation: graphId,
            idCourseCreation: courseId,
            nodeSwlevel: node.nodeSwlevel,
            ordinalPower0: node.ordinalPower0,
            ordinalPower1: node.ordinalPower1,
            ordinalPower2: node.ordinalPower2,
            ordinalPower3: node.ordinalPower3,
            ordinalPowerNegative1: node.ordinalPowerNegative1,
            ordinalPowerNegative2: node.ordinalPowerNegative2,
            ordinalPowerNegative3: node.ordinalPowerNegative3,
            x: node.x,
            y: node.y,
            id: node.id,
            idOriginal: node.idOriginal,
            idPower: node.idPower,
            idNodeTarget: node.idNodeTarget,
            idNodesFile: node.idNodesFile,
            edges: [],
            creationDate: node.creationDate,
            pictureNode: node.pictureNode ? node.pictureNode : undefined
        }

        let form: FormData = new FormData()

        form.append('node', JSON.stringify(n));
        form.append('files', file);

        return this.http.post<any>(`courses/graphs/nodes`, form).pipe(
            map(res => {
                if (!res.error.code) {
                    res.data = {
                        ...res.data,
                        originalX: res.data.x,
                        originalY: res.data.y,
                        type: 'circle',
                        size: SIGMA_CONSTANTS.NODE_SIZE,
                        zindex: 0,
                        color: '#D7DBDC',
                        originalColor: '#D7DBDC',
                        nodesFiles: []
                    };
                }

                return res;
            }),
            tap(res => console.log('nodeNew: ', res))
        );
    }

    public deleteNode(nodeId: number, courseId: number, graphId: number) {
        return this.http.delete(`courses/${courseId}/graphs/${graphId}/nodes/${nodeId}`);
    }

    public createEdge(nodeFromId: number, nodeToId: number, courseId: number, graphId: number, type: string, label: string, size: number, color: string) {
        const body = {
            idNode2: nodeToId,
            size: size,
            label: label,
            type: type,
            color: color
        };

        return this.http.post(`courses/${courseId}/graphs/${graphId}/nodes/${nodeFromId}/edges`, body);
    }

    public deleteEdge(nodeFromId: number, nodeToId: number, courseId: number, graphId: number) {
        return this.http.delete(`courses/${courseId}/graphs/${graphId}/nodes/${nodeFromId}/edges/${nodeToId}`);
    }

    public postNodeFile(nodeFilesId: number, type: string, operators: ModelIcono[] = [], file?: File, text?: string) {
        const form: FormData = new FormData();

        form.append('type', type);


        form.append('operators', JSON.stringify(
            { operators: operators.map(o => ({ id: o.id })) }
        ));

        if (file) {
            form.append('files', file);
        } else {
            form.append('files', JSON.stringify({}))
        }


        if (text) {
            form.append('text', text);
        } else {
            form.append('text', JSON.stringify(''))
        }

        return this.http.post<any>(`nodes/${nodeFilesId}/type/${type}`, form);
    }

    public saveNodePos(nodeId: number, x: number, y: number, courseId: number, graphId: number) {
        const form: FormData = new FormData();

        const n = {
            idNode: nodeId,
            x,
            y,
            idCourseNode1: courseId,
            idTargetNode1: graphId
        }

        form.append('node', JSON.stringify(n));

        return this.http.post<any>(`courses/graphs/nodes`, form);
    }

    public getPowers(courseId: number, graphId: number, max: number, min: number) {
        let httpCalls: Observable<number>[] = [];

        for (let i = min; i <= max; i++) {
            httpCalls = [...httpCalls, this.getNextPower(courseId, graphId, i < 0 ? 'orderpowernegative' + Math.abs(i) : 'orderpower' + i)]
        }

        return forkJoin(httpCalls);
    }

    public getNextPower(courseId: number, graphId: number, power: string): Observable<number> {
        return this.http.get<any>(`courses/${courseId}/graphs/${graphId}/order/${power}`).pipe(map(res => res.data));
    }

    // setCourse(course: CourseModel, file?: File): Observable<CourseResponseModel> {
    //     let form: FormData = new FormData()

    //     form.append('cours', JSON.stringify(course))
    //     if (file)
    //         form.append('files', file)

    //     return this.http.post<CourseResponseModel>(CREATEUPDATECOURS, form)
    // }

    public async DEPRECATEDcreateNode(node, targetId, courseId) {

        var mutation = gql`mutation {
                        createNode ( nodes: {
                              idNode: 0,
                              tittle: "${node.label}",
                              nodeSummary: "${node.description}",
                              nodeSwlevel: ${node.nodeSwlevel},
                              duration: ${parseInt(node.duration)},
                              certificable: ${node.certificable ? 1 : 0},
                              creationDateString: "${this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss').toString()}" ,
                              lastEditionDateString: "${this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss').toString()}",

                              idTargetCreation: ${parseInt(targetId)}
                          }) {idNode}
      }`; //  idCourseTargetA: ${parseInt(courseId)}

        var result = await this.mutate(mutation).toPromise().then((result));
        var idNodeB = result.data.createNode.idNode;

        mutation = gql`mutation {
          createNodesTargets ( nodesTargets: {
              idNodeTarget: 0,
              row: ${Math.trunc(node.y)},
              columnX: ${Math.trunc(node.x)},
              idTargetA: ${parseInt(targetId)},
              idNodeB: ${idNodeB},
              connectionsText: "",
              idTargetOrigin: ${parseInt(targetId)},
          }) { idNodeTarget}
      }`; //  idCourseTargetA: ${parseInt(courseId)}

        await this.mutate(mutation).toPromise();


        mutation = gql`mutation {
        createPowerNodeTarget ( powerNodeTarget: {
          idPowerNodeTarget: 0,
          idTarget: ${parseInt(targetId)},
          orderPower0: ${node.power0 ? node.ordinalPower0 : 0},
          orderPower1: ${node.power1 ? node.ordinalPower1 : 0},
          orderPower2: ${node.power2 ? node.ordinalPower2 : 0},
          orderPowerNegative1: ${node.powerNegative1 ? node.ordinalPowerNegative1 : 0},
          orderPowerNegative2: ${node.powerNegative2 ? node.ordinalPowerNegative2 : 0},
          idNode:${idNodeB},
        }) {idPowerNodeTarget}
    }`;

        var resultPower = await this.mutate(mutation).toPromise().then((resultPower));
        var idpowerNodeTarget = resultPower.data.createPowerNodeTarget.powerNodeTarget;

        this.newNode.next({
            id: 'n' + idNodeB,
            idOriginal: idNodeB,
            label: node.label,
            x: node.x,
            y: node.y,
            originalX: node.x,
            originalY: node.y,
            type: node.type,
            url: node.url,
            size: 8,
            originalColor: '#D7DBDC',
            color: '#D7DBDC',
            description: node.description,
            nodeType: 'Node',
            nodeSwlevel: node.nodeSwlevel,
            duration: node.duration,
            delete: false,
            power2: node.power2,
            power1: node.power1,
            power0: node.power0,
            powerNegative1: node.powerNegative1,
            powerNegative2: node.powerNegative2,
            ordinalPower2: node.ordinalPower2,
            ordinalPower1: node.ordinalPower1,
            ordinalPower0: node.ordinalPower0,
            ordinalPowerNegative1: node.ordinalPowerNegative1,
            ordinalPowerNegative2: node.ordinalPowerNegative2,
            idpowerNodeTarget: idpowerNodeTarget
        });
    }

    // *** N O D E ***
    // U P D A T E
    public async updateNode(node) {
        console.log("ESTOY EN ACTUALIZAR NODE");
        var id = node.idOriginal;
        var variables = {
            id: parseInt(id)
        };

        var mutation = gql`mutation {
              updateNode (
                  nodes: {
                      idNode: ${node.idOriginal},
                      tittle: "${node.tittle}",
                      nodeSummary: "${node.nodesummary}",
                      nodeSwlevel: ${node.nodeSwlevel},
                      duration: ${parseInt(node.duration)},
                      certificable: ${node.certificable ? 1 : 0},
                      lastEditionDateString: "${this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss').toString()}"
                  })
              }`;

        await this.apollo.mutate({
            mutation: mutation,
            refetchQueries: [{
                query: this.queryGetNode,
                variables: variables
            }]
        }).toPromise().then(y => {
        });


        // Comprobar si tiene registro en la tabla nodesFiles:
        var idNodeFile = await this.query(this.queryNodesFiles, variables).toPromise().then((data) => { //pipe( map( data => {
            if (data.data.nodesFiles.nodes.length > 0) {
                return data.data.nodesFiles.nodes[0].idNodeFile;
            }
        });


        if (idNodeFile) {

            mutation = gql`mutation {
          updateNodesFiles ( nodesFiles: {
                  idNode: ${node.idOriginal},
                  idNodeFile:  ${idNodeFile},
                  videoFile: "${node.videoFile ? node.videoFile : ''}",
                  audioFile: "${node.audioFile ? node.audioFile : ''}",
                  pictureFile: "${node.pictureFile ? node.pictureFile : ''}",
                  pdfFile:"${node.pdfFile ? node.pdfFile : ''}",
                  textFile: "${node.textFile ? node.textFile : ''}"
              })
          }`;
        } else {
            mutation = gql`mutation {
            createNodesFiles ( nodesFiles: {
                    idNode: ${node.idOriginal},
                    idNodeFile: 0,
                    videoFile: "${node.videoFile ? node.videoFile : ''}",
                    audioFile: "${node.audioFile ? node.audioFile : ''}",
                    pdfFile:"${node.pdfFile ? node.pdfFile : ''}",
                    pictureFile: "${node.pictureFile ? node.pictureFile : ''}",
                    textFile: "${node.textFile ? node.textFile : ''}"
                }) {idNodeFile}
            }`;
        }

        await this.apollo.mutate({
            mutation: mutation,
            refetchQueries: [{
                query: this.queryNodesFiles,
                variables: variables
            }]
        }).toPromise().then(y => { });


        var id = node.idOriginal;
        var powervariables = {
            id: parseInt(node.idPowerNodeTarget)
        };

        var mutation = gql`mutation {
        updatePowerNodeTarget ( powerNodeTarget: {
          idPowerNodeTarget: ${node.idPowerNodeTarget}
          orderPower0: ${node.ordinalPower0 ? node.ordinalPower0 : 0},
          orderPower1: ${node.ordinalPower1 ? node.ordinalPower1 : 0},
          orderPower2: ${node.ordinalPower2 ? node.ordinalPower2 : 0},
          orderPowerNegative1: ${node.ordinalPowerNegative1 ? node.ordinalPowerNegative1 : 0},
          orderPowerNegative2: ${node.ordinalPowerNegative2 ? node.ordinalPowerNegative2 : 0},
       })
    }`;
        await this.apollo.mutate({
            mutation: mutation,
            refetchQueries: [{
                query: this.powerNodeTarget,
                variables: powervariables
            }]
        }).toPromise().then(y => {

        });

        this.currentNode.next(node);
    }

    // --------------------------------------
    //  P A R S E
    // --------------------------------------
    private parseQuizId(id) {
        return id.split("q", 2)[1];
    }

    private parseNodeId(id) {
        return id.split("n", 2)[1];
    }

    private parseId(id) {
        if (id.split("q", 2).length > 1) {
            return parseInt(id.split("q", 2)[1]);
        } else if (id.split("n", 2).length > 1) {
            return parseInt(id.split("n", 2)[1]);
        } else {
            return 0;
        }
    }
    // --------------------------------------
    //  Q U E R Y
    // --------------------------------------
    private query(query, variables) {

        return this.apollo.query<any>({
            query: query,
            variables: variables
        },
        );
    }

    private mutate(mutation) {

        return this.apollo.mutate({
            mutation: mutation
        });
    }

    public selectFilePreviewAsImage(idNodeFile: number, fileType: number) {
        return this.http.put(`/nodefile/${idNodeFile}/imagenode/${fileType}`, undefined);
    }
}



