import { environment } from 'src/environments/environment';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccessToken } from 'src/app/core/utils';

export class JwtInterceptor implements HttpInterceptor {
    public constructor() { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const accessToken = new AccessToken();

        accessToken.materializeFromLocalStorage();

        if (accessToken && accessToken.source)
            request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${accessToken.source}`) });

        //Add API URL to calls except the json translations
        if (request.url.indexOf('i18n') < 0 && request.url.indexOf('icons') < 0 && request.url.indexOf('blob') < 0 && request.url.indexOf('googleapis') < 0) {
            request = request.clone({ url: environment.apiUrl + request.url })
        }

        request = request.clone({
            headers: request.headers
                .set('Accept', '*/*')
                .set('Access-Control-Allow-Origin', '*')
                // tslint:disable-next-line: max-line-length
                .set('Access-Control-Allow-Headers', 'Authorization, Expires, Pragma, DNT,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,Range'),
        });

        if (request.method === 'GET') {

            const customRequest = request.clone({
                headers: request.headers
                    .set('Cache-Control', 'no-cache')
                    .set('Pragma', 'no-cache')
            });

            return next.handle(customRequest);
        }

        return next.handle(request);
    }
}
