import { Injectable } from '@angular/core';

// Service
import { MapperService } from './mapper.service';

// Model
import { UserRegisterModel } from '../../models/users/user-register.model';

@Injectable()
export class UsersRegisterMapperService extends MapperService<UserRegisterModel, any> {

    public map(user: UserRegisterModel): any {
        return {
          idUser: user.idUser,
          firstName: user.firstName,
          surname: user.surname,
          mail: user.mail,
          birthdate: user.birthdate,
          birthdateString: user.birthdateString,
          mobile: user.mobile,
          nick: user.nick,
          keyWord: user.keyWord,
          pictureUser: user.pictureUser,
          idPreferredLanguage: user.idPreferredLanguage,
          salwareAdministration: user.salwareAdministration,
          profile: user.profile,
          idGenre: user.idGenre,
          creationDate: user.creationDate,
          validationDate: user.validationDate
        };
    }
}
