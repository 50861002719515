import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AcceptDialogData {
    message: string
}

@Component({
    selector: 'app-accept-dialog',
    templateUrl: './accept-dialog.component.html',
    styleUrls: ['./accept-dialog.component.scss']
})
export class AcceptDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<AcceptDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AcceptDialogData) { }

    ngOnInit() {
    }

}
