import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TooltipPosition } from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';
import { ModalOperadoresComponent } from '../modal-operadores/modal-operadores.component';
import { ModelIcono } from 'src/app/core/models/courses/icono.model';
import { SubirMediosService } from 'src/app/core/services/shared/subir-medios.service';
import { gNode } from 'src/app/core/models/graph/gNode.model';
import { LoginService } from 'src/app/core/services/login';
import { NodeService } from 'src/app/core/services/node/node.service';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { ModalReproducirQuizzesComponent } from '../modal-reproducir-quizzes/modal-reproducir-quizzes.component';
import * as countdown from 'countdown';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { SnapshotService } from 'src/app/core/services/snapshot/snapshot.service';

declare var $: any;
interface Time {
    hours: number,
    minutes: number,
    seconds: number;
}

@Component({
    selector: 'app-modal-reproducir-nodo',
    templateUrl: './modal-reproducir-nodo.component.html',
    styleUrls: ['./modal-reproducir-nodo.component.scss']
})

//@ViewChild(FooterReproducirQuizzesComponent,{static:false}) foot: FooterReproducirQuizzesComponent;

export class ModalReproducirNodoComponent implements OnInit, OnDestroy {
    //idCurso:string;
    //type:string;
    nomGrafo: string;
    nomNode: string
    selectedFile: File = null;
    cargando: boolean;
    inicial: boolean;
    iconos: any = [];
    iconosAdicionados: ModelIcono[] = [];
    listaIconosAdicionados = new Map<number, any[]>();
    idFormato: number;
    formats: any[] = [
        { id: 1, titulo: "video", formato: "videocam", edit: false },
        { id: 2, titulo: "imagen", formato: "crop_original", edit: false },
        { id: 3, titulo: "audio", formato: "volume_up", edit: false },
        { id: 4, titulo: "pdf", formato: "picture_as_pdf", edit: false },
        { id: 5, titulo: "texto", formato: "text_snippet", edit: false }];
    videoSource: string;
    imageSource: string;
    audioSource: string;
    pdfSource: string;
    textSource: string;
    mensaje: string;
    imagen: string;
    format: string;
    automatic: any = false;

    positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
    position = new FormControl(this.positionOptions[0]);


    // A.G.
    @Input() idCurso: number;
    @Input() idMapa: number;
    @Input() type: string;
    @Input() currentGraph: any;
    private send = false;
    @Input() node; // :gnode
    @Output() newNode = new EventEmitter<gNode>();
    intervalId;
    time: Time;
    countSeconds: number;
    timerId: number = null;
    @ViewChild('videoElement', { static: false }) videoElement: ElementRef;
    @ViewChild('imgElement', { static: false }) imgElement: ElementRef;
    @ViewChild('audioElement', { static: false }) audioElement: ElementRef;
    @ViewChild('pdfElement', { static: false }) pdfElement: ElementRef;
    @ViewChild('textoElement', { static: false }) textoElement: ElementRef;
    //@ViewChild('canvas',{static:false}) canvas: ElementRef;
    //@ViewChild('downloadLink',{static:false}) downloadLink: ElementRef;

    constructor(public modalService: NgbModal,
        public activeModal: NgbActiveModal,
        public loginService: LoginService,
        private subirMediosService: SubirMediosService,
        private nodeService: NodeService,
        private quizService: QuizzesService,
        public snapshotService: SnapshotService) {
        this.quizService.automatic.subscribe((a) => { this.automatic = a; });
        this.nodeService.currentNode.subscribe((n) => this.node = n);
        this.quizService.currentGraph.subscribe((g) => this.currentGraph = g);
        var subscribe = this.quizService.loading.subscribe((n: boolean) => {
            this.cargando = n
            if (this.cargando == false && loginService.esEstudiante()) {
                this.executeAutomaticStudy();
                subscribe.unsubscribe();
            }
        });
    }

    changeStatus($event) {
        this.send = $event;
    }

    receiveNewNodeData($event) {
        this.newNode.emit($event);
    }
    // Fin A.G.

    ngOnInit() {
        // A.G.
        this.nodeService.setCurrentNode(this.node);
        this.currentGraph.idTarget = this.idMapa;
        this.currentGraph.idCourse = this.idCurso;
        this.quizService.setCurrentGraph(this.currentGraph);
        this.quizService.setType(this.type);
        this.quizService.setExecuteAutomatic(false);
        if (this.type == "node") {
            this.quizService.setCurrentNode(this.node);
        }
        this.automatic = JSON.parse(localStorage.getItem('automatic'));

        /* $("#node").on({
            "shown.bs.modal": function() { console.log('SHOW');
                                          this.closable = false; },
            "click":             function(e) { console.log("CLICK");
                                                this.closable = true;},
            "hidden.bs.modal":  function() { console.log("HIDDEN");
                                            return this.closable; },

            "hide.bs.modal":  function() { console.log("HIDE");
                                          return this.closable;}
        });*/
        // Fin A.G.

        // console.log("Id Curso : " + this.idCurso);
        //this.cargando = false;
        this.inicial = true;
        if (this.loginService.esAutor()) {
            //this.mensaje = "Por favor suba los archivos de estudio";
            this.mensaje = "subir";
        }
        if (this.loginService.esEstudiante()) {
            //this.mensaje = "Aquí se mostrará sus archivos de estudio";
            this.mensaje = "mostrar";
            this.consultarNodeFiles()
            this.consultarNodeOperators()

            this.listaIconosAdicionados.set(1, [{ id: 1, nombre: "visibility", src: "../../../../../assets/images/nodes/eye.png", tipo: "senmotemo" },
            { id: 2, nombre: "hearing", src: "../../../../../assets/images/nodes/ear.png", tipo: "senmotemo" },
            { id: 3, nombre: "copyright", src: "../../../../../assets/images/nodes/lyrics.png", tipo: "assostatic" },
            { id: 4, nombre: "feedback", src: "../../../../../assets/images/nodes/signs.png", tipo: "assostatic" },
            { id: 4, nombre: "videocam", src: "../../../../../assets/images/nodes/camera.png", tipo: "temporary" }]);
            this.listaIconosAdicionados.set(2, [{ id: 1, nombre: "visibility", src: "../../../../../assets/images/nodes/eye.png", tipo: "senmotemo" },
            { id: 2, nombre: "hearing", src: "../../../../../assets/images/nodes/ear.png", tipo: "senmotemo" },
            { id: 3, nombre: "copyright", src: "../../../../../assets/images/nodes/lyrics.png", tipo: "assostatic" },
            { id: 4, nombre: "feedback", src: "../../../../../assets/images/nodes/signs.png", tipo: "assostatic" },
            { id: 4, nombre: "videocam", src: "../../../../../assets/images/nodes/camera.png", tipo: "temporary" }]);
            this.listaIconosAdicionados.set(3, [{ id: 1, nombre: "visibility", src: "../../../../../assets/images/nodes/eye.png", tipo: "senmotemo" },
            { id: 2, nombre: "hearing", src: "../../../../../assets/images/nodes/ear.png", tipo: "senmotemo" },
            { id: 3, nombre: "copyright", src: "../../../../../assets/images/nodes/lyrics.png", tipo: "assostatic" },
            { id: 4, nombre: "feedback", src: "../../../../../assets/images/nodes/signs.png", tipo: "assostatic" },
            { id: 4, nombre: "videocam", src: "../../../../../assets/images/nodes/camera.png", tipo: "temporary" }]);
            this.listaIconosAdicionados.set(4, [{ id: 1, nombre: "visibility", src: "../../../../../assets/images/nodes/eye.png", tipo: "senmotemo" },
            { id: 2, nombre: "hearing", src: "../../../../../assets/images/nodes/ear.png", tipo: "senmotemo" },
            { id: 3, nombre: "copyright", src: "../../../../../assets/images/nodes/lyrics.png", tipo: "assostatic" },
            { id: 4, nombre: "feedback", src: "../../../../../assets/images/nodes/signs.png", tipo: "assostatic" },
            { id: 4, nombre: "videocam", src: "../../../../../assets/images/nodes/camera.png", tipo: "temporary" }]);
            this.listaIconosAdicionados.set(5, [{ id: 1, nombre: "visibility", src: "../../../../../assets/images/nodes/eye.png", tipo: "senmotemo" },
            { id: 2, nombre: "hearing", src: "../../../../../assets/images/nodes/ear.png", tipo: "senmotemo" },
            { id: 3, nombre: "copyright", src: "../../../../../assets/images/nodes/lyrics.png", tipo: "assostatic" },
            { id: 4, nombre: "feedback", src: "../../../../../assets/images/nodes/signs.png", tipo: "assostatic" },
            { id: 4, nombre: "videocam", src: "../../../../../assets/images/nodes/camera.png", tipo: "temporary" }]);
        }

        //OJO PRUEBAS
        if (this.loginService.esEstudiante()) {
            var forma = { titulo: "video" };
            this.reproducirArchivo(forma);
        }
        //PRUEBAS

        //CUENTA REGRESIVA PARA MOSTRAR LOS QUIZ
        //this.executeAutomaticStudy();
    }

    cancelar() {
        this.modalService.dismissAll();
    }

    registrar() {
        this.modalService.dismissAll();
    }

    reproducirFormato(formato: string) {
        this.format = formato;
    }

    closeModal(sendData) {
        this.activeModal.close(sendData);
    }

    onFileSelected(event, titulo) {
        this.iconosAdicionados = [];
        this.videoSource = "";
        this.imageSource = "";
        this.audioSource = "";
        this.pdfSource = "";
        this.textSource = "";
        document.getElementById(titulo).style.backgroundImage = "";
        this.idFormato = event.target.id;
        this.format = titulo;
        //this.cargando = true;
        this.inicial = false;
        var file = event.target.files[0];
        this.formats[this.idFormato - 1].edit = false;
        this.listaIconosAdicionados.delete(this.idFormato);
        if (file) {
            var reader = new FileReader();
            let arch: any;
            reader.readAsDataURL(file);
            console.log("Tipo : " + file.type);
            reader.onload = async (event: any) => {
                console.log("Va empezar cargar video");
                if (this.idFormato == 1) {
                    if (file.type == "video/mp4") {
                        this.videoSource = await event.target.result;
                        //SnapShop del video
                        //this.snapshotService.takeSnapshot(this.videoElement);
                        //Guardar la imagen Servicio y BD asociar numero de nodo y tipo de dato
                        //Consultar la imagen
                        document.getElementById(titulo).style.backgroundImage = "url('../../../assets/images/video.jpg')";
                        //this.cargando = false;
                        console.log("Va empezar a mostrar Operadores");
                        this.cargarIconos();
                        //this.subirMediosService.subirArchivo(file);
                        //arch=this.subirMediosService.descargarArchivo(file);
                        //console.log("Archivo descargado: " + arch);
                        this.registrarNodeFiles();
                        this.formats[0].edit = true;
                        // A.G.
                        this.node.videoFile = file.name;
                        this.nodeService.setCurrentNode(this.node);
                    } else {
                        //this.cargando = false;
                        this.inicial = true;
                        this.mensaje = "noFormato";
                    }
                } else if (this.idFormato == 2) {
                    this.formats[1].edit = false;
                    if (file.type == "image/jpeg" || file.type == "image/png") {
                        this.imageSource = event.target.result;
                        document.getElementById(titulo).style.backgroundImage = 'url(' + this.imageSource + ')';
                        //this.cargando = false;
                        this.cargarIconos();
                        this.registrarNodeFiles();
                        this.formats[1].edit = true;
                        // A.G.
                        this.node.pictureFile = file.name;
                        this.nodeService.setCurrentNode(this.node);
                    } else {
                        //this.cargando = false;
                        this.inicial = true;
                        this.mensaje = "noFormato";
                    }
                } else if (this.idFormato == 3) {
                    if (file.type == "audio/mpeg") {
                        this.audioSource = event.target.result;
                        document.getElementById(titulo).style.backgroundImage = "url('../../../assets/images/fondo.jpg')";
                        //document.getElementById(titulo).style.backgroundImage = 'url(' + this.audioSource  + ')';
                        //this.cargando = false;
                        this.cargarIconos();
                        this.registrarNodeFiles();
                        this.formats[2].edit = true;
                        // A.G.
                        this.node.audioFile = file.name;
                        this.nodeService.setCurrentNode(this.node);
                    } else {
                        //this.cargando = false;
                        this.inicial = true;
                        this.mensaje = "noFormato";
                    }
                } else if (this.idFormato == 4) {
                    if (file.type == "application/pdf") {
                        this.pdfSource = event.target.result;
                        document.getElementById(titulo).style.backgroundImage = "url('../../../assets/images/fondo.jpg')";
                        //document.getElementById(titulo).style.backgroundImage = 'url(' + this.pdfSource  + ')';
                        //this.cargando = false;
                        this.cargarIconos();
                        this.registrarNodeFiles();
                        this.formats[3].edit = true;
                        // A.G.
                        this.node.pdfFile = file.name;
                        this.nodeService.setCurrentNode(this.node);
                    } else {
                        //this.cargando = false;
                        this.inicial = true;
                        this.mensaje = "noFormato";
                    }
                } else if (this.idFormato == 5) {
                    if (file.type == "text/plain") {
                        this.textSource = event.target.result;
                        document.getElementById(titulo).style.backgroundImage = "url('../../../assets/images/fondo.jpg')";
                        //document.getElementById(titulo).style.backgroundImage = 'url(' + this.textSource  + ')';
                        //this.cargando = false;
                        this.cargarIconos();
                        this.registrarNodeFiles();
                        this.formats[4].edit = true;
                        // A.G.
                        this.node.textfile = file.name;
                        this.nodeService.setCurrentNode(this.node);
                    } else {
                        //this.cargando = false;
                        this.inicial = true;
                        this.mensaje = "noFormato";
                    }
                }
            }
        } else {
            //this.cargando = false;
            this.inicial = true;
            this.mensaje = "noEscogido";
        }

    }

    cargarIconos() {
        const modalRef = this.modalService.open(ModalOperadoresComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-30'
            })
        modalRef.componentInstance.id = 1;

        modalRef.result.then((response) => {
            switch (response.result) {
                case 'OK':
                    this.
                        agruparIconosAdicionados(response.iconosSenmotemo, response.iconosAssostatic, response.iconosTemporary, response.iconosAbstract);
                    return;
                case 'ERROR':
                    return;
            }

        }, (reason) => {
        });
    }

    /*onUpload(){
        console.log("Servicio de Registro de formatos");
       const fd = new FormData();
       fd.append('image', this.selectedFile, this.selectedFile.name);
       this.http.post('url',fd,{
           reportProgress:true,
           observe:'events'
       })
       .subscribe(event=>{
           if(event.type === HttpEventType.UploadProgress){
              console.log("Upload Progress : " + Math.round(event.loaded/event.total*100 )+ '%');
           }else if(event.type === HttpEventType.Response){
               console.log(event);
           }
       })
    }*/

    agruparIconosAdicionados(iconosSenmotemo: any[], iconosAssostatic: any[], iconosTemporary: any[], iconosAbstract: any[]) {

        for (var val of iconosSenmotemo) {
            this.iconosAdicionados.push(val);
        }
        for (var val of iconosAssostatic) {
            this.iconosAdicionados.push(val);
        }
        for (var val of iconosTemporary) {
            this.iconosAdicionados.push(val);
        }
        for (var val of iconosAbstract) {
            this.iconosAdicionados.push(val);
        }
        this.listaIconosAdicionados.set(this.idFormato, this.iconosAdicionados);
        console.log(this.listaIconosAdicionados);
    }

    reproducirArchivo(format: any) {
        this.format = format.titulo;
        //this.cargando = true;
        this.inicial = false;
        if (format.titulo == "video") {
            this.videoSource = "../../../assets/videos/1.mp4";
            document.getElementById(format.titulo).style.backgroundImage = "url('../../../assets/images/video.jpg')";
            //this.cargando = false;
        }
        if (format.titulo == "imagen") {
            this.imageSource = "../../../assets/images/fondo_registro.jpg";
            document.getElementById(format.titulo).style.backgroundImage = "url('../../../assets/images/fondo_registro.jpg')";
            //this.cargando = false;
        }
        if (format.titulo == "audio") {
            this.audioSource = "../../../assets/audio/1.mp3";
            document.getElementById(format.titulo).style.backgroundImage = "url('../../../assets/images/headphones.jpg.jpg')";
            //this.cargando = false;
        }
        if (format.titulo == "pdf") {
            this.pdfSource = "../../../assets/document/1.pdf";
            document.getElementById(format.titulo).style.backgroundImage = "url('../../../assets/images/pdf.jpg')";
            //this.cargando = false;
        }
        if (format.titulo == "texto") {
            this.textSource = "../../../assets/document/1.txt";
            document.getElementById(format.titulo).style.backgroundImage = "url('../../../assets/images/texto.jpg')";
            //this.cargando = false;
        }
    }

    registrarNodeFiles() {
        console.log("Registrar nodo");
    }
    registrarNodeOperators() {
        console.log("Registrar operadores");
    }
    consultarNodeFiles() {
        console.log("Consultar nodo");
    }
    consultarNodeOperators() {
        console.log("Consultar operadores");
    }
    editarNodo() {
        console.log("Editar log");
    }

    getImagen(format: any) {
        if (format.titulo == "video") {
            //this.videoSource = "../../../assets/videos/1.mp4";
            //return document.getElementById(format.titulo).style.backgroundImage = "url('../../../assets/images/video.jpg')";
            return "url('../../../assets/images/video.jpg')"
        }
        if (format.titulo == "imagen") {
            //this.imageSource = "../../../assets/images/fondo_registro.jpg";
            //return document.getElementById(format.titulo).style.backgroundImage = "url('../../../assets/images/fondo_registro.jpg')";
            return "url('../../../assets/images/fondo_registro.jpg')"
        }
        if (format.titulo == "audio") {
            //this.audioSource = "../../../assets/audio/1.mp3";
            //return document.getElementById(format.titulo).style.backgroundImage = "url('../../../assets/images/headphones.jpg')";
            return "url('../../../assets/images/headphones.jpg')"
        }
        if (format.titulo == "pdf") {
            //this.pdfSource = "../../../assets/document/1.pdf";
            //return document.getElementById(format.titulo).style.backgroundImage = "url('../../../assets/images/fondo_registro.jpg')";
            return "url('../../../assets/images/pdf.jpg')"
        }
        if (format.titulo == "texto") {
            //this.textSource = "../../../assets/document/1.txt";
            //return document.getElementById(format.titulo).style.backgroundImage = "url('../../../assets/images/fondo_registro.jpg')";
            return "url('../../../assets/images/texto.jpg')"
        }
    }

    executeAutomaticStudy() {
        let date = new Date();
        date.setSeconds(date.getSeconds() + this.node.duration);
        this.timerId = countdown(date, (ts) => {
            this.time = ts;
            console.log("Time:", this.time);
            if (this.time.minutes == 0 && this.time.seconds == 0) {
                if (this.automatic && this.loginService.esEstudiante()) {
                    this.quizService.setExecuteAutomatic(true);
                    const modalRef = this.modalService.open(ModalReproducirQuizzesComponent,
                        {
                            scrollable: true,
                            windowClass: 'modal-dialog-95'
                        })
                    modalRef.componentInstance.node = this.node;
                    modalRef.componentInstance.idCurso = this.idCurso;
                    modalRef.componentInstance.idMapa = this.idMapa;
                    modalRef.componentInstance.currentGraph = this.currentGraph;
                    modalRef.componentInstance.type = 'node';
                    modalRef.componentInstance.nomGrafo = '';
                    modalRef.result.then((result) => {
                        console.log(result);
                    }, (reason) => {
                    });
                    this.closeModal('close');
                }
                if (this.timerId) {
                    clearInterval(this.timerId);
                }
            }
        }, countdown.HOURS | countdown.MINUTES | countdown.SECONDS)
    }

    ngOnDestroy() {
        console.log('ngOnDestroy');
        if (this.timerId) {
            clearInterval(this.timerId);
        }
    }
}
