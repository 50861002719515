import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class SnackbarService {
    private SUCCESS: string = 'snackSuccess';
    private ERROR: string = 'snackError';

    constructor(private snackBar: MatSnackBar) { }

    public success(message: string) {
        this.show(message, this.SUCCESS);
    }

    public error(message: string) {
        this.show(message, this.ERROR);
    }

    private show(message: string, type: string) {
        this.snackBar.open(message, undefined, {
            duration: 5000,
            horizontalPosition: 'left',
            verticalPosition: 'top',
            panelClass: type
        });
    }
}
