import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideBarComponent } from './side-bar/side-bar.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { BaseLayoutComponent } from './base-layout/base-layout.component';
import { SettingsLayoutComponent } from './settings-layout/settings-layout.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';

@NgModule({
    declarations: [
        SideBarComponent,
        HeaderComponent,
        BaseLayoutComponent,
        SettingsLayoutComponent,
        AuthLayoutComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [
        SideBarComponent,
        HeaderComponent
    ]
})
export class LayoutModule { }
