import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';


@Pipe({ name: 'imagen' })
export class ImagenPipe implements PipeTransform {

    constructor() { }

    transform(img: string, tipo: string): any {

        if (!img) return;

        if (img.includes('blob')) {
            return img;
        }

        let url = `${environment.localUrl}`;

        switch (tipo) {
            case 'cursos':
                url += environment.imagesCourses;
                break;
            case 'mapas':
                url += environment.imagesTargets;
                break;
            case 'quizElement':
                url += environment.quizzesContent;
                break;
            case 'graphBg':
                url += environment.graphBackground;
                break;
        }
        return url += `${img}`
    }

}
