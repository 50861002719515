import { Component, Output, EventEmitter, ViewChild, ElementRef, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-menu-fab',
  templateUrl: './menu-fab.component.html',
  styleUrls: ['./menu-fab.component.scss']
})
export class MenuFabComponent {

 


  fabButtons = [
    {
      icon: 'add',
      action: 'ZoomIn',
      tooltip: 'Zoom in',
      activeColor: 'accent',
      inactiveColor: 'light',
      // activeStatus: false
    },
    {
      icon: 'remove',
      action: 'zoomOut',
      tooltip: 'Zoom out',
      activeColor: 'accent',
      inactiveColor: 'light',
      // activeStatus: false
    },
    {
      icon: 'filter_center_focus',
      action: 'center',
      tooltip: 'Center Graph',
      activeColor: 'accent',
      inactiveColor: 'light'
    }
  ];
  buttons = [];
  fabTogglerState = 'inactive';

  private action = '';
  @Output() actionEvent = new EventEmitter<string>();

  
 // @ViewChild('button', {static: false}) button: ElementRef;

  constructor() { }
  async ngOnInit() {
    this.buttons = this.fabButtons;
  }
  
  sendMessage() {
    this.actionEvent.emit(this.action);
  }

  // showItems() {
  //   this.fabTogglerState = 'active';
  //   this.buttons = this.fabButtons;
  // }

  // hideItems() {
  //   this.fabTogglerState = 'inactive';
  //   this.buttons = [];
  // }

  // onToggleFab() {
  //   this.buttons.length ? this.hideItems() : this.showItems();
  // }

  public buttonColor = [];
 
 
  private setcolorByStatus(actionName){
    if (this.getButton(actionName).activeStatus){
        this.buttonColor[actionName] = this.getButton(actionName).activeColor;
    } else {
    this.buttonColor[actionName] = this.getButton(actionName).inactiveColor;
  }
}

  private getButton( actionName ) {
    var btn;
    this.fabButtons.forEach( (b) => {
      if (b.action == actionName){
        btn = b;
      }
    });
    return btn;
  }

  onClick(action) {
   
    this.action = action;
    this.sendMessage();
  }

  




}
