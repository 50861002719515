import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GraphService } from 'src/app/core/services/graph/graph.service';
import { FormsComponent } from '../forms.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { gNode } from 'src/app/core/models/graph/gNode.model';
import { NodeService } from 'src/app/core/services/node/node.service';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { LoginService } from 'src/app/core/services/login';
import { TemplatesService } from 'src/app/core/services/templates/templates.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-quiz-form',
    templateUrl: './quiz-form.component.html',
    styleUrls: ['./quiz-form.component.scss']
})
export class QuizFormComponent extends FormsComponent implements OnInit {

    public template: any;
    public languages = [{ id: 36, valor: 'English' }, { id: 38, valor: 'Spanish' }];
    public form: FormGroup = new FormGroup({
        name: new FormControl('', Validators.required),
        level: new FormControl(0),
        duration: new FormControl('00:00'),
        language1: new FormControl(''),
        language2: new FormControl(''),
        ordinal: new FormControl(0),
    });
    @Output() send = new EventEmitter<boolean>();
    newQuiz: any;
    elements = [];
    elementsTemplatesQuizzes: any[];
    private templateTypes = [
        'VideoQuestions',
        'ImageQuestions',
        'AudioQuestions',
        'PdfQuestions',
        'TextQuestions',
        'VideoOptions',
        'ImageOptions',
        'AudioOptions',
        'PdfOptions',
        'TextOptions',
        'VideoCorrect',
        'ImageCorrect',
        'AudioCorrect',
        'PdfCorrect',
        'TextCorrect'];
    private elementIcons = [
        'videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject',
        'videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject',
        'videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject'];

    constructor(public graphServ: GraphService, public nodeService: NodeService,
        public quizService: QuizzesService, private loginService: LoginService,
        public templatesService: TemplatesService) {
        super(graphServ, nodeService, quizService);

        this.quizService.currentQuiz.subscribe((n) => {
            this.record = n;
        });

        this.templatesService.currentTemplate.subscribe((t) => {
            this.template = t;
        });

        /*this.quizService.newQuiz.subscribe((q) => {
           this.newQuiz = q
           this.saveTemplatesQuizzes();
       });*/
    }

    public async readData(id) {
        if (id === 0) {
            this.record.author = this.loginService.getUser();
        } else {
            var result = await this.quizService.getQuizById(id).then((result));
            console.log("Estoy en el readData result : ", result);
            this.record.author = result.author;
            this.record.creationDateString = result.creationDateString;
            this.record.lastEditionDateString = result.lastEditionDateString;
            this.record.idOriginal = result.idQuiz;
            this.form.get('name').setValue(result.stringCaptionsTitle);
            this.form.get('level').setValue(result.quizSwlevel);

            if (result.quizzesNodes.length > 0) {
                this.form.get('ordinal').setValue(result.quizzesNodes[0].ordinal);
            } else {
                this.form.get('ordinal').setValue(0);
            }
            this.form.get('duration').setValue(this.minutesToTime(result.duration));
            this.form.get('language1').setValue(result.questionLanguage.idLanguage);
            this.form.get('language2').setValue(result.answerLanguage.idLanguage);
        }
    }

    public save() {
        console.log("Enviando Save");
        this.record.stringCaptionsTitle = this.form.value.name;
        this.record.quizSwlevel = this.form.value.level;
        this.record.duration = this.durationToMinutes();
        this.record.text = this.form.value.name;
        this.record.idQuestionLanguage = this.form.value.language1;
        this.record.idAnswerLanguage = this.form.value.language2;

        if (this.record.idOriginal) {
            /*SI EXISTE EL QUIZ ACTULIZAR QUIZ Y TEMPLATE*/
            this.quizService.updateQuiz(this.record);
            if (this.template) {
                this.templatesService.updatesTemplate(this.template);
                this.newQuiz = this.record;
                this.saveTemplatesQuizzes();
            }
        } else {
            /*NO EXISTE EL QUIZ CREAR*/
            this.quizService.DEPRECATEDcreateQuiz(this.record).then((n: any) => {
                this.record.idOriginal = n;
                this.newQuiz = n;
                this.saveTemplatesQuizzes()
                this.quizService.setNewQuiz(this.newQuiz);
            });
        }

        this.send.emit(true);
        this.changed.emit(false);
    }

    public onSubmit() {

    }

    saveTemplatesQuizzes() {
        console.log("****Ingreso a saveTemplatesQuizzes****");
        if (this.newQuiz && this.template) {
            /*REGISTRAR TEMPLATE CON QUIZ Y ELMENTOS DEL QUIZ*/
            this.templatesService.getTemplatesElementQuizzes(this.template.idTemplate, this.newQuiz.idOriginal).toPromise().then((n) => {
                if (n.data.templatesElementQuizzes.nodes.length == 0) {
                    this.templatesService.createTemplatesQuizzes(this.template.idTemplate, this.newQuiz.idOriginal);
                    this.templatesService.getTemplateElementsByIdTemplate(this.template.idTemplate).subscribe((resp: any) => {
                        this.elements = resp.data.templateElement.nodes;
                        this.createElementsTemplateQuiz(this.elements);
                    }, (error) => {
                        console.log("ERROR", error);
                    });
                }
            });

            /*RECALCULAR POSICIONES DE LOS ELEMENTOS DEL TEMPLATE*/
            if (this.newQuiz.idOriginal) {
                //Veririficar si tienes datos
                this.templatesService.getTemplatesElementQuizzesByIdQuiz(this.newQuiz.idOriginal).subscribe((data: any) => {
                    this.elementsTemplatesQuizzes = data.data.templatesElementQuizzes.nodes;
                    if (this.elementsTemplatesQuizzes.length > 0) {
                        //Si los elementos tienen datos recalculamos las posiciones de los elementos
                        this.elementsTemplatesQuizzesToElements();
                    } else {
                        //Si los elementos no tienen datos recalculamos las posiciones de los elementos
                        this.templatesService.getTemplateElementsByIdTemplate(this.template.idTemplate).subscribe((resp: any) => {
                            this.elements = resp.data.templateElement.nodes;
                            this.calculatePositions(this.elements);
                            this.assignIconTypeElement(this.elements);
                            return this.elements;
                        }, (error) => {
                            console.log("ERROR", error);
                        });
                    }
                });
            }
        } else {
            return;
        }
    }
    async createElementsTemplateQuiz(elements) {
        this.calculatePositions(elements);
        this.assignIconTypeElement(elements);
        if (elements) {
            for (var element of elements) {
                element.idQuiz = this.newQuiz.idOriginal;
                await this.templatesService.createTemplatesElementQuizzes(element);
            }
        }
    }

    calculatePositions(elements: any[]) {
        if (elements != null) {
            elements.forEach((element) => {
                element.yposition = element.newyposition;
                element.xposition = element.newxposition;
            });
        }
    }

    assignIconTypeElement(elements: any) {
        for (var i = 0; i < this.elements.length; i++) {
            this.elements[i].style = this.templateTypes[elements[i].elementType];
            this.elements[i].icon = this.elementIcons[elements[i].elementType];
        }
    }

    elementsTemplatesQuizzesToElements() {
        this.elements = [];
        for (var i = 0; i < this.elementsTemplatesQuizzes.length; i++) {
            this.elements[i] = this.elementsTemplatesQuizzes[i].templateElement[0];
        }
        this.calculatePositions2(this.elements);
        this.assignIconTypeElement(this.elements);
        return this.elements;
    }

    calculatePositions2(elements: any[]) {
        if (elements != null) {
            elements.forEach((element) => {
                element.yposition = element.yposition;
                element.xposition = element.xposition;
            });
        }
    };

}
