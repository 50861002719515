import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

// Services
import { AlertService, MessageService, TitleService, DomainRoutingService, FileSavingService } from 'src/app/core/services/shared';
import {
    UsersRegisterMapperService, UsersStudentsParentsMapperService,
    UsersAuthorsMapperService, UsersParentsMapperService, UsersCentersMapperService,
    UsersEmployeersMapperService, UsersProfileValidationMapperService, UsersSettingsProfileMapperService,
    CentersMapperService
} from 'src/app/core/services/mapper';
import { LoginService } from 'src/app/core/services/login';

// Utils
import { DomainRouting } from 'src/app/core/utils';
import { RegisterService } from './register/register.service';
import { SnackbarService } from './shared/snackbar.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule
    ],
    providers: [
        { provide: DomainRouting, useClass: DomainRoutingService },
        AlertService,
        MessageService,
        TitleService,
        LoginService,
        CentersMapperService,
        UsersRegisterMapperService,
        UsersAuthorsMapperService,
        UsersParentsMapperService,
        UsersCentersMapperService,
        UsersEmployeersMapperService,
        UsersStudentsParentsMapperService,
        UsersSettingsProfileMapperService,
        UsersProfileValidationMapperService,
        FileSavingService,
        RegisterService,
        SnackbarService
    ]
})
export class CoreServiceModule { }
