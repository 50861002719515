import { Pipe, PipeTransform } from '@angular/core';
import { SIGMA_CONSTANTS } from 'src/app/core/utils/sigma-constants';
import { environment } from 'src/environments/environment';

@Pipe({
    name: 'nodeCover'
})
export class NodeCoverPipe implements PipeTransform {

    transform(img: string, type: number): any {
        if (!img || img === 'null') {
            return SIGMA_CONSTANTS.IMAGE_DEFAULT;
        }

        let url = `${environment.localUrl}`;
        switch (type) {
            case 1:
                url += environment.nodeContent + 'videoImage/';
                break;
            case 2:
                url += environment.nodeContent + 'picture/';
                break;
            case 3:
                url += environment.nodeContent + 'audio/';
                break;
            case 4:
                url += environment.nodeContent + 'pdf/';
                break;
            case 5:
                url += environment.nodeContent + 'text/';
                break;
            default:
                url += environment.nodeImages;
                break;
        }
        console.log(url);
        return url += `${img}`
    }

}
