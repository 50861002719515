import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { GraphService } from 'src/app/core/services/graph/graph.service';
import { FormGroup } from '@angular/forms';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { NodeService } from 'src/app/core/services/node/node.service';
import { gNode } from 'src/app/core/models/graph/gNode.model';

@Component({
    selector: 'app-forms',
    templateUrl: './forms.component.html',
    styleUrls: ['./forms.component.scss']
})

export class FormsComponent {
    @Input() desc;
    @Input() node: any;
    public record;
    @Output() changed = new EventEmitter<boolean>();
    @Input() idCurso: number;
    @Input() idMapa: number;
    @Input() type;
    public form;

    constructor(public graphServ: GraphService, public nodeService: NodeService, public quizService: QuizzesService) { }

    ngOnInit() {
        if (this.node && this.node.id !== 'temp') {
            var id = this.node.idOriginal ? this.node.idOriginal : 0;
            this.readData(id);

            if (this.node.nodeType === 'Node') {
                this.readDescription();
            }
        }

        this.form.valueChanges.subscribe(() => {
            this.changed.emit(true);
        });
    }

    public readData(id) {
        //
    }

    public readDescription() {
        // this.desc.subscribe(description => {
        //     this.form.get('description').setValue(description);
        //     this.node.description = description;
        // });
    }

    public reset() {
        this.form.reset();
    }

    // --------------------------------------
    //    F O R M A T
    // --------------------------------------

    public minutesToTime(min = 0) {
        var h = Math.trunc(min / 60).toString();
        var m = Math.trunc(min % 60).toString();
        if (m.length < 2) {
            m = '0' + m;
        }
        if (h.length < 2) {
            h = '0' + h;
        }
        return h + ':' + m;
    }

    public durationToMinutes() {
        var t = this.form.value.duration.split(':');
        return (parseInt(t[0]) * 60) + parseInt(t[1]);
    }
    // --------------------------------------
}
