import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
  })
  export class SubirArchivoService {

    constructor(private http: HttpClient) { }

    subirArchivo_(archivo: File, tipo: string, id: string) {

      return new Promise((resolve, reject) => {
        let formData = new FormData();
        let xhr = new XMLHttpRequest();

        formData.append('imagen', archivo, archivo.name);
        xhr.onreadystatechange = () => {

          if (xhr.readyState === 4) {

            if (xhr.status === 200 ) {
              console.log('Imagen subida');
              resolve(JSON.parse(xhr.response));
            } else {
              console.log('Fallo la subida', xhr.response);
              reject(xhr.response);
            }
          }
        };
        let url = environment.apiUrl + '/assets/images/' + tipo;
        xhr.open('PUT', url, true);
        xhr.send(formData);
      });
    }

    subirArchivo(archivo: File) {
        return new Promise((resolve, reject) => {
          let formData = new FormData();
          let xhr = new XMLHttpRequest();

          formData.append('file', archivo, archivo.name);
          xhr.withCredentials = true;

          xhr.addEventListener("readystatechange", function() {
            if (xhr.readyState === 4) {

                if (xhr.status === 200 ) {
                  console.log('Imagen subida');
                  console.log('Status', xhr.status);
                  resolve(xhr.response);
                } else {
                  console.log('Fallo la subida', xhr.response);
                  console.log('Status', xhr.status);
                  reject(xhr.response);
                }
              }
          });
          xhr.open("POST", "https://localhost:44300/api/files");
          //xhr.open("POST", "http://salware.accuro.es:5000/api/files");
          //xhr.setRequestHeader('Access-Control-Allow-Headers', '*');
          //xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
          xhr.send(formData);
        });
      }
  }
