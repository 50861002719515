import { ResponseTemplateCreateModel, TemplateModel } from 'src/app/core/models/quizzes/template.model';
import { ResponseTemplateElements, TemplateElementModel } from './../../models/quizzes/template.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

// GraphQL
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ResponseTemplatesModel } from '../../models/quizzes/template.model';
import { ApiResponse } from '../../models/shared/api-response.model';
import { map } from 'rxjs/operators';
import { QuizTemplateElement } from '../../models/quizzes/quiz-template-element.model';

const GETALLTEMPLATES = 'getListTemplatesByFilter'
const GETTEMPLATEBYID = 'getTemplateById/$1'
const SETFAVOURITE = 'template/$1/user/$2'
const CREATETEMPLATE = 'create-template'
const CREATETEMPLATEELEMENTS = 'create-template-elements'
const GETELEMENTSBYIDTEMPLATE = 'templateElementsBy/$1'


@Injectable({
    providedIn: 'root'
})
export class TemplatesService {

    public currentTemplate = new Subject();
    public flagTemplateUpdate = new Subject();
    public isResponse = new Subject();
    public currentElement = new Subject();
    public saveQuizz = new Subject();

    templatesElementQuizzes = gql
        `query($filter: TemplatesElementQuizzesFilter){
                templatesElementQuizzes(where: $filter){
                    nodes{
                        idTemplateElementQuizz
                        idTemplateElement
                        idQuiz
                        data
                        responseCheck
                      }
                    }
                  }`;

    templatesElementQuizzesById = gql`query($id: Int!) {
    templatesElementQuizzes(where: { idTemplateElementQuizz: $id }) {
      nodes {
        idTemplateElementQuizz
        idTemplateElement
        idQuiz
        data
        responseCheck
        templateElement {
          idTemplate
          elementType
          xposition
          yposition
          xsize
          ysize
        }
       }
      }
    }`;

    returnTemplatesElementQuizzes = `{
        idTemplateElementQuizz
        idTemplateElement
        idQuiz
        data
        responseCheck
    }`;

    returnTemplateQuizzesFields = gql
        `query($filter: TemplatesQuizzesFilter){
            templatesQuizzes(where: $filter){
              nodes{
                    idTemplateQuizz
                    idQuiz
                    idTemplate
                    template{
                        idTemplate
                        templateTittle
                        templateSnapshot
                        tittle
                        quizInstructions
                        writable
                        compuCorrect
                        multiplexed
                      templateElement{
                        idTemplateElement
                        idTemplate
                        elementType
                        xposition
                        yposition
                        xsize
                        ysize
                      }
                   }
                }
              }
            }`;

    returnTemplateQuizzesField = `{
                idTemplateQuizz
                idQuiz
                idTemplate
                template{
                  idTemplate
                  templateTittle
                  templateSnapshot
                  tittle
                  quizInstructions
                  writable
                  compuCorrect
                  multiplexed
                  templateElement{
                    idTemplateElement
                    idTemplate
                    elementType
                    xposition
                    yposition
                    xsize
                    ysize
                  }
                }
            }`;

    //END Querys
    private query(query, variables) {
        return this.apollo.query<any>({
            query: query,
            variables: variables
        },
        );
    }

    constructor(private apollo: Apollo, private http: HttpClient) { }

    public setCurrentTemplate(template: any) {
        this.currentTemplate.next(template);
    }

    public setFlagTemplateUpdate(update: boolean) {
        this.flagTemplateUpdate.next(update);
    }

    public setIsResponse(isResponse: boolean) {
        this.isResponse.next(isResponse);
    }

    public setCurrentElement(element: any) {
        this.currentElement.next(element);
    }

    public emitSaveQuizz(save) {
        if (save) {
            this.saveQuizz.next(save)
        }
    }

    getAllTemplates(idUser: number, filter?: any): Observable<ResponseTemplatesModel> {
        const BODY = {
            idUserCreation: idUser
        }
        return this.http.post<ResponseTemplatesModel>(GETALLTEMPLATES, BODY)
    }

    getTemplateById(id: number): Observable<any> {
        let httpParams = new HttpParams().set('idTemplate', id.toString())
        return this.http.get<any>(GETTEMPLATEBYID, { params: httpParams })
    }

    setFavourite(idTemplate: number, idUser: number): Observable<any> {
        return this.http.get<any>(SETFAVOURITE.replace('$1', idTemplate.toString()).replace('$2', idUser.toString()))
    }

    setTemplate(template: TemplateModel): Observable<ResponseTemplateCreateModel> {
        return this.http.post<ResponseTemplateCreateModel>(CREATETEMPLATE, template);
    }

    createTemplatesQuizzes(idTemplate: number, idQuiz: number) {
        var createTemplatesQuizzes = gql
            `mutation {
                createTemplatesQuizzes(
                 templatesQuizzes: {
                  idTemplateQuizz:0
                  idTemplate: ${idTemplate}
                  idQuiz: ${idQuiz}
                }
              ) {
                idTemplateQuizz
              }
            }`;


        this.apollo.mutate({
            mutation: createTemplatesQuizzes,
            refetchQueries: [{
                query: this.returnTemplateQuizzesFields,
                variables: {
                    filter: { idQuiz: idQuiz }
                }
            }]
        }).toPromise().then(y => {
            console.log("Create a createTemplatesQuizzes");
        });
    }


    getTemplatesElementQuizzesByIdQuiz(idQuiz) {
        const query = gql`query($id: Int!) {
       templatesElementQuizzes(where: { idQuiz: $id }) {
        nodes {
          idTemplateElementQuizz
          idTemplateElement
          idQuiz
          data
          responseCheck
          templateElement {
             idTemplate
             elementType
             xposition
             yposition
             xsize
             ysize
           }
         }
        }
      }`;

        var result;
        try {
            result = this.apollo.query<any>({
                query: query,
                variables: {
                    id: idQuiz
                }
            });
        } catch (e) {
            console.log("ERROR", e)
        }
        return result;
    }

    getTemplatesElementQuizzesbyId(idQuiz) {
        const query = gql`query($id: Int!) {
       templatesElementQuizzes(where: { idQuiz: $id }) {
         nodes {
          idTemplateElementQuizz
          idTemplateElement
          idQuiz
          data
          responseCheck
          templateElement {
             idTemplate
             elementType
             xposition
             yposition
             xsize
             ysize
            }
          }
        }
      }`

        var result;
        try {
            result = this.apollo.query<any>({
                query: query,
                variables: {
                    id: idQuiz
                }
            });
        } catch (e) {
            console.log("ERROR", e)
        }
        return result;
    }

    public async getTemplatesElementQuizzesById(idTemplateElementQuizzes) {
        const query = this.templatesElementQuizzesById;
        const variables = {
            id: parseInt(idTemplateElementQuizzes)
        };
        var data = await this.query(query, variables).toPromise().then(data);
        return data.data.templatesElementQuizzes;
    }

    setTemplatesElementQuizzes(templateElementQuizz: any) {
        if (templateElementQuizz.idTemplateElementQuizz) {
            this.updateTemplatesElementQuizzes(templateElementQuizz);
        } else {
            this.createTemplatesElementQuizzes(templateElementQuizz);
        }
    }

    updateTemplatesElementQuizzes(templateElementQuizz) {
        var mutation = gql
            `mutation {
          updateTemplatesElementQuizzes (
            templatesElementQuizzes: {
                    idTemplateElementQuizz: ${templateElementQuizz.idTemplateElementQuizz}
                    idTemplateElement: ${templateElementQuizz.idTemplateElement}
                    idQuiz: ${templateElementQuizz.idQuiz}
                    data: ${templateElementQuizz.data !== undefined ? templateElementQuizz.data : null}
                    responseCheck: ${templateElementQuizz.responseCheck !== undefined ? templateElementQuizz.responseCheck : 0}
                    })
                }`;
        return this.apollo.mutate({
            mutation: mutation,
            refetchQueries: [{
                //query: this.getAllTemplatesQuery,
                query: this.getTemplatesElementQuizzesbyId(templateElementQuizz.idTemplateElementQuizz),
                variables: {
                    filter: { idTemplateElementQuizz: templateElementQuizz.idTemplateElementQuizz }
                }
            }]
        }).toPromise().then(y => {
        });
    }

    createTemplatesElementQuizzes(templateElementQuizz) {
        var createTemplateElementQuery = gql
            `mutation {
              createTemplatesElementQuizzes(
                templatesElementQuizzes: {
                  idTemplateElementQuizz: 0
                  idTemplateElement: ${templateElementQuizz.idTemplateElement}
                  idQuiz: ${templateElementQuizz.idQuiz}
                  data: ${templateElementQuizz.data !== undefined ? templateElementQuizz.data : null}
                  responseCheck: ${templateElementQuizz.responseCheck !== undefined ? templateElementQuizz.responseCheck : 0}
                }
              ) {
                idTemplateElementQuizz
              }
            }`
        return this.apollo.mutate({
            mutation: createTemplateElementQuery
        }).toPromise().then(y => {
        });
    }

    getTemplatesElementQuizzes(idTemplateElement: number, idQuiz: number) {
        var filter = { idTemplateElement: idTemplateElement, idQuiz: idQuiz };

        return this.apollo.query<any>({
            query: gql`query($filter: TemplatesElementQuizzesFilter) {
                templatesElementQuizzes(
                    where: $filter
                  ) {
                    nodes ${this.returnTemplatesElementQuizzes}
                  }
            }`,
            variables: {
                filter: filter
            }
        });
    }

    updateTemplate(template: TemplateModel): Observable<any> {
        return this.setTemplate(template)
    }

    /*Update del Template desde un Quiz*/
    updatesTemplate(template: any) {
        console.log(" updatesTemplate template desde Quiz ", template);
        var idTemplate = template.idTemplate;
        var templateTittle = template.templateTittle;
        var quizInstruction = template.quizInstruction;
        var updateVariables = ""
        if (templateTittle != null) updateVariables += 'templateTittle: "' + templateTittle + '",';
        if (quizInstruction != null) updateVariables += 'quizInstructions: "' + quizInstruction + '",';
        var mutation = gql
            `mutation {
              updateTemplates (
                  templates: {
                      idTemplate: ${idTemplate}
                       ${updateVariables}
                  })
              }`;
        return this.apollo.mutate({
            mutation: mutation,
            refetchQueries: [{
                query: this.returnTemplateQuizzesFields,
                variables: {
                    filter: { idQuiz: template.idQuiz }
                }
            }]
        }).toPromise().then(y => {
        });
    }

    getTemplateElementsByIdTemplate(idTemplate: number): Observable<ResponseTemplateElements> {
        return this.http.get<ResponseTemplateElements>(GETELEMENTSBYIDTEMPLATE.replace('$1', idTemplate.toString()))
    }

    createTemplateElements(elements: TemplateElementModel[]): Observable<any> {
        return this.http.post<any>(CREATETEMPLATEELEMENTS, elements)
    }

    updateTemplateElement(elements: TemplateElementModel[]) {
        return this.http.post<any>(CREATETEMPLATEELEMENTS, elements)
    }

}
