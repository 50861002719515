import { Component, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-modal-tasks',
  templateUrl: './modal-tasks.component.html',
  styleUrls: ['./modal-tasks.component.scss']
})
export class ModalTasksComponent implements OnInit {

  idCurso:string;
  images: Array<string> = []
  currentImage: number = 0
  @Output() option:string;
  constructor() {
    this.images = [
      '../../../../assets/images/demo_images/1.png',
      '../../../../assets/images/demo_images/2.png',
      '../../../../assets/images/demo_images/3.png',
      '../../../../assets/images/demo_images/4.png'
    ]
  }

  ngOnInit() {
  }

  changeImage(){
    if(this.currentImage != this.images.length -1)
      this.currentImage++
  }

}
