import { Component, OnInit, Output, EventEmitter, Injectable } from '@angular/core';
import { SigmaToolbarsService } from 'src/app/core/services/sigma-toolbars/sigma-toolbars.service';

@Component({
  selector: 'app-staircase-chart',
  templateUrl: './staircase-chart.component.html',
  styleUrls: ['./staircase-chart.component.scss']
})


export class StaircaseChartComponent implements OnInit {

  //@Output() showStaircaseChart = new EventEmitter();

  public show = false;

  constructor(private toolsService: SigmaToolbarsService) { }

  ngOnInit() {
  }

  public viewStaircaseChart() {
    this.show = !this.show;
    //this.showStaircaseChart.emit(this.show);
    this.toolsService.changeShowStaircaseChart(this.show);
  }

}
