import { Injectable } from '@angular/core';

// Service
import { MapperService } from './mapper.service';

// Model
import { UserCenterModel } from '../../models/users';

@Injectable()
export class UsersCentersMapperService extends MapperService<UserCenterModel, any> {

    public map(data: UserCenterModel): any {
      return {
        idUserCenter: data.idUserCenter,
        idUser: data.idUser,
        idCenter: data.idCenter,
        creationDate: data.creationDate,
        creationDateString: data.creationDateString
      };
    }
}
