import { User } from './../../../../core/models/users/user.models';
import { SnackbarService } from 'src/app/core/services/shared/snackbar.service';
import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

// Models
import { CourseModel, CourseTargetModel, DetailCourseTargetModel } from 'src/app/core/models/courses';
import { MateriaModel } from 'src/app/core/models/masters';

// Services
import { TranslateService } from '@ngx-translate/core';
import { TargetsService } from 'src/app/core/services/targets';
import { MastersService } from 'src/app/core/services/masters';
import { AlertService } from 'src/app/core/services/shared';
import { LoginService } from 'src/app/core/services/login';
import { UsersService } from 'src/app/core/services/users';
import { ModalAceptarCancelarComponent } from '../../modal';
import { ImagenPipe } from 'src/app/shared/pipes/imagen.pipe';


@Component({
    selector: 'app-editar-curso-mapa',
    templateUrl: './modal-editar-curso-mapa.component.html',
    styleUrls: ['./modal-editar-curso-mapa.component.scss'],
    providers: [
        ImagenPipe
    ]
})
export class ModalEditarCursoMapaComponent implements OnInit {

    public form: FormGroup

    id: string;
    user: User

    curso: CourseModel;
    mapa: CourseTargetModel;
    idImage: string = ''
    target: DetailCourseTargetModel;

    // Maestros
    materias: MateriaModel[] = [];
    cargando = false;

    validationMessages = {
        title: [],
        description: [],
        subject: [],
    };

    constructor(public router: Router,
        public translateService: TranslateService,
        private loginService: LoginService,
        private usersService: UsersService,
        public targetsService: TargetsService,
        private mastersService: MastersService,
        private alertService: AlertService,
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private snackBar: SnackbarService,
        private modalService: NgbModal,
        private imagePipe: ImagenPipe
    ) {
    }

    ngOnInit() {

        this.cargando = true;
        this.user = this.loginService.getUser()

        // Obtengo los maestros
        this.obtenerDatosMaestros();
        this.generateForm();

        if (this.id !== 'nuevo') {
            // Si no es nuevo busco sus datos
            this._getTargetDetail();
        }

        this.cargando = false;

        this._translateText()
    }

    obtenerDatosMaestros(): void {
        this.mastersService.getAllSubjects().subscribe(data => { this.materias = data });
    }

    private _getTargetDetail() {

        this.targetsService.getTargetById(parseInt(this.id)).subscribe(result => {
            this.target = result.data;

            this.form.patchValue({
                ...result.data,
                image: result.data.backgroundImage ? this.imagePipe.transform(result.data.backgroundImage, 'mapas') : result.data.backgroundImage,
                published : this.target.published ? true  : false
            })

            if (!this.isMine())
                this.form.disable()
        })

    }

    private _translateText() {
        this.translateService.get('VALIDACIONES.TITLEREQUIRED').subscribe((res: string) => {
            this.validationMessages.title.push({ type: 'required', message: res });
        });

        this.translateService.get('VALIDACIONES.DESCRIPTIONREQUIRED').subscribe((res: string) => {
            this.validationMessages.description.push({ type: 'required', message: res });
        });
        this.translateService.get('VALIDACIONES.SUBJECTREQUIRED').subscribe((res: string) => {
            this.validationMessages.subject.push({ type: 'required', message: res });
        });
    }

    generateForm() {

        this.form = this.fb.group({
            image: [{ value: '', disabled: false }],
            tittle: ['', Validators.required],
            description: ['', Validators.required],
            idSubject: ['', Validators.required],
            labels: [''],
            published : [false]
        })

    }

    isMine(): boolean {
        if (this.loginService.esAutor() && this.user.idUser === this.curso.user.idUser)
            return true
        return false
    }

    /**
     * Publish or unpublish target
     * @param $ev Click event in the switch component
    */

    publish($ev){
        $ev.preventDefault()
        $ev.stopImmediatePropagation()
        let currentValue:boolean = !this.form.get('published').value
        let modalMessage:string = currentValue ? this.translateService.instant('EDITARCURSOMAPA.PUBLISHMSG') : this.translateService.instant('EDITARCURSOMAPA.UNPUBLISHMSG')
        let errorMessage:string = currentValue ? this.translateService.instant('GENERAL.KOPUBLISH') : this.translateService.instant('GENERAL.KOUNPUBLISH')
        let okMessage:string = currentValue ? this.translateService.instant('GENERAL.OKPUBLISH') : this.translateService.instant('GENERAL.OKUNPUBLISH')

        //Open modal message alert to confirm the selection

        const modalRef = this.modalService.open(ModalAceptarCancelarComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-60'
            });


        modalRef.componentInstance.mensaje = modalMessage

        modalRef.result.then((result: boolean) => {
            if (result) {
                this.mastersService.setPublishType('target',Number(this.id),currentValue).subscribe(result => {
                    if(result.data){
                        this.snackBar.success(okMessage)
                        this.form.get('published').setValue(currentValue) // Update form value
                    }                        
                    else
                        this.snackBar.error(this.translateService.instant('EDITARCURSOMAPA.KOPUBLISHCONTENT'))                        
                }, err => {
                    this.snackBar.error(errorMessage)
                })
                    
            }
        });
    }
    



    grabarCursoMapa() {


        if (this.form.valid) {

            let image: File | string = ''

            const v = this.form.value
            let request: DetailCourseTargetModel = new DetailCourseTargetModel(v.tittle, v.description, v.idSubject, this.user.idUser, v.labels)

            request.published = v.published ? Date.now() : null

            if (this.id !== 'nuevo') {
                request.idTarget = this.target.idTarget
                //Si el target no tiene imagen
                if (this.target.backgroundImage == '' || this.target.backgroundImage == null) {
                    if (typeof v.image === 'object')
                        image = v.image
                }
                else { //Si el curso tiene imagen
                    if (typeof v.image == 'string' && v.image != '')
                        request.backgroundImage = this.target.backgroundImage
                    else if (typeof v.image == 'object') {
                        request.backgroundImage = this.target.backgroundImage
                        image = v.image
                    }
                    else if (typeof v.image == 'string' && v.image == '') {
                        request.backgroundImage = null
                    }
                }
            }
            else
                image = v.image


            this.targetsService.setTarget(request, image, this.curso.idCourse).subscribe(data => {
                //Show an alert message
                this.snackBar.success(this.translateService.instant('EDITARCURSO.OKSAVE'))
                this.closeModal('ok')
            }, error => {
                console.error(error)
                this.snackBar.error(this.translateService.instant('EDITARCURSO.KOSAVE'))
            })

        }


    }

    deleteGraph() {

        if (!this.loginService.esAutor()) {
            return;
        }

        // Abro un modal preguntando si desea borrar el curso o no
        const modalRef = this.modalService.open(ModalAceptarCancelarComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-60'
            });


        modalRef.componentInstance.mensaje = this.translateService.instant('EDITARCURSOMAPA.DELETEGRAPH');

        modalRef.result.then((result: boolean) => {
            if (result) {
                this.targetsService.deleteTarget(parseInt(this.id)).subscribe(result => {
                    this.activeModal.close('Delete graph');
                    this.snackBar.success(this.translateService.instant('EDITARCURSOMAPA.OKDELETE'))
                }, err => {
                    this.snackBar.error(this.translateService.instant('EDITARCURSOMAPA.KODELETE'))
                })
            }
        });

    }

    closeModal(sendData) {
        this.activeModal.close(sendData);
    }

    // VER NODOS MAPA
    verNodosMapa() {

        // Si soy estudiante le mando al mapa directamente
        if (this.loginService.esAutor()) {

            // tslint:disable-next-line: max-line-length
            //this.usersService.addUserLog(this.loginService.getUser().idUser, `Ver nodos mapa - Editar curso-mapa`, 'OK');

            // Y le mando al mapa seleccionado
            this.router.navigate([`/course/${this.curso.idCourse}/graph/${this.id}`]);

            this.closeModal('closeAll');

            return;
        }
    }
}
