import { ErrorModel } from './../shared/error.model';
import { QuizTemplateElement } from './quiz-template-element.model';


export class ResponseTemplatesModel {
    error: ErrorModel
    data: TemplateModel[]
    status: number
}

export class ResponseTemplateElements {
    error: ErrorModel
    data: TemplateElementModel[]
    status: number
}

export class ResponseTemplateCreateModel {
    error: ErrorModel
    data: TemplateModel
    status: number
}

export interface ElementTypeModel {
    idElementType: number
    description?: string
}



export class TemplateModel {
    idTemplate: number;
    audioAnswer: number;
    audioOptions: number;
    audioQuestions: number;
    compuCorrect: number;
    imageAnswer: number;
    imageOptions: number;
    imageQuestions: number;
    multiplexed: number;
    pdfAnswer: number;
    pdfOptions: number;
    pdfQuestions: number;
    quizInstructions: string;
    quizTittle: string;
    templateSnapshot: string;
    templateTittle: string;
    textAnswer: number;
    textOptions: number;
    textQuestions: number;
    tittle: number;
    videoAnswer: number;
    videoOptions: number;
    videoQuestions: number;
    writable: number;
    creationDate: number;
    editDate: number;
    idUserCreate: number;
    isFavorite: number;
    templateElements: QuizTemplateElement[];

    constructor(templateTitle: string, compuCorrect: number, idUser: number, multiplexed: number, writable: number, quizzTitle: string, quizInstructions: string) {
        this.idTemplate = 0
        this.idUserCreate = idUser
        this.templateTittle = templateTitle
        this.compuCorrect = compuCorrect
        this.multiplexed = multiplexed
        this.writable = writable
        this.quizTittle = quizzTitle
        this.quizInstructions = quizInstructions

        this.creationDate = null
        this.editDate = null
        this.templateSnapshot = null
        this.templateElements = null
        this.tittle = 1
    }
}

export class TemplateElementModel {
    idTemplateElement: number;
    elementsType: ElementTypeModel;
    xPosition: number;
    xSize: number;
    yPosition: number;
    ySize: number;
    idTemplate: number;
    newyposition?: number;
    newxposition?: number;
    style?: string;
    icon?: string;
    data?: string;
    responseCheck?: number | boolean;
    idTemplateElementQuizz?: number;

    constructor(idTemplateElement: number, elementType: number, xPos: number, yPos: number, xSize: number, ySize: number, idTemplate: number) {
        this.idTemplateElement = idTemplateElement
        this.elementsType = {
            idElementType: elementType
        }
        this.xPosition = xPos
        this.yPosition = yPos
        this.xSize = xSize
        this.ySize = ySize
        this.idTemplate = idTemplate
    }
}
