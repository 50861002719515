import { Injectable } from '@angular/core';

// Service
import { MapperService } from './mapper.service';

// Model
import { UserParentModel } from '../../models/users/user-parent.model';

@Injectable()
export class UsersParentsMapperService extends MapperService<UserParentModel, any> {

    public map(data: UserParentModel): any {
        return {
          idUserParent: data.idUserParent,
          idUser: data.idUser,
          creditCard: data.creditCard,
          expirationDateCard: data.expirationDateCard,
          cvv: data.cvv,
          creationDate: data.creationDate,
          creationDateString: data.creationDateString
        };
    }
}
