import { TemplateModel } from './../../../core/models/quizzes/template.model';
import { Component, OnInit } from '@angular/core';
import { TemplatesService } from 'src/app/core/services/templates/templates.service';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PatternFormComponent } from './pattern-form/pattern-form.component';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { LoginService } from 'src/app/core/services/login';


@Component({
    selector: 'app-patterns',
    templateUrl: './patterns.component.html',
    styleUrls: ['./patterns.component.scss']
})
export class PatternsComponent implements OnInit {
    public directionLinks: boolean = true;
    public autoHide: boolean = false;
    public responsive: boolean = true;
    isShowFavourites: boolean = false

    constructor(private templatesService: TemplatesService,
        private activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private loginService: LoginService,
        private quizService: QuizzesService,) {
        this.templatesService.flagTemplateUpdate.subscribe((flagTemplateUpdate) => {
            if (flagTemplateUpdate) {
                this.ngOnInit();
            }
        });
    }

    public templates: TemplateModel[] = [];
    public templatesBackup: TemplateModel[] = [];

    public cargando: boolean;
    public quiz: any;
    public flagFavorites: boolean;
    public idUser: number;

    public form: FormGroup = new FormGroup({
        options: new FormControl(false),
        writable: new FormControl(false),
        manual: new FormControl(false),
        compu: new FormControl(false),
        noMulti: new FormControl(false),
        multi: new FormControl(false),
        qVideo: new FormControl(0),
        qPicture: new FormControl(0),
        qAudio: new FormControl(0),
        qPdf: new FormControl(0),
        qText: new FormControl(0),
        oVideo: new FormControl(0),
        oPicture: new FormControl(0),
        oAudio: new FormControl(0),
        oPdf: new FormControl(0),
        oText: new FormControl(0),
        label: new FormControl('')
    });

    ngOnInit() {
        this.cargando = true;
        this.flagFavorites = false;
        this.idUser = this.loginService.getUser().idUser;
        this._getTemplates()
        this.cargando = false;
        this.form.valueChanges.subscribe(() => { });
    }

    private _getTemplates() {
        this.templatesService.getAllTemplates(this.idUser).subscribe(result => {
            this.templates = this.templatesBackup = result.data
            this.cargando = false;
        }, err => {
            this.cargando = false;
        })

    }

    searchPatterns() {
        this.cargando = true;
        if (this.withValue()) {
            this.templatesService.getAllTemplates(this.idUser, this.form.value).subscribe(result => {
                this.cargando = false;
                this.templates = result.data.length > 0 ? result.data : [];
            }, err => {
                this.templates = []
                this.cargando = false;
            })
        } else {
            this.cargando = false;
        }
    }

    public createPatterns() {
        const modalRef = this.modalService.open(PatternFormComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-85'
            });
        modalRef.componentInstance.quiz = this.quiz // --> VALID LINE
    }

    setFavourite(event, idTemplate: number, index: number) {
        event.stopPropagation();
        this.templatesService.setFavourite(idTemplate, this.idUser).subscribe(result => {
            this.templates[index].isFavorite = this.templates[index].isFavorite ? 0 : 1
        })
    }

    getFavourites() {
        this.isShowFavourites = !this.isShowFavourites;
        this.isShowFavourites ? this.templates = this.templates.filter(e => e.isFavorite) : this.templates = this.templatesBackup
    }


    closeModal(sendData) {
        this.activeModal.close(sendData);
    }

    public withValue() {
        if (this.form.value.options == true || this.form.value.writable == true || this.form.value.manual == true ||
            this.form.value.compu == true || this.form.value.noMulti == true || this.form.value.multi == true ||
            this.form.value.qVideo > 0 || this.form.value.qPicture > 0 || this.form.value.qAudio > 0 ||
            this.form.value.qPdf > 0 || this.form.value.qText > 0 || this.form.value.oVideo > 0 ||
            this.form.value.oPicture > 0 || this.form.value.oAudio > 0 || this.form.value.oPdf > 0 ||
            this.form.value.oText > 0 || this.form.value.label != '') {
            return true;
        } else {
            return false;
        }
    }
}
