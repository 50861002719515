import { AuthorResponseModel } from './../../models/masters/author-response.model';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CenterModel, MateriaModel, NivelModel, PaisModel, IdiomaModel, RegionModel } from '../../models/masters';

// GraphQL
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

// Models
import { GeneroModel } from '../../models/masters/genero.model';
import { PublishResponseModel } from '../../models/masters/publish.model';

const GETALLSUBJECTS = 'getDatosMaestrosSubjects'
const GETALLCOUNTRIES = 'getDatosMaestrosCountries'
const GETALLLEVELS = 'getDatosMaestrosSwlevels'
const GETREGIONSBYCOUNTRY = 'getCountriesRegionsByIdCountry'
const GETCENTERS = 'getDatosMaestrosCenters'
const GETLANGUAGES = 'getDatosMaestrosLanguage'
const GETCENTERBYID = ''
const GETAUTHORS = 'getDatosMaestrosAuthorsCourses'


@Injectable({
    providedIn: 'root'
})
export class MastersService {

    centerFields = `{
    idCenter
    centerName
    centerAddress
    centerCountry
    centerRegion
    centerMail
    centerPhone
    centerUrl
    centerPicture
  }`;

    constructor(private apollo: Apollo, private http: HttpClient) { }

    /**
     * Function that returns all subjects
     * @returns
     */
    getAllSubjects(): Observable<MateriaModel[]> {
        return this.http.get<MateriaModel[]>(GETALLSUBJECTS);
    }

    /**
     * Function that return all languages avilables in the app
     * @returns
     */
    getAllLanguages(): Observable<IdiomaModel[]> {
        return this.http.get<IdiomaModel[]>(GETLANGUAGES)
    }

    /**
     * Function that return all countries in the app
     * @returns
     */
    getAllCountries(): Observable<PaisModel[]> {
        return this.http.get<PaisModel[]>(GETALLCOUNTRIES)
    }

    /**
     * Function that return all levels in the app
     * @returns
     */
    getAllLevels(): Observable<NivelModel[]> {
        return this.http.get<NivelModel[]>(GETALLLEVELS)
    }

    /**
     * Function that return a list of regions filter by country
     * @param idCountry ID of the country (ex: ESP)
     * @returns
     */
    getAllRegionsByIdCountry(idCountry: string): Observable<RegionModel[]> {
        let httpParams = new HttpParams().set('idCountry', idCountry)
        return this.http.get<RegionModel[]>(GETREGIONSBYCOUNTRY, { params: httpParams })
    }

    /**
     * Function that return all the centers in the app
     * @returns
     */
    getAllCenters(): Observable<CenterModel[]> {
        return this.http.get<CenterModel[]>(GETCENTERS)
    }

    /**
     * Function that return the info of the center
     * @param idCenter ID of the center (ex: 1)
     * @returns Center detail
     */

    getCenterById(idCenter: number): Observable<CenterModel> {
        return this.http.get<CenterModel>(GETCENTERBYID)
    }

    getAuthors(): Observable<AuthorResponseModel> {
        return this.http.get<AuthorResponseModel>(GETAUTHORS)
    }

    getAllGenders(): Observable<GeneroModel[]> {
        const generos: GeneroModel[] = [];
        generos.push(new GeneroModel(1, 'MISC.MALE'));
        generos.push(new GeneroModel(2, 'MISC.FEMALE'));
        generos.push(new GeneroModel(3, 'MISC.OTHER'));

        return of(generos);
    }

    /**
     * Function that publishes or unpublishes an item type
     * @param type Type of element: “course” – “target” – “node” – “quiz”
     * @param id ID of the element
     * @param value Value of the switch component: true/false
     * @returns Boolean: True or false if successful
     */
    setPublishType(type:string, id:number, value: boolean): Observable<PublishResponseModel>{
        let endpoint = `published/${type}/${id}/${value}`
        return this.http.get<PublishResponseModel>(endpoint);
    }

    setCenter(data: CenterModel) {

        const centerFields = `center: {
      idCenter: ${data.idCenter}
      centerName: ${(data.centerName !== null && data.centerName !== undefined) ? `"${data.centerName}"` : null}
      centerAddress: ${(data.centerAddress !== null && data.centerAddress !== undefined) ? `"${data.centerAddress}"` : null}
      centerCountry: ${(data.centerCountry !== null && data.centerCountry !== undefined) ? `"${data.centerCountry}"` : null}
      centerRegion: ${(data.centerRegion !== null && data.centerRegion !== undefined) ? data.centerRegion : null}
      centerMail: ${(data.centerMail !== null && data.centerMail !== undefined) ? `"${data.centerMail}"` : null}
      centerPhone: ${(data.centerPhone !== null && data.centerPhone !== undefined && data.centerPhone != '') ? `"${data.centerPhone}"` : null}
      centerUrl: ${(data.centerUrl !== null && data.centerUrl !== undefined) ? `"${data.centerUrl}"` : null}
      centerPicture: ${(data.centerPicture !== null && data.centerPicture !== undefined) ? `"${data.centerPicture}"` : null}
    }`;

        if (data.idCenter === 0) {

            const createCenter = gql`mutation {
        createCenter (${centerFields})
        ${this.centerFields}
      }`;

            return this.apollo.mutate({
                mutation: createCenter
            });
        } else {

            const updateCenter = gql`mutation {
        updateCenter ${centerFields}
      }`;

            return this.apollo.mutate({
                mutation: updateCenter
            });
        }
    }

    getAllEnterprises() {
        return this.apollo.query<any>({
            query: gql`
        {
          enterprise {
            nodes {
              idEnterprise
              name
              address
              country
            }
          }
        }`});
    }
}
