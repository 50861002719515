import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberInputDirective } from './directives/number-input.directive';
import { PipesModule } from './pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../pages/material.module';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from './components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImagenPipe } from './pipes/imagen.pipe';
import { NodeCoverPipe } from './pipes/node-cover.pipe';

@NgModule({
    declarations: [
        NumberInputDirective,
    ],
    imports: [
        CommonModule,
        PipesModule,
        TranslateModule,
        MaterialModule,
        RouterModule,
        ComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule
    ],
    exports: [
        NumberInputDirective,
        PipesModule,
        TranslateModule,
        MaterialModule,
        RouterModule,
        ComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule
    ],
    providers: [
        ImagenPipe,
        NodeCoverPipe
    ]
})
export class SharedModule { }
