import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

// Utils
import { LocalStorage } from 'src/app/core/utils';

// Services
import { TitleService } from 'src/app/core/services/shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mis-datos',
  templateUrl: './mis-datos.component.html',
  styleUrls: ['./mis-datos.component.scss']
})
export class MisDatosComponent implements OnInit {

  public loading = false;
  formulario: FormGroup;

  constructor(private titleService: TitleService,
              private translateService: TranslateService,
              private localStorage: LocalStorage) { }

  ngOnInit() {

    this.setFormData();

    const lang = this.localStorage.getItem('language');
    this.translateService.use(lang);

    this.setData();
  }

  setFormData() {

      this.formulario = new FormGroup({
          nombre: new FormControl(null, Validators.required ),
          perfil: new FormControl(null, Validators.required ),
          correo: new FormControl(null, [Validators.required, Validators.email] ),
          condiciones: new FormControl( false ),
        });
  }

  saveData() {

    this.loading = true;

    if (this.formulario.invalid) {
      return;
    }

    if ( !this.formulario.value.condiciones ) {
      // Swal.fire({
      //   title: 'Importante',
      //   text: 'Debe aceptar las condiciones',
      //   icon: 'warning'});

      return;
    }

  //   let usuario = new Usuario(
  //     this.formulario.value.nombre,
  //     this.formulario.value.correo,
  //     this.formulario.value.password
  //   );

  //   this._usuarioService.crearUsuario(usuario)
  //     .subscribe( resp => this._router.navigate(['/login']));
  // }

    this.loading = false;
  }

  public setData() {

      // Titulo pagina
      this.translateService.get('REGISTER.REGISTER').subscribe((res: string)  => {
          this.titleService.setHTMLTitle(res);
      });

      // Perfiles
      // this.translateService.get('PERFILES.ALUMNO').subscribe((res: string) => {
      //     this.profileOptions.push(res);
      // });

      // this.translateService.get('PERFILES.PADRE').subscribe((res: string) => {
      //     this.profileOptions.push(res);
      // });

      // this.translateService.get('PERFILES.AUTOR').subscribe((res: string) => {
      //     this.profileOptions.push(res);
      // });

      // this.translateService.get('PERFILES.CENTROENSEÑANZA').subscribe((res: string) => {
      //     this.profileOptions.push(res);
      // });

      // this.translateService.get('PERFILES.EMPRESA').subscribe((res: string) => {
      //     this.profileOptions.push(res);
      // });
  }
}
