import { CommonModule, DatePipe } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { WebcamModule } from 'ngx-webcam';


import { ChartsModule } from 'ng2-charts';

// Envelope
import { FileUploadModule } from 'ng2-file-upload';
import { NgSwitcheryModule } from 'angular-switchery-ios';

// Modules
import { MaterialModule } from './material.module';
import { GraphQLModule } from '../graphql.module';

// Pipes
import { TranslateModule } from '@ngx-translate/core';

// Pages
import { NoPageFoundComponent } from './no-page-found';

import { SettingsComponent } from './settings';
import { BuzonComponent } from './buzon';

import { GeneralComponent } from './general/general.component';
import { SenseModeComponent } from './sense-mode/sense-mode.component';
import { PopoverModule } from 'ngx-popover';

// Providers
import { ColorPickerModule } from 'ngx-color-picker';
import { NgCircleProgressModule } from 'ng-circle-progress';

// Services
import { CoreServiceModule } from '../core/services/core-services.module';
// tslint:disable-next-line: max-line-length
import { NgxPrintModule } from 'ngx-print';

import { NgxMaskModule } from 'ngx-mask';
//import { GrafoComponent } from './grafo';


import { ResizableModule } from 'angular-resizable-element';

import { MisDatosComponent } from './mis-datos';
import { SharedModule } from '../shared/shared.module';

import { QuillModule } from 'ngx-quill'

@NgModule({
    declarations: [
        // No Page
        NoPageFoundComponent,
        // Sidebar
        GeneralComponent,
        SenseModeComponent,
        // Buzon
        BuzonComponent,
        // Cursos
        // Settings
        SettingsComponent,
        MisDatosComponent
    ],
    imports: [
        SharedModule,
        CommonModule,
        HttpClientModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        CoreServiceModule,
        FileUploadModule,
        NgSwitcheryModule,
        WebcamModule,
        MaterialModule,
        ColorPickerModule,
        NgxPrintModule,
        NgCircleProgressModule,
        NgbModule,
        PopoverModule,
        ColorPickerModule,
        ChartsModule,
        GraphQLModule,
        ResizableModule,
        NgxMaskModule
    ],
    exports: [
        // No Page
        NoPageFoundComponent,
        // Sidebar
        GeneralComponent,
        SenseModeComponent,
        // Settings
        SettingsComponent,
        // Buzon
        BuzonComponent,
        MaterialModule,
        TranslateModule,
        MisDatosComponent
    ],
    providers: [NgbActiveModal, DatePipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PagesModule { }
