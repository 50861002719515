import { Injectable } from '@angular/core';

// Service
import { MapperService } from './mapper.service';

// Model
import { UserStudentParentModel } from '../../models/users';

@Injectable()
export class UsersStudentsParentsMapperService extends MapperService<UserStudentParentModel, any> {

    public map(user: UserStudentParentModel): any {
        return {
          idUserStudentParent: user.idUserStudentParent,
          idUser: user.idUser,
          firstName: user.firstName,
          surname: user.surname,
          mail: user.mail,
          birthdate: user.birthdate,
          birthdateString: user.birthdateString,
          mobile: user.mobile,
          creationDate: user.creationDate,
          creationDateString: user.creationDateString
        };
    }
}
