import { User } from './../../models/users/user.models';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// GraphQL
import { HttpClient } from '@angular/common/http';

// Utils
import { LocalStorage } from 'src/app/core/utils/local-storage';

// Models
import { LoginResponse } from 'src/app/core/models/users';
import { BehaviorSubject, Observable } from "rxjs";
import { LoginModel } from '../../models/users/login.model';
import { Profiles } from '../../utils/profiles.enum';

@Injectable()
export class LoginService {
    private usuario: User;
    private token: string;

    private currentProfile: BehaviorSubject<Profiles> = new BehaviorSubject<Profiles>(null);
    public currentProfile$: Observable<Profiles> = this.currentProfile.asObservable();

    public constructor(private router: Router, private localStorage: LocalStorage, private http: HttpClient) {
        // Me aseguro de tener los datos del localStorage
        this.getUserLocalStorage();
    }

    login(login: LoginModel): Observable<LoginResponse> {
        const apiUrl = 'login-user';
        return this.http.post<LoginResponse>(apiUrl, login);
    }

    public validateUserLocalStorage() {
        // Tengo que validar que la session este abierta - PENDIENTE
        if (this.usuario !== null) {
            return true;
        }

        return false;
    }

    public setUser(usuario: User) {
        this.usuario = usuario
    }

    public setUserLocalStorage(usuario: User) {
        this.usuario = usuario;
        this.usuario.keyWord = '-';
        this.localStorage.setItem('user', JSON.stringify(this.usuario));
    }

    private getUserLocalStorage() {
        if (this.usuario === undefined || this.usuario === null) {
            this.usuario = JSON.parse(localStorage.getItem('user'));
        }
    }

    public getUser(): User {
        return this.usuario;
    }

    public getToken() {
        return this.token;
    }

    // PENDIENTE
    // public renewToken() {
    //     let url = URL_SERVICIOS + '/login/renuevatoken';
    //     url += '?token=' + this.token;

    //     return this.http.get(url)
    //               .pipe(map( (resp: any) => {
    //                 this.token = resp.token;
    //                 localStorage.setItem('token', this.token);

    //                 return true;
    //               }),
    //               catchError(err => {
    //                 this.logout();

    //                 Swal.fire({
    //                   title: 'No se pudo renovar token',
    //                   text: 'No fue posible',
    //                   icon: 'error'
    //                 });

    //                 return throwError(err.message);
    //               }));
    // }

    public getProfile() {
        return this.usuario.profile;
    }

    public setProfile(newProfile: Profiles, redirect: boolean = true) {

        // Si el perfil ha cambiado
        if (this.usuario.profile !== newProfile) {
            this.usuario.profile = newProfile;

            this.currentProfile.next(newProfile);

            // PENDIENTE Lo grabo en BD

            this.setUserLocalStorage(this.usuario);

            // Para que se cargue el guard que detecta si ha cambiado el perfil le añado un numero aleatorio
            const id = Math.floor(Math.random() * (10000));

            if (redirect) {
                // Y lo mando a dashboard
                this.router.navigate(['/dashboard', id]);
            }
        }
    }

    public esPerfilVacio() {
        return this.usuario.profile === undefined || this.usuario.profile === null || this.usuario.profile === '';
    }

    public esEstudiante() {
        return !this.esPerfilVacio() && this.usuario.profile.toUpperCase() === Profiles.Student;
    }

    public esPadre() {
        return !this.esPerfilVacio() && this.usuario.profile.toUpperCase() === Profiles.Father;
    }

    public esProfesor() {
        return !this.esPerfilVacio() && this.usuario.profile.toUpperCase() === Profiles.Teacher;
    }

    public esAutor() {
        return !this.esPerfilVacio() && this.usuario.profile.toUpperCase() === Profiles.Author;
    }

    public esCentro() {
        return !this.esPerfilVacio() && this.usuario.profile.toUpperCase() === Profiles.Center;
    }

    public esEmpleador() {
        return !this.esPerfilVacio() && this.usuario.profile.toUpperCase() === Profiles.Employer;
    }

    public logout(redirectToLogin: boolean = true) {
        this.usuario = null;

        if (this.localStorage) {
            this.localStorage.removeItem('user');

            if (redirectToLogin) {
                this.router.navigate(['login']);
            }
        }
    }
}
