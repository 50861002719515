import { Component, OnInit } from '@angular/core';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Services
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/core/services/login';
import { UsersService } from 'src/app/core/services/users';

// Components
import { ModalCursoMotivacionesEstudiantesComponent } from 'src/app/shared/components/cursos/modal-curso-motivaciones-estudiantes';
import { ModalCursoMotivacionesAutorComponent } from 'src/app/shared/components/cursos/modal-curso-motivaciones-autor';

@Component({
    selector: 'app-modal-curso-motivaciones',
    templateUrl: './modal-curso-motivaciones.component.html',
    styleUrls: ['./modal-curso-motivaciones.component.scss']
})
export class ModalCursoMotivacionesComponent implements OnInit {

    idCurso: string;

    valoracionEstudiante: number;
    emitidaAutor: number;

    cargando = false;

    constructor(public translateService: TranslateService,
        private loginService: LoginService,
        private usersService: UsersService,
        private modalService: NgbModal,
        private activeModal: NgbActiveModal) {
    }

    ngOnInit() {

        this.cargando = true;

        this.cargando = false;
    }

    closeModal(sendData) {
        this.activeModal.close(sendData);

        // tslint:disable-next-line: max-line-length
        //this.usersService.addUserLog(this.loginService.getUser().idUser, `Cerrar ventana modal - Curso Motivaciones`, 'OK');
    }

    // MODAL MOTIVACIONES ESTUDIANTES
    mostrarMotivacionesEstudiantes() {
        const modalRef = this.modalService.open(ModalCursoMotivacionesEstudiantesComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-80'
            });

        // tslint:disable-next-line: max-line-length
        //this.usersService.addUserLog(this.loginService.getUser().idUser, `Abrir modal Motivaciones estudiantes - Curso Motivaciones`, 'OK');

        modalRef.componentInstance.idCurso = this.idCurso;

        modalRef.result.then((result) => {
            console.log(result);
        }, (reason) => {
        });
    }

    // FIN MODAL MOTIVACIONES ESTUDIANTES

    // MODAL MOTIVACIONES AUTOR
    mostrarMotivacionesAutor() {
        const modalRef = this.modalService.open(ModalCursoMotivacionesAutorComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-80'
            });

        // tslint:disable-next-line: max-line-length
        //this.usersService.addUserLog(this.loginService.getUser().idUser, `Abrir modal Motivaciones autor - Curso Motivaciones`, 'OK');

        modalRef.componentInstance.idCurso = this.idCurso;

        modalRef.result.then((result) => {
            console.log(result);
        }, (reason) => {
        });
    }

    // FIN MODAL MOTIVACIONES AUTOR
}
