import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ModalCursosComponent } from 'src/app/shared/components/cursos/modal-cursos';
import { ModalEditarCursoComponent } from 'src/app/shared/components/cursos/modal-editar-curso';
import { ModalCalendarComponent } from 'src/app/shared/components/modal-calendar/modal-calendar.component';
import { ModalCertifyComponent } from 'src/app/shared/components/modal-certify/modal-certify.component';
import { ModalHelpComponent } from 'src/app/shared/components/modal-help/modal-help.component';
import { ModalTasksHomeComponent } from 'src/app/shared/components/modal-tasks-home/modal-tasks-home.component';
import { ModalTasksComponent } from 'src/app/shared/components/sigma-canvas/modal-tasks/modal-tasks.component';
import { UserRegisterModel } from '../../models/users/user-register.model';
import { UserSettingsProfileModel } from '../../models/users/user-settings-profile.model';
import { LoginService } from '../../services/login';
import { UsersProfileValidationMapperService } from '../../services/mapper/users-profile-validation-mapper.service';
import { UsersRegisterMapperService } from '../../services/mapper/users-register-mapper.service';
import { UsersService } from '../../services/users/users.service';
import { LocalStorage } from '../../utils/local-storage';
import { Profiles } from '../../utils/profiles.enum';
declare var $: any;

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

    @Input() isSettings = false;

    userData: UserRegisterModel;
    usersSettingsProfileData: UserSettingsProfileModel;

    menuSeleccionado: string;

    public constructor(private activatedRoute: ActivatedRoute,
        private router: Router,
        private modalService: NgbModal,
        public translateService: TranslateService,
        public loginService: LoginService,
        private usersService: UsersService,
        private localStorage: LocalStorage,
        private usersRegisterMapperService: UsersRegisterMapperService,
        private usersProfileValidationMapperService: UsersProfileValidationMapperService) {

        this.activatedRoute.params.subscribe(params => {
            // De esta forma cada vez que cambia el parametro lo actualizo
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        });
    }

    public ngOnInit() {

        this.traducirOpciones();

        this.obtenerDatosUsuario();

        this.cargarMenuSeleccionado();
    }

    private traducirOpciones() {
        const lang = this.localStorage.getItem('language');
        this.translateService.use(lang);
    }

    private obtenerDatosUsuario() {

        this.usersService.getUserByIdUser(this.loginService.getUser().idUser)
            .subscribe((resp: any) => {
                if (resp.idUser !== 0) {
                    // Mapeo los datos del usuario
                    this.userData = this.usersRegisterMapperService.transform(resp);

                    // Los de las validaciones del usuario (si las tiene)
                    /*if (resp.data.users.nodes[0].usersProfileValidation.length > 0) {
                      // tslint:disable-next-line: max-line-length
                      this.userData.usersProfileValidation = this.usersProfileValidationMapperService.transform(resp.data.users.nodes[0].usersProfileValidation);
                    }*/

                    // Si isSettings == true Cargo los user settings profile para ver que menus van en gris
                    if (this.isSettings) {
                        this.usersSettingsProfileData = resp.usersSettingsProfiles;
                    }
                }
            });
    }

    private cargarMenuSeleccionado() {
        if (this.isSettings) {
            this.menuSeleccionado = this.router.url.replace('/user-data/', '');
        }
    }

    // La funcion que cambia el perfil
    cambiarPerfil(newProfile: Profiles) {
        this.loginService.setProfile(newProfile);
    }

    // Cargar Modal de cursos
    verListadoCursos(opcion) {
        $('.popover, .popover2').hide();

        //TODO ALEX
        //Permitir ver los cursos, pero en el caso de ser no estar subscrito, no permitir entrar más, pero verlos desde fuera no habría problema..
        // Voy a validar su acceso
        /*const resp = this.usersService.checkIfUserDataAreRegistered(this.userData, this.loginService.getProfile());

        if (!resp.result){
          return false;
        }

        // Si soy un estudiante voy a ver que estoy validado
        if (this.loginService.esEstudiante()) {
          // const result = this.usersService.checkIfUserDataAreRegistered(this.loginService.getUser().idUser)
        }*/

        const modalRef = this.modalService.open(ModalCursosComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-95',
                // keyboard: false,
                // backdrop: 'static'
            });

        modalRef.componentInstance.opcion = opcion;

    }

    nuevoCurso() {
        const modalRef = this.modalService.open(ModalEditarCursoComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-100 modal-dialog-100-nuevo'
            });

        modalRef.componentInstance.id = 'nuevo';
    }

    // Sidebar Settings
    public verSettings(opcion: string) {
        this.router.navigate([`user-data/${opcion.toLowerCase()}`]);
    }
    // Fin Sidebar Settings

    public competitionLonely(option: any) {
        $('.popover, .popover2').hide();
        const modalRef = this.modalService.open(ModalTasksComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-80'
            })

        modalRef.componentInstance.idCurso = 1;
        modalRef.componentInstance.option = option;
    }

    public competitionCouples(option: any) {
        $('.popover, .popover2').hide();
        const modalRef = this.modalService.open(ModalTasksComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-80'
            })

        modalRef.componentInstance.idCurso = 1;
        modalRef.componentInstance.option = option;
    }

    public competitionGroups(option: any) {
        $('.popover, .popover2').hide();
        const modalRef = this.modalService.open(ModalTasksComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-80'
            })

        modalRef.componentInstance.idCurso = 1;
        modalRef.componentInstance.option = option;
    }

    public verEnsenar(): void {

        $('.popover, .popover2').hide();
        const modalRef = this.modalService.open(ModalHelpComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-80'
            }
        )
    }

    public showTasks(): void {
        $('.popover, .popover2').hide();
        const modalRef = this.modalService.open(ModalTasksHomeComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-80'
            }
        )
    }

    public openCalendar(): void {
        $('.popover, .popover2').hide();
        const modalRef = this.modalService.open(ModalCalendarComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-80'
            }
        )
    }

    public openCertify(): void {
        $('.popover, .popover2').hide();
        const modalRef = this.modalService.open(ModalCertifyComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-80'
            }
        )
    }
}
