import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Utils
import { DomainRouting } from 'src/app/core/utils';

@Injectable()
export class DomainRoutingService implements DomainRouting {

    public constructor( protected router: Router ) { }

    public redirectToAuthPoint( router: Router, returnUrl?: string )  {
        let options = { queryParams: {} };

        if (returnUrl) {
            options.queryParams = { returnUrl };
        }

        router.navigate( ['login'], options );
    }
}
