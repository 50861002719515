import { TemplateElementType } from '../models/quizzes/template-element-type.model';

export const TEMPLATE_ELEMENTS_TYPES: TemplateElementType[] = [
    {
        name: 'VideoQuestions',
        type: 'video',
        description: 'FORMATS.VIDEO',
        icon: 'videocam',
        isAnswer: false,
        isOption: false,
        isQuestion: true
    },
    {
        name: 'ImageQuestions',
        type: 'image',
        description: 'FORMATS.IMAGE',
        icon: 'image',
        isAnswer: false,
        isOption: false,
        isQuestion: true
    },
    {
        name: 'AudioQuestions',
        type: 'audio',
        description: 'FORMATS.AUDIO',
        icon: 'volume_up',
        isAnswer: false,
        isOption: false,
        isQuestion: true
    },
    {
        name: 'PdfQuestions',
        type: 'pdf',
        description: 'FORMATS.PDF',
        icon: 'picture_as_pdf',
        isAnswer: false,
        isOption: false,
        isQuestion: true
    },
    {
        name: 'TextQuestions',
        type: 'text',
        description: 'FORMATS.TEXT',
        icon: 'subject',
        isAnswer: false,
        isOption: false,
        isQuestion: true
    },
    {
        name: 'VideoOptions',
        type: 'video',
        description: 'FORMATS.VIDEO',
        icon: 'videocam',
        isAnswer: false,
        isOption: true,
        isQuestion: false
    },
    {
        name: 'ImageOptions',
        type: 'image',
        description: 'FORMATS.IMAGE',
        icon: 'image',
        isAnswer: false,
        isOption: true,
        isQuestion: false
    },
    {
        name: 'AudioOptions',
        type: 'audio',
        description: 'FORMATS.AUDIO',
        icon: 'volume_up',
        isAnswer: false,
        isOption: true,
        isQuestion: false
    },
    {
        name: 'PdfOptions',
        type: 'pdf',
        description: 'FORMATS.PDF',
        icon: 'picture_as_pdf',
        isAnswer: false,
        isOption: true,
        isQuestion: false
    },
    {
        name: 'TextOptions',
        type: 'text',
        description: 'FORMATS.TEXT',
        icon: 'subject',
        isAnswer: false,
        isOption: true,
        isQuestion: false
    },
    {
        name: 'VideoCorrect',
        type: 'video',
        description: 'FORMATS.VIDEO',
        icon: 'videocam',
        isAnswer: true,
        isOption: false,
        isQuestion: false
    },
    {
        name: 'ImageCorrect',
        type: 'image',
        description: 'FORMATS.IMAGE',
        icon: 'image',
        isAnswer: true,
        isOption: false,
        isQuestion: false
    },
    {
        name: 'AudioCorrect',
        type: 'audio',
        description: 'FORMATS.AUDIO',
        icon: 'volume_up',
        isAnswer: true,
        isOption: false,
        isQuestion: false
    },
    {
        name: 'PdfCorrect',
        type: 'pdf',
        description: 'FORMATS.PDF',
        icon: 'picture_as_pdf',
        isAnswer: true,
        isOption: false,
        isQuestion: false
    },
    {
        name: 'TextCorrect',
        type: 'text',
        description: 'FORMATS.TEXT',
        icon: 'subject',
        isAnswer: true,
        isOption: false,
        isQuestion: false
    },
];
