export class CourseStatisticsAuthorModel {
    idCourse: number;
    idAutor: number;
    autor: string;
    cursos: number;
    grafos: number;
    explicaciones: number;
    quizzesOriginales: number;
    quizzesAlternativas: number;
    quizzesHomologadas: number;
    quizzesCertificadas: number;
    conexionesDentro: number;
    conexionesFuera: number;
    rankings: number;
    ratings: number;
    image: string;
}
