import { Component, OnInit } from '@angular/core';

declare function init_plugins();

@Component({
    selector: 'app-settings-layout',
    templateUrl: './settings-layout.component.html',
    styleUrls: ['./settings-layout.component.scss']
})
export class SettingsLayoutComponent implements OnInit {

    constructor() { }

    ngOnInit() {
        init_plugins();
    }

}
