import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FileSavingService {
    [x: string]: any;
  constructor(public http: HttpClient) { }
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    }).set('access-control-allow-origin', 'http://localhost:4200')
  };

 uploadFile(formData:FormData){
     let urlApi = 'https://localhost:44300/api/files';
    //let urlApi = 'http://salware.accuro.es:5000/api/files';
    console.log("POST urlApi " + "'" + urlApi + "'");
    console.log("formData : " + formData);
    return this.http.post(urlApi,formData,{
        headers:{
            'Content-Type': 'multipart/form-data'
        }
    });
  }

  downloadFile(name:any){
    let urlApi = 'https://localhost:44300/api/files/'+ name;
    //let urlApi = 'http://salware.accuro.es:5000/api/files/'+ name;
    console.log("GET urlApi " + "'" + urlApi + "'");
    var res = this.http.get(urlApi);
    console.log("res : "+ res);
    return res;
  }
}
