import { Component, OnInit } from '@angular/core';

import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';

// Services
import { TranslateService } from '@ngx-translate/core';
import { CoursesService } from 'src/app/core/services/courses';
import { LoginService } from 'src/app/core/services/login';
import { UsersService } from 'src/app/core/services/users';

// Modals
import { CourseModel } from 'src/app/core/models/courses';

@Component({
  selector: 'app-modal-curso-niveles',
  templateUrl: './modal-curso-niveles.component.html',
  styleUrls: ['./modal-curso-niveles.component.scss']
})
export class ModalCursoNivelesComponent implements OnInit {

  curso: CourseModel;

  cargando = false;

  public barChartOptions: ChartOptions = {
    responsive: true,
  };

  public barChartLabels: Label[] = ['6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17',
                                    '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30+'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [0, 0, 0, 125, 563, 1201, 27854, 21759, 11478, 4521, 641, 214, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 985],
      label: 'Usuarios por edades' }
  ];
  public barChartColors: Color[] = [
    { backgroundColor: '#1CA4FC' }
  ];

  constructor(public translateService: TranslateService,
              public coursesService: CoursesService,
              private loginService: LoginService,
              private usersService: UsersService,
              private activeModal: NgbActiveModal) {
  }

  ngOnInit() {

    this.cargando = true;

    this.cargando = false;
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);

    // tslint:disable-next-line: max-line-length
    //this.usersService.addUserLog(this.loginService.getUser().idUser, `Cerrar ventana modal - Curso niveles`, 'OK');
  }
}
