import { Component, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

// Services
import { AlertService } from 'src/app/core/services/shared';

@Component( {
    selector: 'app-alert',
    templateUrl: 'alert.component.html'
} )

export class AlertComponent implements OnDestroy {

    @Input() public context = AlertService.AlertServiceContextValues.None;

    public alertMessage: any;
    private subscription: Subscription;

    public constructor( private alertService: AlertService ) {

        this.subscription = alertService.getMessage()
                                        .subscribe( alertMessage => this.onAlertReceived( alertMessage ) );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private onAlertReceived(alertMessage: any) {

        if (alertMessage) {
            const contextMatch = alertMessage && alertMessage.context.length > 0 && this.context === alertMessage.context;

            this.alertMessage = contextMatch ? alertMessage : null;

            // Si es success lo cierro a los 5 segundos
            if (this.alertMessage != null && this.alertMessage.type === 'success') {
                setTimeout(() => {  this.alertMessage = null; }, 5000);
            }
        } else {
            this.alertMessage = null;
        }
    }
}
