import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { gNode } from 'src/app/core/models/graph/gNode.model';
import { TemplatesService } from 'src/app/core/services/templates/templates.service';

declare var $: any;

@Component({
    selector: 'app-modal-registar-quizzes',
    templateUrl: './modal-registar-quizzes.component.html',
    styleUrls: ['./modal-registar-quizzes.component.scss']
})
export class ModalRegistarQuizzesComponent implements OnInit, OnDestroy {
    idCurso: string;
    idMapa: string;
    mensajeQuiz: string;
    quizEnviado: any;
    @Input() type: string;
    @Input() node;
    nomGrafo: string;
    template: any;
    constructor(private quizService: QuizzesService,
        private templatesService: TemplatesService) {
    }
    ngOnDestroy(): void {
        console.log('ModalRegistrarQuizzesOnDestroy');
    }

    @Output() newQuiz = new EventEmitter<gNode>();

    receiveNewNodeData($event) {
        console.log("recibe evento")
        this.newQuiz.emit($event);
    }

    ngOnInit() {
        this.quizService.setCurrentQuiz(this.node);
        this.templatesService.setCurrentTemplate(this.template);
    }
}
