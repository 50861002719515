import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-auth-layout',
    templateUrl: './auth-layout.component.html',
    styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
    showLanguageSelector: boolean = true;

    constructor(private router: Router) { }

    ngOnInit() {
        this.showLanguageSelector = !this.isSignUpPage(this.router.url);
        this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd))
            .subscribe(res => {
                console.log(res.url);
                this.showLanguageSelector = !this.isSignUpPage(res.url);
            });
    }

    private isSignUpPage(url: string) {
        return url === '/auth/sign-up';
    }

}
