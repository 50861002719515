import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SigmaToolbarsService {

  public helpStatus = new Subject<boolean>(); //new Observable<boolean>();
  public colorFilters = new Subject <Array<[string, boolean]>> ();
  public showInfo = new Subject<boolean>();
  public showStaircaseChart = new Subject<boolean>();
  public visibility = new Subject<{nodes: boolean, quizzes: boolean}>();
  public isStudyModeAutomatic = new Subject<boolean>();
  public showModalCourses = new Subject<boolean>();

  constructor() { }
  
  public changeHelpStatus(status: boolean): void {
    this.helpStatus.next(status);
  }

  public changeColorFilters( colorFilters ) {
    this.colorFilters.next(colorFilters);
  }

  public changeShowInfo( showInfo ){
    this.showInfo.next(showInfo);
  }

  public changeShowStaircaseChart( show ){
    this.showStaircaseChart.next(show);
  }

  public changeVisibility( visibility ) {
    this.visibility.next(visibility);
  }

  public changeIsStudyModeAutomatic( showAutomatic ){
    this.showStaircaseChart.next(showAutomatic);
  }

  public changeShowModalCourses(){
    this.showModalCourses.next(true)
  }

}
