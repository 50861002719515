import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RegisterModel } from '../../models/users/register.model';

@Injectable()
export class RegisterService {

    constructor(private http: HttpClient) { }

    checkEmailExists(email: string): Observable<boolean> {
        const url = `getExistMail?mail=${email}`;
        return this.http.get<boolean>(url).pipe(catchError(err => { console.error(err); return of(false) }));
    }

    registerUser(user: RegisterModel) {
        const url = `register-user`;
        return this.http.post<any>(url, user);
    }
}
