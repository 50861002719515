import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SigmaToolbarsService } from 'src/app/core/services/sigma-toolbars/sigma-toolbars.service';

@Component({
  selector: 'app-info-toolbar',
  templateUrl: './info-toolbar.component.html',
  styleUrls: ['./info-toolbar.component.scss']
})
export class InfoToolbarComponent implements OnInit {

  constructor( private toolsService: SigmaToolbarsService ) { }

  ngOnInit() {}

  public emitshowInfoChk($event) {
    this.toolsService.changeShowInfo($event.checked);
  }
}
