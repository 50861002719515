import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sense-mode',
  templateUrl: './sense-mode.component.html',
  styleUrls: ['./sense-mode.component.scss']
})
export class SenseModeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
