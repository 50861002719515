import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SigmaToolbarsService } from 'src/app/core/services/sigma-toolbars/sigma-toolbars.service';

@Component({
  selector: 'app-color-filter-toolbar',
  templateUrl: './color-filter-toolbar.component.html',
  styleUrls: ['./color-filter-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ColorFilterToolbarComponent implements OnInit {


 // @Output() colorFilters = new EventEmitter();
 private colorFilters = new Array<[string,boolean]>();

  @Input() filterType: string;

  private checkboxKeys  = { 'student' : {
                                'greyChk' : true,
                                'redChk' : true,
                                'yellowChk' : true,
                                'lgreenChk' : true,
                                'dgreenChk' : true
                              },
                            'operators' : {
                                'senmotemo' : true,
                                'assostatic' : true,
                                'temporary' : true,
                                'abstract' : true
                              }
  };

 

  constructor(private toolsService: SigmaToolbarsService) { }

  ngOnInit() {  
    
    Object.entries( this.checkboxKeys[this.filterType]).forEach(
      ([key, value]) => {
        this.colorFilters[key] = value;
      }
    );
    
}

  private emitChange($event){  
    this.colorFilters[$event.source.id] = $event.checked;
    //this.colorFilters.emit(this.colorFilters);
    this.toolsService.changeColorFilters(this.colorFilters);
}

private asIsOrder(a, b) {
  return 1;
}

}
