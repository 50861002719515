import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';

// Utils
import { DomainRouting } from 'src/app/core/utils';

// Services
import { UsersService } from 'src/app/core/services/users';
import { LoginService } from 'src/app/core/services/login';


@Injectable()
export class ProfileValidationGuard implements CanActivate {
    public constructor(private router: Router,
        private domainRoutingService: DomainRouting,
        private usersService: UsersService,
        private loginService: LoginService) { }

    public canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot,
        routerStateSnapshot: RouterStateSnapshot) {
        console.log('ProfileValidationGuard');
        const user = this.loginService.getUser();

        if (user.idUser !== 0) {

            const userData = user;

            const perfil = this.loginService.getProfile();

            // Y ahora compruebo si ha sido validado
            const res = this.usersService.checkIfUserDataAreRegistered(userData, perfil);

            if (res.result !== true) {
                // Y lo mando a los datos de usuario
                this.router.navigate([`/user-data/${perfil}`]);
            } else {
                return true;
            }
        } else {
            return false;
        }

    }
}
