import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit, AfterViewChecked } from '@angular/core';
import * as countdown from 'countdown';
import { TemplatesService } from 'src/app/core/services/templates/templates.service';
import { NgbTabTitle, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QuizzesService } from 'src/app/core/services/quizzes/quizzes.service';
import { ModalReproducirComponent } from '../../../modal-reproducir/modal-reproducir.component';
import { FormGroup, FormControl } from '@angular/forms';
import { FileSavingService, SubirArchivoService } from 'src/app/core/services/shared';
import { environment } from 'src/environments/environment';
import { SubirMediosService } from 'src/app/core/services/shared/subir-medios.service';
import { HttpClient } from '@angular/common/http';

interface Time {
    hours: number,
    minutes: number,
    seconds: number;
}

@Component({
    selector: 'app-body-quizzes',
    templateUrl: './body-quizzes.component.html',
    styleUrls: ['./body-quizzes.component.scss']
})
export class BodyQuizzesComponent implements OnInit {
    @Input() mensajeBody: string;
    @Input() quizEnviado: any;
    visibility: boolean;
    //progress = 0;
    //progressBar = document.querySelector('.progress-bar');
    intervalId;
    time: Time;
    iniBlock: boolean;
    countSeconds: number;
    timerId: number = null;
    @Input() date: Date | string;
    //@Input() template:any;
    template: any = {};
    //@Input() tittle:boolean;
    //@Input() quizInstructions:boolean;
    elements = [];
    elementsTemplatesQuizzes: any[];
    source: any;
    quiz: any;
    newQuiz: any;
    progress: boolean;
    imagen: any;
    titulo: string;
    elementCurrent: any;
    description: string;
    fontStyles: string[] = ["normal", "italic", "oblique", "inherit", "initial", "unset"];
    fontStyle: string;
    fontSizes: string[] = ["8", "9", "10", "11", "12", "14", "16", "18", "20"];
    fontSize: string;
    private templateTypes = [
        'VideoQuestions',
        'ImageQuestions',
        'AudioQuestions',
        'PdfQuestions',
        'TextQuestions',
        'VideoOptions',
        'ImageOptions',
        'AudioOptions',
        'PdfOptions',
        'TextOptions',
        'VideoCorrect',
        'ImageCorrect',
        'AudioCorrect',
        'PdfCorrect',
        'TextCorrect'];
    private elementIcons = [
        'videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject',
        'videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject',
        'videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject'];

    //@ViewChild('descInput', {static:true}) descInput: ElementRef;

    constructor(private templatesService: TemplatesService,
        private quizService: QuizzesService,
        private modalService: NgbModal,
        private fileSavingService: FileSavingService,
        private subirMediosService: SubirMediosService,
        private subirArchivoService: SubirArchivoService,
        public http: HttpClient) {
        this.quizService.currentQuiz.subscribe((q) => {
            this.quiz = q
        });

        /*this.quizService.newQuiz.subscribe((q) => {
            this.newQuiz = q
            this.saveTemplatesQuizzes();
        });*/

        /*this.quizService.currentImagen.subscribe( (q) => {this.imagen = q
          console.log("QUIZZ BODY IMAGEN: ", this.imagen);
        });*/

        this.templatesService.currentTemplate.subscribe((t) => {
            this.template = t;
        });

        this.templatesService.saveQuizz.subscribe((t) => {
            this.saveQuizz();
        });
    }
    /*this.templatesService.currentElement.subscribe((e)=>{
         this.elementCurrent=e;
         this.description=this.elementCurrent.data;
    });*/

    public form: FormGroup = new FormGroup({
        templateTittle: new FormControl(''),
        quizInstructions: new FormControl('')
    });

    async ngOnInit() {
        this.form.valueChanges.subscribe(() => {
            this.template.templateTittle = this.form.value.templateTittle;
            this.template.quizInstructions = this.form.value.quizInstructions;
            this.templatesService.setCurrentTemplate(this.template);
        });
        this.description = "";
        this.progress = false;
        console.log("INI this.progress ngOnInit : ", this.progress);
        // await this.quizService.currentQuiz.subscribe( (q) => this.quiz = q);
        this.visibility = true;

        if (this.template) {
            /*RECALCULAR LAS POSCIONES DE LOS ELEMENTOS DEL TEMPLATE*/
            this.templatesService.getTemplateElementsByIdTemplate(this.template.idTemplate).subscribe((resp: any) => {
                this.elements = resp.data;
                console.log(this.elements);
                // this.calculatePositions(this.elements);
                this.assignIconTypeElement(this.elements);
                return this.elements;
            }, (error) => {
                console.log("ERROR", error);
            });

            /*if (this.quiz.idOriginal) {
                //Veririficar si tienes datos
                await this.templatesService.getTemplatesElementQuizzesByIdQuiz(this.quiz.idOriginal).subscribe((data:any) => {
                    this.elementsTemplatesQuizzes = data.data.templatesElementQuizzes.nodes;
                    if(this.elementsTemplatesQuizzes.length > 0 ){
                        //Si los elementos tienen datos recalculamos las posiciones de los elementos
                        this.elementsTemplatesQuizzesToElements();
                    }else{
                       //Si los elementos no tienen datos recalculamos las posiciones de los elementos
                       this.templatesService.getTemplateElementsByIdTemplate(this.template.idTemplate).subscribe((resp: any) => {
                          this.elements = resp.data.templateElement.nodes;
                          this.calculatePositions(this.elements);
                          this.assignIconTypeElement(this.elements);
                          return this.elements;
                      }, (error) => {
                        console.log("ERROR", error);
                      });
                    }
                });
            }*/
        }
    }

    elementsTemplatesQuizzesToElements() {
        this.elements = [];
        for (var i = 0; i < this.elementsTemplatesQuizzes.length; i++) {
            this.elements[i] = this.elementsTemplatesQuizzes[i].templateElement[0];
        }
        this.calculatePositions2(this.elements);
        this.assignIconTypeElement(this.elements);
        return this.elements;
    }

    descubrirRespuesta() {
        console.log("Descubir Respuestas");
    }

    autoEvaluacionNegativa() {
        console.log("Autoevaluacion Negativa");
    }

    autoEvaluacionPositiva() {
        console.log("Autoevaluacion Positiva");
    }

    nextQuiz() {
        this.mensajeBody = "Paso al siguiente quiz";
    }


    ngOnDestroy() {
        if (this.timerId) {
            clearInterval(this.timerId);
        }
    }

    calculatePositions(elements: any[]) {
        if (elements != null) {
            elements.forEach((element) => {
                element.yposition = element.newyposition;
                element.xposition = element.newxposition;
            });
        }
    }

    calculatePositions2(elements: any[]) {
        if (elements != null) {
            elements.forEach((element) => {
                element.yposition = element.yposition;
                element.xposition = element.xposition;
            });
        }
    };

    cargarRecurso(event, element: any) {
        this.progress = true;
        var file = event.target.files[0];
        if (file) {
            var reader = new FileReader();
            let arch: any;
            reader.readAsDataURL(file);
            reader.onload = async (event: any) => {
                this.source = event.target.result;
                var idDiv = '#' + element.idTemplateElement;
                //document.getElementById(idDiv).style.backgroundImage = 'url(' + this.getImage(file) + ')';
                //element.data = file.name;

                //Cargar Imagen
                const base64 = this.source.split(';base64,')[1];
                const imageName = 'archivo' + Date.now() + '.png';
                const imageBlob = this.dataURItoBlob(base64);
                const imageFile = new File([imageBlob], imageName, { type: 'image/png' });
                console.log("imageFile : ", imageFile);
                //INI
                /*let formData = new FormData();
                formData.append("file", imageFile, imageFile.name);
                this.fileSavingService.uploadFile(formData).subscribe(
                    res => {
                        console.log("Inicio mostrar Imagen ", res);
                        element.data = imageFile.name;
                        document.getElementById(idDiv).style.backgroundImage = 'url(' + this.getImages(element) + ')';
                        console.log("Fin mostrar Imagen");
                    },
                    err => {
                        console.log("Error", err)
                    });*/

                let urlApi = 'https://localhost:44300/api/files';
                let formData = new FormData();
                formData.append("file", imageFile, imageFile.name);
                /*this.http.post(urlApi,formData,{
                    headers:{
                        'Content-Type': 'multipart/form-data'
                    }
                }).subscribe(
                    res => {
                        console.log("Inicio mostrar Imagen ", res);
                        element.data = imageFile.name;
                        document.getElementById(idDiv).style.backgroundImage = 'url(' + this.getImages(element) + ')';
                        console.log("Fin mostrar Imagen");
                    },
                    err => {
                        console.log("Error", err)
                    });*/

                /*var xhr = new XMLHttpRequest();
                xhr.withCredentials = true;

                xhr.addEventListener("re,,2a,,dystatechange", function() {
                if(this.readyState === 4) {
                   console.log(this.responseText);
                }
                });

                xhr.open("POST", "https://localhost:44300/api/files");
                xhr.send(formData);

                element.data = imageFile.name;
                document.getElementById(idDiv).style.backgroundImage = 'url(' + this.getImages(element) + ')';*/

                /*this.subirMediosService.subirArchivo(imageFile);
                console.log("Inicio mostrar Imagen ");
                element.data = imageFile.name;
                document.getElementById(idDiv).style.backgroundImage = 'url(' + this.getImages(element) + ')';
                console.log("Fin mostrar Imagen");*/

                this.subirArchivoService.subirArchivo(imageFile).then((data) => {
                    //Registrar
                    this.quiz.idOriginal
                    element.idTemplateElement

                    console.log("Ini mostrar Imagen");
                    console.log(data);
                    element.data = imageFile.name;
                    document.getElementById(idDiv).style.backgroundImage = 'url(' + this.getImages(element) + ')';
                    console.log("Fin mostrar Imagen");
                });
                //FIN
            }
        }
        this.progress = false;
    }

    getImage(file: any) {
        if (file.type == "video/mp4") {
            //return  '../../../../../assets/images/video.jpg';
            //var file;
            /*this.fileSavingService.downloadFile("archivo1610318560749.png").subscribe((res)=>{
                console.log("Response Download: ", res);
                return res;
            })*/

        }
        if (file.type == "image/jpeg" || file.type == "image/png") {
            console.log("getImage : " + file);
            //return file;
            //return '../../../../../assets/images/'+ file.name;
            return '../../../../../assets/images/Abstract.png';
            //return '/home/default/Files/Abstract.png';
            //return '~/Files/Abstract.png';
        }
        if (file.type == "audio/mpeg") {
            return '../../../../../assets/images/headphones.jpg';
        }
        if (file.type == "pdf") {
            return '../../../../../assets/document/' + file.name;
        }
        if (file.type == "txt") {
            return '../../../../../assets/document/' + file.name;
        }
    }

    getImages(element: any) {
        if (element.data) {
            if (element.icon == "videocam") {
                return '../../../assets/images/video.jpg';
            }
            if (element.icon == "image") {
                console.log("getImages : " + element.data);
                //return '../../../assets/images/' + element.data;
                /*let url = `${environment.localUrl}${environment.imagesUrl}`;
                var url_l= url + element.data;*/
                var url_l = 'assets/images/' + element.data;
                //var url_l = '../../../assets/images/Abstract.png';
                console.log("url_l :" + url_l);
                return url_l;
                //return '../../../assets/images/' + element.data;
                //return '../../../assets/images/' + 'Abstract.png';
                //return '/home/default/Files/Abstract.png';
                /*console.log("cantidad Elementos : " + this.elements.length);
                var nameArch = "Abstract.png";
                 this.fileSavingService.downloadFile(nameArch).subscribe((res)=>{
                    console.log("Responde: "+ res);
                    return res;
                 })*/
            }
            if (element.icon == "volume_up") {
                return '../../../../../assets/images/headphones.jpg';
            }
            if (element.icon == "picture_as_pdf") {
                return '../../../assets/document/' + element.data;
            }
            if (element.icon == "subject") {
                return '../../../assets/document/' + element.data;
            }
        }
    }

    dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/png' });
        return blob;
    }

    changeValue(event: any) {
        console.log("event : ", event.target.value);
        this.titulo = event.target.value;
        console.log("this.titulo :", this.titulo);
    }

    setElementType(quiz: any, element: any) {
        console.log("setElementType quiz : ", quiz);
        console.log("setElementType element : ", element);
        switch (element.elementType) {
            case (0): {
                quiz.stringQuestionsVideo = element.idTemplateElement;
                break;
            }
            case (1): {
                quiz.stringQuestionsImage = element.idTemplateElement;
                break;
            }
            case (2): {
                quiz.stringQuestionsAudio = element.idTemplateElement;
                break;
            }
            case (3): {
                quiz.stringQuestionsDoc = element.idTemplateElement;
                break;
            }
            case (4): {
                quiz.stringQuestionsText = element.idTemplateElement;
                break;
            }
            case (5): {
                quiz.stringOptionsVideo = element.idTemplateElement;
                break;
            }
            case (6): {
                quiz.stringOptionsImage = element.idTemplateElement;
                break;
            }
            case (7): {
                quiz.stringOptionsAudio = element.idTemplateElement;
                break;
            }
            case (8): {
                quiz.stringOptionsDoc = element.idTemplateElement;
                break;
            }
            case (9): {
                quiz.stringOptionsText = element.idTemplateElement;
                break;
            }
            case (10): {
                quiz.stringAnswersVideo = element.idTemplateElement;
                break;
            }
            case (11): {
                quiz.stringAnswersImage = element.idTemplateElement;
                break;
            }
            case (12): {
                quiz.stringAnswersAudio = element.idTemplateElement;
                break;
            }
            case (13): {
                quiz.stringAnswersDoc = element.idTemplateElement;
                break;
            }
            case (14): {
                quiz.stringAnswersText = element.idTemplateElement;
                break;
            }
            default: {
                break;
            };
        }
    }

    getDescription(element: any) {
        this.elementCurrent = element;
        //this.description=this.elementCurrent.data;
        console.log("getDescription this.elementCurrent  : ", this.elementCurrent);
        //console.log("this.description : ", this.description);
        var subscribe = this.templatesService.getTemplatesElementQuizzes(this.elementCurrent.idTemplateElement, this.quiz.idOriginal).subscribe((data) => {
            if (data.data.templatesElementQuizzes.nodes.length > 0) {
                this.description = data.data.templatesElementQuizzes.nodes[0].data;
            } else {
                this.description = "";
            }
            var parts: string[] = this.description.split("#");
            this.description = parts[0];
            this.fontStyle = parts[1];
            this.fontSize = parts[2];
            subscribe.unsubscribe();
        });
    }

    // saveQuestion(desc:any){
    //   console.log("ENTRA SAVE");
    //     var description = desc+"#"+this.fontStyle+"#"+this.fontSize;
    //     this.templatesService.createTemplatesElementQuizzes(this.elementCurrent.idTemplateElement,this.quiz.idOriginal,description);
    // }

    closeModalText() {
        console.log("closeModalText");
        this.description = "";
        console.log("this.description : ", this.description);
    }

    showFondStyle(event: Event) {
        this.fontStyle = (event.target as HTMLSelectElement).value;
    }

    showFontSize(event: Event) {
        this.fontSize = (event.target as HTMLSelectElement).value + 'px';
    }


    onChange(event, element: any) {
        var resposeCheck = event.target.value;
        if (resposeCheck == "true") {
            if (event.target.classList.contains('result-wrong')) {
                event.target.classList.remove('result-wrong');
            }

            event.target.classList.add('result-correct')
        } else {
            if (event.target.classList.contains('result-correct')) {
                event.target.classList.remove('result-correct');
            }
            event.target.classList.add('result-wrong')
        }
        this.elements.forEach((x) => {
            if (x.idTemplateElement == element.idTemplateElement) x.responseCheck = resposeCheck ? 1 : 0;
        })

    }

    saveQuizz() {
        // this.elements.forEach((element) => {
        //   element.idQuiz = this.newQuiz.idOriginal;
        //   this.templatesService.setTemplatesElementQuizzes(this.elements);
        // });
    }

    saveTemplatesQuizzes() {
        console.log("****Ingreso a saveTemplatesQuizzes****");
        if (this.newQuiz && this.template) {
            /*REGISTRAR TEMPLATE CON QUIZ Y ELEMNTOS DEL QUIZ*/
            this.templatesService.getTemplatesElementQuizzes(this.template.idTemplate, this.newQuiz.idOriginal).toPromise().then((n) => {
                if (n.data.templatesElementQuizzes.nodes.length == 0) {
                    /* Revisar porque manda doble evento*/
                    this.templatesService.createTemplatesQuizzes(this.template.idTemplate, this.newQuiz.idOriginal);
                    if (this.elements) {
                        this.elements.forEach((element) => {
                            element.idQuiz = this.newQuiz.idOriginal;
                            this.templatesService.setTemplatesElementQuizzes(element);
                        });
                    }
                }
            });

            /*RECALCULAR POSICIONES DE LOS ELEMENTOS DEL TEMPLATE*/
            if (this.newQuiz.idOriginal) {
                //Veririficar si tienes datos
                this.templatesService.getTemplatesElementQuizzesByIdQuiz(this.newQuiz.idOriginal).subscribe((data: any) => {
                    this.elementsTemplatesQuizzes = data.data.templatesElementQuizzes.nodes;
                    if (this.elementsTemplatesQuizzes.length > 0) {
                        //Si los elementos tienen datos recalculamos las posiciones de los elementos
                        this.elementsTemplatesQuizzesToElements();
                    } else {
                        //Si los elementos no tienen datos recalculamos las posiciones de los elementos
                        this.templatesService.getTemplateElementsByIdTemplate(this.template.idTemplate).subscribe((resp: any) => {
                            this.elements = resp.data.templateElement.nodes;
                            this.calculatePositions(this.elements);
                            this.assignIconTypeElement(this.elements);
                            return this.elements;
                        }, (error) => {
                            console.log("ERROR", error);
                        });
                    }
                });
            }
        } else {
            return;
        }
    }

    assignIconTypeElement(elements: any) {
        for (var i = 0; i < this.elements.length; i++) {
            this.elements[i].style = this.templateTypes[elements[i].elementType];
            this.elements[i].icon = this.elementIcons[elements[i].elementType];
        }
    }

}
