import { Component, OnInit } from '@angular/core';
import { TaskService } from 'src/app/core/services/task';

@Component({
  selector: 'app-modal-competition-groups',
  templateUrl: './modal-competition-groups.component.html',
  styleUrls: ['./modal-competition-groups.component.scss']
})
export class ModalCompetitionGroupsComponent implements OnInit {
  idCurso:string;
  remitente:any;
  constructor(public taskService:TaskService) { }

  ngOnInit() {
    this.taskService.setTask(this.remitente);
  }

}
