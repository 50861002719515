import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { first } from 'rxjs/operators';
import { LoginService } from 'src/app/core/services/login';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { Profiles } from 'src/app/core/utils/profiles.enum';


@Component({
    selector: 'app-sigma-menu',
    templateUrl: './sigma-menu.component.html',
    styleUrls: ['./sigma-menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SigmaMenuComponent implements OnInit {
    automaticGroup: boolean = false;

    constructor(public quizService: QuizzesService, public loginService: LoginService) { }

    ngOnInit() {
        this.quizService.getAutomatic().pipe(first()).subscribe(res => {
            this.automaticGroup = res;
        })
    }

    selectAtomatic() {
        this.quizService.setAutomatic(true);
    }

    selectManual() {
        this.quizService.setAutomatic(false);
    }

    viewAsStudent() {
        this.loginService.setProfile(Profiles.Student, false);
    }

    viewAsAuthor() {
        this.loginService.setProfile(Profiles.Author, false);
    }
}
