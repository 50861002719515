export const environment = {
    production: true,
    debugUserLog: false,
    apiUrl: 'https://salware.com/api-dev/mvc/rest/',
    localUrl: 'https://salware.com',
    imagesCourses: '/images-dev/courses/',
    imagesTargets: '/images-dev/target/',
    imagesUrl: '/assets/images/',
    remoteFilesUrl: 'https://salware.com/images-dev/',
    nodeImages: '/images-dev/nodes/',
    quizzesContent: '/images-dev/quizzesContent/',
    graphBackground: '/images-dev/targets/BackGround/',
    nodeContent: '/images-dev/nodeContent/',
    attempNumbersProfile: 3,
    attempNumbersKey: 4
};
