import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubirMediosService {

  constructor( private http: HttpClient ) { }

  descargarArchivo(file: File) {
      let formData = new FormData();
      let archivo : any;

      formData.append('archivo', file, file.name);
      this.http.post(environment.apiUrl + 'download', formData, {
          responseType: 'blob'
       }).subscribe(
          data => console.log(data),
          // data =>saveAs(data),
          err => console.log('Hubo un error : ' + err),
          () => console.log('Descarga Finalizada')
      );

      return archivo;
  }

  subirArchivo(file: File) {
    let formData = new FormData();

    formData.append('file', file, file.name);

    this.http.post(environment.apiUrl + '/api/files/', formData, {
        reportProgress: true,
        observe: 'events'
    })
    .subscribe(event => {
        if(event.type === HttpEventType.UploadProgress) {
           console.log('Upload Progress : ' + Math.round(event.loaded / event.total * 100 ) + '%');
        } else if (event.type === HttpEventType.Response) {
            console.log(event);
        }
    },
    err => console.log('Hubo un error : ' + err),
    () => console.log('Subida Finalizada'))
  }
}
