import { FileUploadersComponent } from './node-form/file-uploader/file-uploader.component';
import { NodeFormsComponent } from './node-form/node-form.component';
import { ClockComponent } from './sigma-menu/clock/clock.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './alert/alert.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { FormsComponent } from './forms/forms.component';
import { QuizFormComponent } from './forms/quiz-form/quiz-form.component';
import { IdiomaComponent } from './idioma/idioma.component';
import { PipesModule } from '../pipes/pipes.module';
import { PatternsComponent } from './patterns/patterns.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/pages/material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingComponent } from './loading/loading.component';
import { RatingComponent } from './rating/rating.component';
import { SigmaMenuComponent } from './sigma-menu/sigma-menu.component';
import { ColorFilterToolbarComponent } from './sigma-menu/color-filter-toolbar/color-filter-toolbar.component';
import { StaircaseChartComponent } from './sigma-menu/staircase-chart/staircase-chart.component';
import { HelpToolComponent } from './sigma-menu/help-tool/help-tool.component';
import { GraphVisibilityToolbarComponent } from './sigma-menu/graph-visibility-toolbar/graph-visibility-toolbar.component';
import { InfoToolbarComponent } from './sigma-menu/info-toolbar/info-toolbar.component';
import { MenuFabComponent } from './menu-fab/menu-fab.component';
import { ModalAceptarCancelarComponent, ModalComponent } from './modal';
import { ModalReproducirComponent } from './modal-reproducir/modal-reproducir.component';
import { PatternDraftComponent } from './patterns/pattern-draft/pattern-draft.component';
import { PatternFormComponent } from './patterns/pattern-form/pattern-form.component';
import { ModalCompetitionCouplesComponent } from './sigma-canvas/modal-competition-couples/modal-competition-couples.component';
import { ModalCompetitionGroupsComponent } from './sigma-canvas/modal-competition-groups/modal-competition-groups.component';
import { ModalConectarNodoComponent } from './sigma-canvas/modal-conectar-nodo/modal-conectar-nodo.component';
import { ModalOperadoresComponent } from './sigma-canvas/modal-operadores/modal-operadores.component';
import { ModalRegistrarNodoComponent } from './sigma-canvas/modal-registrar-nodo/modal-registrar-nodo.component';
import { ModalRegistarQuizzesComponent } from './sigma-canvas/modal-registar-quizzes/modal-registar-quizzes.component';
import { ModalReproducirNodoComponent } from './sigma-canvas/modal-reproducir-nodo/modal-reproducir-nodo.component';
import { ModalReproducirQuizzesComponent } from './sigma-canvas/modal-reproducir-quizzes/modal-reproducir-quizzes.component';
import { ModalSelectRecipientComponent } from './sigma-canvas/modal-select-recipient/modal-select-recipient.component';
import { ModalTasksComponent } from './sigma-canvas/modal-tasks/modal-tasks.component';
import { PowerComponentComponent } from './sigma-canvas/power-component/power-component.component';
import { RegistrarCursoComponent } from './sigma-canvas/registrar-curso/registrar-curso.component';
import { RegistrarNodoComponent } from './sigma-canvas/registrar-nodo/registrar-nodo.component';
import { BodySelectRecipientComponent } from './sigma-canvas/modal-select-recipient/body-select-recipient/body-select-recipient.component';
import { HeadSelectRecipientComponent } from './sigma-canvas/modal-select-recipient/head-select-recipient/head-select-recipient.component';
import { BodyReproducirQuizzesComponent } from './sigma-canvas/modal-reproducir-quizzes/body-reproducir-quizzes/body-reproducir-quizzes.component';
import { FooterReproducirQuizzesComponent } from './sigma-canvas/modal-reproducir-quizzes/footer-reproducir-quizzes/footer-reproducir-quizzes.component';
import { HeadReproducirQuizzesComponent } from './sigma-canvas/modal-reproducir-quizzes/head-reproducir-quizzes/head-reproducir-quizzes.component';
import { HeadNodesComponent } from './sigma-canvas/modal-reproducir-nodo/head-nodes/head-nodes.component';
import { BodyQuizzesComponent } from './sigma-canvas/modal-registar-quizzes/body-quizzes/body-quizzes.component';
import { FooterQuizzesComponent } from './sigma-canvas/modal-registar-quizzes/footer-quizzes/footer-quizzes.component';
import { HeadQuizzesComponent } from './sigma-canvas/modal-registar-quizzes/head-quizzes/head-quizzes.component';
import { BodyCompetitionGroupsComponent } from './sigma-canvas/modal-competition-groups/body-competition-groups/body-competition-groups.component';
import { HeadCompetitionCouplesComponent } from './sigma-canvas/modal-competition-couples/head-competition-couples/head-competition-couples.component';
import { HeadCompetitionGroupsComponent } from './sigma-canvas/modal-competition-groups/head-competition-groups/head-competition-groups.component';
import { BodyCompetitionCouplesComponent } from './sigma-canvas/modal-competition-couples/body-competition-couples/body-competition-couples.component';
import { BodyTasksListComponent } from './sigma-canvas/modal-tasks/body-tasks-list/body-tasks-list.component';
import { BodyTasksOptionsComponent } from './sigma-canvas/modal-tasks/body-tasks-options/body-tasks-options.component';
import { HeadTasksComponent } from './sigma-canvas/modal-tasks/head-tasks/head-tasks.component';
import { NodeFormComponent } from './forms/node-form/node-form.component';
import { FileUploaderComponent } from './forms/node-form/file-uploader/file-uploader.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { RouterModule } from '@angular/router';
import { FileUploadModule } from 'ng2-file-upload';
import { NgSwitcheryModule } from 'angular-switchery-ios';
import { WebcamModule } from 'ngx-webcam';
import { NgxPrintModule } from 'ngx-print';
import { PopoverModule } from 'ngx-popover';
import { ChartsModule } from 'ng2-charts';
import { GraphQLModule } from 'src/app/graphql.module';
import { ResizableModule } from 'angular-resizable-element';
import { NgxMaskModule } from 'ngx-mask';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { PlantillaReproducirQuizComponent } from './sigma-canvas/modal-reproducir-quizzes/body-reproducir-quizzes/plantilla-reproducir-quiz/plantilla-reproducir-quiz.component';
import { ModalCursoAutoresComponent } from './cursos/modal-curso-autores';
import { ModalCursoLenguajeComponent } from './cursos/modal-curso-lenguaje';
import { ModalCursoMotivacionesComponent } from './cursos/modal-curso-motivaciones';
import { ModalCursoMotivacionesAutorComponent } from './cursos/modal-curso-motivaciones-autor';
import { ModalCursoMotivacionesEstudiantesComponent } from './cursos/modal-curso-motivaciones-estudiantes';
import { ModalCursoNivelesComponent } from './cursos/modal-curso-niveles';
import { ModalCursoTiempoComponent } from './cursos/modal-curso-tiempo';
import { ModalCursosComponent } from './cursos/modal-cursos';
import { ModalEditarCursoComponent } from './cursos/modal-editar-curso';
import { ModalEditarCursoMapaComponent } from './cursos/modal-editar-curso-mapa';
import { ModalSubirImagenComponent } from './cursos/modal-subir-imagen';
import { ImguploadComponent } from './imgupload/imgupload.component';
import { ShareButtonComponent } from './share-button/share-button.component';
import { ReportButtonComponent } from './report-button/report-button.component';
import { AcceptDialogComponent } from './accept-dialog/accept-dialog.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { ShowModalCourseComponent } from './sigma-menu/show-modal-course/show-modal-course.component';
import { ModalHelpComponent } from './modal-help/modal-help.component';
import { ModalTasksHomeComponent } from './modal-tasks-home/modal-tasks-home.component';
import { SwitchPublishComponent } from './switch-publish/switch-publish.component';
import { ModalCalendarComponent } from './modal-calendar/modal-calendar.component';
import { ModalCertifyComponent } from './modal-certify/modal-certify.component';
import { OperatorsTableComponent } from './sigma-canvas/operators-table/operators-table.component';
import { OperatorIconComponent } from './sigma-canvas/operators-table/components/operator-icon/operator-icon.component';

@NgModule({
    declarations: [
        AlertComponent,
        ConfirmDialogComponent,
        FormsComponent,
        NodeFormComponent,
        FileUploaderComponent,
        IdiomaComponent,
        LoadingComponent,
        PatternsComponent,
        QuizFormComponent,
        RatingComponent,
        SigmaMenuComponent,
        ColorFilterToolbarComponent,
        StaircaseChartComponent,
        HelpToolComponent,
        GraphVisibilityToolbarComponent,
        InfoToolbarComponent,
        MenuFabComponent,
        ModalComponent,
        ModalAceptarCancelarComponent,
        ModalReproducirComponent,
        PatternsComponent,
        PatternDraftComponent,
        PatternFormComponent,
        RatingComponent,
        ModalCompetitionCouplesComponent,
        ModalCompetitionGroupsComponent,
        ModalConectarNodoComponent,
        ModalOperadoresComponent,
        ModalRegistarQuizzesComponent,
        ModalRegistrarNodoComponent,
        ModalReproducirNodoComponent,
        ModalReproducirQuizzesComponent,
        ModalSelectRecipientComponent,
        ModalTasksComponent,
        PowerComponentComponent,
        RegistrarCursoComponent,
        RegistrarNodoComponent,
        BodySelectRecipientComponent,
        HeadSelectRecipientComponent,
        BodyReproducirQuizzesComponent,
        FooterReproducirQuizzesComponent,
        HeadReproducirQuizzesComponent,
        HeadNodesComponent,
        BodyQuizzesComponent,
        FooterQuizzesComponent,
        HeadQuizzesComponent,
        BodyCompetitionGroupsComponent,
        HeadCompetitionGroupsComponent,
        BodyCompetitionCouplesComponent,
        HeadCompetitionCouplesComponent,
        BodyTasksListComponent,
        BodyTasksOptionsComponent,
        HeadTasksComponent,
        PlantillaReproducirQuizComponent,
        ModalCursosComponent,
        ModalEditarCursoComponent,
        ModalEditarCursoMapaComponent,
        ModalSubirImagenComponent,
        ModalCursoLenguajeComponent,
        ModalCursoMotivacionesComponent,
        ModalCursoMotivacionesEstudiantesComponent,
        ModalCursoMotivacionesAutorComponent,
        ModalCursoNivelesComponent,
        ModalCursoTiempoComponent,
        ModalCursoAutoresComponent,
        ClockComponent,
        NodeFormsComponent,
        FileUploadersComponent,
        ImguploadComponent,
        ShareButtonComponent,
        ReportButtonComponent,
        AcceptDialogComponent,
        ShowModalCourseComponent,
        ModalHelpComponent,
        ModalTasksHomeComponent,
        SwitchPublishComponent,
        ModalCalendarComponent,
        ModalCertifyComponent,
        OperatorsTableComponent,
        OperatorIconComponent
    ],
    imports: [
        CommonModule,
        PipesModule,
        TranslateModule,
        MaterialModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        ColorPickerModule,
        NgCircleProgressModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgSwitcheryModule,
        WebcamModule,
        MaterialModule,
        NgxPrintModule,
        PopoverModule,
        ChartsModule,
        GraphQLModule,
        ResizableModule,
        NgxMaskModule,
        AngularDraggableModule
    ],
    exports: [
        AlertComponent,
        ConfirmDialogComponent,
        FormsComponent,
        NodeFormComponent,
        FileUploaderComponent,
        IdiomaComponent,
        LoadingComponent,
        PatternsComponent,
        QuizFormComponent,
        RatingComponent,
        SigmaMenuComponent,
        ColorFilterToolbarComponent,
        StaircaseChartComponent,
        HelpToolComponent,
        GraphVisibilityToolbarComponent,
        InfoToolbarComponent,
        MenuFabComponent,
        ModalComponent,
        ModalAceptarCancelarComponent,
        ModalReproducirComponent,
        PatternsComponent,
        PatternDraftComponent,
        PatternFormComponent,
        RatingComponent,
        ModalCompetitionCouplesComponent,
        ModalCompetitionGroupsComponent,
        ModalConectarNodoComponent,
        ModalOperadoresComponent,
        ModalRegistarQuizzesComponent,
        ModalRegistrarNodoComponent,
        ModalReproducirNodoComponent,
        ModalReproducirQuizzesComponent,
        ModalSelectRecipientComponent,
        ModalTasksComponent,
        PowerComponentComponent,
        RegistrarCursoComponent,
        RegistrarNodoComponent,
        PlantillaReproducirQuizComponent,
        ModalCursosComponent,
        ModalEditarCursoComponent,
        ModalEditarCursoMapaComponent,
        ModalSubirImagenComponent,
        ModalCursoLenguajeComponent,
        ModalCursoMotivacionesComponent,
        ModalCursoMotivacionesEstudiantesComponent,
        ModalCursoMotivacionesAutorComponent,
        ModalCursoNivelesComponent,
        ModalCursoTiempoComponent,
        ModalCursoAutoresComponent,
        ClockComponent,
        NodeFormsComponent,
        FileUploadersComponent,
        ColorPickerModule,
        ImguploadComponent,
        ShareButtonComponent,
        ReportButtonComponent,
        AcceptDialogComponent,
        ShowModalCourseComponent,
        ModalHelpComponent,
        ModalTasksHomeComponent,
        SwitchPublishComponent,
        ModalCalendarComponent,
        ModalCertifyComponent
    ],
    entryComponents: [
        ModalComponent,
        ModalAceptarCancelarComponent,
        PatternsComponent,
        ConfirmDialogComponent,
        ModalRegistrarNodoComponent,
        ModalConectarNodoComponent,
        ModalReproducirNodoComponent,
        ModalOperadoresComponent,
        ModalRegistarQuizzesComponent,
        ModalSelectRecipientComponent,
        ModalTasksComponent,
        PatternFormComponent,
        PatternDraftComponent,
        ModalReproducirQuizzesComponent,
        ModalReproducirComponent,
        ModalCompetitionCouplesComponent,
        ModalCompetitionGroupsComponent,
        ModalCursosComponent,
        ModalEditarCursoComponent,
        ModalEditarCursoMapaComponent,
        ModalSubirImagenComponent,
        ModalCursoLenguajeComponent,
        ModalCursoMotivacionesComponent,
        ModalCursoMotivacionesEstudiantesComponent,
        ModalCursoMotivacionesAutorComponent,
        ModalCursoNivelesComponent,
        ModalCursoTiempoComponent,
        ModalCursoAutoresComponent,
        AcceptDialogComponent,
        ModalHelpComponent,
        ModalTasksHomeComponent,
        ModalCalendarComponent,
        ModalCertifyComponent
    ]
})
export class ComponentsModule { }
