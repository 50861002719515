import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ModalCursosComponent } from 'src/app/shared/components/cursos/modal-cursos/modal-cursos.component';
import { ModalEditarCursoComponent } from 'src/app/shared/components/cursos/modal-editar-curso/modal-editar-curso.component';
import { UserRegisterModel } from '../../models/users';
import { LoginService } from '../../services/login';
import { UsersProfileValidationMapperService } from '../../services/mapper/users-profile-validation-mapper.service';
import { UsersRegisterMapperService } from '../../services/mapper/users-register-mapper.service';
import { TitleService } from '../../services/shared/title.service';
import { UsersService } from '../../services/users';
import { LocalStorage } from '../../utils';
import { Profiles } from '../../utils/profiles.enum';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
    @Input() isSettings = false;

    userData: UserRegisterModel;
    showSigmaMenu: boolean;
    eProfiles = Profiles;

    public constructor(private router: Router,
        private modalService: NgbModal,
        public translateService: TranslateService,
        public loginService: LoginService,
        private usersService: UsersService,
        private localStorage: LocalStorage,
        public titleService: TitleService,
        private usersRegisterMapperService: UsersRegisterMapperService,
        private usersProfileValidationMapperService: UsersProfileValidationMapperService) {

    }

    public ngOnInit() {

        this.traducirOpciones();

        this.obtenerDatosUsuario();

        if (this.router.url.includes('/graph/')) {
            this.showSigmaMenu = true;
        }
    }

    private traducirOpciones() {
        const lang = this.localStorage.getItem('language');
        this.translateService.use(lang);
    }

    private obtenerDatosUsuario() {

        this.usersService.getUserByIdUser(this.loginService.getUser().idUser).subscribe((response: any) => {
            if (response.idUser != 0) {
                // Mapeo los datos del usuario
                this.userData = this.usersRegisterMapperService.transform(response);

                // Los de las validaciones del usuario (si las tiene)
                /*if (response.usersProfileValidation.length > 0) {
                // tslint:disable-next-line: max-line-length
                this.userData.usersProfileValidation = this.usersProfileValidationMapperService.transform(response.usersProfileValidation);
                }*/
            }
        });
    }

    // La funcion que cambia el perfil
    cambiarPerfil(newProfile: Profiles) {

        this.loginService.setProfile(newProfile);

        // Lo registro en el log
        //this.usersService.addUserLog(this.loginService.getUser().idUser, `Cambio perfil [${perfil}] - Header`, 'OK');

        switch (newProfile) {
            case Profiles.Student:
                this.translateService.get('HEADER.ESTUDIANTE').subscribe((res: string) => {
                    this.titleService.barTitle = res;
                });

                return;
            case Profiles.Father:
                this.translateService.get('HEADER.PADRE').subscribe((res: string) => {
                    this.titleService.barTitle = res;
                });

                return;
            case Profiles.Teacher:
                this.translateService.get('HEADER.PROFESOR').subscribe((res: string) => {
                    this.titleService.barTitle = res;
                });

                return;
            case Profiles.Author:
                this.translateService.get('HEADER.AUTOR').subscribe((res: string) => {
                    this.titleService.barTitle = res;
                });

                return;
            case Profiles.Center:
                this.translateService.get('HEADER.CENTRO').subscribe((res: string) => {
                    this.titleService.barTitle = res;
                });

                return;
            case Profiles.Employer:
                this.translateService.get('HEADER.EMPLEADOR').subscribe((res: string) => {
                    this.titleService.barTitle = res;
                });

                return;
            default:
                this.translateService.get('HEADER.USUARIO').subscribe((res: string) => {
                    this.titleService.barTitle = res;
                });

                return;
        }
    }

    // Funcionalidades del Menu principal
    nuevoCurso() {

        if (!this.loginService.esAutor()) {
            // Solo si es autor, si no no hago nada
            return;
        }

        // Lo registro en el log
        //this.usersService.addUserLog(this.loginService.getUser().idUser, `Crear curso nuevo - Header`, 'OK');

        const modalRef = this.modalService.open(ModalEditarCursoComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-100 modal-dialog-100-nuevo'
            });

        modalRef.componentInstance.id = 'nuevo';
        modalRef.result.then((result) => {
            console.log(result);
        }, (reason) => {
        });
    }

    // Cargar Modal de cursos
    verListadoCursos() {
        const modalRef = this.modalService.open(ModalCursosComponent,
            {
                scrollable: true,
                windowClass: 'modal-dialog-95',
                // keyboard: false,
                // backdrop: 'static'
            });

        // Lo registro en el log
        //this.usersService.addUserLog(this.loginService.getUser().idUser, `Ver listado cursos - Header`, 'OK');

        modalRef.result.then((result) => {
            console.log(result);
        }, (reason) => {
        });
    }

    // Cerrar simulacion de ventana modal
    public cerrarVentana() {
        console.log('cerrarVentana')
        const id = Math.floor(Math.random() * (10000));

        // Y lo mando a dashboard
        this.router.navigate(['/dashboard', id]);
    }

    public logOut(): void {
        this.loginService.logout();
    }

}
