import { Injectable } from '@angular/core';

// Service
import { MapperService } from './mapper.service';

// Model
import { UserSettingsProfileModel } from '../../models/users';

@Injectable()
export class UsersSettingsProfileMapperService extends MapperService<UserSettingsProfileModel, any> {

    public map(data: UserSettingsProfileModel): any {
        return {
          idUserSettingProfile: data.idUserSettingProfile,
          idUser: data.idUser,
          profile: data.profile,
          creationDate: data.creationDate,
          creationDateString: data.creationDateString
        };
    }
}
