import { Component, OnInit } from '@angular/core';
//import { ResizeEvent } from 'angular-resizable-element';
import { TranslateService } from '@ngx-translate/core';
import { TemplateElementModel } from './../../../../core/models/quizzes/template.model';
import { TemplateModel } from 'src/app/core/models/quizzes/template.model';
import { TemplatesService } from 'src/app/core/services/templates/templates.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuizzesService } from 'src/app/core/services/quizzes';
import { IResizeEvent } from 'angular2-draggable/lib/models/resize-event';
import { SnackbarService } from 'src/app/core/services/shared/snackbar.service';

const PIXELSY: number = 30
const PIXELSX: number = 30
const SIDESCROLL: number = 10

export interface DragEventModel {
    x: number,
    y: number
}

@Component({
    selector: 'app-pattern-draft',
    templateUrl: './pattern-draft.component.html',
    styleUrls: ['./pattern-draft.component.scss']
})
export class PatternDraftComponent implements OnInit {
    public mouse: { x: number, y: number }
    cargando = false;
    init = false;
    private quiz: any;
    private idTemplate: number;
    public template: TemplateModel;
    public elements: TemplateElementModel[] = [];

    private templateTypes = ['VideoQuestions',
        'ImageQuestions',
        'AudioQuestions',
        'PdfQuestions',
        'TextQuestions',
        'VideoOptions',
        'ImageOptions',
        'AudioOptions',
        'PdfOptions',
        'TextOptions',
        'VideoCorrect',
        'ImageCorrect',
        'AudioCorrect',
        'PdfCorrect',
        'TextCorrect'];

    private elementIcons = ['videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject',
        'videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject',
        'videocam',
        'image',
        'volume_up',
        'picture_as_pdf',
        'subject'];

    constructor(private templatesService: TemplatesService, private activeModal: NgbActiveModal, private modalService: NgbModal,
        private quizService: QuizzesService, private snackBar: SnackbarService, private translateService: TranslateService) {
        this.quizService.currentQuiz.subscribe((q) => { this.quiz = q })
    }

    ngOnInit() {
        this.idTemplate = this.template.idTemplate;
        this.cargando = true;
        this.templatesService.getTemplateElementsByIdTemplate(this.idTemplate).subscribe(result => {
            this.cargando = false;
            this.elements = result.data;

            this.elements.forEach((e, i) => {
                e.style = this.templateTypes[e.elementsType.idElementType];
                e.icon = this.elementIcons[e.elementsType.idElementType];
                e.xPosition = e.xPosition + (i * SIDESCROLL + PIXELSX);
                e.yPosition = e.yPosition + (i * SIDESCROLL + PIXELSY);
            });
        });
    }

    onMoveEnd($ev: DragEventModel, element: TemplateElementModel) {
        element.newxposition = $ev.x
        element.newyposition = $ev.y
    }

    onResizeStop($ev: IResizeEvent, element: TemplateElementModel) {
        element.xSize = $ev.size.width
        element.ySize = $ev.size.height
    }

    saveElements() {

        this.elements.forEach(e => {
            e.xPosition = e.newxposition ? (e.newxposition + e.xPosition) : e.xPosition
            e.yPosition = e.newyposition ? (e.newyposition + e.yPosition) : e.yPosition
            document.getElementById(e.idTemplateElement.toString()).style.transform = 'none'
        })

        this.templatesService.updateTemplateElement(this.elements).subscribe(result => {
            this.templatesService.setFlagTemplateUpdate(true);
            this.snackBar.success(this.translateService.instant('PATTERNS.OKUPDATEDELEMENTS'))

            //OPEN FORM QUIZZES

            // const modalRef = this.modalService.open(ModalRegistarQuizzesComponent,
            // {
            //   scrollable: true,
            //   windowClass: 'modal-dialog-95'
            // });
            // modalRef.componentInstance.node = this.quiz;
            // modalRef.componentInstance.template = this.template;

            this.activeModal.close();
        }, err => {
            this.snackBar.error(this.translateService.instant('PATTERNS.KOUPDATEELEMENTS'))
        })
    }

    closeModal(sendData) {
        this.activeModal.close(sendData);
    }
}
