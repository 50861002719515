import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SigmaToolbarsService } from 'src/app/core/services/sigma-toolbars/sigma-toolbars.service';

@Component({
  selector: 'app-graph-visibility-toolbar',
  templateUrl: './graph-visibility-toolbar.component.html',
  styleUrls: ['./graph-visibility-toolbar.component.scss']
})
export class GraphVisibilityToolbarComponent implements OnInit {

  //@Output() show = new EventEmitter(

  constructor( private toolsService: SigmaToolbarsService ) { }

  ngOnInit() {
  }

  public viewNodes() {
    //this.show.emit({
    this.toolsService.changeVisibility({
      nodes: true,
      quizzes: false
    });
  }

  public viewQuizzes() {
    //this.show.emit({
      this.toolsService.changeVisibility({
      nodes: false,
      quizzes: true
    });
  }

  public viewAll() {
   //this.show.emit({
    this.toolsService.changeVisibility({
      nodes: true,
      quizzes: true
    });
  }

}
