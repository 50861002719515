import { Injectable } from '@angular/core';
// GraphQL
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { QuizzesService } from '../quizzes/quizzes.service';
import { TemplatesService } from '../templates/templates.service';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../../models/shared/api-response.model';
import { QuizStack } from '../../models/quizzes/quiz-stack.model';
import { QuizModel } from '../../models/quizzes';
import { User } from '../../models/users/user.models';

@Injectable({
    providedIn: 'root'
})
export class QuizzesstackService {

    returnQuizzesStackFields = `{
        idQuizzesstack
        idQuiz
        idQuizOriginal
        idCourse
        idTarget
        idUser
        knowledge
        result
        nextQuestion
        asked
        askedString
      }`;

    constructor(
        private apollo: Apollo,
        private datePipe: DatePipe,
        public quizService: QuizzesService,
        public templateService: TemplatesService,
        private httpClient: HttpClient
    ) {

    }

    public createQuizzesStack(quiz: QuizModel, user: User, courseId: number, graphId: number, openTime: number, result: boolean, files: File[]) {
        let body: QuizStack;

        body = {
            idQuiz: quiz.idOriginal,
            idQuizOriginal: quiz.idOriginal,
            idUser: user.idUser,
            asked: openTime,
            answered: Date.now(),
            result: result ? 1 : 0,
            timeCreation: Date.now(),
            idTarget: graphId,
            idCourse: courseId,
            certifiedQuiz: quiz.certifiedQuiz,
            role: 'o',
            answersText: 'a',
        }


        let form: FormData = new FormData();

        form.append('quizzesstack', JSON.stringify(body));

        files.forEach(file => {
            form.append('files', file);
        });

        return this.httpClient.post<ApiResponse<any>>(`answerQuiz`, form);
    }

    public async createQuizzesStackOriginal(quiz: any) {
        var mutation = gql`mutation {
        createQuizzesstack ( quizzesstack: {
              idQuizzesstack:0,
              idQuiz: ${quiz.idQuiz},
              idQuizOriginal: ${quiz.idQuizOriginal},
              idCourse: ${quiz.idCourse},
              idTarget: ${quiz.idTarget},
              idUser: ${quiz.idUser},
              knowledge: ${quiz.knowledge},
              result: ${quiz.result},
              nextQuestion: ${quiz.nextQuestion},
              asked: "${this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss')}",
              askedString: "${this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss').toString()}",
              }) {idQuizzesstack}
            }`;

        var variables = {
            id: quiz.idQuizOriginal, idu: quiz.idUser
        };

        var queryRefetch = gql`query($id: Int!, $idu: Int!) {
                        quizzesstack(where: {idQuizOriginal: $id, idUser: $idu})
                        {
                          nodes ${this.returnQuizzesStackFields}
                        }
                  }`;

        await this.apollo.mutate({
            mutation: mutation,
            refetchQueries: [{
                query: queryRefetch,
                variables: variables
            }]
        }).toPromise().then(y => {
            console.log("TERMINA DE CREAR QUIZZESSTACK", y)
        });
    }

    getQuizzesStackByIdQuizOriginal(quiz: any) {
        const filter = { idQuizOriginal: quiz.idQuizOriginal, idUser: quiz.idUser };
        return this.apollo.query<any>({
            query: gql`query($filter: QuizzesstackFilter) {
             quizzesstack(
                 where: $filter
               ) {
                 nodes ${this.returnQuizzesStackFields}
               }
         }`,
            variables: {
                filter: filter
            }
        });
    }

    updateQuizzesStackOriginal(quizstack: any) {
        var mutation = gql`mutation {
          updateQuizzesstack (
            quizzesstack: {
                idQuizzesstack:${quizstack.idQuizzesstack},
                idQuiz: ${quizstack.idQuiz},
                idQuizOriginal: ${quizstack.idQuizOriginal},
                idCourse: ${quizstack.idCourse},
                idTarget: ${quizstack.idTarget},
                idUser: ${quizstack.idUser},
                knowledge: ${parseFloat(quizstack.knowledge).toFixed(2)},
                result: ${quizstack.result},
                nextQuestion: ${quizstack.nextQuestion},
                asked: "${this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss')}",
                askedString: "${this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss').toString()}",
              })
          }`;
        var variables = {
            id: quizstack.idQuizOriginal, idu: quizstack.idUser
        };
        var queryRefetch = gql`query($id: Int!, $idu: Int!) {
                           quizzesstack(where: {idQuizOriginal: $id, idUser: $idu})
                            {
                              nodes ${this.returnQuizzesStackFields}
                            }
                      }`;

        return this.apollo.mutate({
            mutation: mutation,
            refetchQueries: [{
                query: queryRefetch,
                variables: variables
            }]
        }).toPromise().then(y => {
            console.log("TERMINA DE ACTUALIZAR QUIZZESSTACK", y)
        });
    }

    getQuizzesStackByIdQuiz(idQuiz: number) {
        const filter = { idQuiz: idQuiz };
        return this.apollo.query<any>({
            query: gql`query($filter: QuizzesStackFilter) {
           quizzesstack(
               where: $filter
             ) {
               nodes ${this.returnQuizzesStackFields}
             }
       }`,
            variables: {
                filter: filter
            }
        });
    }

    /*GET QUIZ OF STACK*/
    getQuizStack(userQuiz: any) {
        //Consulto pila para el quizz original ordenada por id de pila
        var nodesqs: any[];
        var nodesqa: any[];
        var quizzesStack: any[];
        var quizzesAlternatives: any[];
        var idQuiz: any;
        var subscribe = this.getQuizzesStackByIdQuizOriginal(userQuiz).subscribe((data) => {
            console.log("quizzesstack : ", data.data.quizzesstack.nodes);
            if (data.data.quizzesstack.nodes.length > 0) {
                nodesqs = data.data.quizzesstack.nodes;
                //ORDER ASC
                quizzesStack = nodesqs.sort(function (a, b) {
                    return a["idQuizzesstack"] - b["idQuizzesstack"];
                });
                var lastQuiz = quizzesStack[quizzesStack.length - 1];
                if (lastQuiz.idQuizOriginal == userQuiz.idQuizOriginal && lastQuiz.result == 0) {
                    var susbQuizQuiz = this.quizService.getQuizzesQuizzesById(userQuiz.idQuizOriginal).subscribe((data) => {
                        if (data.data.quizzesQuizzes.nodes.length > 0) {
                            nodesqa = data.data.quizzesQuizzes.nodes;
                            //ORDER ASC
                            quizzesAlternatives = nodesqa.sort(function (a, b) {
                                return a["idQuizQuiz"] - b["idQuizQuiz"];
                            });
                            var i = 0;
                            var cant = 0;
                            while (cant < quizzesAlternatives.length) {
                                cant++;
                                var indice = this.getIndice(quizzesAlternatives, lastQuiz.idQuiz);
                                if (lastQuiz.result == 0) {
                                    if (indice == -1) {
                                        idQuiz = quizzesAlternatives[0].linkedIdQuiz;
                                        this.showQuizStack(idQuiz, userQuiz);
                                        return true;
                                    }

                                    if (indice < (quizzesAlternatives.length - 1)) {
                                        idQuiz = quizzesAlternatives[indice + 1].linkedIdQuiz;
                                        this.showQuizStack(idQuiz, userQuiz);
                                        return true;
                                    }

                                    if (indice == (quizzesAlternatives.length - 1)) {
                                        idQuiz = lastQuiz.idQuizOriginal;
                                        this.showQuizStack(idQuiz, userQuiz);
                                        return true;
                                    }

                                } else if (lastQuiz.result == 1) {
                                    //Si esta y respondio correctamente
                                    //(preguntar a Ramon)
                                }
                            }
                        } else {
                            // Si no hay alternativos
                            //Se presenta el quiz original
                            idQuiz = userQuiz.idQuizOriginal;
                            this.showQuizStack(idQuiz, userQuiz);
                        }
                        susbQuizQuiz.unsubscribe();
                    });
                } else if (lastQuiz.idQuizOriginal == userQuiz.idQuizOriginal && lastQuiz.result == 1) {
                    //Si esta y respondio correctamente
                    //(preguntar a Ramon)
                }
            } else {
                //Si no esta
                //Se presenta el original
                idQuiz = userQuiz.idQuizOriginal;
                this.showQuizStack(idQuiz, userQuiz);
            }
            subscribe.unsubscribe();
        });
    }

    showQuizStack(idQuiz: any, userQuiz: any) {
        this.quizService.getQuizById(idQuiz).then((quiz) => {
            console.log("DATA OF QUIZ: ", quiz);
            //this.quizService.setArrayQuiz(this.quizzesBloque);
            //this.quizEnviado.emit(quiz);// Solo para el carge el quiz al inicio y carge la plantilla en en body
            quiz.idQuiz = idQuiz;//idQuiz Original o Alternativa
            quiz.idQuizOriginal = userQuiz.idQuizOriginal;//idQuiz Original
            this.quizService.setSelectQuiz(quiz);//Las demas gestiones se hace con este quiz
            this.templateService.setFlagTemplateUpdate(true);
            if (quiz && quiz.templatesQuizzes[0]) {
                /*this.quizService.getUsersQuizzes(userQuiz).then((usersQuizzes)=>{
                  if(usersQuizzes){
                      usersQuizzes.timesDone = usersQuizzes.timesDone + 1;
                  }
                  userQuiz.score=0;
                  this.quizService.createUsersQuizzes(userQuiz,usersQuizzes);
                  this.quizService.setUserQuiz(userQuiz);
               })*/
                userQuiz.score = 0;
                this.quizService.createUsersQuizzes(userQuiz);
                this.quizService.setUserQuiz(userQuiz);
            }
        });
    }

    getIndice(arrayQuiz, idQuiz) {
        var indice = -1;
        arrayQuiz.filter(function (quiz, i) {
            if (quiz.linkedIdQuiz === idQuiz) {
                indice = i;
            }
        });
        return indice;
    }

    /*UPDATE SCORE QUIZ OF STACK*/

}
